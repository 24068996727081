import { useQuery } from '@apollo/react-hooks'
import { pathOr } from 'ramda'

import CURRENT_LOAN_UPLOADER_QUERY from '../graphql/currentLoanUploaderQuery'

const isVerifiedInResponse = pathOr(false, ['currentLoanUploader', 'verified'])

const shouldMock = process.env.REACT_APP_MOCK_API === 'true'

export default function useCurrentLoanUploader() {
  let { error, loading, data } = useQuery(CURRENT_LOAN_UPLOADER_QUERY, {
    skip: shouldMock
  })

  let verified = isVerifiedInResponse(data)

  if (shouldMock) {
    verified = true
    loading = false
  }

  return {
    loading,
    error,
    verified
  }
}
