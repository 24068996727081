import styled from 'styled-components/macro'

const Title = styled.h1`
  font-family: Gotham;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.09px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00023f;
  margin-bottom: 4px;
`

export default Title
