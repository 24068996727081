import React from 'react'
import CustomLink from 'components/CustomLink'
import OuterLink from './styled/OuterLink'
import Outer from './styled/Outer'

export default React.forwardRef(function Link({ active, href, children }, ref) {
  return (
    <Outer>
      <div ref={ref}>
        <CustomLink active={active} as={OuterLink} to={href}>
          {children}
        </CustomLink>
      </div>
    </Outer>
  )
})
