import React from 'react'
import styled from 'styled-components/macro'
import Controller from './Controller'

const ArrowContainer = styled.div`
  width: 100px;
  height: 50px;
  position: absolute;
  overflow: hidden;
  top: -49px;

  ${props =>
    props.position === 'bottom'
      ? `
      left: 50%;
      transform: translateX(-50%) rotate(-180deg);
    `
      : `
      right: -25px;
      transform: rotate(-180deg);
    `}
`

const Arrow = styled.div`
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  transform: rotate(-45deg);
  top: -28px;
  left: 34px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
`

const Flyout = props => {
  const {
    anchor,
    children,
    positionRelativeToAnchor = true,
    onDismiss,
    position,
    show
  } = props

  if (!anchor.current) {
    return null
  }

  return (
    <Controller
      anchor={anchor.current}
      bgColor="white"
      onDismiss={onDismiss}
      positionRelativeToAnchor={positionRelativeToAnchor}
      position={position}
      shouldFocus
      show={show}
    >
      <ArrowContainer position={position}>
        <Arrow />
      </ArrowContainer>
      {children}
    </Controller>
  )
}

export default Flyout
