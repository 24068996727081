import styled from 'styled-components/macro'

const Row = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 1245px) {
    display: block;
    padding: 0 88px;
  }
  @media (max-width: 768px) {
    padding: 0 66px;
  }
  @media (max-width: 630px) {
    padding: 0 17px;
  }
`

export default Row
