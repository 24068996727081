/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import SimpleBtnBase from 'components/SimpleBtn'
import Btn from './styled/Btn'

function SimpleBtn(props) {
  return <SimpleBtnBase {...props} component={Btn} />
}

export default SimpleBtn
