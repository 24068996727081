import { equals, mergeWith } from 'ramda'

function _isObject(x) {
  return Object.prototype.toString.call(x) === '[object Object]'
}

function merger(l, r) {
  if (equals(l, r)) {
    return l
  }
  if (_isObject(l) && _isObject(r)) {
    return mergeWith(merger, l, r)
  }
  return r
}

const run = mergeWith(merger)

export default run
