import 'intersection-observer'
import { useEffect } from 'react'
import { path } from 'ramda'

const getCurrent = path(['current'])
const defaultThreshold = [0, 0.25, 0.5, 0.75, 1]
const defaultRootMargin = '0% 0% -28% 0%'
export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = defaultThreshold,
  rootMargin = defaultRootMargin
}) {
  useEffect(() => {
    const currentRoot = getCurrent(root)
    const currentTarget = getCurrent(target)
    // currentRoot is allowed to be null,
    // because this means we are tracking Viewport
    if (!currentTarget) {
      return
    }

    const observer = new window.IntersectionObserver(onIntersect, {
      root: currentRoot,
      rootMargin,
      threshold
    })

    observer.observe(currentTarget)

    // Let's clean up after ourselves.
    return () => {
      observer.unobserve(currentTarget)
    }
  }, [getCurrent(root), getCurrent(target), onIntersect, threshold, rootMargin])
}
