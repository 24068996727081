import styled from 'styled-components/macro'
import { isNil } from 'ramda'

const WrapperTile = styled.div`
  height: auto;
  transition: width ${({ transitionDurMs }) => transitionDurMs}ms;
  width: ${({ w }) => (isNil(w) ? '236px' : w)};
  ${({ customCss }) => customCss}
`

export default WrapperTile
