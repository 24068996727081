/* eslint no-multi-assign: 0 */
import React, { useMemo, Fragment } from 'react'
import { groupBy } from 'ramda'
import Outer from './styled/Outer'
import LabelOuter from './styled/LabelOuter'
import IconOuter from './styled/IconOuter'
import Icon from './styled/Icon'
import TitleOuter from './styled/TitleOuter'
import Title from './styled/Title'
import Divider from './styled/Divider'

function PersonaLabels({ personaLabels }) {
  const plGroups = useMemo(() => {
    return Object.values(groupBy(({ group = 0 }) => group, personaLabels))
  }, [personaLabels])

  return (
    <Outer>
      {plGroups.map((plGroup, idx) => (
        <Fragment key={idx}>
          {plGroup.map(({ id, title, iconPath, color }) => (
            <PersonaLabel
              key={id}
              title={title}
              iconPath={iconPath}
              color={color}
            />
          ))}
          {idx !== plGroups.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Outer>
  )
}

export default PersonaLabels

function PersonaLabel({ title, iconPath, color }) {
  return (
    <LabelOuter>
      <IconOuter>
        <Icon bgColor={color} src={iconPath} />
      </IconOuter>
      <TitleOuter>
        <Title>{title}</Title>
      </TitleOuter>
    </LabelOuter>
  )
}
