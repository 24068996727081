import styled from 'styled-components/macro'
import PrettyBtn from 'components/PrettyBtn'

const FooterPopBtn = styled(PrettyBtn)`
  @media (max-width: 750px) {
    width: 100vw;
    border-radius: 0;
  }
`

export default FooterPopBtn
