import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const CenterText = styled(animated.div)`
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin: auto;
  text-align: center;
  font-weight: 200;
  font-size: 48px;
  letter-spacing: -1.4px;
`

export default CenterText
