import React from 'react'
import Outer from './styled/Outer'
import Title from './styled/Title'
import Subtitle from './styled/Subtitle'

function IntroText({
  style,
  borrowerFirstName,
  borrowerLastName,
  loanOfficerFullName
}) {
  const loFirstName = (loanOfficerFullName || '').split(' ')[0]
  return (
    <Outer style={style}>
      <Title>Hello, {loFirstName}</Title>
      <Subtitle>
        Please wait while I prepare {borrowerFirstName} {borrowerLastName}'s
        loan recommendation.
      </Subtitle>
    </Outer>
  )
}

export default IntroText
