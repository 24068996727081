import {
  SHOW_MODAL,
  HIDE_MODAL
} from './constants'

export function showModal(modal){
  return dispatch => {
		dispatch({
      type: SHOW_MODAL,
      payload: {modal}
    })
  }
}

export function hideModal(){
  return dispatch => {
		dispatch({
      type: HIDE_MODAL
    })
  }
}

export default {
  showModal,
  hideModal
}