/**
 * CHANGES:
 * WE NO LONGER RETURN A PLAIN PROMISE,
 * WE RETURN AN OBJECT WITH A PROMISE AND A CANCEL FUNCTION:
 * const {promise, cancel} = queue.add(func)

 * It limits concurrently executed promises
 *
 * @param {Number} [maxPendingPromises=Infinity] max number of concurrently executed promises
 * @param {Number} [maxQueuedPromises=Infinity]  max number of queued promises
 * @constructor
 *
 * @example
 *
 * var queue = new Queue(1)
 *
 * queue.add(function () {
 *     // resolve of this promise will resume next request
 *     return downloadTarballFromGithub(url, file)
 * })
 * .then(function (file) {
 *     doStuffWith(file)
 * })
 *
 * queue.add(function () {
 *     return downloadTarballFromGithub(url, file)
 * })
 * // This request will be paused
 * .then(function (file) {
 *     doStuffWith(file)
 * })
 */
const noop = () => {}

/**
 * @param {*} value
 * @returns {LocalPromise}
 */
const resolveWith = value => {
  if (value && typeof value.then === 'function') {
    return value
  }

  return new Promise(resolve => {
    resolve(value)
  })
}

const Queue = (
  maxPendingPromisesOpt = Infinity,
  maxQueuedPromisesOpt = Infinity,
  opts = {}
) => {
  let options = opts
  let pendingPromises = 0
  let maxPendingPromises = maxPendingPromisesOpt
  let maxQueuedPromises = maxQueuedPromisesOpt
  let queue = []

  const dequeue = () => {
    if (pendingPromises >= maxPendingPromises) {
      return false
    }

    // Remove from queue
    let item = queue.shift()
    if (!item) {
      if (options.onEmpty) {
        options.onEmpty()
      }
      return false
    }

    try {
      pendingPromises++

      let createPromise = item.promiseGenerator

      if (item.isCancelled()) {
        createPromise = noop
      }

      resolveWith(createPromise())
        // Forward all stuff
        .then(
          value => {
            // It is not pending now
            pendingPromises--
            // It should pass values
            item.resolve(value)
            dequeue()
          },
          err => {
            // It is not pending now
            pendingPromises--
            // It should not mask errors
            item.reject(err)
            dequeue()
          },
          message => {
            // It should pass notifications
            item.notify(message)
          }
        )
    } catch (err) {
      pendingPromises--
      item.reject(err)
      dequeue()
    }

    return true
  }

  const add = promiseGenerator => {
    let didCancel

    function isCancelled() {
      return didCancel
    }

    function cancel() {
      didCancel = true
    }
    const promise = new Promise((resolve, reject, notify) => {
      // Do not queue to much promises
      if (queue.length >= maxQueuedPromises) {
        reject(new Error('Queue limit reached'))
        return
      }
      // Add to queue
      queue.push({
        promiseGenerator,
        resolve,
        reject,
        notify: notify || noop,
        isCancelled
      })

      dequeue()
    })

    return {
      cancel,
      isCancelled,
      promise
    }
  }

  return {
    add
  }
}

export default Queue
