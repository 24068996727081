import styled, { keyframes } from 'styled-components/macro'
import { animated } from 'react-spring'

const rotate = keyframes`
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
`

const Inner = styled(animated.div)`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  animation: ${rotate} 30s infinite linear;
  transform-origin: 50% 50%;
`

export default Inner
