import styled from 'styled-components/macro'

const PreviewArea = styled.div`
  flex: 1;
  @media (max-width: 1030px) {
    display: none;
  }
`

export default PreviewArea
