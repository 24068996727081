import useMeasure from 'lib/useMeasure'

export default function useCalcDropdownOffsets({ positionerRef, dropdownRef }) {
  let {
    boundingClientRect: { top = 0, x: positionerLeft = 0 },
    firstRender,
    scrollY,
    windowInnerWidth
  } = useMeasure(positionerRef, { watchWindowResize: true })

  const {
    boundingClientRect: { width: dropdownWidth }
  } = useMeasure(dropdownRef, { watchWindowResize: true })

  const offScreenRightOffset = positionerLeft + dropdownWidth - windowInnerWidth

  let left = positionerLeft

  if (offScreenRightOffset > -40) {
    left = left - offScreenRightOffset - 40
  }

  const styles = {
    // if we are on the first render then the computation
    // for the offsets of useMeasure are 0, so don't show the
    // dropdown yet
    opacity: firstRender ? 0 : 1,
    top: `${scrollY + top + 35}px`,
    left: `${left}px`,
    position: 'absolute'
  }

  return styles
}
