import { useState, useCallback, useContext, useEffect } from 'react'
import { isNil, path } from 'ramda'
import useKeysDownTracker from 'lib/useKeysDownTracker'
import useCaretPosition from '../useCaretPosition'
import { PrivateContext, FormContext } from '../useForm/Context'

function useManageFocus() {
  const [focused, setFocus] = useState(false)

  const onFocus = useCallback(() => {
    setFocus(true)
  }, [])

  const onBlur = useCallback(() => {
    setFocus(false)
  }, [])

  return {
    focused,
    onFocus,
    onBlur
  }
}

function useManageChange() {
  const [values, setValue] = useState({ value: '', prevValue: '' })
  const { value, prevValue } = values

  const manageOnChange = useCallback(
    e => {
      setValue({ prevValue: value, value: e.target.value })
    },
    [value]
  )

  const manageSetValue = useCallback(
    ({ value: v, prevValue: pv }) => {
      // use value for prevValue here unless explicitly set!
      setValue({
        value: isNil(v) ? value : v,
        prevValue: isNil(pv) ? value : pv
      })
    },
    [value, prevValue]
  )

  return {
    ...values,
    setValue: manageSetValue,
    onChange: manageOnChange
  }
}

const createUseFormatOn = ({ eventName, formatter }) => ({
  value,
  prevValue,
  setCaretPosition,
  setValue,
  getImmediateCaretPosition
}) => {
  const manageOnEvent = useCallback(
    (e, config = {}) => {
      if (!formatter) {
        return
      }

      const res = formatter({
        event: e,
        value,
        prevValue,
        getImmediateCaretPosition,
        ...config
      })
      if (!res) {
        return
      }
      // debugger
      if (!isNil(res.value) && res.value !== e.target.value) {
        setValue(res)
      }
      if (!isNil(res.caret)) {
        console.log('setting caret to', res.caret)
        setCaretPosition({ caret: res.caret })
      }
    },
    [
      value,
      prevValue,
      setCaretPosition,
      setValue,
      getImmediateCaretPosition,
      formatter
    ]
  )

  return {
    [eventName]: manageOnEvent
  }
}

// const createUseFormatOn = ({eventName, formatter}) => ({ value, setValue }) => {
//   const manageFormatOn = useCallback(
//     e => {
//       if (!formatter) {
//         return
//       }
//       const res = formatter({ event: e, value })
//       if (!res) {
//         return
//       }
//       if (!isNil(res.value) && res.value !== value) {
//         setValue(res)
//       }
//     },
//     [value, setValue, formatter]
//   )

//   return {
//     [eventName]: manageFormatOn
//   }
// }

const mergeResultAndAccumEventHandlers = (...funcs) => data => {
  const initEventHandlers = {
    onChange: [],
    onClick: [],
    onKeyDown: [],
    onFocus: [],
    onBlur: [],
    onKeyUp: []
  }
  const eventHandlerNames = Object.keys(initEventHandlers)

  return funcs.reduce(
    ({ result, eventHandlers }, func) => {
      const out = func(result)
      eventHandlerNames.forEach(eventName => {
        const handler = out[eventName]
        if (handler) {
          delete out[eventName]
          eventHandlers[eventName].push(handler)
        }
      })

      return { result: { ...out, ...result }, eventHandlers }
    },
    { result: data, eventHandlers: initEventHandlers }
  )
}

function useSetOnValueChangeErrors() {
  const [onValueChangeErrors, setOnValueChangeErrors] = useState()

  return {
    setOnValueChangeErrors,
    onValueChangeErrors
  }
}

const createOnValueChangeValidate = ({ onValueChangeValidate }) => ({
  value,
  onValueChangeErrors,
  setOnValueChangeErrors
}) => {
  useEffect(() => {
    if (onValueChangeValidate) {
      const res = onValueChangeValidate(value)

      const errors = path(['errors'], res) || res

      if (errors !== onValueChangeErrors) {
        setOnValueChangeErrors(errors)
      }
    }
  }, [onValueChangeValidate, value])

  return {}
}

export function createUseInput({ withContext } = {}) {
  return function useInput({
    name,
    formatOnType,
    formatOnBlur,
    formatOnChange,
    onValueChangeValidate
  } = {}) {
    if (withContext && !name) {
      throw new Error(
        "When using createUseInput({withContext: true}), useInput MUST be given an object as an argument with the key 'name': value."
      )
    }
    let out = {}
    const [firstRender, setFirstRender] = useState(true)

    out = {
      ...out,
      firstRender
    }

    let { result, eventHandlers } = mergeResultAndAccumEventHandlers(
      useSetOnValueChangeErrors,
      useManageFocus,
      useManageChange,
      useCaretPosition,
      () => {
        const {
          trackKeyDown: onKeyDown,
          trackKeyUp: onKeyUp
        } = useKeysDownTracker()
        return { onKeyDown, onKeyUp }
      },
      // formatting on type is special so it has it's own implementation
      createUseFormatOn({ eventName: 'onKeyDown', formatter: formatOnType }),
      createUseFormatOn({ eventName: 'onBlur', formatter: formatOnBlur }),
      createUseFormatOn({ eventName: 'onChange', formatter: formatOnChange }),
      createOnValueChangeValidate({ onValueChangeValidate })
    )(out)
    Object.entries(eventHandlers).forEach(([eventName, handlers]) => {
      result = {
        ...result,
        [eventName]: e => {
          let res
          handlers.forEach(handler => {
            res = handler(e, res)
          })
        }
      }
    })

    if (withContext) {
      const { setContext } = useContext(PrivateContext)
      const { name: formName } = useContext(FormContext)
      setContext({
        data: {
          [formName]: {
            [name]: {
              focused: result.focused,
              value: result.value,
              onValueChangeErrors: result.onValueChangeErrors
            }
          }
        }
      })
    }

    const {
      getImmediateCaretPosition,
      onValueChangeErrors,
      ...publicOut
    } = result

    if (firstRender) {
      setFirstRender(false)
    }

    return publicOut
  }
}

const useInput = createUseInput({ withContext: true })

export default useInput
