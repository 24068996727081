import React, { useMemo, memo, useCallback, useEffect, useRef } from 'react'
import { IconTileWithLabels } from 'components/Tiles'
import { format } from 'date-fns'
import useComputeDimsFromAuto from 'lib/useComputeDimsFromAuto'
import useMouseEnterLeaveState from 'lib/useMouseEnterLeaveState'
import fileThemeConfig, {
  secureIconPath
} from 'domains/DocManager/fileThemeConfig'
import { last, head } from 'ramda'
import { css } from 'styled-components/macro'
import useCanDeleteFileProcessor from 'domains/DocManager/useable/useCanDeleteFileProcessor'
import OuterTile from '../../styled/OuterTile'
import Message from './components/Message'
import ActionDropdown from './components/ActionDropdown'
import InnerTile from './styled/InnerTile'
import WrapperTile from './styled/WrapperTile'

const iconCss = css`
  width: 33px;
  height: 33px;
`
const secureIconCss = css`
  width: 11px;
  height: 11px;
`

const emptyObj = {}
export default memo(function FileTile({
  file: {
    id,
    fileData: { name, type },
    uploadDate,
    progress,
    status,
    errorType,
    frontendValidationErrors,
    cancel: cancelUpload,
    deleteUntil
  },
  deleteFileById,
  fileRemovalCompleteById,
  delayFadeMs,
  outerTileCss,
  wrapperTileCss,
  ringsOuterCss
}) {
  const uploadText = useMemo(() => {
    return format(uploadDate, 'MMM dd, yyyy h:mm aaa')
  }, [uploadDate])

  const { iconPath, text } = useMemo(() => {
    const ext = last(type.split('/'))
    return fileThemeConfig[ext] || emptyObj
  }, [type])

  const renderMessage = useCallback(() => {
    if (status === 'queued') {
      return (
        <Message key="queued" dots="true">
          Waiting
        </Message>
      )
    }
    if (status === 'uploading') {
      return (
        <Message key="uploading" dots>
          Uploading
        </Message>
      )
    }
    // this status is when the "multi upload itself"
    // has completed, but we have not received the actual
    // 200 response from the server indicating that the
    // file has made it to s3
    if (status === 'finalizing') {
      return (
        <Message key="finalizing" dots="true">
          Finalizing
        </Message>
      )
    }
    // file upload has made it to the server, may or may
    // not have made it to s3 yet or gotten a 200 resp
    // (possibly was previously in 'finalizing')
    // so still show 'Deleting' text
    if (status === 'deleting') {
      return (
        <Message key="uploading" dots>
          Deleting
        </Message>
      )
    }
    if (status === 'removing') {
      return (
        <Message key="removing" dots>
          Removing
        </Message>
      )
    }

    if (frontendValidationErrors && frontendValidationErrors.length) {
      const { errorMessage } = head(frontendValidationErrors)
      return <Message key="frontendValidationErrors">{errorMessage}</Message>
    }
    if (errorType === 'NETWORK_ERROR') {
      return <Message key={errorType}>Internet connection problem.</Message>
    }
    if (errorType === 'UPLOAD_SIZE_ERROR') {
      return (
        <Message key={errorType}>
          Your file exceeds the maximum size limit of 10 MB.
        </Message>
      )
    }
    if (errorType === 'INTERNAL_SERVER_ERROR') {
      return <Message key={errorType}>Uh oh. Unexpected error.</Message>
    }
    if (errorType) {
      return <Message key="UNKNOWN_ERROR">hmmm. Unkown error.</Message>
    }
  }, [status, errorType, frontendValidationErrors])

  const {
    isOver,
    handleMouseEnter,
    handleMouseLeave
  } = useMouseEnterLeaveState()

  const { canDelete } = useCanDeleteFileProcessor({
    run: !!deleteUntil,
    deleteUntil
  })

  let topRightElement
  if (canDelete) {
    topRightElement = (
      <ActionDropdown
        id={id}
        status={status}
        moveUp={isOver}
        cancelUpload={cancelUpload}
        deleteFileById={deleteFileById}
      />
    )
  }

  let removalDurMs = 300

  let innerTileTransY = '0px'
  let innerTileOpacity = 1
  let wrapperTileWidth = '236px'

  if (status === 'removing') {
    innerTileTransY = '60px'
    innerTileOpacity = 0
    wrapperTileWidth = '0px'
  }

  useEffect(() => {
    let t

    if (status === 'removing') {
      t = setTimeout(() => {
        fileRemovalCompleteById(id)
      }, removalDurMs)
    }
    return () => t && clearTimeout(t)
  }, [status, id])

  const wrapperTileRef = useRef()
  const { height: wrapperMobileNaturalHeight } = useComputeDimsFromAuto({
    ref: wrapperTileRef,
    monitorWidth: false,
    deps: [status]
  })

  return (
    <WrapperTile
      w={wrapperTileWidth}
      transitionDurMs={removalDurMs}
      customCss={wrapperTileCss}
      ref={wrapperTileRef}
      status={status}
      style={{
        height: wrapperMobileNaturalHeight
      }}
    >
      <OuterTile delay={delayFadeMs} customCss={outerTileCss}>
        <InnerTile
          transY={innerTileTransY}
          opacity={innerTileOpacity}
          transitionDurMs={removalDurMs}
        >
          <IconTileWithLabels
            title={name}
            note={uploadText}
            cardText={text}
            iconTopLeftPath={iconPath}
            iconTopLeftCss={iconCss}
            iconBottomRightPath={secureIconPath}
            iconBottomRightCss={secureIconCss}
            progressFraction={progress}
            status={status}
            renderMessage={renderMessage}
            ringsOuterCss={ringsOuterCss}
            topRightElement={topRightElement}
            isOverCard={isOver}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </InnerTile>
      </OuterTile>
    </WrapperTile>
  )
})
