import React, { useEffect } from 'react'
// import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'
// import useLogout from 'domains/CurrentUser/useable/useLogout'
// import uploadIconPath from 'components/Notifications/images/upload-icon.png'
import useCurrentLoanUploader from 'domains/DocManager/hocs/withUploadCodeLogin/useable/useCurrentLoanUploader'
import useCurrentLoanUploaderLogout from 'domains/DocManager/hocs/withUploadCodeLogin/useable/useCurrentLoanUploaderLogout'
// import CircleAndName from './components/CircleAndName'
// import Divider from './styled/Divider'
import { ErrorNotification } from 'components/Notifications'
import { useNotificationsActions } from 'lib/notifications'
import { path, isNil } from 'ramda'
import useShowErrorNotificationForResponseError from 'domains/shared/useable/useShowErrorNotificationForResponseError'
import AuthArea from './components/AuthArea'
import Outer from './styled/Outer'

// const emptyObj = {}
export default function MemberNavRightContent() {
  const { upsertNote } = useNotificationsActions()

  // const logout = useLogout()
  // let [{ currentUser, isLoadingComputed }] = useCurrentUser()
  // currentUser = currentUser || emptyObj
  // const isLoggedIn = !!currentUser.id
  const {
    verified,
    loading: currentLoanUploaderLoading
    // error: currentLoanUploaderError
  } = useCurrentLoanUploader()

  const { error: logoutNetworkError } = useCurrentLoanUploaderLogout()

  useShowErrorNotificationForResponseError({
    id: 'UPLOADER_LOGIN_NETWORK_ERROR',
    serverError: logoutNetworkError
  })

  useEffect(() => {
    const statusCode = path(['networkError', 'statusCode'], logoutNetworkError)
    if (!isNil(statusCode)) {
      upsertNote(
        {
          id: 'LOGOUT_NETWORK_ERROR',
          textPaddingRight: '30px',
          component: ErrorNotification,
          stretchToMaxWidth: '452px',
          tag: 'error',
          mainText: 'Uh oh. There was a problem logging you out.',
          subtext:
            'Wait a few seconds and try again. Ensure your internet connection in working.'
        },
        { prepend: true }
      )
    }
  }, [logoutNetworkError])

  /*
   *  TEMPORARY
   */
  // const isLoggedIn = true
  // const isLoadingComputed = false
  // const currentUser = {}

  return (
    <Outer>
      <AuthArea isLoading={currentLoanUploaderLoading} isLoggedIn={verified} />
    </Outer>
  )
}

// {isLoggedIn && (
//   <Fragment>
//     <CircleAndName
//       isLoading={isLoadingComputed}
//       isLoggedIn={isLoggedIn}
//       {...currentUser}
//     />
//     <Divider />
//   </Fragment>
// )}
