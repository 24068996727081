import styled from 'styled-components/macro'

const InputOuter = styled.div`
  margin-bottom: 50px;
  @media (max-width: 1030px) {
    margin-bottom: 60px;
  }
`

export default InputOuter
