import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const RingBase = styled(animated.div)`
  border-radius: 50%;
  border: 1px solid #4100f5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`

export default RingBase
