import useBrowseFiles from '../useBrowseFiles'
import useEvents from '../useEvents'

export default function useDroppable({
  onReceiveFiles,
  onDrop,
  documentDragLeave
}) {
  const eventsConfig = useEvents({ onReceiveFiles, onDrop, documentDragLeave })
  const browseFilesConfig = useBrowseFiles({ onReceiveFiles })

  return {
    ...eventsConfig,
    ...browseFilesConfig
  }
}
