import React from 'react'
// import RandomColor from './randomColor'

const UserGenAvatar = ({ currentUser, size }) => {
  // eslint-disable-next-line
  const avatarSize = size === 'small' ? 38 : size === 'tiny' ? 32 : 42
  const initials = currentUser.email.substring(0, 2).toUpperCase()
  //   const initials = currentUser.username.substring(0, 2).map((name) => name[0].toUpperCase()).slice(0,2)
  // const bgColor = RandomColor({
  //   count: 1,
  //   luminosity: 'dark',
  //   seed: currentUser.id
  // })[0]
  const fontSize = avatarSize * 0.32
  return (
    <svg height={avatarSize} width={avatarSize}>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
        <stop stopColor="#2FE4D0" offset="0%" />
        <stop stopColor="#0DC5B1" offset="100%" />
      </linearGradient>
      <rect
        fill="url(#linearGradient-1)"
        x="0"
        y="0"
        height={avatarSize}
        width={avatarSize}
      />
      <g>
        <text
          fill="#ffffff"
          fontSize={fontSize}
          x="50%"
          y="50%"
          dy=".38em"
          textAnchor="middle"
        >
          {initials}
        </text>
      </g>
    </svg>
  )
}

UserGenAvatar.propTypes = {}

export default UserGenAvatar
