import React from 'react'

export default function ZillowIcon() {
  return (
    <svg
      width="19px"
      height="15px"
      viewBox="0 0 19 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-181.000000, -1.000000)" fill="#00023F">
          <path d="M181.807264,16 C182.481778,15.704978 188.878097,13.5968828 194.758566,13.2473891 C194.886532,12.7563484 195.531944,11.1114616 195.649147,10.6637597 C189.546233,11.0947622 184.585846,12.8986906 182.592602,13.8131792 L181.807264,16 Z M191.357693,1 C190.301672,1.48626942 187.263968,3.80946827 185.62911,5.02931665 C187.618766,4.37207761 192.403747,3.32001802 194.852647,3.26355829 C194.08764,2.72838361 191.937328,1.28110587 191.357693,1 L191.357693,1 Z M182.824616,13.178206 C184.695873,11.2709007 191.422673,5.12235593 191.422673,5.12235593 C187.923334,5.93108201 184.269717,7.03045645 182.963345,7.61851243 L181,9.61925463 C182.640041,9.27135132 183.170244,9.20336108 184.369778,9.02881302 L182.824616,13.178206 Z M195.310296,3.58005089 C195.310296,3.58005089 189.372821,9.48764778 188.44357,10.335339 C190.248652,9.68843768 193.94891,8.92066479 196.296154,8.78229868 C196.56883,7.95687324 196.857452,7.35967237 196.857452,7.35967237 L200,7.04914383 C200,7.04914383 197.612492,5.13706727 195.310296,3.58005089 L195.310296,3.58005089 Z" />
        </g>
      </g>
    </svg>
  )
}
