import { gql } from 'graphql.macro'

export default gql`
  fragment LoanOfficerFragment on LoanOfficer {
    id
    name
    jobTitle
    mobilePhone
    workPhone
    email
    token
    nmlsId
    isActive
    avatarUrl
    avatar {
      original
      thumb
    }
  }
`
