/**
 *
 * Landing.js
 *
 */

import React from 'react'
import DocumentDatagrid from 'domains/BizDash/Shared/components/Datagrid';

const ErrorDocuments = () => <DocumentDatagrid filter={['error', 'shutdown']} />

export default ErrorDocuments