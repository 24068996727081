import styled, { keyframes, css } from 'styled-components/macro'

const shake = ({ shakeFlag }) => keyframes`
  ${
    shakeFlag === true
      ? css`
          0% {
            transform: translateX(0px);
          }
          33% {
            transform: translateX(10px);
          }
          66% {
            transform: translateX(-10px);
          }
          100% {
            transform: translateX(0px);
          }
        `
      : shakeFlag === false
      ? css`
          0% {
            transform: translateX(0px);
          }
          33% {
            transform: translateX(10px);
          }
          67% {
            transform: translateX(-10px);
          }
          100% {
            transform: translateX(0px);
          }
        `
      : undefined
  }
`

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  animation: ${shake} 0.4s ease ${({ delay }) => delay || 0}s forwards;
  transition: all 0.4;
`

export default Wrapper
