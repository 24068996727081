import React from "react";
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgMailto = props => (
  <ResponsiveSvg wRatio={68} hRatio={68} maxWidth={300} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 68 68">
      <defs>
        <circle id="mailto_svg__a" cx={20} cy={20} r={20} />
        <filter
          x="-54.6%"
          y="-52.1%"
          width="209.3%"
          height="209.3%"
          filterUnits="objectBoundingBox"
          id="mailto_svg__b"
        >
          <feMorphology
            radius={0.357}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feMorphology radius={1} in="SourceAlpha" result="shadowInner" />
          <feOffset dy={1} in="shadowInner" result="shadowInner" />
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={7}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.145098039 0 0 0 0 0.149019608 0 0 0 0 0.368627451 0 0 0 0.1 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g transform="translate(14 13)" fill="none" fillRule="evenodd">
        <mask id="mailto_svg__c" fill="#fff">
          <use xlinkHref="#mailto_svg__a" />
        </mask>
        <use
          fill="#000"
          filter="url(#mailto_svg__b)"
          xlinkHref="#mailto_svg__a"
        />
        <use stroke="#4DFFC9" strokeWidth={0.714} xlinkHref="#mailto_svg__a" />
        <g
          mask="url(#mailto_svg__c)"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M14.4 15h11.2c.77 0 1.4.619 1.4 1.375v8.25c0 .756-.63 1.375-1.4 1.375H14.4c-.77 0-1.4-.619-1.4-1.375v-8.25c0-.756.63-1.375 1.4-1.375z" />
          <path d="M27 16.375l-7 4.813-7-4.813" />
        </g>
      </g>
    </svg>
  </ResponsiveSvg>
);

export default SvgMailto;
