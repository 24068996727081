import { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
// import { findIndex, propEq, remove, path } from 'ramda'
import { map, assoc, reduce } from 'ramda'
import PromiseQueue from 'lib/promiseQueue'

import UPLOAD_DOCS from '../uploadDocsMuation'

export default function useUploadDocs(maxConcurrency = 4) {
  const uploadPromiseQueue = useRef(null)
  const [fileUploadStatus, setFileProgress] = useState({})
  // const [cancellables, setCancellables] = useState({})
  const [uploadDocsMutation] = useMutation(UPLOAD_DOCS)

  useEffect(() => {
    uploadPromiseQueue.current = PromiseQueue(maxConcurrency)
  }, [])

  const uploadDocs = async ({ input = {}, uploads }) => {
    // Set inital progress status for the upload keys provided
    setFileProgress(fp =>
      reduce(
        (accum, { id: uploadId }) =>
          assoc(uploadId, { status: 'pending', progress: 0 }, accum),
        fp,
        uploads
      )
    )

    map(
      upload => async () => {
        // TODO: curry this into the fn rather than using this scope
        const { id: uploadId, file } = upload
        try {
          const resp = await uploadDocsMutation({
            variables: { input, uploads: [file] },
            context: {
              fetchOptions: {
                useUpload: true,
                onProgress: ({ loaded, total }) => {
                  setFileProgress(fp =>
                    assoc(
                      uploadId,
                      { status: 'uploading', progress: loaded / total },
                      fp
                    )
                  )
                },
                onAbort: () => {
                  setFileProgress(fp =>
                    assoc(uploadId, { status: 'aborted', progress: 0 }, fp)
                  )
                },
                onSuccess: () => {
                  setFileProgress(fp =>
                    assoc(uploadId, { status: 'success', progress: 1 }, fp)
                  )
                },
                onStart: () => {
                  setFileProgress(fp =>
                    assoc(uploadId, { status: 'uploading', progress: 0 }, fp)
                  )
                },
                onError: () => {
                  setFileProgress(fp =>
                    assoc(uploadId, { status: 'error', progress: 0 }, fp)
                  )
                }
                // possibly send abort message to xhr
                // onAbortPossible: (abortHandler) => {

                // }
              }
            }
          })
          return resp
        } catch (e) {
          if (e.graphQLErrors) {
            e.graphQLErrors.forEach(err => {
              if (err.extensions.code === 'UNAUTHENTICATED') {
                console.log('redirect to login')
                setFileProgress(fp =>
                  assoc(uploadId, { status: 'error', progress: 0 }, fp)
                )
              }
            })
          }
        }
      },
      uploads
    )

    // setCancellables(allCancellables)
  }

  return {
    fileUploadStatus,
    uploadDocs
  }
}
