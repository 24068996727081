import React, { useReducer, useCallback, memo, useEffect } from 'react'
import useSafeDispatch from 'lib/useSafeDispatch'
import qs from 'query-string'
import { useGetUrl } from 'lib/UrlTracker'
import { mergeDeepRight, isNil } from 'ramda'
import {
  isHttpResponse,
  responseNotOk,
  convertStatusToGeneralError
} from 'domains/shared/utils/responseHelpers'
import axios from 'axios'
import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'
import FeedbackSection from './components/FeedbackSection'
import FeedbackModal from './components/FeedbackModal'
import Inner from './styled/Inner'
import Outer from './styled/Outer'

const SET_MODAL_OPEN = 'SET_MODAL_OPEN'
const SET_MODAL_SLIDE = 'SET_MODAL_SLIDE'
const SET_CONSUME_SLIDE_UPDATE = 'SET_CONSUME_SLIDE_UPDATE'
const SET_INPUT = 'SET_INPUT'
const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING'
const UPDATE_DATA = 'UPDATE_DATA'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case SET_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: payload.status
      }
    }
    case SET_MODAL_SLIDE: {
      return {
        ...state,
        modalSlide: payload.slide,
        consumeSlideUpdate: null
      }
    }
    case SET_CONSUME_SLIDE_UPDATE: {
      return {
        ...state,
        consumeSlideUpdate: payload.action
      }
    }
    case SET_IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: payload.isSubmitting
      }
    }
    case UPDATE_DATA: {
      return mergeDeepRight(state, payload)
    }
    case SET_INPUT: {
      return {
        ...state,
        inputData: {
          ...state.inputData,
          ...payload
        }
      }
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    modalOpen: false,
    inputData: {
      feedbackCorrect: null,
      feedbackText: ''
    },
    frontendErrors: {},
    backendError: null
  }
}

const slidesConfig = {
  NOT_SET: {
    getNext: ({ inputData: { feedbackCorrect } }) =>
      feedbackCorrect ? 'DONE' : 'READY_TO_LEARN'
  },
  READY_TO_LEARN: {
    getNext: () => 'DONE'
  }
}

function getSlide(state) {
  let { modalSlide, consumeSlideUpdate } = state
  modalSlide = modalSlide || 'NOT_SET'
  const callable = slidesConfig[modalSlide][consumeSlideUpdate]
  return callable && callable(state)
}

const noop = () => {}

// loaiData
function Feedback({ loaiData, updateLocalLoanRecommendationData = noop }) {
  const { queryStringParams: { loai_token: loaiToken } = {} } = useGetUrl()
  let [state, dispatch] = useReducer(reducer, {}, init)
  const {
    modalOpen,
    modalSlide,
    consumeSlideUpdate,
    inputData,
    isSubmitting,
    frontendErrors,
    backendError,
    wantsToChangeResponse
  } = state

  const { feedbackCorrect: storedFeedbackCorrect } = loaiData

  const traverseToNextSlide = useCallback(() => {
    dispatch({
      type: SET_CONSUME_SLIDE_UPDATE,
      payload: {
        action: 'getNext'
      }
    })
  }, [])

  dispatch = useSafeDispatch(dispatch)

  const updateData = payload => {
    dispatch({
      type: UPDATE_DATA,
      payload
    })
  }

  async function submitFeedback(sendData) {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: {
        isSubmitting: true
      }
    })
    const { feedbackCorrect, feedbackText } = sendData

    try {
      await axios.patch(
        `${LOWER_API_DOMAIN}/loai-results/${
          loaiData.id
        }/feedback?${qs.stringify({ loai_token: loaiToken })}`,
        {
          feedbackCorrect,
          feedbackText
        }
      )
      updateData({
        isSubmitting: false,
        backendError: null,
        frontendErrors: []
      })
      updateLocalLoanRecommendationData({ feedbackCorrect, feedbackText })
    } catch (e) {
      if (responseNotOk(e)) {
        updateData({
          isSubmitting: false,
          backendError: e,
          frontendErrors: []
        })
      }
      // always rethrow, the caller needs to handle
      // what to do regarding traversal
      throw e
    }
  }

  const onFeedbackCorrectStatusBtnClick = async status => {
    const correct = status === 'THUMBS_UP'
    let newInputData = { feedbackCorrect: correct }
    // if correct is true,
    // we want to wipe out feedback text
    if (correct) {
      newInputData = {
        ...newInputData,
        feedbackText: null
      }
    }

    dispatch({
      type: SET_INPUT,
      payload: newInputData
    })

    // we also want to submit immediately if
    // feedbackCorrect=true (we don't need to ask any more questions)
    if (correct) {
      try {
        await submitFeedback(newInputData)
      } catch (e) {
        if (isHttpResponse(e)) {
          // we don't want to traverse
          // and we don't want to open the modal
          // when we couldn't save the desired
          // feedbackCorrect=true
          return
        }
        // rethrow for non-http response
        throw e
      }
    }

    // if feedbackCorrect=false,
    // remove any backendError,
    // the user is starting over,
    // so open modal and traverse
    updateData({
      backendError: null
    })

    traverseToNextSlide()
    dispatch({
      type: SET_MODAL_OPEN,
      payload: {
        status: true
      }
    })
  }

  useEffect(() => {
    if (consumeSlideUpdate) {
      const slide = getSlide(state)
      dispatch({
        type: SET_MODAL_SLIDE,
        payload: {
          slide
        }
      })
    }
  }, [consumeSlideUpdate])

  const onFeedbackTextInputChange = useCallback(({ target: { value } }) => {
    dispatch({
      type: SET_INPUT,
      payload: {
        feedbackText: value
      }
    })
  }, [])

  const onReadyToLearnSubmit = async e => {
    e.preventDefault()
    const { feedbackText } = inputData

    if (!feedbackText || feedbackText.length < 10) {
      updateData({
        backendError: null,
        frontendErrors: [
          {
            field: 'feedbackText',
            message: 'Please provide a response with at least 10 characters.'
          }
        ]
      })
      return
    }
    if (feedbackText && feedbackText.length >= 2000) {
      updateData({
        backendError: null,
        frontendErrors: [
          {
            field: 'feedbackText',
            message: 'Please provide a response with less than 2000 characters.'
          }
        ]
      })
      return
    }

    await submitFeedback(inputData)
    traverseToNextSlide()
  }

  function onCloseBtnClick() {
    updateData({
      // reset everything
      isSubmitting: false,
      backendError: null,
      frontendErrors: [],
      inputData: { feedbackCorrect: null, feedbackText: '' },
      modalOpen: false,
      modalSlide: null,
      wantsToChangeResponse: false
    })
  }

  function onChangeMyResponseCancelClick() {
    updateData({
      wantsToChangeResponse: false
    })
  }

  function onChangeMyResponseClick() {
    updateData({
      wantsToChangeResponse: true
    })
  }

  const generalError = convertStatusToGeneralError(backendError, 'saving')
  const didAlreadySubmit = !isNil(storedFeedbackCorrect)

  return (
    <>
      <FeedbackModal
        loadingSlide={!!consumeSlideUpdate}
        modalOpen={modalOpen}
        modalSlide={modalSlide}
        onFeedbackTextInputChange={onFeedbackTextInputChange}
        inputData={inputData}
        onReadyToLearnSubmit={onReadyToLearnSubmit}
        isSubmitting={isSubmitting}
        submitErrors={frontendErrors}
        generalError={generalError}
        onCloseBtnClick={onCloseBtnClick}
        onDoneClick={onCloseBtnClick}
      />
      <Outer>
        <Inner>
          <FeedbackSection
            onFeedbackCorrectStatusBtnClick={onFeedbackCorrectStatusBtnClick}
            generalError={generalError}
            didAlreadySubmit={didAlreadySubmit}
            wantsToChangeResponse={wantsToChangeResponse}
            onChangeMyResponseClick={onChangeMyResponseClick}
            onChangeMyResponseCancelClick={onChangeMyResponseCancelClick}
          />
        </Inner>
      </Outer>
    </>
  )
}

export default memo(Feedback)
