/* eslint no-multi-assign: 0 */
import React from 'react'
import NestedProgressRings from 'components/NestedProgressRings'
import BlockQuoteMetric from 'components/BlockQuoteMetric'
import { css } from 'styled-components/macro'
import { reverse } from 'ramda'
import Outer from './styled/Outer'
import RingsOuter from './styled/RingsOuter'
import RingsInner from './styled/RingsInner'
import PersonIcon from './styled/PersonIcon'
import graphIconPath from './svg/graph-icon.svg'
import Header from '../Shared/Header'
import MetricRow from '../Shared/styled/MetricRow'
import MetricItem from '../Shared/styled/MetricItem'
import NoPeersMatch from './styled/NoPeersMatch'

const firstMetricItemCss = css`
  @media (max-width: 700px) {
    margin-bottom: 50px;
  }
`

const middleMetricItemCss = css`
  @media (max-width: 700px) {
    min-width: 0;
    margin-bottom: 50px;
  }
  @media (max-width: 370px) {
    flex: auto;
    width: 100%;
    margin-bottom: 50px;
  }
`

const lastMetricItemCss = css`
  @media (max-width: 700px) {
    flex: auto;
    width: 100%;
    margin-bottom: 0;
  }
`

const ringsMeta = [
  {
    minWidth: '165px',
    css: firstMetricItemCss
  },
  {
    minWidth: '157px',
    css: middleMetricItemCss
  },
  {
    minWidth: '157px',
    css: lastMetricItemCss
  }
]

function PeerMatch({ rings }) {
  const ringsDesc = reverse(rings)
  return (
    <Outer>
      <Header
        title="Peer match"
        iconImagePath={graphIconPath}
        marginBottom="58px"
      />
      {rings.length ? (
        <>
          <RingsOuter>
            <RingsInner>
              <NestedProgressRings rings={ringsDesc} />
              <PersonIcon />
            </RingsInner>
          </RingsOuter>
          <MetricRow>
            {rings.map(({ id, total, title, ringColor }, idx) => (
              <MetricItem
                key={id}
                minWidth={ringsMeta[idx].minWidth}
                css={ringsMeta[idx].css}
              >
                <BlockQuoteMetric
                  sideType="dot"
                  sideColor={ringColor}
                  metricText={total}
                  metricLabel="peers"
                  note={title}
                />
              </MetricItem>
            ))}
          </MetricRow>
        </>
      ) : (
        <NoPeersMatch>
          There are no similar peers in conjunction with this loan's dataset.
        </NoPeersMatch>
      )}
    </Outer>
  )
}

export default PeerMatch
