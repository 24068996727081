import { useEffect } from 'react'
import { useDropdownsActions } from 'lib/DropdownsManager/useDropdowns'

export default function DropdownPortal({ id, positionerRef, render }) {
  const { showDropdown, hideDropdown } = useDropdownsActions()

  useEffect(() => {
    showDropdown({ id, positionerRef, render })
  }, [id, positionerRef, render])

  useEffect(() => {
    return () => hideDropdown(id)
  }, [id, hideDropdown])

  return null
}
