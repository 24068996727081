import { useState, useCallback } from 'react'

export default function useMouseEnterLeaveState() {
  const [isOver, setIsOver] = useState(false)

  const handleMouseEnter = useCallback(
    function handleMouseEnter() {
      if (!isOver) {
        setIsOver(true)
      }
    },
    [isOver]
  )

  const handleMouseLeave = useCallback(
    function handleMouseLeave() {
      if (isOver) {
        setIsOver(false)
      }
    },
    [isOver]
  )

  return {
    isOver,
    handleMouseEnter,
    handleMouseLeave
  }
}
