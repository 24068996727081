import { gql } from 'graphql.macro'

export default gql`
  mutation {
    signOutCurrentLoanUploader {
      success
      errors {
        field
        message
      }
    }
  }
`
