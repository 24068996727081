import React from 'react'
import { ErrorNotification } from 'components/Notifications'

export default function ErrorProgressNotification({
  groupId,
  uploadsComplete,
  totalFiles,
  totalFilesError,
  ...restProps
}) {
  const mainText =
    totalFilesError && totalFiles === 1
      ? `The file upload failed!`
      : totalFilesError === totalFiles
      ? `All ${totalFilesError} files failed!`
      : `${totalFilesError} of ${totalFiles} files failed.`

  return (
    <ErrorNotification
      {...restProps}
      stretchToMaxWidth="453px"
      textPaddingRight="30px"
      autoDismissAfterMs={5000}
      mainText={mainText}
      subtext="Please see the individual errors for each file in the current session."
    />
  )
}
