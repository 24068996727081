import styled from 'styled-components/macro'
// import { animated } from 'react-spring'

const TitleArea = styled.div`
  position: relative;
  margin-bottom: 100px;
  text-align: center;
  transform: ${({ transform }) => transform};
  opacity: ${({ opacity }) => opacity};
  transition: ${({ config: { duration = 1000, easing = 'ease-out' } }) =>
    `${duration}ms ${easing}`};
`

export default TitleArea
