import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import List from './List'

export default {
  // if you need to customize these items in the mainNavigation,
  // then utilize props below and add deps to the array at bottom of useMemo
  // (useMemo is needed here otherwise our sidebar will be rerender EVERY TIME
  // an unrelated render happens)
  mainNavigation: () =>
    /* props */

    useMemo(() => {
      return {
        header: {
          label: 'Loan Officers',
          route: '/loanofficers',
          active: ({ location: { pathname } }) => {
            return pathname.includes('/loanofficers')
          }
        }
      }
    }, []),
  routes: () => (
    <Switch>
      <Route exact path="/loanofficers" component={List} />
    </Switch>
  )
}
