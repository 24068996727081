import { useContext, useEffect } from 'react'
import { ActionContext } from '../Context'

export default function useHideOnClickoutOfDropdown(id, dropdownRef) {
  const { hideDropdown } = useContext(ActionContext)

  useEffect(() => {
    function handleClick(e) {
      hideDropdown(id)
    }
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [dropdownRef.current, id])
}
