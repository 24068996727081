import { useQuery } from '@apollo/react-hooks'

import GET_LOAN_PURPOSES from '../LoanPurposesQuery'

export default function useApplicationCampaigns() {
  const { loading, errors, data } = useQuery(GET_LOAN_PURPOSES, {
    fetchPolicy: 'cache-and-network'
  })

  return {
    loading,
    fetchErrors: errors,
    data
  }
}
