import styled from 'styled-components/macro'

const TextOuter = styled.div`
  flex: 1;
  margin-top: ${({ marginTop }) => marginTop || 0};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  padding-right: ${({ paddingRight }) => paddingRight || '0px'};
`

export default TextOuter
