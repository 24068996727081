/* eslint no-multi-assign: 0 */
import React from 'react'

function MailBtn() {
  return (
    <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1228.000000, -36.000000)">
          <g transform="translate(39.000000, 37.000000)">
            <g transform="translate(1190.000000, 0.000000)">
              <circle
                stroke="#4100F5"
                strokeWidth="1.15368008"
                opacity="0.75"
                cx="20"
                cy="20"
                r="20"
              />
              <path
                d="M28.0952381,15.9193942 L20.1992321,20.3854812 L11.9047619,15.8852456 L11.9047619,15.748 C11.9047619,14.7826063 12.6873682,14 13.6527619,14 L26.3472381,14 C27.3126318,14 28.0952381,14.7826063 28.0952381,15.748 L28.0952381,15.9193942 Z M28.0952381,17.9276313 L28.0952381,24.252 C28.0952381,25.2173937 27.3126318,26 26.3472381,26 L13.6527619,26 C12.6873682,26 11.9047619,25.2173937 11.9047619,24.252 L11.9047619,17.8739517 L20.2168567,22.3837496 L28.0952381,17.9276313 Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MailBtn
