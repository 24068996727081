import styled, { keyframes } from 'styled-components/macro'

const move = keyframes`
  from {
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const OuterTile = styled.div`
  width: 200px;
  margin: 0 18px;
  margin-bottom: 36px;
  transform: translateY(60px);
  opacity: 0;
  animation: ${move} 0.4s ease ${({ delay }) => delay || 0}ms;
  animation-fill-mode: forwards;
  ${({ customCss }) => customCss}
`

export default OuterTile
