import React, { memo } from 'react'
import DotTextDotAnimator from 'components/DotTextDotAnimator'

const height = 11

export default memo(function Message({ dots, children }) {
  return (
    <DotTextDotAnimator hideDots={!dots} height={height}>
      <span style={{ display: 'inline-block' }}>{children}</span>
    </DotTextDotAnimator>
  )
})
