import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import useCurrentUser from '../useable/useCurrentUser'

const ForceAuthenticate = ({
  render,
  redirectToPath,
  children,
  location,
  allowedAfterLoginPathRgx,
  ...restProps
}) => {
  const [{ currentUser, loading, fetchCurrentUserError }] = useCurrentUser()

  if (loading || (!currentUser && !fetchCurrentUserError)) {
    // THIS SHOULD RETURN A LOADING INDICATOR
    return null
  }
  if (!currentUser) {
    let search
    if (
      allowedAfterLoginPathRgx &&
      allowedAfterLoginPathRgx.test(location.pathname)
    ) {
      search = `?after_login_path=${location.pathname}`
    }
    return (
      <Redirect
        to={{
          pathname: redirectToPath,
          search,
          state: { from: location }
        }}
      />
    )
  }
  return render(restProps)
}

export default withRouter(ForceAuthenticate)
