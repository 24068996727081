import styled from 'styled-components/macro'

const Outer = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 73px 0 55px;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 750px) {
    padding: 0 7px 0 23px;
  }
`

export default Outer
