import { from as fromJS } from 'seamless-immutable'

import { SHOW_MODAL, HIDE_MODAL } from './constants'

// The initial state of the App
export const initialState = fromJS({
  currentModal: null
})

function setModal(state, { payload: { modal } }) {
  return state.setIn(['currentModal'], modal)
}

function removeModal(state) {
  return state.setIn(['currentModal'], null)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return setModal(state, action)
    case HIDE_MODAL:
      return removeModal(state, action)
    default:
      return state
  }
}
