import styled from 'styled-components/macro'

const Row = styled.div`
  display: flex;
  max-width: ${({ tighten }) => (tighten ? '843px' : '1043px')};
  margin: 0 auto;
  @media (max-width: 1160px) {
    max-width: 729px;
  }
  @media (max-width: 810px) {
    max-width: 470px;
    display: block;
  }
  @media (max-width: 560px) {
    max-width: 434px;
    padding: 0 32px;
  }
`

export default Row
