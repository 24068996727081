import styled from 'styled-components/macro'

const HeaderCell = styled.div`
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  ${props => props.theme.font('medium')}
`

export default HeaderCell
