import styled from 'styled-components/macro'

const Sentence = styled.h1`
  font-family: Gotham;
  font-weight: 200;
  font-size: 48px;
  line-height: 54px;
  color: white;
  margin-bottom: 40px;
`

export default Sentence
