import styled from 'styled-components/macro'

const Label = styled.h3`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.19px;
  color: white;
  flex: 1;
`

export default Label
