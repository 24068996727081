/* eslint no-param-reassign: 0 */
export default function createCancellableTimelineAsync(asyncFunc, ref) {
  function create() {
    let didCancel = false
    const func = () => {
      function call(callable, ...args) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          if (didCancel) {
            reject(new Error('CANCELLED'))
            return
          }
          const res = await Promise.resolve(callable(...args))
          if (didCancel) {
            reject(new Error('CANCELLED'))
            return
          }
          resolve(res)
        })
      }
      function cancel() {
        didCancel = true
      }
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        const res = await asyncFunc({ call, cancel })
        resolve(res)
      })
    }
    func.cancel = () => {
      didCancel = true
    }
    func.isCancelled = () => {
      return didCancel
    }

    return func
  }

  if (!ref.current || ref.current.isCancelled()) {
    // console.log('creating new function')
    ref.current = create()
  } else {
    // console.log('not creating function')
  }
  return ref.current
}
