import styled from 'styled-components/macro'

const UnderGraphLabel = styled.div`
  display: block;
  width: 100%;
  flex: 1;
  transform: translateX(${({ translateX }) => translateX || 0}%);
`

export default UnderGraphLabel
