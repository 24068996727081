/* eslint no-unused-expressions: 0 */
import React from 'react'
import useMouseEnterLeaveState from 'lib/useMouseEnterLeaveState'
import Btn, { SubmitBtn } from './styled/Btn'
import useArrowRight from './usable/useArrowRight'

function PrettyBtn({
  display,
  width,
  maxWidth,
  fontSize,
  textAlign,
  padding,
  onClick,
  arrowRight,
  component,
  children,
  preventDefault,
  as: asForwarded,
  outerCss,
  className
}) {
  function handleClick(e) {
    if (asForwarded !== 'button' && preventDefault !== false) {
      e.preventDefault()
    }
    onClick && onClick()
  }

  const {
    handleMouseEnter,
    handleMouseLeave,
    isOver
  } = useMouseEnterLeaveState()

  let btnStyles = {}
  let arrowRightElement = null

  ;({ element: arrowRightElement, parentStyles: btnStyles } = useArrowRight({
    arrowRight,
    parentStyles: btnStyles,
    transRight: isOver
  }))

  let Comp = asForwarded === 'button' ? SubmitBtn : Btn
  if (component) {
    Comp = component
  }

  return (
    <Comp
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      display={display}
      width={width}
      maxWidth={maxWidth}
      textAlign={textAlign}
      fontSize={fontSize}
      padding={padding}
      {...btnStyles}
      customCss={outerCss}
      className={className}
    >
      {children}
      {arrowRightElement}
    </Comp>
  )
}

export default PrettyBtn
