/* eslint prefer-destructuring: 0 */
import { createContext } from 'react'

const Context = createContext()
const Provider = Context.Provider

const PrivateContext = createContext()
const PrivateProvider = PrivateContext.Provider

const FormContext = createContext()
const FormProvider = FormContext.Provider

export {
  Context,
  Provider,
  PrivateContext,
  PrivateProvider,
  FormContext,
  FormProvider
}
