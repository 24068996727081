import styled from 'styled-components/macro'

const TriangleAreaOuter = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

export default TriangleAreaOuter
