import React, { useCallback } from 'react'
import Outer from './styled/Outer'
import GroupTitle from './styled/GroupTitle'
import BtnGroup from './styled/BtnGroup'
import BtnBase from './styled/BtnBase'

export default function MultiToggleGroup({ category, group, onBtnClick }) {
  return (
    <Outer>
      <GroupTitle>{category}</GroupTitle>
      <BtnGroup>
        {group.map(item => (
          <Btn {...item} onClick={onBtnClick} />
        ))}
      </BtnGroup>
    </Outer>
  )
}

function Btn({ selected, text, meta, value, onClick }) {
  const handleClick = useCallback(
    e => {
      e.preventDefault()
      onClick({ selected: !selected, value, meta })
    },
    [onClick, selected, value, meta]
  )

  return (
    <BtnBase selected={selected} onClick={handleClick}>
      {text}
    </BtnBase>
  )
}
