/**
 *
 * Landing.js
 *
 */

import React from 'react'
import styled from 'styled-components/macro'
import PageNav from 'domains/BizDash/Shared/components/Sheet/HeaderNav'

import module from '../index'

const ActivityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const Activity = ({ match: { url } }) => (
  <ActivityPageWrapper>
    <PageNav navItems={module.pageNavigation()} />
    {module.routes(url)}
  </ActivityPageWrapper>
)

export default Activity
