import styled from 'styled-components/macro'

const Outer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'rgba(0, 0, 0, 0.3)'};
  border-radius: ${({ borderRounded }) => (borderRounded ? '4px' : 0)};
`

export default Outer
