import styled from 'styled-components/macro'
import { isNil } from 'ramda'

const InnerTile = styled.div`
  transform: translateY(${({ transY }) => (isNil(transY) ? '0px' : transY)});
  transition: all ${({ transitionDurMs }) => transitionDurMs}ms;
  opacity: ${({ opacity }) => (isNil(opacity) ? 1 : opacity)};
`

export default InnerTile
