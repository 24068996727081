import styled from 'styled-components/macro'

const Halo = styled.div`
  border-radius: 50%;
  box-shadow: inset 0 0 150px rgba(65, 0, 245, 0.15),
    0 0 20px rgba(65, 0, 245, 0.25);
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 98%;
  height: 98%;
`

export default Halo
