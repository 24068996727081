import { useEffect } from 'react'

export default function useDebounce(func, { ms, deps = [] }) {
  let id
  function clear() {
    if (id) {
      clearTimeout(id)
      id = null
    }
  }

  useEffect(() => {
    clear()
    id = setTimeout(func, ms)

    return () => {
      clear(id)
    }
  }, [ms, ...deps])

  return {
    clear
  }
}
