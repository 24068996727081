import styled from 'styled-components/macro'

const TriangleBorderShadow = styled.div`
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid #eeeeee;
  position: absolute;
  top: 0.5px;
  left: 50%;
  transform: translateX(-50%);
`

export default TriangleBorderShadow
