import { createGlobalStyle } from 'styled-components/macro'
import { path } from 'ramda'

const GlobalStyle = createGlobalStyle`
  html, body, #root{
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
  body {
    background-color: ${props =>
      path(['styles', 'body', 'bgColor'], props) || 'white'};

    font-family: Gotham;
  }

  body h1, body h2, body h3, body h4, body h5, body h6 {
    margin: 0;
  }
  body *:focus{
    outline: none;
  }

  a, button {
    cursor: pointer;
  }
  p{
    margin: 0 0 10px 0;
  }
`

export default GlobalStyle
