import React, { useState, useCallback } from 'react'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import BtnContainer from './styled/BtnContainer'

export default function EditSaveCancel({
  neverShowCancel,
  neverShowDelete,
  isEditing,
  onSaveClick,
  onCancelClick,
  onEditClick,
  saveText,
  onDelete
}) {
  const [tryingToDelete, setTryingToDelete] = useState(false)

  const handleDelete = useCallback(() => {
    if (tryingToDelete) {
      onDelete()
      setTryingToDelete(false)
    } else {
      setTryingToDelete(true)
    }
  }, [tryingToDelete, setTryingToDelete, onDelete])

  return (
    <BtnContainer>
      {isEditing && (
        <SimpleBtn onClick={onSaveClick}>{saveText || 'Save'}</SimpleBtn>
      )}
      {isEditing ? (
        !neverShowCancel && (
          <SimpleBtn onClick={onCancelClick}>Cancel</SimpleBtn>
        )
      ) : (
        <SimpleBtn onClick={onEditClick}>Edit</SimpleBtn>
      )}
      {!isEditing && !neverShowDelete && (
        <SimpleBtn onClick={handleDelete}>
          {tryingToDelete ? 'Delete: You sure?' : 'Delete'}
        </SimpleBtn>
      )}
    </BtnContainer>
  )
}
