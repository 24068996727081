import { useCallback, useEffect, useState } from 'react'
import { useActions } from 'lib/MeasureDeep/useable'
import useMeasure from 'lib/useMeasure'

export default function MeasureDeep({
  id,
  disable,
  measureOnIdResizes,
  monitor,
  children: childFunc
}) {
  const [refState, setRefState] = useState({ current: null })

  const setRef = useCallback(el => {
    setRefState({ current: el })
  }, [])
  const bounds = useMeasure(refState, { disable, monitor })
  const { unregister, updateItem } = useActions()

  const {
    contentRect: { x, y, width, height, top, right, bottom, left },
    boundingClientRect: {
      x: xBCR,
      y: yBCR,
      width: widthBCR,
      height: heightBCR,
      top: topBCR,
      right: rightBCR,
      bottom: bottomBCR,
      left: leftBCR
    } = {}
  } = bounds

  useEffect(() => {
    updateItem(id, {
      contentRect: { x, y, width, height, top, right, bottom, left },
      boundingClientRect: {
        x: xBCR,
        y: yBCR,
        width: widthBCR,
        height: heightBCR,
        top: topBCR,
        right: rightBCR,
        bottom: bottomBCR,
        left: leftBCR
      },
      measureOnIdResizes,
      ref: refState.current
    })
  }, [
    id,
    x,
    y,
    width,
    height,
    top,
    right,
    bottom,
    left,
    xBCR,
    yBCR,
    widthBCR,
    heightBCR,
    topBCR,
    rightBCR,
    bottomBCR,
    leftBCR,
    measureOnIdResizes,
    refState.current
  ])

  useEffect(() => {
    return () => unregister(id)
  }, [id])

  return childFunc(setRef)
}
