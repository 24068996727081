import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'

import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import CampaignForm from 'domains/BizDash/Campaigns/CampaignForm'
import { campaignFormValidation } from '../../validation'

const LoanOfficerListItemContainer = styled.div`
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`

const LoanOfficerListItemFormContainer = styled.div`
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
`

const Label = styled.div`
  flex: 1 1 auto;
`

const LoanOfficerListItem = ({
  applicationCampaign,
  campaignTypes,
  idx,
  onDelete,
  onSave
}) => {
  const [isEditingItem, setIsEditingItem] = useState(false)

  const onEditClick = useCallback(() => setIsEditingItem(true), [])
  const onCancelClick = useCallback(() => setIsEditingItem(false), [])
  const onDeleteClick = useCallback(() => onDelete(applicationCampaign.id), [])

  return (
    <div>
      {isEditingItem ? (
        <LoanOfficerListItemFormContainer>
          <CampaignForm
            idx={idx}
            applicationCampaign={applicationCampaign}
            campaignTypes={campaignTypes}
            validate={campaignFormValidation}
            onCancel={onCancelClick}
            onSubmit={onSave}
          />
        </LoanOfficerListItemFormContainer>
      ) : (
        <LoanOfficerListItemContainer>
          <Label>{applicationCampaign.name}</Label>
          <SimpleBtn onClick={onEditClick}>Edit</SimpleBtn>
          <SimpleBtn onClick={onDeleteClick}>Delete</SimpleBtn>
        </LoanOfficerListItemContainer>
      )}
    </div>
  )
}

export default LoanOfficerListItem
