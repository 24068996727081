import styled from 'styled-components/macro'

const Pretitle = styled.h3`
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: 0.25px;
`

export default Pretitle
