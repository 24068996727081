import React, { Fragment } from 'react'
import Area from 'components/QuickEditor/components/Sentence/styled/Area'
import { filter, prop } from 'ramda'

export default function PersonaCodeDisplayer({ originalValue }) {
  const selectedPersonaCodes = filter(prop('selected'), originalValue)

  return (
    <Area>
      {!selectedPersonaCodes.length
        ? 'No codes selected'
        : selectedPersonaCodes.map(({ text, key }, i) => (
            <Fragment key={key}>
              {text}
              {i !== selectedPersonaCodes.length - 1 && '-'}
            </Fragment>
          ))}
    </Area>
  )
}
