import styled from 'styled-components/macro'

const Circle = styled.div`
  position: relative;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor || 'grey'};
`

export default Circle
