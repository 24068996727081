/**
 *
 * Landing.js
 *
 */

import React from 'react'
import DocumentDatagrid from 'domains/BizDash/Shared/components/Datagrid';

const AllDocuments = () => <DocumentDatagrid />


export default AllDocuments