import React from 'react'
import { useHistory } from 'react-router-dom'

export default function CustomLink({ as: Comp, to, ...rest }) {
  let history = useHistory()

  function onClick(e) {
    e && e.preventDefault()
    if (to) {
      history.push(to)
    }
  }

  return (
    <Comp onClick={onClick} href={to.pathname ? to.pathname : to} {...rest} />
  )
}
