import React, {
  useLayoutEffect,
  useEffect,
  useCallback,
  useState,
  memo
} from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import {
  GOOGLE_ANALYTICS_UA,
  GOOGLE_ANALYTICS_CONFIG
} from 'domains/shared/utils/googleAnalyticsConfig'
import useGlobalStyleAdjuster from 'domains/shared/GlobalStyle/usable/useGlobalStyleAdjuster'
import { useNotificationsActions } from 'lib/notifications'
import { InfoNotification } from 'components/Notifications'
import uploadIconPath from 'components/Notifications/images/upload-icon.png'
import { useDroppable, useActions as useDroppableActions } from 'lib/Droppable'
import useSyncChange from 'lib/useSyncChange'
import { last, propEq, compose } from 'ramda'
import DocsCurrent from './components/DocsCurrent'
import DocsList from './components/DocsList'
import Outer from './styled/Outer'
import HeaderArea from './styled/HeaderArea'
import Title from './styled/Title'
import BodyArea from './styled/BodyArea'
import TitleArea from './styled/TitleArea'
import DocsNav from './components/DocsNav'
import FullScreenDropArea from './components/FullScreenDropArea'
import useUploadFiles from './useable/useUploadFiles'
import ProgressNotification from './components/ProgressNotification'
import ErrorProgressNotification from './components/ErrorProgressNotification'
import useComputeCurrentUploadStats, {
  useComposeFileGroupsForStats
} from './useable/useComputeCurrentUploadStats'
import withUploadCodeLogin from './hocs/withUploadCodeLogin'
import useValidateFileOnChange from './useable/useValidateFileOnChange'
import withGalaxyIntroAnimation from './hocs/withGalaxyIntroAnimation'
import withUploadThankYou from './hocs/withUploadThankYou'
import withLegacyBrowserWarning from './hocs/withLegacyBrowserWarning'
import FooterDoneBtn from './components/FooterDoneBtn'

const permittedFiles = [
  'jpg',
  'jpeg',
  'png',
  'pdf',
  'msword',
  'vnd.openxmlformats-officedocument.wordprocessingml.document'
]

function DocManager({
  match: { url: matchUrl },
  location,
  isIntroOverlayVisible
}) {
  const { updateStyles } = useGlobalStyleAdjuster()
  const { upsertNote, dismissingNote } = useNotificationsActions()

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_UA, GOOGLE_ANALYTICS_CONFIG)
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactGA.event({
      category: 'Session',
      action: 'Login Or Refresh Session'
    })
  }, [])

  useLayoutEffect(() => {
    updateStyles({
      body: {
        bgColor: '#F7F8FA'
      }
    })
  }, [updateStyles])

  useEffect(() => {
    if (!isIntroOverlayVisible) {
      upsertNote(
        {
          id: 'DOC_UPLOADS_HOW_TO',
          component: InfoNotification,
          stretchToMaxWidth: '562px',
          textOuterMaxWidth: '542px',
          textPaddingRight: '30px',
          mainText: 'Drag your documents onto the browser window to start.',
          subtext:
            'We only accept plain Word, JPG, PNG, and PDF file types. Please do not password-protect these files.',
          iconPath: uploadIconPath
        },
        { prepend: true }
      )
    }
    return () => dismissingNote('DOC_UPLOADS_HOW_TO')
  }, [isIntroOverlayVisible])

  const {
    files,
    filesById,
    queueNewFiles,
    updateFiles,
    deleteFileById,
    fileRemovalCompleteById
  } = useUploadFiles()
  const filesNeedValidating = files.filter(propEq('status', 'queued'))

  const { validatorRunner } = useValidateFileOnChange({
    files: filesNeedValidating,
    updateFiles,
    validateFile: useCallback(
      ({
        file: {
          fileData: { size, type }
        },
        markValid,
        markInvalid
      }) => {
        const errors = []

        if (size > 15000000) {
          console.log('too big!', size)
          errors.push({
            errorType: 'FILE_SIZE_TOO_BIG',
            errorMessage: 'File too large.'
          })
          ReactGA.event({
            category: 'UploadFiles',
            action: 'File too large'
          })
        }
        type = last(type.split('/'))

        if (!permittedFiles.includes(type)) {
          // console.log('wrong file type!', type)
          errors.push({
            errorType: 'UNSUPPORTED_FILE_TYPE',
            errorMessage: 'Unsupported file type.'
          })
          ReactGA.event({
            category: 'UploadFiles',
            action: 'Unsupported file type'
          })
        }

        if (errors.length) {
          markInvalid(errors)
        } else {
          // console.log('right size!', size)
          markValid()
        }
      },
      []
    )
  })

  const { fileGroups } = useComposeFileGroupsForStats(filesById)

  const onReceiveFiles = useCallback((newFiles, { dropped }) => {
    if (!dropped) {
      //   console.log('dropped files!', newFiles)
      //   queueNewFiles(newFiles)
      // } else {
      // console.log('natively selected files!', newFiles)
      queueNewFiles(newFiles)
    }
  }, [])

  let {
    browseFiles,
    isHoveringOver,
    droppedFiles,
    handlers: { onDragEnter, onDragOver, onDrop, onDragLeave }
  } = useDroppable({ onReceiveFiles })

  const { update, unregister } = useDroppableActions()

  const [dropAnimationStatus, setDropAnimationStatus] = useState()
  const onDropAnimationStatusChange = useCallback(status => {
    setDropAnimationStatus(status)
  }, [])

  const [
    droppedFilesReadyToProcess,
    setDroppedFilesReadyToProcess
  ] = useSyncChange(droppedFiles)

  useEffect(() => {
    if (droppedFilesReadyToProcess.length && dropAnimationStatus === 'hide') {
      // console.log('queueing!')
      setDroppedFilesReadyToProcess([])
      queueNewFiles(droppedFiles)
    }
  }, [droppedFilesReadyToProcess, dropAnimationStatus])

  // console.log(dropAnimationStatus)
  useEffect(() => {
    update({
      id: 'DOC_UPLOAD_DROP_AREA',
      FULL_SCREEN: true,
      render: () => (
        <FullScreenDropArea
          isHoveringOver={isHoveringOver}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          droppedFiles={droppedFiles}
          onAnimationStatusChange={onDropAnimationStatusChange}
        />
      )
    })
  }, [
    isHoveringOver,
    onDrop,
    onDragLeave,
    onDragOver,
    droppedFiles,
    onDropAnimationStatusChange
  ])

  useEffect(() => {
    return () => unregister('DOC_UPLOAD_DROP_AREA')
  }, [])

  useEffect(() => {
    if (files.length) {
      // console.log('dismissing!')
      dismissingNote('DOC_UPLOADS_HOW_TO')
    }
  }, [files.length])

  return (
    <Outer onDragEnter={onDragEnter} onDragOver={onDragOver}>
      <HeaderArea>
        <TitleArea>
          <Title>Upload Documents.</Title>
        </TitleArea>
        <DocsNav location={location} />
      </HeaderArea>
      <BodyArea>
        <Switch>
          <Route
            path={`${matchUrl}/upload`}
            render={props => (
              <DocsCurrent
                {...props}
                deleteFileById={deleteFileById}
                fileRemovalCompleteById={fileRemovalCompleteById}
                browseFiles={browseFiles}
                files={files}
              />
            )}
          />
          <Route
            path={`${matchUrl}/all-docs`}
            render={props => <DocsList {...props} />}
          />
          <Redirect from="*" to={`${matchUrl}/upload`} />
        </Switch>
      </BodyArea>
      {Object.values(fileGroups.groups).map(fileGroup => (
        <ProgressNotificationManager key={fileGroup.groupId} {...fileGroup} />
      ))}
      <FooterDoneBtn fileGroups={fileGroups} />
      {validatorRunner}
    </Outer>
  )
}

const ProgressNotificationManager = memo(
  function ProgressNotificationManagerComp(props) {
    const {
      groupId,
      uploadsComplete,
      totalFilesWorkingOrSuccess,
      currentBytesWorkingOrSuccess,
      totalBytesWorkingOrSuccess,
      timeRemaining,
      totalFiles,
      totalFilesError
    } = useComputeCurrentUploadStats(props)

    const { upsertNote } = useNotificationsActions()

    useEffect(() => {
      upsertNote(
        {
          id: groupId,
          render: noteProps => (
            <ProgressNotification
              {...noteProps}
              uploadsComplete={uploadsComplete}
              totalFilesWorkingOrSuccess={totalFilesWorkingOrSuccess}
              currentBytesWorkingOrSuccess={currentBytesWorkingOrSuccess}
              totalBytesWorkingOrSuccess={totalBytesWorkingOrSuccess}
              timeRemaining={timeRemaining}
              totalFiles={totalFiles}
              totalFilesError={totalFilesError}
            />
          )
        },
        { prepend: true, shake: false }
      )
    }, [
      groupId,
      totalFilesWorkingOrSuccess,
      uploadsComplete,
      currentBytesWorkingOrSuccess,
      totalBytesWorkingOrSuccess,
      timeRemaining,
      totalFiles,
      totalFilesError
    ])

    useEffect(() => {
      if (uploadsComplete && totalFilesError) {
        upsertNote(
          {
            id: `${groupId}_ERROR_PROGRESS_NOTIFICATION`,
            render: noteProps => (
              <ErrorProgressNotification
                {...noteProps}
                uploadsComplete={uploadsComplete}
                totalFiles={totalFiles}
                totalFilesError={totalFilesError}
              />
            )
          },
          { shake: false }
        )
      }
    }, [groupId, uploadsComplete, totalFiles, totalFilesError])

    return null
  }
)

export default compose(
  withLegacyBrowserWarning,
  withGalaxyIntroAnimation,
  withUploadThankYou,
  withUploadCodeLogin
)(DocManager)
