import Ajv from 'ajv'
import ajvErrors from 'ajv-errors'

export const loanOfficerFormSchema = () => {
  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    required: ['name', 'token', 'email'],
    properties: {
      name: {
        type: 'string'
      },
      token: {
        type: 'string'
      },
      email: {
        type: 'string'
      }
    },
    errorMessage: {
      required: {
        name: 'required',
        token: 'required',
        email: 'required'
      }
    }
  }
}

const ajv = ajvErrors(new Ajv({ allErrors: true, jsonPointers: true }))
const validator = ajv.compile(loanOfficerFormSchema())

export const loanOfficerFormValidation = loanOfficerInput => {
  validator(loanOfficerInput)

  const errors = {}
  if (validator.errors) {
    validator.errors.forEach(obj => {
      // Massage ajv errors to the { 'fieldName': 'errorMessage' } format expected by redux-form
      if (obj.keyword === 'required') {
        errors[obj.params.missingProperty] = obj.message
      } else if (obj.keyword === 'errorMessage') {
        errors[obj.params.errors[0].params.missingProperty] = obj.message
      } else {
        errors[obj.dataPath.replace('.', '')] = obj.message
      }
    })
  }
  return errors
}
