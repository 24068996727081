import styled from 'styled-components/macro'

const Outer = styled.div`
  position: absolute;
  top: 28px;
  bottom: 0;
  left: 20px;
  right: 20px;
  box-shadow: 0 2px 50px 0 #00000d;
  opacity: 0.6;
`

export default Outer
