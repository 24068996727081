import styled from 'styled-components/macro'

const MetricItem = styled.div`
  position: relative;
  flex: 1;
  min-width: ${({ minWidth }) => minWidth || 0};
  ${({ css }) => css}
`

export default MetricItem
