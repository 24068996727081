import { gql } from 'graphql.macro'

export default gql`
  mutation($input: VerifyLoanUploadCodeInput!) {
    verifyLoanUploadCode(input: $input) {
      verified
      errors {
        field
        message
      }
    }
  }
`
