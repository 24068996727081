import { useEffect, useCallback, useRef } from 'react'

export default function useSafeDispatch(dispatch) {
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return useCallback(
    (...args) => {
      if (isMountedRef.current) {
        return dispatch(...args)
      }
    },
    [isMountedRef.current]
  )
}
