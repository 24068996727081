/* eslint-disable */
const _uuid = (function() {
  var i, lut, self
  self = {}
  lut = []
  i = 0
  while (i < 256) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16)
    i++
  }
  self.generate = function(options) {
    var d0, d1, d2, d3, res
    if (options == null) {
      options = {}
    }
    d0 = (Math.random() * 0xffffffff) | 0
    d1 = (Math.random() * 0xffffffff) | 0
    d2 = (Math.random() * 0xffffffff) | 0
    d3 = (Math.random() * 0xffffffff) | 0
    res =
      lut[d0 & 0xff] +
      lut[(d0 >> 8) & 0xff] +
      lut[(d0 >> 16) & 0xff] +
      lut[(d0 >> 24) & 0xff] +
      '-' +
      lut[d1 & 0xff] +
      lut[(d1 >> 8) & 0xff] +
      '-' +
      lut[((d1 >> 16) & 0x0f) | 0x40] +
      lut[(d1 >> 24) & 0xff] +
      '-' +
      lut[(d2 & 0x3f) | 0x80] +
      lut[(d2 >> 8) & 0xff] +
      '-' +
      lut[(d2 >> 16) & 0xff] +
      lut[(d2 >> 24) & 0xff] +
      lut[d3 & 0xff] +
      lut[(d3 >> 8) & 0xff] +
      lut[(d3 >> 16) & 0xff] +
      lut[(d3 >> 24) & 0xff]
    if (options.noDashes) {
      return res.replace(/-/g, '')
    } else {
      return res
    }
  }
  return self
})()
const uuid = _uuid.generate
export default uuid
