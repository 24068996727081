import React from 'react'

export default function FacebookIcon() {
  return (
    <svg
      width="8px"
      height="15px"
      viewBox="0 0 8 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-40.000000, 0.000000)" fill="#00023F">
          <g>
            <g transform="translate(40.238471, 0.000000)">
              <g transform="translate(0.015562, 0.074303)">
                <path d="M6.96690595,8.01236592 L4.78078935,8.01236592 L4.78078935,14.9102167 L1.54159249,14.9102167 L1.54159249,8.01236592 L-1.42108547e-14,8.01236592 L-1.42108547e-14,5.18612044 L1.54159249,5.18612044 L1.54159249,3.35666691 C1.54159249,2.04662607 2.14711363,0 4.81004158,0 L7.20969946,0.0100079514 L7.20969946,2.75418824 L5.46821674,2.75418824 C5.18447011,2.75418824 4.78078935,2.89930353 4.78078935,3.5248005 L4.78078935,5.18612044 L7.25065258,5.18612044 L6.96690595,8.01236592 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
