/**
 *
 * Base.js
 *
 */

import React, { useState, useCallback } from 'react'
import ForceAuthenticate from 'domains/CurrentUser/Authenticated'
import UserNav from 'domains/BizDash/Shared/components/UserNav'
import IfAuthenticated from 'domains/CurrentUser/IfAuthenticated'
import IfNotAuthenticated from 'domains/CurrentUser/IfNotAuthenticated'
import Content from 'domains/BizDash/Shared/components/Content'
import Header from 'domains/BizDash/Shared/components/Header'
import Sidebar from 'domains/BizDash/Shared/components/Sidebar'

import { DisclosuresProvider } from 'domains/BizDash/DisclosureQueue/store'
import { ModalProvider } from 'domains/BizDash/Modal/store'
import { AppProvider } from 'domains/BizDash/store'

import MailtoLink from 'domains/BizDash/Shared/components/MailtoLink'
import ModalPortal from 'domains/BizDash/Modal/ModalPortal'
import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'
import { currentUserPresent } from 'domains/CurrentUser/helpers'

const Base = ({ render, mainNavigation }) => {
  const [sidebarOpen, setSideBarOpen] = useState(false)
  const [{ currentUser }] = useCurrentUser()
  const showNavOpener = currentUserPresent({ currentUser })

  const onNavOpenerClick = useCallback(() => setSideBarOpen(!sidebarOpen), [
    setSideBarOpen,
    sidebarOpen
  ])

  return (
    <AppProvider>
      <ModalProvider>
        <DisclosuresProvider>
          <Content>
            <Header
              onNavOpenerClick={onNavOpenerClick}
              showNavOpener={showNavOpener}
            >
              <UserNav />
              <IfNotAuthenticated
                render={() => <MailtoLink to="support@lower.com" />}
              />
            </Header>
            <IfAuthenticated
              render={() => (
                <Sidebar navConfigs={mainNavigation()} open={sidebarOpen} />
              )}
            />
            <ForceAuthenticate
              redirectToPath="/admin-sign-in"
              render={() => render()}
            />
          </Content>
          <ModalPortal />
        </DisclosuresProvider>
      </ModalProvider>
    </AppProvider>
  )
}

export default Base
