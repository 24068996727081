import React from 'react'
import HeaderCell from './styled/HeaderCell'

const headerLabels = {
  status: 'Status',
  filename: 'File Name',
  encompass_attachment_id: 'ID',
  created_at: 'Date'
}

const DefaultHeaderRenderer = ({dataKey}) => {
  return (
    <HeaderCell>
      {headerLabels[dataKey]}
    </HeaderCell>
  )
}
  

export default DefaultHeaderRenderer
