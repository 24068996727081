import styled from 'styled-components/macro'

const NavBtnsOuter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 110px;
  margin: 37px auto 0 auto;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  @media (max-width: 768px) {
    opacity: 1;
  }
`

export default NavBtnsOuter
