import styled, { css } from 'styled-components/macro'

// 760 = 25*2 + 710
const DragHoverHelper = styled.div`
  position: ${({ position }) => position || 'absolute'};
  overflow: hidden;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  ${({ expand }) =>
    expand
      ? css`
          bottom: 0;
        `
      : ''};
`
export default DragHoverHelper
