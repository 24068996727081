import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'
import fetch from 'unfetch'

const create = () => {
  function loginUser(data) {
    return fetch(`${LOWER_API_DOMAIN}/sign-in`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(response => {
        return { data: { user: response } }
      })
  }

  function currentUser() {
    return fetch(`${LOWER_API_DOMAIN}/current-user`, {
      method: 'get',
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then(response => {
        return { data: { user: response } }
      })
  }

  function logoutUser() {
    return fetch(`${LOWER_API_DOMAIN}/sign-out`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    }).then(() => {
      return true
    })
  }

  return {
    loginUser,
    logoutUser,
    currentUser
  }
}
const theApi = create()
export default theApi
