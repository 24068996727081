/**
 *
 * Landing.js
 *
 */

import React from 'react'
import DocumentDatagrid from 'domains/BizDash/Shared/components/Datagrid';

const DownloadedDocuments = () => <DocumentDatagrid filter='downloaded' />

export default DownloadedDocuments