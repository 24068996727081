/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import Pretitle from './styled/Pretitle'
import Title from './styled/Title'
import Divider from './styled/Divider'
import Row from './styled/Row'
import DataItem from './styled/DataItem'
import DataLabel from './styled/DataLabel'
import DataNumber from './styled/DataNumber'
import Sup from './styled/Sup'
import Sub from './styled/Sub'

function LoanTile({
  sz,
  pretitle,
  title,
  interestRate,
  term,
  termUnits,
  cssStyles
}) {
  let outerStyles = {}
  if (sz === 'small') {
    outerStyles = {
      minHeight: '241px'
    }
  }
  return (
    <Outer {...outerStyles} css={cssStyles}>
      <Pretitle>{pretitle}</Pretitle>
      <Title>{title}</Title>
      <Divider />
      <Row>
        <DataItem>
          <DataLabel>Interest Rate</DataLabel>
          <DataNumber>
            {interestRate ? (
              <>
                {interestRate}
                <Sup>&#37;</Sup>
              </>
            ) : (
              <>-</>
            )}
          </DataNumber>
        </DataItem>
        <DataItem>
          <DataLabel>Term</DataLabel>
          {term ? (
            <DataNumber>
              {term}
              <Sub>{termUnits}</Sub>
            </DataNumber>
          ) : (
            <DataNumber>-</DataNumber>
          )}
        </DataItem>
      </Row>
    </Outer>
  )
}

export default LoanTile
