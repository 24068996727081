import React, { useContext, memo, useMemo, useEffect, useCallback } from 'react'
import { path } from 'ramda'
import { PrivateContext, FormProvider } from '../Context'

const emptyObj = {}
export default function Form({
  component: Comp,
  name,
  onSubmit,
  render,
  validate,
  children
}) {
  if (!name) {
    throw new Error(
      "lib/useForm/Form component must be provided a name to distinguish it's data in FormProvider Context"
    )
  }

  const { setContext, data = emptyObj } = useContext(PrivateContext)

  const onSubmitWrapped = useCallback(
    e => {
      e.preventDefault()
      setContext({
        data: {
          [name]: {
            didAttemptSubmit: true,
            executeOnSubmit: true
          }
        }
      })
    },
    [onSubmit, setContext]
  )

  const executeOnSubmit = path([name, 'executeOnSubmit'], data)

  useEffect(() => {
    if (executeOnSubmit) {
      setContext({
        data: {
          [name]: {
            executeOnSubmit: false
          }
        }
      })
      onSubmit()
    }
  }, [executeOnSubmit])

  const formContext = useMemo(() => {
    return {
      name,
      data: data[name]
    }
  }, [data[name], name])

  useEffect(() => setContext({ validate }), [setContext, data, validate])

  Comp = useMemo(() => (Comp ? memo(Comp) : undefined), [Comp])

  let element
  if (render) {
    element = render()
  } else if (Comp) {
    element = <Comp onSubmit={onSubmitWrapped} />
  } else {
    element = <form onSubmit={onSubmitWrapped}>{children}</form>
  }

  return <FormProvider value={formContext}>{element}</FormProvider>
}
