import React from 'react'

export default function GlassdoorIcon() {
  return (
    <svg
      width="11px"
      height="15px"
      viewBox="0 0 11 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-147.000000, -1.000000)" fill="#00023F">
          <g transform="translate(147.000000, 1.000000)">
            <path d="M8.78590342,12.8047537 L-7.53424661e-06,12.8047537 C-7.53424661e-06,13.9772415 0.980197945,14.9407537 2.19946507,14.9407537 L8.78582808,14.9407537 C9.99311575,14.9407537 10.9853007,13.9888756 10.9853007,12.8047537 L10.9853007,4.03992439 C10.9853007,3.99353415 10.9494377,3.95870488 10.9016705,3.95870488 L8.86953356,3.95870488 C8.82176644,3.95870488 8.78582808,3.99353415 8.78582808,4.03992439 L8.78582808,12.8047537 L8.78590342,12.8047537 Z M8.78590342,2.19512195e-05 C9.99311575,2.19512195e-05 10.9853007,0.951973171 10.9853007,2.13609512 L2.19946507,2.13609512 L2.19946507,10.8892902 C2.19946507,10.9356805 2.16360205,10.9705098 2.11575959,10.9705098 L0.0836979452,10.9705098 C0.0358554795,10.9705098 -7.53424661e-06,10.9356805 -7.53424661e-06,10.8892902 L-7.53424661e-06,2.13609512 C-7.53424661e-06,0.963534146 0.980197945,2.19512195e-05 2.19946507,2.19512195e-05 L8.78590342,2.19512195e-05 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
