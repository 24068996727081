import React from 'react'
import styled from 'styled-components/macro'
import { createPortal } from 'react-dom'

const OverlayWrapper = styled.div`
  position: absolute;
  z-index: 9999999;
  background: white;
  ${props => `
        top: ${props.position.y}px;
        left: ${props.position.x}px;
    `}
`

function getBoundingClientObj(element) {
  return element.getBoundingClientRect()
}

const PortalPop = props => {
  const { anchor, children, classNames } = props
  if (!anchor) {
    return null
  }
  const rect = getBoundingClientObj(anchor)
  const menuWrapper = (
    <OverlayWrapper
      className={classNames}
      position={{ x: rect.left, y: rect.top }}
    >
      {children}
    </OverlayWrapper>
  )
  return createPortal(menuWrapper, document.body)
}

export default PortalPop
