import styled from 'styled-components/macro'

const ColumnsOuter = styled.div`
  display: flex;
  @media (max-width: 1030px) {
    display: block;
  }
`

export default ColumnsOuter
