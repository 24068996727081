import styled from 'styled-components/macro'

const Pretitle = styled.h2`
  font-family: Gotham;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.32px;
  line-height: 28px;
  color: #0600f5;
`

export default Pretitle
