import styled from 'styled-components/macro'
import { isNil } from 'ramda'

const TriangleOuter = styled.div`
  position: absolute;
  bottom: ${({ bottom }) => (isNil(bottom) ? '0px' : bottom)};
  left: ${({ left }) => (isNil(left) ? '0px' : left)};
  transform: translate(
    ${({ translateX }) => (isNil(translateX) ? '0px' : `${translateX}px`)},
    100%
  );
  transition: all 0.2s;
`

export default TriangleOuter
