/* eslint no-multi-assign: 0 */
/* eslint no-shadow: 0 */
import { useReducer, useEffect, useCallback } from 'react'
import { mergeDeepRight } from 'ramda'
import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'
import axios from 'axios'

function createAction(dispatch, type) {
  return payload => {
    dispatch({ type, payload })
  }
}

function loanRecommendationReducer(state, action) {
  switch (action.type) {
    case 'updateData':
      return mergeDeepRight(state, action.payload)
    default:
      throw new Error()
  }
}

function init() {
  return {
    loading: true
  }
}

export default function useFetchLoanRecommendation({ loaiToken }) {
  const [state, dispatch] = useReducer(loanRecommendationReducer, {}, init)

  const updateData = createAction(dispatch, 'updateData')

  async function fetchLoanRecommendation(loaiToken) {
    try {
      const { data } = await axios.get(
        `${LOWER_API_DOMAIN}/loai/loan-recommendation`,
        { params: { loai_token: loaiToken } }
      )
      updateData({ loading: false, error: null, data })
    } catch (e) {
      updateData({ loading: false, error: e, data: null })
    }
  }

  useEffect(() => {
    fetchLoanRecommendation(loaiToken)
  }, [loaiToken])

  const updateLocalLoanRecommendationData = useCallback(data => {
    updateData({ data })
  }, [])

  return [state, { updateLocalLoanRecommendationData }]
}
