import React from 'react'
import Select from './styled/Select'
import ErrorItem from '../TextArea/styled/ErrorItem'

export default function SelectBox({
  editedValue,
  onSelectChange,
  selectOptions,
  errors
}) {
  return (
    <>
      <Select value={editedValue} onChange={onSelectChange}>
        {selectOptions.map(({ text, value }, i) => (
          <option key={i} value={value}>
            {text}
          </option>
        ))}
      </Select>
      {!!errors &&
        !!errors.length &&
        errors.map(({ message }, i) => (
          <ErrorItem key={i}>{message}</ErrorItem>
        ))}
    </>
  )
}
