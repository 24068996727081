import styled from 'styled-components/macro'

const Outer = styled.div`
  min-height: ${({ minHeight }) => minHeight || '315px'};
  background-color: #4100f5;
  border-radius: 7px;
  padding: 27px 32px;
  ${({ css }) => css}
`

export default Outer
