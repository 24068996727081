import createStore from 'domains/shared/Store'
import modalReducer, { initialState } from './reducer'
import modalActions from './actions'

const [ModalContext, ModalProvider, useModal] = createStore(modalReducer, modalActions, initialState);

export {
  ModalContext,
  ModalProvider,
  useModal
}