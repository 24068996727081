import styled from 'styled-components/macro'

const Para = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: Gotham_Book;
  letter-spacing: 0.25px;
  margin-bottom: 18px;
`

export default Para
