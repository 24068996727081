/* eslint camelcase: 0 */
import { createGlobalStyle } from 'styled-components/macro'

import gothamBlackUrl_eot from '../fonts/gotham/Gotham_Black.eot'
import gothamBlackUrl_woff2 from '../fonts/gotham/Gotham_Black.woff2'
import gothamBlackUrl_woff from '../fonts/gotham/Gotham_Black.woff'
import gothamBlackUrl_ttf from '../fonts/gotham/Gotham_Black.ttf'
import gothamBlackUrl_svg from '../fonts/gotham/Gotham_Black.svg'

import gothamBlackItalicUrl_eot from '../fonts/gotham/Gotham_BlackItalic.eot'
import gothamBlackItalicUrl_woff2 from '../fonts/gotham/Gotham_BlackItalic.woff2'
import gothamBlackItalicUrl_woff from '../fonts/gotham/Gotham_BlackItalic.woff'
import gothamBlackItalicUrl_ttf from '../fonts/gotham/Gotham_BlackItalic.ttf'
import gothamBlackItalicUrl_svg from '../fonts/gotham/Gotham_BlackItalic.svg'

import gothamBoldUrl_eot from '../fonts/gotham/Gotham_Bold.eot'
import gothamBoldUrl_woff2 from '../fonts/gotham/Gotham_Bold.woff2'
import gothamBoldUrl_woff from '../fonts/gotham/Gotham_Bold.woff'
import gothamBoldUrl_ttf from '../fonts/gotham/Gotham_Bold.ttf'
import gothamBoldUrl_svg from '../fonts/gotham/Gotham_Bold.svg'

import gothamBoldItalicUrl_eot from '../fonts/gotham/Gotham_BoldItalic.eot'
import gothamBoldItalicUrl_woff2 from '../fonts/gotham/Gotham_BoldItalic.woff2'
import gothamBoldItalicUrl_woff from '../fonts/gotham/Gotham_BoldItalic.woff'
import gothamBoldItalicUrl_ttf from '../fonts/gotham/Gotham_BoldItalic.ttf'
import gothamBoldItalicUrl_svg from '../fonts/gotham/Gotham_BoldItalic.svg'

import gothamBookUrl_eot from '../fonts/gotham/Gotham_Book.eot'
import gothamBookUrl_woff2 from '../fonts/gotham/Gotham_Book.woff2'
import gothamBookUrl_woff from '../fonts/gotham/Gotham_Book.woff'
import gothamBookUrl_ttf from '../fonts/gotham/Gotham_Book.ttf'
import gothamBookUrl_svg from '../fonts/gotham/Gotham_Book.svg'

import gothamBookItalicUrl_eot from '../fonts/gotham/Gotham_BookItalic.eot'
import gothamBookItalicUrl_woff2 from '../fonts/gotham/Gotham_BookItalic.woff2'
import gothamBookItalicUrl_woff from '../fonts/gotham/Gotham_BookItalic.woff'
import gothamBookItalicUrl_ttf from '../fonts/gotham/Gotham_BookItalic.ttf'
import gothamBookItalicUrl_svg from '../fonts/gotham/Gotham_BookItalic.svg'

import gothamExtraLightUrl_eot from '../fonts/gotham/Gotham_ExtraLight.eot'
import gothamExtraLightUrl_woff2 from '../fonts/gotham/Gotham_ExtraLight.woff2'
import gothamExtraLightUrl_woff from '../fonts/gotham/Gotham_ExtraLight.woff'
import gothamExtraLightUrl_ttf from '../fonts/gotham/Gotham_ExtraLight.ttf'
import gothamExtraLightUrl_svg from '../fonts/gotham/Gotham_ExtraLight.svg'

import gothamExtraLightItalicUrl_eot from '../fonts/gotham/Gotham_ExtraLightItalic.eot'
import gothamExtraLightItalicUrl_woff2 from '../fonts/gotham/Gotham_ExtraLightItalic.woff2'
import gothamExtraLightItalicUrl_woff from '../fonts/gotham/Gotham_ExtraLightItalic.woff'
import gothamExtraLightItalicUrl_ttf from '../fonts/gotham/Gotham_ExtraLightItalic.ttf'
import gothamExtraLightItalicUrl_svg from '../fonts/gotham/Gotham_ExtraLightItalic.svg'

import gothamLightUrl_eot from '../fonts/gotham/Gotham_Light.eot'
import gothamLightUrl_woff2 from '../fonts/gotham/Gotham_Light.woff2'
import gothamLightUrl_woff from '../fonts/gotham/Gotham_Light.woff'
import gothamLightUrl_ttf from '../fonts/gotham/Gotham_Light.ttf'
import gothamLightUrl_svg from '../fonts/gotham/Gotham_Light.svg'

import gothamLightItalicUrl_eot from '../fonts/gotham/Gotham_LightItalic.eot'
import gothamLightItalicUrl_woff2 from '../fonts/gotham/Gotham_LightItalic.woff2'
import gothamLightItalicUrl_woff from '../fonts/gotham/Gotham_LightItalic.woff'
import gothamLightItalicUrl_ttf from '../fonts/gotham/Gotham_LightItalic.ttf'
import gothamLightItalicUrl_svg from '../fonts/gotham/Gotham_LightItalic.svg'

import gothamMediumUrl_eot from '../fonts/gotham/Gotham_Medium.eot'
import gothamMediumUrl_woff2 from '../fonts/gotham/Gotham_Medium.woff2'
import gothamMediumUrl_woff from '../fonts/gotham/Gotham_Medium.woff'
import gothamMediumUrl_ttf from '../fonts/gotham/Gotham_Medium.ttf'
import gothamMediumUrl_svg from '../fonts/gotham/Gotham_Medium.svg'

import gothamMediumItalicUrl_eot from '../fonts/gotham/Gotham_MediumItalic.eot'
import gothamMediumItalicUrl_woff2 from '../fonts/gotham/Gotham_MediumItalic.woff2'
import gothamMediumItalicUrl_woff from '../fonts/gotham/Gotham_MediumItalic.woff'
import gothamMediumItalicUrl_ttf from '../fonts/gotham/Gotham_MediumItalic.ttf'
import gothamMediumItalicUrl_svg from '../fonts/gotham/Gotham_MediumItalic.svg'

import gothamThinUrl_eot from '../fonts/gotham/Gotham_Thin.eot'
import gothamThinUrl_woff2 from '../fonts/gotham/Gotham_Thin.woff2'
import gothamThinUrl_woff from '../fonts/gotham/Gotham_Thin.woff'
import gothamThinUrl_ttf from '../fonts/gotham/Gotham_Thin.ttf'
import gothamThinUrl_svg from '../fonts/gotham/Gotham_Thin.svg'

import gothamThinItalicUrl_eot from '../fonts/gotham/Gotham_ThinItalic.eot'
import gothamThinItalicUrl_woff2 from '../fonts/gotham/Gotham_ThinItalic.woff2'
import gothamThinItalicUrl_woff from '../fonts/gotham/Gotham_ThinItalic.woff'
import gothamThinItalicUrl_ttf from '../fonts/gotham/Gotham_ThinItalic.ttf'
import gothamThinItalicUrl_svg from '../fonts/gotham/Gotham_ThinItalic.svg'

import gothamUltraUrl_eot from '../fonts/gotham/Gotham_Ultra.eot'
import gothamUltraUrl_woff2 from '../fonts/gotham/Gotham_Ultra.woff2'
import gothamUltraUrl_woff from '../fonts/gotham/Gotham_Ultra.woff'
import gothamUltraUrl_ttf from '../fonts/gotham/Gotham_Ultra.ttf'
import gothamUltraUrl_svg from '../fonts/gotham/Gotham_Ultra.svg'

import gothamUltraItalicUrl_eot from '../fonts/gotham/Gotham_UltraItalic.eot'
import gothamUltraItalicUrl_woff2 from '../fonts/gotham/Gotham_UltraItalic.woff2'
import gothamUltraItalicUrl_woff from '../fonts/gotham/Gotham_UltraItalic.woff'
import gothamUltraItalicUrl_ttf from '../fonts/gotham/Gotham_UltraItalic.ttf'
import gothamUltraItalicUrl_svg from '../fonts/gotham/Gotham_UltraItalic.svg'

const GothamFontsStyle = createGlobalStyle`
  @font-face {
    font-family: "Gotham_Black";
    font-display: block;
    src: url('${gothamBlackUrl_eot}') format('eot'),
         url('${gothamBlackUrl_woff2}') format('woff2'),
         url('${gothamBlackUrl_woff}') format('woff'),
         url('${gothamBlackUrl_ttf}')  format('truetype'),
         url('${gothamBlackUrl_svg}#Gotham_Bold') format('svg');
  }

  @font-face {
    font-family: "Gotham_Bold";
    font-display: block;
    src: url('${gothamBoldUrl_eot}') format('eot'),
         url('${gothamBoldUrl_woff2}') format('woff2'),
         url('${gothamBoldUrl_woff}') format('woff'),
         url('${gothamBoldUrl_ttf}')  format('truetype'),
         url('${gothamBoldUrl_svg}#Gotham_Bold') format('svg');
  }

  @font-face {
    font-family: "Gotham_Black";
    font-display: block;
    src: url('${gothamBlackItalicUrl_eot}') format('eot'),
         url('${gothamBlackItalicUrl_woff2}') format('woff2'),
         url('${gothamBlackItalicUrl_woff}') format('woff'),
         url('${gothamBlackItalicUrl_ttf}')  format('truetype'),
         url('${gothamBlackItalicUrl_svg}#Gotham_Black') format('svg');
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamBoldUrl_eot}') format('eot'),
         url('${gothamBoldUrl_woff2}') format('woff2'),
         url('${gothamBoldUrl_woff}') format('woff'),
         url('${gothamBoldUrl_ttf}')  format('truetype'),
         url('${gothamBoldUrl_svg}#Gotham') format('svg');
    font-weight: 700;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamBoldItalicUrl_eot}') format('eot'),
         url('${gothamBoldItalicUrl_woff2}') format('woff2'),
         url('${gothamBoldItalicUrl_woff}') format('woff'),
         url('${gothamBoldItalicUrl_ttf}')  format('truetype'),
         url('${gothamBoldItalicUrl_svg}#Gotham') format('svg');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham_Book";
    font-display: block;
    src: url('${gothamBookUrl_eot}') format('eot'),
         url('${gothamBookUrl_woff2}') format('woff2'),
         url('${gothamBookUrl_woff}') format('woff'),
         url('${gothamBookUrl_ttf}')  format('truetype'),
         url('${gothamBookUrl_svg}#Gotham_Book') format('svg');
  }

  @font-face {
    font-family: "Gotham_Book";
    font-display: block;
    src: url('${gothamBookItalicUrl_eot}') format('eot'),
         url('${gothamBookItalicUrl_woff2}') format('woff2'),
         url('${gothamBookItalicUrl_woff}') format('woff'),
         url('${gothamBookItalicUrl_ttf}')  format('truetype'),
         url('${gothamBookItalicUrl_svg}#Gotham_Book') format('svg');
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamExtraLightUrl_eot}') format('eot'),
         url('${gothamExtraLightUrl_woff2}') format('woff2'),
         url('${gothamExtraLightUrl_woff}') format('woff'),
         url('${gothamExtraLightUrl_ttf}')  format('truetype'),
         url('${gothamExtraLightUrl_svg}#Gotham_ExtraLight') format('svg');
    font-weight: 200;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamExtraLightItalicUrl_eot}') format('eot'),
         url('${gothamExtraLightItalicUrl_woff2}') format('woff2'),
         url('${gothamExtraLightItalicUrl_woff}') format('woff'),
         url('${gothamExtraLightItalicUrl_ttf}')  format('truetype'),
         url('${gothamExtraLightItalicUrl_svg}#Gotham_ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamLightUrl_eot}') format('eot'),
         url('${gothamLightUrl_woff2}') format('woff2'),
         url('${gothamLightUrl_woff}') format('woff'),
         url('${gothamLightUrl_ttf}')  format('truetype'),
         url('${gothamLightUrl_svg}#Gotham_Light') format('svg');
    font-weight: 300;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamLightItalicUrl_eot}') format('eot'),
         url('${gothamLightItalicUrl_woff2}') format('woff2'),
         url('${gothamLightItalicUrl_woff}') format('woff'),
         url('${gothamLightItalicUrl_ttf}')  format('truetype'),
         url('${gothamLightItalicUrl_svg}#Gotham_LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamMediumUrl_eot}') format('eot'),
         url('${gothamMediumUrl_woff2}') format('woff2'),
         url('${gothamMediumUrl_woff}') format('woff'),
         url('${gothamMediumUrl_ttf}')  format('truetype'),
         url('${gothamMediumUrl_svg}#Gotham_Medium') format('svg');
    font-weight: 400;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamMediumItalicUrl_eot}') format('eot'),
         url('${gothamMediumItalicUrl_woff2}') format('woff2'),
         url('${gothamMediumItalicUrl_woff}') format('woff'),
         url('${gothamMediumItalicUrl_ttf}')  format('truetype'),
         url('${gothamMediumItalicUrl_svg}#Gotham_MediumItalic') format('svg');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamThinUrl_eot}') format('eot'),
         url('${gothamThinUrl_woff2}') format('woff2'),
         url('${gothamThinUrl_woff}') format('woff'),
         url('${gothamThinUrl_ttf}')  format('truetype'),
         url('${gothamThinUrl_svg}#Gotham_Thin') format('svg');
    font-weight: 100;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamThinItalicUrl_eot}') format('eot'),
         url('${gothamThinItalicUrl_woff2}') format('woff2'),
         url('${gothamThinItalicUrl_woff}') format('woff'),
         url('${gothamThinItalicUrl_ttf}')  format('truetype'),
         url('${gothamThinItalicUrl_svg}#Gotham_ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamUltraUrl_eot}') format('eot'),
         url('${gothamUltraUrl_woff2}') format('woff2'),
         url('${gothamUltraUrl_woff}') format('woff'),
         url('${gothamUltraUrl_ttf}')  format('truetype'),
         url('${gothamUltraUrl_svg}#Gotham_Ultra') format('svg');
    font-weight: 900;
  }

  @font-face {
    font-family: "Gotham";
    font-display: block;
    src: url('${gothamUltraItalicUrl_eot}') format('eot'),
         url('${gothamUltraItalicUrl_woff2}') format('woff2'),
         url('${gothamUltraItalicUrl_woff}') format('woff'),
         url('${gothamUltraItalicUrl_ttf}')  format('truetype'),
         url('${gothamUltraItalicUrl_svg}#Gotham_UltraItalic') format('svg');
    font-weight: 900;
    font-style: italic;
  }

  
`

export default GothamFontsStyle
