import { from as fromJS } from 'seamless-immutable'
import {
  FETCH_DISCLOSURES_LOADING,
  FETCH_DISCLOSURES_SUCCESS,
  FETCH_DISCLOSURES_ERROR,
  SET_DISCLOSURE_SEARCH_QUERY,
  FETCH_MORE_DISCLOSURES_SUCCESS,
  RESET_DISCLOSURE
} from './constants'

// The initial state of the App
export const initialState = fromJS({
  disclosures: {
    items: null,
    paging: null,
    loading: false,
    searchQuery: null
  }
})

const deep = {
  deep: true
}

function setLoading(state, { isLoading }) {
  return state.setIn(['disclosures', 'loading'], isLoading)
}

function setData(
  state,
  {
    data: {
      disclosures: { items, paging }
    }
  }
) {
  return state.setIn(
    ['disclosures'],
    { ...state.disclosures, loading: false, items, paging },
    deep
  )
}

function addData(
  state,
  {
    data: {
      disclosures: { items, paging }
    }
  }
) {
  return state.setIn(
    ['disclosures'],
    {
      ...state.disclosures,
      loading: false,
      items: state.disclosures.items.concat(items),
      paging
    },
    deep
  )
}

function setQueryData(state, searchQuery) {
  return state.setIn(['disclosures', 'searchQuery'], searchQuery, deep)
}

function setError(state, { error }) {
  return state.setIn(['disclosures', 'error'], error)
}

function setDisclosureData(state, { data: { disclosure } }) {
  const updatedDisclosureIndex = state.disclosures.items.findIndex(
    item => item.id === disclosure.id
  )
  return state.setIn(
    ['disclosures', 'items', updatedDisclosureIndex],
    disclosure
  )
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DISCLOSURES_LOADING:
      return setLoading(state, payload.isLoading)
    case FETCH_DISCLOSURES_SUCCESS:
      return setData(state, payload)
    case FETCH_MORE_DISCLOSURES_SUCCESS:
      return addData(state, payload)
    case FETCH_DISCLOSURES_ERROR:
      return setError(state, payload)
    case SET_DISCLOSURE_SEARCH_QUERY:
      return setQueryData(state, payload)
    case RESET_DISCLOSURE:
      return setDisclosureData(state, payload)
    default:
      return state
  }
}
