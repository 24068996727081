import styled from 'styled-components/macro'

const Outer = styled.div`
  text-align: center;
  margin-top: 275px;
  margin-top: 20vmin;
  padding: 0 30px;
`

export default Outer
