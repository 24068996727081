import React, { memo, useReducer, useCallback, useMemo, useEffect } from 'react'
import isFunction from 'lodash/isFunction'
import {
  uniq,
  without,
  mergeDeepRight,
  omit,
  map,
  update,
  findIndex,
  identity,
  indexBy,
  pluck
} from 'ramda'
import uuid from 'lib/uuid'
import { Provider, ActionProvider } from '../Context'

const UPSERT_NOTE = 'UPSERT_NOTE'
const REMOVE_NOTE = 'REMOVE_NOTE'
const DISMISS_NOTE = 'DISMISS_NOTE'
const REMOVE_NOTES = 'REMOVE_NOTES'
const DISMISS_NOTES = 'DISMISS_NOTES'
const DISMISS_NOTES_BY_TAG = 'DISMISS_NOTES_BY_TAG'
const DISMISS_ALL_NOTES = 'DISMISS_ALL_NOTES'

function dismissNotesInState(state, ids, { visualStatus = 'dismissed' } = {}) {
  const dismissedNotes = ids.reduce((acc, id) => {
    return mergeDeepRight(state.notes, { [id]: { visualStatus } })
  }, {})
  return {
    ...state.notes,
    ...dismissedNotes
  }
}

function dismissNotesByTagInState(
  state,
  tag,
  { visualStatus = 'dismissed', exceptIds = [] } = {}
) {
  const exceptIdsIndexed = indexBy(identity, exceptIds)
  const notes = Object.values(state.notes).reduce(
    (acc, { id, tag: noteTag }) => {
      if (!exceptIdsIndexed[id] && noteTag === tag) {
        return mergeDeepRight(acc, { [id]: { visualStatus } })
      }
      return acc
    },
    state.notes
  )
  return notes
}

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case UPSERT_NOTE: {
      let shakeKey
      // if the note already exists AND it wasn't previously dismissed,
      // then we want to "SHAKE" it to draw the user's eyes over to
      // the notification
      if (
        state.notes[payload.note.id] &&
        state.notes[payload.note.id].visualStatus !== 'dismissed' &&
        payload.shake !== false
      ) {
        shakeKey = uuid()
      }
      let order
      if (payload.prepend) {
        order = [payload.note.id, ...state.order]
      } else {
        order = [...state.order, payload.note.id]
      }
      order = uniq(order)
      return {
        order,
        notes: mergeDeepRight(state.notes, {
          [payload.note.id]: { ...payload.note, visualStatus: 'show', shakeKey }
        })
      }
    }
    case REMOVE_NOTE: {
      return {
        order: without([payload.id], state.order),
        notes: omit([payload.id], state.notes)
      }
    }
    case REMOVE_NOTES: {
      return {
        order: without([...payload.ids], state.order),
        notes: omit([...payload.ids], state.notes)
      }
    }
    case DISMISS_NOTE: {
      return {
        ...state,
        notes: mergeDeepRight(state.notes, {
          [payload.id]: { visualStatus: payload.visualStatus || 'dismissed' }
        })
      }
    }
    case DISMISS_NOTES: {
      return {
        ...state,
        notes: dismissNotesInState(state, payload.ids, {
          visualStatus: payload.visualStatus || 'dismissed'
        })
      }
    }
    case DISMISS_NOTES_BY_TAG: {
      return {
        ...state,
        notes: dismissNotesByTagInState(state, payload.tag, {
          exceptIds: payload.exceptIds,
          visualStatus: payload.visualStatus || 'dismissed'
        })
      }
    }
    case DISMISS_ALL_NOTES: {
      const exceptIds = payload.exceptIds || []
      return {
        ...state,
        notes: dismissNotesInState(
          state,
          Object.keys(omit(exceptIds, state.notes)),
          { visualStatus: payload.visualStatus || 'dismissed' }
        )
      }
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    notes: {},
    order: []
  }
}

export function createWithNotificationsProvider(/* opts = {} */) {
  return function withNotificationsProvider(Child) {
    if (Child) {
      Child = memo(Child)
    }
    return function NotificationsProvider({ children, ...restProps }) {
      const [{ notes, order }, dispatch] = useReducer(reducer, {}, init)

      const upsertNote = useCallback((note, { prepend, shake } = {}) => {
        dispatch({
          type: UPSERT_NOTE,
          payload: {
            note,
            prepend,
            shake
          }
        })
      }, [])

      const removeNote = useCallback(id => {
        dispatch({
          type: REMOVE_NOTE,
          payload: {
            id
          }
        })
      }, [])

      const removeNotes = useCallback(ids => {
        dispatch({
          type: REMOVE_NOTES,
          payload: {
            ids
          }
        })
      }, [])

      const dismissNote = useCallback(id => {
        dispatch({
          type: DISMISS_NOTE,
          payload: {
            id
          }
        })
      }, [])

      const dismissingNote = useCallback(id => {
        dispatch({
          type: DISMISS_NOTE,
          payload: {
            id,
            visualStatus: 'dismissing'
          }
        })
      }, [])

      const dismissNotesByTag = useCallback((tag, { exceptIds } = {}) => {
        dispatch({
          type: DISMISS_NOTES_BY_TAG,
          payload: {
            tag,
            exceptIds
          }
        })
      }, [])

      const dismissingNotesByTag = useCallback((tag, { exceptIds } = {}) => {
        dispatch({
          type: DISMISS_NOTES_BY_TAG,
          payload: {
            tag,
            exceptIds,
            visualStatus: 'dismissing'
          }
        })
      }, [])

      const dismissAllNotes = useCallback(({ exceptIds } = {}) => {
        dispatch({
          type: DISMISS_ALL_NOTES,
          payload: {
            exceptIds
          }
        })
      }, [])

      const actionContext = useMemo(() => {
        return {
          upsertNote,
          removeNote,
          dismissNote,
          dismissingNote,
          dismissNotesByTag,
          dismissingNotesByTag,
          dismissAllNotes
        }
      }, [
        dismissNote,
        dismissingNote,
        upsertNote,
        removeNote,
        dismissNotesByTag,
        dismissingNotesByTag,
        dismissAllNotes
      ])

      if (Child) {
        children = <Child {...restProps} />
      } else if (isFunction(children)) {
        children = children(restProps)
      }

      let sortedNotes = useMemo(() => map(id => notes[id], order), [
        notes,
        order
      ])

      const firstNonDismissedNoteIdx = useMemo(
        () =>
          findIndex(
            ({ visualStatus }) => visualStatus !== 'dismissed',
            sortedNotes
          ),
        [sortedNotes]
      )

      const firstNonDismissedNote = sortedNotes[firstNonDismissedNoteIdx]

      sortedNotes = useMemo(() => {
        if (!firstNonDismissedNote) {
          return sortedNotes
        }
        return update(
          firstNonDismissedNoteIdx,
          { ...firstNonDismissedNote, isFirstNonDismissedNote: true },
          sortedNotes
        )
      }, [sortedNotes, firstNonDismissedNote, firstNonDismissedNoteIdx])

      useEffect(() => {
        const dismissedNotes = sortedNotes.filter(
          ({ visualStatus }) => visualStatus === 'dismissed'
        )

        const dismissedIds = pluck('id', dismissedNotes)
        if (dismissedIds.length) {
          removeNotes(dismissedIds)
        }
      }, [sortedNotes])

      const context = useMemo(() => {
        return {
          sortedNotes,
          notes,
          order
        }
      }, [sortedNotes, notes, order])

      // find any no

      return (
        <Provider value={context}>
          <ActionProvider value={actionContext}>{children}</ActionProvider>
        </Provider>
      )
    }
  }
}

export const withNotificationsProvider = createWithNotificationsProvider()
export const NotificationsProvider = withNotificationsProvider()
