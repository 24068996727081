import styled from 'styled-components/macro'

const Outer = styled.div`
  position: fixed;
  padding: 35px 135px;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    padding: 0;
  }
`

export default Outer
