import React, { memo, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import {
  withMeasureDeepProvider,
  MeasureDeep,
  useData as useMeasureDeepData
} from 'lib/MeasureDeep'
import { path, isNil, pluck } from 'ramda'
import Outer from './styled/Outer'
import Inner from './styled/Inner'
import TriangleOuter from './styled/TriangleOuter'
import TriangleWithBorder from './components/TriangleWithBorder'
import LinksOuter from './styled/LinksOuter'
import Link from './components/Link'

const docsManagerPath = '/consumer-portal/doc-manager'

const links = [
  {
    id: 0,
    href: '/upload',
    text: 'Current Session'
  }
  // {
  //   id: 1,
  //   href: '/all-docs',
  //   text: 'View All Documents'
  // }
]

const DocsNav = memo(function DocsNavComp() {
  let activeLink
  const allLinks = links.map(link => {
    const href = `${docsManagerPath}${link.href}`
    const match = useRouteMatch({
      path: href
    })
    if (match) {
      activeLink = link
    }
    const updatedLink = {
      ...link,
      href,
      active: !!match
    }

    if (match) {
      activeLink = updatedLink
    }
    return updatedLink
  })

  const measureOnIdResizes = useMemo(() => ['DocsNavInner'], [])

  const measureOnIdResizesOuter = useMemo(() => pluck('id', allLinks), [])

  return (
    <Outer>
      <MeasureDeep
        id="DocsNavInner"
        measureOnIdResizes={measureOnIdResizesOuter}
      >
        {ref => (
          <Inner ref={ref}>
            <LinksOuter>
              {allLinks.map(({ href, text, active, id }) => (
                <MeasureDeep
                  key={id}
                  id={id}
                  measureOnIdResizes={measureOnIdResizes}
                >
                  {refLink => (
                    <Link href={href} active={active} ref={refLink}>
                      {text}
                    </Link>
                  )}
                </MeasureDeep>
              ))}
            </LinksOuter>
            <ComputeTriangle activeLinkId={path(['id'], activeLink)} />
          </Inner>
        )}
      </MeasureDeep>
    </Outer>
  )
})

const emptyObj = {}
// eslint-disable-next-line react/no-multi-comp
const ComputeTriangle = memo(function ComputeTriangleComp({ activeLinkId }) {
  const { items } = useMeasureDeepData()
  const {
    [activeLinkId]: {
      data: {
        boundingClientRect: { left: leftActive, width: widthActive } = emptyObj
      } = emptyObj
    } = emptyObj,
    DocsNavInner: {
      data: { boundingClientRect: { left: leftOuter } = emptyObj } = emptyObj
    } = emptyObj
  } = items

  // const navItems = sortBy(prop('id'), Object.values(pick([0, 1], items)))
  // const frontNavItems = navItems.slice(0, activeLinkId)

  let leftTriangleOffset

  if (!isNil(leftActive)) {
    leftTriangleOffset = leftActive - leftOuter + widthActive / 2
    // minus 1/2 width of triangle (36/2)
    leftTriangleOffset -= 18
  }

  return (
    <TriangleOuter translateX={leftTriangleOffset}>
      <TriangleWithBorder />
    </TriangleOuter>
  )
})

export default withMeasureDeepProvider(DocsNav)
