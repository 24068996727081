import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const TextAreaOuter = styled(animated.div)`
  max-width: 520px;
  margin: 0 auto 60px auto;
  @media (max-width: 500px) {
    margin-bottom: 40px;
  }
`

export default TextAreaOuter
