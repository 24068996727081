import styled from 'styled-components/macro'

const HeaderArea = styled.div`
  background-color: white;
  padding: 109px 0 0 0;
  @media (max-width: 750px) {
    padding-top: 60px;
  }
`

export default HeaderArea
