import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components/macro'

import GlowUpButton from 'domains/BizDash/Shared/components/GlowUpButton'

const ErrorContainer = styled.div``

const Header = styled.div`
  color: white;
  font-size: 36px;
  margin-bottom: 40px;
`

const Body = styled.div`
  color: white;
  margin-bottom: 50px;
  font-size: 16px;
  ${props => props.theme.font('regular')}
`

const ClearButton = styled.div`
  ${GlowUpButton}
  display: inline-block;
  padding: 20px 60px;
  letter-spacing: 1.5px;
`

const FormItemChild = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    afterChildren: true,
    transition: { duration: 200 }
  },
  exit: { opacity: 0, x: -100, transition: { duration: 200 } },
  beforeEnter: { opacity: 0, x: 100 }
})

const UserLoginForm = ({ clearErrors }) => {
  return (
    <ErrorContainer onClick={clearErrors}>
      <FormItemChild>
        <Header>Account not found.</Header>
      </FormItemChild>
      <FormItemChild>
        <Body>
          Sorry, your email address is not registered in our system. Please make
          sure you have entered your details correctly.
        </Body>
      </FormItemChild>
      <FormItemChild>
        <ClearButton onClick={clearErrors}>TRY AGAIN</ClearButton>
      </FormItemChild>
    </ErrorContainer>
  )
}

export default UserLoginForm
