import React from 'react'
import styled, { css } from 'styled-components/macro'

const SheetContainer = styled.div`
  background: white;
  ${({ overflow }) =>
    overflow
      ? css`
          overflow: ${overflow};
        `
      : null}
  position: relative;
  border-radius: 8px 8px 0 0;
  padding: 72px 75px 0 72px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
  display: flex;
  @media (max-width: 760px) {
    padding: 30px 40px 0 30px;
  }
`

const SheetInner = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  grid-area: body;
  width: 100%;
`

export default function Sheet({ children, ...restProps }) {
  return (
    <SheetContainer {...restProps}>
      <SheetInner>{children}</SheetInner>
    </SheetContainer>
  )
}
