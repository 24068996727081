import styled from 'styled-components/macro'

const Outer = styled.div`
  position: ${({ position }) => position || 'fixed'};
  top: 56vh;
  width: 100%;
  padding-bottom: 25px;
  transition: top 1s ease-in-out;
  @media (max-width: 1200px) {
    top: 40vh;
  }

  @media (max-width: 768px) {
    top: 70vh;
  }
`

export default Outer
