import styled from 'styled-components/macro'

const ErrorItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: Gotham;
  font-weight: 200;
  margin-bottom: 5px;
  color: #ff5e5e;
`

export default ErrorItem
