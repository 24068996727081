import styled from 'styled-components/macro'

const LogoOuter = styled.div`
  height: 52px;
  width: 149px;
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 750px) {
    width: 92px;
  }
`

export default LogoOuter
