import React, { useLayoutEffect } from 'react'
import Input from 'components/Input'
import onKeyDownFormatToMaxLengthOf from 'lib/formatters/onKeyDownFormatToMaxLengthOf'
import onChangeFormatToMaxLengthOf from 'lib/formatters/onChangeFormatToMaxLengthOf'
import composeValidators from 'lib/composeValidators'
import createRequired from 'lib/validators/createRequired'
import { useFormData, Form } from 'lib/useForm'
import { path } from 'ramda'
import BtnShowHideAnimated from 'domains/shared/BtnShowHideAnimated'
import useGlobalStyleAdjuster from 'domains/shared/GlobalStyle/usable/useGlobalStyleAdjuster'
import { css } from 'styled-components/macro'
import Title from './styled/Title'
import Pretitle from './styled/Pretitle'
import Outer from './styled/Outer'
import InputOuter from './styled/InputOuter'

const inputCss = css`
  @media (max-width: 750px) {
    &::placeholder {
      font-size: 32px;
      letter-spacing: 15px;
      line-height: 70px;
    }
    font-size: 32px;
    line-height: 59px;
    letter-spacing: 8px;
  }
`

const inputOuterCss = css`
  @media (max-width: 750px) {
    max-width: 280px;
  }
`

const inputStyles = {
  fontSize: '48px',
  lineHeight: '61px',
  maxWidth: '390px',
  textAlign: 'center',
  inputMarginLeft: '-3%',
  placeHolderLetterSpacing: '15px',
  letterSpacing: '15px',
  extendInputWidth: '108%'
}

const codeValidations = composeValidators([
  value =>
    value && (value.length !== 8 || !/^[\da-zA-Z]*$/.test(value))
      ? 'INVALID_CODE'
      : undefined,
  createRequired('CODE_REQUIRED')
])

const onKeyDownFormatter = onKeyDownFormatToMaxLengthOf(8, {
  allowRgx: /^[\da-zA-Z]*$/
})
const onChangeFormatter = onChangeFormatToMaxLengthOf(8, {
  replaceRgx: /[^\da-zA-Z]/g
})

export default function UploadCodeLogin({ onSubmit }) {
  const { updateStyles } = useGlobalStyleAdjuster()

  useLayoutEffect(() => {
    updateStyles({
      body: {
        bgColor: '#FFFFFF'
      }
    })
  }, [updateStyles])

  const formData = useFormData()

  let valid
  if (!formData) {
    valid = false
  } else {
    valid = !path(
      ['docUploadCodePage', 'code', 'onValueChangeErrors', 'length'],
      formData
    )
  }
  // console.log(formData)

  return (
    <Outer>
      <Pretitle>Verify & Upload</Pretitle>
      <Title>Let's keep this secure. Just need your code.</Title>
      <Form name="docUploadCodePage" onSubmit={onSubmit}>
        <InputOuter>
          <Input
            {...inputStyles}
            formatOnChange={onChangeFormatter}
            formatOnType={onKeyDownFormatter}
            onValueChangeValidate={codeValidations}
            name="code"
            placeholder="********"
            inputCss={inputCss}
            outerCss={inputOuterCss}
          />
        </InputOuter>
        <BtnShowHideAnimated show={valid} as="button" maxWidth="200px">
          VERIFY ME
        </BtnShowHideAnimated>
      </Form>
    </Outer>
  )
}
