import React from 'react'
import SimpleBtn from 'domains/LoanRecommender/shared/components/SimpleBtn'
import { useTrail } from 'react-spring'
import Outer from './styled/Outer'
import Title from '../shared/styled/Title'
import Subtitle from '../shared/styled/Subtitle'
import BtnOuter from '../shared/styled/BtnOuter'

export default function Done({ onDoneClick, animationStatus }) {
  const childAnimations = useTrail(3, {
    from: {
      transform: 'translateY(70px)'
    },
    to: {
      transform: `translateY(0px)`
    },
    reverse: animationStatus === 'LEAVING'
  })

  return (
    <Outer>
      <Title style={childAnimations[0]}>Thank you.</Title>
      <Subtitle style={childAnimations[1]}>
        Your loan feedback is appreciated.
      </Subtitle>
      <BtnOuter style={childAnimations[2]}>
        <SimpleBtn onClick={onDoneClick} maxWidth="192px">
          DONE
        </SimpleBtn>
      </BtnOuter>
    </Outer>
  )
}
