import styled from 'styled-components/macro'

const Btn = styled.button`
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`

export default Btn
