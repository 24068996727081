import { css } from 'styled-components'

export default css`
  position: relative;
  overflow: hidden;
  border: 1px solid #4dffc9;
  color: white;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 1.5px;
  margin-top: 40px;
  transition: all 0.15s ease-in-out;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  &::before {
    transition: all 0.15s ease-in-out;
    content: '';
    z-index: -1;
    position: absolute;
    top: 15px;
    left: -10%;
    transform: scale(0.7);
    margin: -15px 0 0 1px;
    width: 120%;
    height: 100%;
    border-radius: 20px;
    background: rgba(77, 255, 201, 0);
    transform-origin: 50% 50%;
  }
  &:hover {
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    &::before {
      transform: scale(1);
      background: rgba(77, 255, 201, 1);
    }
  }
  ${props => props.theme.font('strong')}
`
