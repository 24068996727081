import styled from 'styled-components/macro'

const OuterLink = styled.a`
  color: ${({ active }) => (active ? '#0158FF' : '#00023F')};
  font-weight: ${({ active }) => (active ? '600' : '300')};
  font-family: Gotham;
  line-height: 20px;
  font-size: 15px;
  text-decoration: none !important;
  transition: all 0.3s;
`

export default OuterLink
