/* eslint no-restricted-syntax: 0 */
import React, { useMemo } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'

import LoginPage from 'domains/CurrentUser/Modals/Login'
import BaseWithTitleAndSidebar from 'domains/BizDash/Shared/layouts/BaseWithTitleAndSidebar'
import theme from 'domains/BizDash/Shared/styles/theme'
import DisclosureQueueConfig from 'domains/BizDash/DisclosureQueue'
import LoanOfficersConfig from 'domains/BizDash/LoanOfficers'
import PersonasConfig from 'domains/BizDash/Personas'
import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'

import CampaignsConfig from 'domains/BizDash/Campaigns'

function useGenerateNavListItems({ props, listItemsRenderables }) {
  const listOfConfigs = []

  // Internally, these 'renderable' functions
  // should use useMemo and return an obj{ header: obj, items: array}
  for (let renderable of listItemsRenderables) {
    listOfConfigs.push(renderable(props))
  }

  const mainNavList = useMemo(() => listOfConfigs, listOfConfigs)

  return mainNavList
}

export default {
  routes: props => {
    const mainNavLists = useGenerateNavListItems({
      listItemsRenderables: [
        // do not conditionally change length of this list,
        // you should change the length by conditionally excluding
        // items _within_ that given lists function.
        // THIS IS A LIST OF FUNCTIONS THAT RETURN {header: obj, items:[]}
        DisclosureQueueConfig.mainNavigation,
        PersonasConfig.mainNavigation,
        CampaignsConfig.mainNavigation,
        LoanOfficersConfig.mainNavigation
        // (function(props){ return {header: obj, items:[]}),
        // ...
      ],
      props
    })

    const { fetchCurrentUserIfNeeded } = useCurrentUser()[1]
    fetchCurrentUserIfNeeded()

    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/admin-sign-in" component={LoginPage} />
          <BaseWithTitleAndSidebar
            mainNavigation={() => mainNavLists}
            render={() => (
              <Switch>
                <Redirect
                  exact
                  from="/disclosures"
                  to="/disclosures/activity/all"
                />
                <Route
                  path="/disclosures"
                  render={({ match: { url } }) =>
                    DisclosureQueueConfig.routes({ url })
                  }
                />
                <Redirect exact from="/personas" to="/personas/overviews" />
                <Route
                  path="/personas"
                  render={({ match: { url } }) => (
                    <PersonasConfig.routes url={url} />
                  )}
                />
                <Route
                  path="/loanofficers"
                  render={({ match: { url } }) =>
                    LoanOfficersConfig.routes({ url })
                  }
                />
                <Route
                  path="/campaigns"
                  render={({ match: { url } }) =>
                    CampaignsConfig.routes({ url })
                  }
                />
              </Switch>
            )}
          />
        </Switch>
      </ThemeProvider>
    )
  }
}
