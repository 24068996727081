import { memo } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { animated } from 'react-spring'

const DotOrbit = styled(animated.div)`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`

const move = ({ angleDegrees }) => keyframes`
  0% {
    transform: rotate(${angleDegrees}deg);
  }
  100% {
    transform: rotate(${angleDegrees + 360}deg);
  }
`

const DotOrbitInner = memo(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: ${move} ${({ singleRevolutionDurMs }) => singleRevolutionDurMs}ms
    linear ${({ delay }) => delay || 0}s infinite;
`)

export { DotOrbit, DotOrbitInner }
