import React, { memo, useCallback, useRef } from 'react'
import useMouseEnterLeaveState from 'lib/useMouseEnterLeaveState'
import {
  DropdownPortal,
  useDropdownsData,
  useDropdownsActions
} from 'lib/DropdownsManager'
import DeleteIcon from './components/DeleteIcon'
import Outer from './styled/Outer'
import DropdownList from './components/DropdownList'

const emptyObj = {}
function ActionDropdown({ moveUp, status, id, cancelUpload, deleteFileById }) {
  // const [deleteIconIsHovered, setDeleteIconIsHovered] = useState()
  // const [isOpen, setIsOpen] = useState()
  const dropdownId = `tile-${id}`
  const {
    dropdowns: { [dropdownId]: { show: isOpen } = emptyObj }
  } = useDropdownsData()
  const { toggleDropdown } = useDropdownsActions()

  const onDeleteIconClick = useCallback(() => {
    toggleDropdown(dropdownId)
  }, [])

  const onDeleteItemClick = useCallback(() => {
    // if it's success or error
    if (status === 'success') {
      // console.log('delete flow on click')
      deleteFileById(id)
    } else if (status === 'error' || status === 'queued') {
      // console.log('remove local flow on click')
      // if the file is in an error state, or queued state (waiting),
      // we can just remove it locally, not working about
      // calling cancelUpload because it's either after
      // a frontend validation error, or never actually started
      // the upload process (it's not streaming to the server)
      deleteFileById(id, { removeLocal: true })
    } else if (status === 'finalizing') {
      console.warn('Not allowed to delete or cancel while finalizing.')
      // console.log('canceling and deleting flow on click')
      // cancelUpload()
      // deleteFileById(id)
    } else {
      // console.log('cancel flow on click')
      cancelUpload()
    }
  }, [id, status, deleteFileById, cancelUpload])

  const {
    handleMouseEnter,
    handleMouseLeave,
    isOver: isOverDeleteIcon
  } = useMouseEnterLeaveState()

  const dropdownPositionerRef = useRef()

  const renderDropdown = useCallback(
    ({ id: dId, show, positionerRef }) =>
      !!show && (
        <DropdownList
          id={dId}
          onDeleteItemClick={onDeleteItemClick}
          positionerRef={positionerRef}
          moveUp={moveUp}
          status={status}
        />
      ),
    [onDeleteItemClick, moveUp, status]
  )

  return (
    <Outer>
      {status !== 'deleting' &&
        status !== 'removing' &&
        status !== 'finalizing' && (
          <>
            <DeleteIcon
              innerRef={dropdownPositionerRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={onDeleteIconClick}
              darkPaint={isOverDeleteIcon || isOpen}
            />
            {!!isOpen && (
              <DropdownPortal
                id={dropdownId}
                positionerRef={dropdownPositionerRef}
                render={renderDropdown}
              />
            )}
          </>
        )}
    </Outer>
  )
}

export default memo(ActionDropdown)
