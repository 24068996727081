import styled from 'styled-components/macro'

const Shadow = styled.div`
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  height: 50px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.3);
`

export default Shadow
