import styled from 'styled-components/macro'

export default styled.div`
  padding: 21px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  color: #00023f;
  background: white;
  line-height: 1em;
  transition: all 0.07s ease-in-out;
  font-size: 12px;
`

export const FlyoutIcon = styled.div`
  margin-right: 12px;
  width: 16px;
  height: 16px;
`
