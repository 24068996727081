import useCurrentUser from '../useable/useCurrentUser'

const IfNotAuthenticated = ({ render, children, ...restProps }) => {
  const [{ currentUser, loading }] = useCurrentUser()
  return !loading && currentUser && currentUser.email === 'anon'
    ? render(restProps)
    : null
}

export default IfNotAuthenticated
