import React, { useState, useCallback, useEffect, useMemo } from 'react'
import useEditor, { collectFieldValuesFromEditors } from 'lib/useEditor'
import Editor from 'components/QuickEditor'
import Spinner from 'components/Spinner'
import LabelAndValue from 'domains/BizDash/Shared/styled/LabelAndValue'
import Label from 'domains/BizDash/Shared/styled/EditorInputLabel'
import EditSaveCancel from 'domains/BizDash/Shared/components/EditSaveCancel'
import SelectBox from 'components/QuickEditor/components/SelectBox'
import SelectTextDisplayer from 'components/QuickEditor/components/SelectTextDisplayer'
import NumberInput from 'components/QuickEditor/components/NumberInput'
import usePersonaOverviews from 'domains/BizDash/Personas/Shared/providers/PersonaOverviewsProvider/useable/usePersonaOverviews'
import { filter, propEq, indexBy, prop, path, isNil } from 'ramda'
import usePersonaGuidanceCombos from 'domains/BizDash/Personas/Shared/providers/PersonaGuidanceCombosProvider/useable/usePersonaGuidanceCombos'
import PersonaCodeMultiSelect from './components/PersonaCodeMultiSelect'
import PersonaCodeDisplayer from './components/PersonaCodeDisplayer'
import Outer from './styled/Outer'

function useConvertPersonaOverviewsToMultiSelectViaPersonaGuidanceComboId(
  personaGuidanceComboId
) {
  const [{ personaOverviews }] = usePersonaOverviews()
  // console.log(personaGuidanceComboId, personaOverviews)

  const [{ personaOverviewsGuidanceCombos }] = usePersonaGuidanceCombos()
  // console.log(personaOverviewsGuidanceCombos)
  return useMemo(() => {
    // first, get the personaOverviewsGuidanceCombos associated with the given personaGuidanceComboId
    const assocPersonaOverviewsGuidanceCombos = filter(
      propEq('personaGuidanceComboId', personaGuidanceComboId),
      personaOverviewsGuidanceCombos
    )

    const personaOverviewsGuidanceCombosByPoId = indexBy(
      prop('personaOverviewId'),
      assocPersonaOverviewsGuidanceCombos
    )

    return personaOverviews.map(personaOverview => {
      const personaOverviewsGuidanceCombo =
        personaOverviewsGuidanceCombosByPoId[personaOverview.id]
      if (personaOverviewsGuidanceCombo) {
        return {
          selected: true,
          text: personaOverview.code.toUpperCase(),
          value: personaOverview.code.toUpperCase(),
          key: personaOverview.id,
          meta: {
            personaOverviewsGuidanceComboId: personaOverviewsGuidanceCombo.id,
            personaOverviewId: personaOverview.id,
            personaGuidanceComboId
          }
        }
      }
      return {
        selected: false,
        text: personaOverview.code.toUpperCase(),
        value: personaOverview.code,
        key: personaOverview.id,
        meta: {
          personaOverviewId: personaOverview.id,
          personaGuidanceComboId
        }
      }
    })
  }, [personaOverviews, personaOverviewsGuidanceCombos])
}

const guidanceConstantSelectOptions = [
  {
    text: 'Highly Recommended',
    value: 'highly_recommended'
  },
  {
    text: 'Recommended with Caution',
    value: 'recommended_with_caution'
  },
  {
    text: 'Not Recommended',
    value: 'not_recommended'
  }
]

const loanPurposeTypeSelectOptions = [
  {
    text: 'Purchase',
    value: 'purchase'
  },
  {
    text: 'Refinance',
    value: 'refi'
  }
]

function GuidanceCombo({
  guidanceConstant,
  loanPurposeType,
  description,
  collisionBoost,
  id,
  onSave,
  onDelete,
  loading,
  errors,
  isNew
}) {
  const personaOverviewsMultiSelectItems = useConvertPersonaOverviewsToMultiSelectViaPersonaGuidanceComboId(
    id
  )
  // console.log(personaOverviewsMultiSelectItems)
  const [isEditing, setIsEditing] = useState(isNew || false)

  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [isEditing])

  const updatePersonaCodeEditedValue = ({
    editedValue: list,
    chosenValue: {
      selected,
      meta: { personaOverviewId: chosenValuePersonaOverviewId }
    }
  }) => {
    return list.map(item => {
      const {
        meta: { personaOverviewId }
      } = item
      if (personaOverviewId === chosenValuePersonaOverviewId) {
        return {
          ...item,
          selected
        }
      }
      return item
    })
  }

  const personaCodeEditor = useEditor({
    field: 'personaCode',
    value: personaOverviewsMultiSelectItems,
    isEditing,
    isLoading: loading,
    updateEditedValue: updatePersonaCodeEditedValue,
    errors
  })

  const guidanceConstantEditor = useEditor({
    field: 'guidanceConstant',
    value: guidanceConstant,
    isEditing,
    isLoading: loading,
    selectOptions: guidanceConstantSelectOptions,
    errors
  })

  const loanPurposeTypeEditor = useEditor({
    field: 'loanPurposeType',
    value: loanPurposeType,
    isEditing,
    isLoading: loading,
    selectOptions: loanPurposeTypeSelectOptions,
    errors
  })

  const collisionBoostEditor = useEditor({
    field: 'collisionBoost',
    value: collisionBoost,
    isEditing,
    isLoading: loading,
    errors,
    formatInput: val => (isNil(val) ? '' : val),
    formatOutput: val => {
      const numb = parseInt(val, 10)
      return Number.isNaN(numb) ? null : numb
    },
    noValueText: 'None'
  })

  const descEditor = useEditor({
    field: 'description',
    value: description,
    isEditing,
    isLoading: loading,
    errors
  })

  const onSaveClick = useCallback(() => {
    const data = collectFieldValuesFromEditors([
      guidanceConstantEditor,
      loanPurposeTypeEditor,
      descEditor,
      personaCodeEditor,
      collisionBoostEditor
    ])

    onSave({
      ...data,
      id
    })
  }, [
    onSave,
    id,
    personaCodeEditor,
    guidanceConstantEditor,
    loanPurposeTypeEditor,
    descEditor,
    collisionBoostEditor
  ])

  useEffect(() => {
    if (!isNew && !loading && !path(['length'], errors)) {
      setIsEditing(false)
    }
  }, [loading, errors, isNew])

  const handleDelete = useCallback(() => {
    const data = collectFieldValuesFromEditors([
      guidanceConstantEditor,
      loanPurposeTypeEditor,
      descEditor,
      personaCodeEditor,
      collisionBoostEditor
    ])

    onDelete({
      ...data,
      id
    })
  }, [
    onDelete,
    id,
    personaCodeEditor,
    guidanceConstantEditor,
    loanPurposeTypeEditor,
    descEditor,
    collisionBoostEditor
  ])

  return (
    <Outer>
      {loading && <Spinner borderRounded />}
      <LabelAndValue>
        <Label>Loan Purpose</Label>
        <Editor
          editor={loanPurposeTypeEditor}
          inputCollector={SelectBox}
          displayer={SelectTextDisplayer}
        />
      </LabelAndValue>
      <LabelAndValue>
        <Label>Persona Code</Label>
        <Editor
          editor={personaCodeEditor}
          inputCollector={PersonaCodeMultiSelect}
          displayer={PersonaCodeDisplayer}
        />
      </LabelAndValue>
      <LabelAndValue>
        <Label>Collision Booster</Label>
        <Editor editor={collisionBoostEditor} inputCollector={NumberInput} />
      </LabelAndValue>
      <LabelAndValue>
        <Label>Simple Guidance</Label>
        <Editor
          editor={guidanceConstantEditor}
          inputCollector={SelectBox}
          displayer={SelectTextDisplayer}
        />
      </LabelAndValue>
      <LabelAndValue>
        <Label>Long Description</Label>
        <Editor sz="sm" editor={descEditor}>
          {description}
        </Editor>
      </LabelAndValue>
      <EditSaveCancel
        isEditing={isEditing}
        neverShowCancel={isNew}
        saveText={isNew && 'Create'}
        onSaveClick={onSaveClick}
        onCancelClick={toggleIsEditing}
        onEditClick={toggleIsEditing}
        neverShowDelete={isNew}
        onDelete={handleDelete}
      />
    </Outer>
  )
}

export default GuidanceCombo
