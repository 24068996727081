import React from 'react'
import styled, { css } from 'styled-components/macro'
import { isNil } from 'ramda'

const Btn = styled.button`
  position: relative;
  display: ${({ display }) => display || 'inline-block'};
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: white;
  padding-top: 15px;
  padding-right: ${({ paddingRight }) =>
    !isNil(paddingRight) ? paddingRight : '26'}px;
  padding-bottom: 14px;
  padding-left: 26px;

  ${({ padding }) =>
    !isNil(padding)
      ? css`
          padding: ${padding};
        `
      : null}

  background-color: #4100f5;
  border-radius: 4px;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 22px;
  font-weight: 600;
  font-family: Gotham;
  letter-spacing: 0.27px;
  cursor: pointer;
  transition: opacity 0.1s;
  &:hover {
    background-color: #571bfd;
  }
  &:active {
    background-color: #3a00db;
  }

  ${({ customCss }) => customCss}
`

const SubmitBtnStyled = styled(Btn)``
export function SubmitBtn(props) {
  return <SubmitBtnStyled {...props} as="button" />
}

export default Btn
