/**
 *
 * Header.js
 *
 */

import React from 'react'
import styled, { css } from 'styled-components/macro'
import Logo from 'domains/shared/LogoAnimated'

const NavOpenerOuter = styled.div`
  position: relative;
  height: 20px;
  width: 35px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
  @media (max-width: 1155px) {
    display: block;
  }
`
const NavOpenerLine = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  height: 3px;
  border-radius: 3px;
  background-color: white;
  ${({ pos }) =>
    pos === 'top'
      ? css`
          top: 0;
        `
      : pos === 'middle'
      ? css`
          top: 50%;
          transform: translateY(-50%);
        `
      : pos === 'bottom'
      ? css`
          bottom: 0;
        `
      : null}
`

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  grid-area: header;
  margin: 0 45px;
  align-items: center;
  z-index: 100;
`

const LogoContainer = styled.div`
  flex: 0 1 116px;
`

const NavigationElements = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`

const Header = ({ children, showNavOpener, onNavOpenerClick }) => (
  <HeaderContainer>
    {!!showNavOpener && (
      <NavOpenerOuter onClick={onNavOpenerClick}>
        <NavOpenerLine pos="top" />
        <NavOpenerLine pos="middle" />
        <NavOpenerLine pos="bottom" />
      </NavOpenerOuter>
    )}
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <NavigationElements>{children}</NavigationElements>
  </HeaderContainer>
)

export default Header
