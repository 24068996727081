import styled from 'styled-components/macro'

const MainArea = styled.div`
  flex: 1;
  margin-right: 20px;
  @media (max-width: 1030px) {
    margin: 0 auto;
    max-width: 450px;
  }
`

export default MainArea
