import styled from 'styled-components/macro'

const Svg = styled.svg`
  position: absolute;
  right: 31px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: ${({ w }) => w};
  height: 14px;
  transform: translateX(${({ transRight }) => (transRight ? 10 : 0)}px);
  transition: 0.2s ease-in-out;
`

export default Svg
