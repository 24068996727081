/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import Btn from './styled/Btn'

function SimpleBtn({ onClick, component, children, ...restProps }) {
  function handleClick(e) {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }
  let Comp = Btn
  if (component) {
    Comp = component
  }
  return (
    <Comp onClick={handleClick} {...restProps}>
      {children}
    </Comp>
  )
}

export default SimpleBtn
