import React from 'react'
import IconSwatch from 'components/IconSwatch'
import HeaderArea from './styled/HeaderArea'
import Title from './styled/Title'
import IconOuter from './styled/IconOuter'

function Header({ title, iconImagePath, marginBottom }) {
  return (
    <HeaderArea marginBottom={marginBottom}>
      <Title>{title}</Title>
      <IconOuter>
        <IconSwatch iconImagePath={iconImagePath} />
      </IconOuter>
    </HeaderArea>
  )
}

export default Header
