import styled, { keyframes } from 'styled-components/macro'
import propOrDefault from 'lib/propOrDefault'

const animate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const propOrDefaultBg = propOrDefault(
  'backgroundColor',
  'rgba(255, 255, 255, 0.2)'
)

const SpinnerInner = styled.div`
  &, &:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid ${propOrDefaultBg};
  border-right: 5px solid ${propOrDefaultBg};
  border-bottom: 5px solid ${propOrDefaultBg};
  border-left: 5px solid ${propOrDefault('color', 'white')};
  transform: translateZ(0);
  animation: ${animate} .8s infinite linear;
}
`

export default SpinnerInner
