import React from 'react'
import Outer from './styled/Outer'
import Img from './styled/Img'
import docExamplePath from './images/doc-example.png'

export default function DocAnimated() {
  return (
    <Outer>
      <Img src={docExamplePath} />
    </Outer>
  )
}
