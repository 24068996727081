import React from 'react'
import TextAreaBase from './styled/TextArea'
import ErrorItem from './styled/ErrorItem'

export default function TextArea({ onInputChange, value, errors, cssStyles }) {
  return (
    <>
      <TextAreaBase
        cssStyles={cssStyles}
        onChange={onInputChange}
        value={value}
      />
      {!!errors &&
        !!errors.length &&
        errors.map(({ message }, i) => (
          <ErrorItem key={i}>{message}</ErrorItem>
        ))}
    </>
  )
}
