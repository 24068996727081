import React from 'react'
import PrettyBtn from 'components/PrettyBtn'
import CustomLink from 'components/CustomLink'
import { useTransition, animated } from 'react-spring'
import { css } from 'styled-components/macro'
import Outer from './styled/Outer'

const prettyBtnCss = css`
  margin-top: 16px;
  @media (max-width: 750px) {
    background-color: transparent !important;
    color: #00023f;
    margin-top: 8px;
    &:hover {
      opacity: 0.8;
    }
  }
`

export default function AuthArea({ isLoading, isLoggedIn }) {
  const visual = isLoading
    ? 'IS_LOADING_VISUAL'
    : isLoggedIn
    ? 'LOGGED_IN_VISUAL'
    : null
  // : 'LOGGED_OUT_VISUAL'

  const transitions = useTransition(visual, null, {
    from: { position: 'absolute', opacity: 0, transform: 'translateY(30px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-30px)' }
  })

  // don't show loading indicator because the request
  // will most likely be very fast
  const btns = transitions.map(({ item, key, props }) =>
    item === 'IS_LOADING_VISUAL' ? (
      <animated.div key={key} style={props} />
    ) : item === 'LOGGED_OUT_VISUAL' ? (
      <animated.div key={key} style={props}>
        <CustomLink
          as={PrettyBtn}
          fontSize="11px"
          padding="9px 14px"
          to="/auth"
          maxWidth="200px"
          outerCss={prettyBtnCss}
        >
          SIGN IN
        </CustomLink>
      </animated.div>
    ) : null
  )

  return <Outer>{btns}</Outer>
}
