import React from 'react'
import styled from 'styled-components/macro'
import MailToSvg from '../svg/Mailto'

const MailtoLinkContainer = styled.a`
  display: block;
  width: 100%;
  max-width: 80px;
  cursor: pointer;
`

const MailtoLink = ({ to }) => {
  const handleClick = event => {
    event.preventDefault()
    window.location.href = `mailto:${to}`
  }
  return (
    <MailtoLinkContainer onClick={handleClick}>
      <MailToSvg maxWidth={80} />
    </MailtoLinkContainer>
  )
}

export default MailtoLink
