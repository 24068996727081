import React from 'react'
import FlyoutDetailButton from 'domains/BizDash/Shared/components/FlyoutDetailButton'
import FlyoutMenuItem, {
  FlyoutIcon
} from 'domains/BizDash/Shared/components/FlyoutMenuItem'
import ResourceData from './styled/ResourceData'
import DownloadIcon from './svg/Download'
import ResetIcon from './svg/RefreshCcw'

const DetailCellRenderer = ({ rowData, columnData: { reset, download } }) => {
  const showResetButton = ['processing', 'error', 'shutdown'].includes(
    rowData.status
  )
  const showDownloadButton = ['processed', 'downloaded', 'printed'].includes(
    rowData.status
  )
  const showDetailButton = !['in_kafka_queue'].includes(rowData.status)
  return (
    <ResourceData>
      {showDetailButton && (
        <FlyoutDetailButton
          render={() => {
            return (
              <div>
                {showResetButton && (
                  <FlyoutMenuItem onClick={() => reset(rowData.id)}>
                    <FlyoutIcon>
                      <ResetIcon />
                    </FlyoutIcon>{' '}
                    Reset Doc
                  </FlyoutMenuItem>
                )}
                {showDownloadButton && (
                  <FlyoutMenuItem onClick={() => download(rowData.id)}>
                    <FlyoutIcon>
                      <DownloadIcon />
                    </FlyoutIcon>{' '}
                    Download
                  </FlyoutMenuItem>
                )}
              </div>
            )
          }}
        />
      )}
    </ResourceData>
  )
}

export default DetailCellRenderer
