import styled from 'styled-components/macro'

const ResourceData = styled.div`
  font-weight: 300;
  line-height: 30px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
`

export default ResourceData
