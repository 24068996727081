import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'react-final-form'
import InputField from '../../Shared/components/Form/TextInputField'
import DisplayFieldValue from '../../Shared/components/Form/DisplayFieldValue'

import useLoanPurposes from '../graphql/hooks/useLoanPurposes'

const StyledField = styled(Field)``

const FieldRow = styled.div`
  display: flex;
`

const applicationCampaignForm = ({ name }) => {
  const {
    loading: loadingLoanPurposes,
    fetchErrors: loanPurposeFetchErrors,
    data: loanPurposesData = {}
  } = useLoanPurposes()
  const { loanPurposes } = loanPurposesData

  return (
    <FieldRow>
      <div>
        {!loanPurposes && loadingLoanPurposes ? (
          <p>loading...</p>
        ) : loanPurposeFetchErrors && loanPurposeFetchErrors.length > 0 ? (
          <p>error loading loan purposes</p>
        ) : (
          <h3>
            <DisplayFieldValue
              name={`${name}.loanPurposeId`}
              mapping={loanPurposes.map(lpd => ({
                text: lpd.name,
                value: lpd.id
              }))}
            />{' '}
            (Required)
          </h3>
        )}
        <StyledField
          name={`${name}.leadSourceId`}
          placeholder="Lead Source ID"
          required
          component={InputField}
        />
      </div>
    </FieldRow>
  )
}

export default applicationCampaignForm
