import Ajv from 'ajv'
import ajvErrors from 'ajv-errors'
import { assocPath, isEmpty, reject, pipe, map } from 'ramda'

export const campaignFormSchema = () => {
  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      applicationCampaign: {
        type: 'object',
        required: ['campaignType', 'name'],
        properties: {
          campaignType: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          newLeadPostbackUrl: {
            type: 'string',
            format: 'uri'
          },
          leadSourceConfigs: {
            type: 'array',
            items: {
              type: 'object',
              required: ['loanPurposeId', 'leadSourceId'],
              leadSourceId: {
                type: 'string'
              },
              loanPurposeId: {
                type: 'string'
              },
              errorMessage: {
                required: {
                  loanPurposeId: 'required',
                  leadSourceId: 'required'
                }
              }
            }
          }
        },
        errorMessage: {
          required: {
            campaignType: 'required',
            name: 'required'
          }
        }
      }
    }
  }
}

const ajv = ajvErrors(new Ajv({ allErrors: true, jsonPointers: true }))
const validator = ajv.compile(campaignFormSchema())

const toIntIfCanParse = v => {
  const parsedValue = parseInt(v, 10)
  return Number.isNaN(parsedValue) ? v : parsedValue
}

export const campaignFormValidation = loanOfficerInput => {
  validator(loanOfficerInput)

  let errors = {}
  if (validator.errors) {
    validator.errors.forEach(obj => {
      // Massage ajv errors to the { 'fieldName': 'errorMessage' } format expected by redux-form
      if (obj.keyword === 'required') {
        errors[obj.params.missingProperty] = obj.message
      } else if (obj.keyword === 'errorMessage') {
        const pathArray = pipe(
          reject(isEmpty),
          map(toIntIfCanParse)
        )(obj.dataPath.split('/'))

        errors = assocPath(
          [...pathArray, obj.params.errors[0].params.missingProperty],
          obj.message,
          errors
        )
      } else {
        const pathArray = reject(isEmpty, obj.dataPath.split('/'))
        errors = assocPath(pathArray, obj.message, errors)
      }
    })
  }

  return errors
}
