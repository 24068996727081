/**
 *
 * Sidebar.js
 *
 */

import React, { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'
import { withRouter, NavLink, Link } from 'react-router-dom'

import MailToButton from 'domains/BizDash/Shared/components/MailtoButton'

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  color: #8384ff;
  padding-top: 100px;
  z-index: 1;
  transition: all 0.4s;
  overflow: hidden;
  @media (max-width: 1155px) {
    width: 0px;
    ${({ open }) =>
      open
        ? css`
            width: 300px;
            background-color: #3a02d5;
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
`

const SidebarBody = styled.div`
  flex: 1 0 auto;
  min-width: 300px;
`

const SidebarFooter = styled.div`
  flex: 0 0 auto;
  padding: 0 40px 40px 40px;
`

const SidebarSubnavComponenet = styled(NavLink)`
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: #8384ff;
  }
  text-decoration: none;
  position: relative;
  display: block;
  margin-left: 62px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1em;
  ${props => props.theme.font('medium')}
  &.active {
    &&& {
      color: white;
    }
    &::before {
      opacity: 1;
    }
  }
  &::before {
    position: absolute;
    content: '';
    flex: 0 0 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #4dffc9;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
`

const SidebarNavComponentContainer = styled.div`
  position: relative;
  padding-left: 42px;
  margin-bottom: 27px;
`

const SidebarNavComponenetLink = styled(({ active, ...rest }) => (
  <Link {...rest} />
))`
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit;
  }
  text-decoration: none;
  display: block;
  font-size: 16px;
  ${props => props.theme.font('medium')}
  &&& {
    ${props =>
      props.active &&
      `
      color: white;
    `}
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 124%;
    top: -12%;
    width: 3px;
    background: ${({ active }) => (active ? '#4dffc9' : 'transparent')};
  }
`

const SidebarNavComponent = props => (
  <SidebarNavComponentContainer>
    <SidebarNavComponenetLink {...props} />
  </SidebarNavComponentContainer>
)

const Sidebar = props => {
  const { navConfigs, open } = props

  return (
    <SidebarContainer open={open}>
      <SidebarBody>
        {(navConfigs || []).map(
          ({ header: { label, route, active }, items } = {}) => {
            return (
              <Fragment key={label}>
                <SidebarNavComponent
                  to={route}
                  active={active(props)}
                  key={label}
                >
                  {label}
                </SidebarNavComponent>
                {(items || []).map(navItemConfig => (
                  <SidebarSubnavComponenet
                    to={navItemConfig.route}
                    key={navItemConfig.label}
                  >
                    {navItemConfig.label}
                  </SidebarSubnavComponenet>
                ))}
              </Fragment>
            )
          }
        )}
      </SidebarBody>
      <SidebarFooter>
        <MailToButton to="support@lower.com">Contact</MailToButton>
      </SidebarFooter>
    </SidebarContainer>
  )
}

export default withRouter(Sidebar)
