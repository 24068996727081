import styled, { keyframes } from 'styled-components/macro'

// keyframes returns a unique name based on a hash of the contents of the keyframes
const move = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(1200%);
  }
`

const Wave = styled.div`
  display: block;
  width: 10%;
  height: 100%;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  box-shadow: 0px 0px 40px 30px #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.2;
  animation: ${move} 1s ease-in-out ${({ delay }) => delay || 0}s infinite;
`

export default Wave
