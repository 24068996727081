import React, { Fragment } from 'react'
import {
  useNotificationsData,
  useNotificationsActions
} from 'lib/notifications/useNotifications'

export default function NotificationsDisplayer() {
  const { sortedNotes } = useNotificationsData()
  const actions = useNotificationsActions()

  // sortedNotes.map((note)=>{
  //   console.log(note)
  // })

  return sortedNotes.map(({ render, component: Comp, ...rest }) => {
    if (render) {
      return (
        <Fragment key={rest.id}>{render({ ...rest, ...actions })}</Fragment>
      )
    }
    if (Comp) {
      return <Comp key={rest.id} {...rest} {...actions} />
    }
    return null
  })
}
