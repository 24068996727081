import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f9f9f9;
`

export default Outer
