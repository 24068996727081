import styled from 'styled-components/macro'
import patternSvg from '../svg/loai-bg-pattern.svg'

const PatternBg = styled.div`
  height: 600px;
  width: 100%;
  background: url(${patternSvg});
  background-size: auto auto;
  background-repeat: repeat;
`

export default PatternBg
