import styled, { css, keyframes } from 'styled-components/macro'

// keyframes returns a unique name based on a hash of the contents of the keyframes
const move = props => keyframes`
  ${
    props.side === 'left'
      ? css`
          0% {
            transform: translateX(130%);
          }
          100% {
            transform: translateX(-130%);
          }
        `
      : css`
          0% {
            transform: translateX(-130%);
          }
          100% {
            transform: translateX(130%);
          }
        `
  }
`

const Dot = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: ${move} 1s ease-in-out ${({ delay }) => delay || 0}s infinite;
`

export default Dot
