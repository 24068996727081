import { gql } from 'graphql.macro'

export default gql`
  mutation($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      isValid
      errors {
        field
        message
      }
    }
  }
`
