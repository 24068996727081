import styled from 'styled-components/macro'

const PageTitle = styled.div`
  flex: 1 1 auto;
  font-size: 36px;
  ${props => props.theme.font('strong')}
  @media (max-width: 760px) {
    font-size: 20px;
    line-height: 26px;
  }
`

export default PageTitle
