import React from 'react'
import styled from 'styled-components/macro'
import { cond, equals } from 'ramda'
import UserGenAvatar from './UserGenAvatar'
import useCurrentUser from '../useable/useCurrentUser'

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  ${props => {
    const avatarSize = cond([
      [equals('small'), () => 42],
      [equals('tiny'), () => 38],
      [() => true, () => 48]
    ])(props.size)
    return `        
            width: ${avatarSize}px;
            height: ${avatarSize}px;
            border-radius: 50%;
        `
  }}
`

export const InnerCircle = styled.div`
  background: gray;
  overflow: hidden;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  ${props => {
    const avatarSize = cond([
      [equals('small'), () => 38],
      [equals('tiny'), () => 32],
      [() => true, () => 42]
    ])(props.size)

    return `        
            width: ${avatarSize}px;
            height: ${avatarSize}px;
            border-radius: 50%;
            position: relative;
        `
  }}
`

const Avatar = props => {
  const { size, className, onClick } = props
  const [{ currentUser }] = useCurrentUser()
  return (
    <StyledAvatar className={className} size={size} onClick={onClick}>
      <InnerCircle size={size}>
        {/* Use this when we have access to a users profile */}
        {/* {user.avatar ? (
                        <div>{user.avatar}</div>
                    ) : ( */}
        <UserGenAvatar currentUser={currentUser} {...props} />
        {/* ) */}}
      </InnerCircle>
    </StyledAvatar>
  )
}

Avatar.propTypes = {}

export default Avatar
