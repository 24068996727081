import React, { useMemo } from 'react'
import RingBase from './styled/RingBase'
import Outer from './styled/Outer'
import InnerGlow from './styled/InnerGlow'
import DotsRing from './components/DotsRing'

function Ring({ style, innerGlowStyle }) {
  const { rawWidth, ...ringStyle } = style

  const interp = useMemo(
    () => style.rawWidth.interpolate.bind(style.rawWidth),
    [style.rawWidth.interpolate]
  )

  return (
    <Outer style={ringStyle}>
      <RingBase style={{ borderWidth: style.borderWidth }}>
        <InnerGlow style={innerGlowStyle} />
      </RingBase>
      <DotsRing
        opacity={0.3}
        dimFactor={1.15}
        totalDots={60}
        interpolateFromRingWidth={interp}
      />
      <DotsRing
        opacity={0.2}
        dimFactor={1.3}
        totalDots={60}
        interpolateFromRingWidth={interp}
      />
      <DotsRing
        opacity={0.15}
        dimFactor={1.45}
        totalDots={60}
        interpolateFromRingWidth={interp}
      />
    </Outer>
  )
}

export default Ring
