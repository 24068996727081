import React from 'react'
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgRefreshCcw = props => (
  <ResponsiveSvg wRatio={15} hRatio={13} maxWidth={20} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 15 13" {...props}>
      <g
        stroke="#00023F"
        strokeWidth={1.17}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 1.611v3.667h3.545M14 11.389V7.722h-3.545" />
        <path d="M12.517 4.667c-.61-1.78-2.06-3.113-3.84-3.53-1.781-.417-3.644.14-4.935 1.476L1 5.278m13 2.444l-2.742 2.665c-1.29 1.335-3.154 1.893-4.935 1.476-1.78-.417-3.23-1.75-3.84-3.53" />
      </g>
    </svg>
  </ResponsiveSvg>
)

export default SvgRefreshCcw
