import styled from 'styled-components/macro'

const Inner = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  padding: 17px 15px 14px 15px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'grey'};
  width: 100%;
`

export default Inner
