import { useEffect } from 'react'
import { ErrorNotification } from 'components/Notifications'
import { useNotificationsActions } from 'lib/notifications'
import extractErrorTypeFromApolloError from 'lib/extractErrorTypeFromApolloError'

const baseNoteConfig = {
  textPaddingRight: '30px',
  component: ErrorNotification,
  stretchToMaxWidth: '452px',
  tag: 'error'
}

export default function useShowErrorNotificationForResponseError({
  id,
  serverError,
  ...rest
}) {
  const { upsertNote } = useNotificationsActions()

  useEffect(() => {
    const errorType = extractErrorTypeFromApolloError(serverError)
    if (errorType === 'INTERNAL_SERVER_ERROR') {
      // debugger
      upsertNote(
        {
          ...baseNoteConfig,
          id,
          mainText: 'Uh oh. Looks like there was a problem.',
          subtext: 'Wait a few seconds and try again, then contact support.',
          ...rest
        },
        { prepend: true }
      )
    } else if (errorType === 'NETWORK_ERROR') {
      // debugger
      upsertNote(
        {
          ...baseNoteConfig,
          id,
          mainText: `Hmmm. We can't connect to the internet...`,
          subtext: `Please check your internet connection.`,
          ...rest
        },
        { prepend: true }
      )
    }
  }, [serverError])
}
