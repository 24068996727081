import styled from 'styled-components/macro'

const TextArea = styled.textarea`
  font-family: Gotham_Book;
  font-weight: 100;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.4px;
  width: 100%;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.27);
  ${({ cssStyles }) => cssStyles}
`

export default TextArea
