const enterKey = 13
const ctrlKey = 17
const cmdKey = 91
// const vKey = 86
// const cKey = 67
const deleteKey = 8
const backspaceKey = 46
// const aKey = 65
const leftKey = 37
const upKey = 38
const rightKey = 39
const downKey = 40

const specialMultiKeys = [ctrlKey, cmdKey]

const specialSingleKeys = [
  leftKey,
  upKey,
  rightKey,
  downKey,
  deleteKey,
  backspaceKey,
  enterKey
]

function isMultiAction(keyCode, keysDown) {
  return (
    Object.keys(keysDown).length &&
    specialMultiKeys.find(specialKeyCode => keysDown[specialKeyCode])
  )
}

function isSingleAction(keyCode) {
  return specialSingleKeys.find(specialKeyCode => specialKeyCode === keyCode)
}

function hasSelection({ start, end }) {
  return start !== end
}

export default function onKeyDownFormatToMaxLengthOf(
  maxLength,
  { allowRgx } = {}
) {
  return ({ event, value, keysDown = {}, getImmediateCaretPosition }) => {
    const { key, keyCode } = event
    // const notSpecialKeys =
    //   keyCode !== enterKey &&
    //   keyCode !== ctrlKey &&
    //   keyCode !== cmdKey &&
    //   keyCode !== vKey &&
    //   keyCode !== cKey &&
    //   keyCode !== deleteKey &&
    //   keyCode !== backspaceKey &&
    //   keyCode !== aKey &&
    //   keyCode !== leftKey &&
    //   keyCode !== upKey &&
    //   keyCode !== rightKey &&
    //   keyCode !== downKey

    const multiAction = isMultiAction(keyCode, keysDown)
    const singleAction = isSingleAction(keyCode, keysDown)

    if (!multiAction && !singleAction) {
      if (!allowRgx.test(key)) {
        event.preventDefault()
      } else if (
        value &&
        value.length >= maxLength &&
        !hasSelection(getImmediateCaretPosition())
      ) {
        event.preventDefault()
        return { value: value.slice(0, maxLength) }
      }
    }
  }
}
