import React from 'react'
import styled from 'styled-components/macro'

const StatusBubbleContainer = styled.div`
  flex: 0 0 8px;
  width: 8px;
  height: 8px;
  margin-right: 15px;
  border-radius: 50%;
  font-size: 0.7rem;
  background: ${props => props.color};
  vertical-align: center;
`

const StatusBubble = ({ color, emoji }) => (
  <StatusBubbleContainer color={color}>{emoji}</StatusBubbleContainer>
)

export default StatusBubble
