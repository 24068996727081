import styled from 'styled-components/macro'

// The goal for the Outer container
// is to stretch vert/horiz across the
// viewport, but stretch even more vertically
// and then move higher up above the viewport
// so we can avoid the weird 'cutting off the top'
// of our globe when using overflow hidden.
// Then we move the Inner container back down
// to the viewport top boundary, to make
// percent calculations easy.
// Essentially, we are trying to simulate
// overflow-x: hidden, overflow-y: visible,
// but that is not possible in any browser:
// https://www.brunildo.org/test/Overflowxy2.html.
// So I invented this solution.
const Outer = styled.div`
  height: 110vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: -10vh;
  left: 0;
`

export default Outer
