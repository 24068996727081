import { useEffect } from 'react'
import { useAppStore } from '../../store'

const useDisclosures = (args = { filter: null }) => {
  const { filter } = args
  const [
    disclosures,
    paging,
    loading,
    searchQuery,
    fetchDisclosures,
    fetchMoreDisclosures,
    resetDisclosure,
    downloadDisclosure
  ] = useAppStore((state, actions) => {
    return [
      state.disclosureQueue.disclosures.items,
      state.disclosureQueue.disclosures.paging,
      state.disclosureQueue.disclosures.loading,
      state.disclosureQueue.disclosures.searchQuery,
      actions.fetchDisclosures,
      actions.fetchMoreDisclosures,
      actions.resetDisclosure,
      actions.downloadDisclosure
    ]
  })

  useEffect(() => {
    fetchDisclosures({ filter, searchQuery })
  }, [searchQuery])

  return {
    disclosures,
    paging,
    loading: loading || disclosures === null,
    fetchMoreDisclosures,
    resetDisclosure,
    downloadDisclosure
  }
}

export default useDisclosures
