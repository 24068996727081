import React, { memo, useReducer, useCallback, useMemo, useRef } from 'react'
import isFunction from 'lodash/isFunction'
import {
  equals,
  omit,
  path,
  uniq,
  flatten,
  compose,
  map,
  filter,
  pick
} from 'ramda'
import useDebounce from 'lib/useDebounce'
import smartDeepMerge from 'lib/smartDeepMerge'
import { DataProvider, ActionProvider } from '../Context'

const UNREGISTER = 'UNREGISTER'
const UPDATE_ITEM = 'UPDATE_ITEM'
const UPDATE_ALL_ITEMS = 'UPDATE_ALL_ITEMS'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case UNREGISTER: {
      return {
        ...state,
        items: omit([payload.id], state.items)
      }
    }
    case UPDATE_ITEM: {
      return {
        ...state,
        items: smartDeepMerge(state.items, {
          [payload.id]: { id: payload.id, data: payload.data }
        })
      }
    }
    case UPDATE_ALL_ITEMS: {
      const res = {
        ...state,
        items: smartDeepMerge(state.items, payload.items)
      }
      return res
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    items: {}
  }
}

export function createWithMeasureDeepProvider(/* opts = {} */) {
  return function withMeasureDeepProvider(Child) {
    if (Child) {
      Child = memo(Child)
    }
    return function MeasureDeepProvider({ children, ...restProps }) {
      const [state, dispatch] = useReducer(reducer, {}, init)
      const prevStateRef = useRef(state)
      const {
        current: { items: prevItems }
      } = prevStateRef
      const { items } = state

      const unregister = useCallback(id => {
        dispatch({
          type: UNREGISTER,
          payload: {
            id
          }
        })
      }, [])

      const updateItem = useCallback((id, data) => {
        dispatch({
          type: UPDATE_ITEM,
          payload: {
            id,
            data
          }
        })
      }, [])

      const updateAllItems = useCallback(theItems => {
        dispatch({
          type: UPDATE_ALL_ITEMS,
          payload: {
            items: theItems
          }
        })
      }, [])

      useDebounce(
        () => {
          const itemsChanged = Object.values(items).filter(
            item =>
              path(['data', 'measureOnIdResizes'], item) &&
              prevItems[item.id] !== item
          )
          // console.log('itemChanged', itemsChanged)

          const idsForUpdate = compose(
            uniq,
            filter(item => item),
            flatten,
            map(path(['data', 'measureOnIdResizes']))
          )(itemsChanged)

          const updatedItems = idsForUpdate.reduce((acc, id) => {
            const elRef = path([id, 'data', 'ref'], items)
            const origRect = path([id, 'data', 'boundingClientRect'], items)
            const rect = pick(
              ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left'],
              elRef.getBoundingClientRect()
            )

            if (equals(rect, origRect)) {
              return acc
            }
            return {
              ...acc,
              [id]: {
                data: {
                  boundingClientRect: rect
                }
              }
            }
          }, {})
          if (Object.keys(updatedItems).length) {
            updateAllItems(updatedItems)
          }
        },
        { ms: 100, deps: [items] }
      )

      const actionContext = useMemo(() => {
        return {
          unregister,
          updateItem
        }
      }, [unregister, updateItem])

      const dataContext = useMemo(() => {
        return {
          items
        }
      }, [items])

      if (Child) {
        children = <Child {...restProps}>{children}</Child>
      } else if (isFunction(children)) {
        children = children(restProps)
      }
      prevStateRef.current = state
      return (
        <DataProvider value={dataContext}>
          <ActionProvider value={actionContext}>{children}</ActionProvider>
        </DataProvider>
      )
    }
  }
}

export const withMeasureDeepProvider = createWithMeasureDeepProvider()

export const MeasureDeepProvider = withMeasureDeepProvider()
