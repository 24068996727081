import React from 'react'
import ProgressBar from 'components/ProgressBar'
import ProgressBarOuter from './styled/ProgressBarOuter'

export default function NotificationProgressBar({
  currentBytesWorkingOrSuccess,
  totalBytesWorkingOrSuccess
}) {
  const fraction = currentBytesWorkingOrSuccess / totalBytesWorkingOrSuccess

  return (
    <ProgressBarOuter>
      <ProgressBar
        paint="white"
        waveBackgroundColor="rgba(65,0,245,.9)"
        fraction={fraction}
        height="4px"
        startAtZero
      />
    </ProgressBarOuter>
  )
}
