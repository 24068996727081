/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import personIconPath from './svg/person-icon.svg'
import Header from '../Shared/Header'
import Content from './styled/Content'
import Title from './styled/Title'
import Para from './styled/Para'
import OuterPersonaLabels from './styled/OuterPersonaLabels'
import PersonaLabels from './components/PersonaLabels'
import PersonaTopLabel from './components/PersonaTopLabel'

function Persona({
  personaName,
  personaContent = '',
  personaTopLabel,
  personaLabels
}) {
  const personaContentParas = personaContent.split(/\n/)

  return (
    <Outer>
      <Header
        title="Persona"
        iconImagePath={personIconPath}
        marginBottom="28px"
      />
      <Content>
        <Title>{personaName}</Title>
        {personaContentParas.map((para, i) => (
          <Para key={i}>{para}</Para>
        ))}
      </Content>
      {personaTopLabel && <PersonaTopLabel title={personaTopLabel} />}
      <OuterPersonaLabels>
        <PersonaLabels personaLabels={personaLabels} />
      </OuterPersonaLabels>
    </Outer>
  )
}

export default Persona
