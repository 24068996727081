import api from './api'
import {
  SET_DISCLOSURE_SEARCH_QUERY,
  FETCH_DISCLOSURES_LOADING,
  FETCH_DISCLOSURES_SUCCESS,
  FETCH_DISCLOSURES_ERROR,
  RESET_DISCLOSURE,
  FETCH_MORE_DISCLOSURES_SUCCESS
} from './constants'

export function fetchDisclosuresLoading(isLoading) {
  return {
    type: FETCH_DISCLOSURES_LOADING,
    payload: { isLoading }
  }
}

export function fetchDisclosuresSuccess(payload) {
  return {
    type: FETCH_DISCLOSURES_SUCCESS,
    payload
  }
}

export function fetchDisclosuresError(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_DISCLOSURES_ERROR,
      payload
    })
  }
}

export function fetchMoreDisclosuresSuccess(payload) {
  return {
    type: FETCH_MORE_DISCLOSURES_SUCCESS,
    payload
  }
}

export function fetchDisclosures(payload) {
  return async dispatch => {
    await dispatch(fetchDisclosuresLoading({ isLoading: true }))
    const {
      data: { items, paging }
    } = await api.getDisclosureQueue({
      statusFilter: payload.filter,
      searchQuery: payload.searchQuery
    })
    return dispatch(
      fetchDisclosuresSuccess({ data: { disclosures: { items, paging } } })
    )
  }
}

export function fetchMoreDisclosures(payload) {
  return async dispatch => {
    await dispatch(fetchDisclosuresLoading({ isLoading: true }))
    const {
      data: { items, paging }
    } = await api.getDisclosureQueue({
      statusFilter: payload.filter,
      searchQuery: payload.searchQuery,
      cursor: payload.cursor
    })
    return dispatch(
      fetchMoreDisclosuresSuccess({ data: { disclosures: { items, paging } } })
    )
  }
}

export function setSearchQuery(payload) {
  return dispatch => {
    dispatch({
      type: SET_DISCLOSURE_SEARCH_QUERY,
      payload
    })
  }
}

export function resetDisclosure(payload) {
  return async dispatch => {
    const data = await api.resetDisclosure(payload)
    dispatch({
      type: RESET_DISCLOSURE,
      payload: { data }
    })
  }
}

export function downloadDisclosure(payload) {
  return async () => {
    api.downloadDisclosure(payload)
  }
}

export default {
  resetDisclosure,
  fetchDisclosures,
  fetchMoreDisclosures,
  downloadDisclosure,
  fetchDisclosuresLoading,
  setSearchQuery
}
