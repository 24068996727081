import styled from 'styled-components/macro'

const TextArea = styled.textarea`
  font-size: ${({ sz }) => (sz === 'sm' ? '16px' : '20px')};
  line-height: ${({ sz }) => (sz === 'sm' ? '22px' : '26px')};
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 10px;
  color: #00023f;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  min-height: 150px;
`

export default TextArea
