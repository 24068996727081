import React, { useRef, useMemo } from 'react'
import {
  useCalcDropdownOffsets,
  useHideOnClickoutOfDropdown
} from 'lib/DropdownsManager'
import DropdownItem from '../DropdownItem'
import OuterDropdownList from './styled/OuterDropdownList'

export default function DropdownList({
  id,
  moveUp,
  status,
  onDeleteItemClick,
  positionerRef
}) {
  // console.log(positionerRef)
  const dropdownRef = useRef()
  let styles = useCalcDropdownOffsets({ positionerRef, dropdownRef })
  useHideOnClickoutOfDropdown(id, dropdownRef)

  const moveUpPx = moveUp ? -5 : 0
  // console.log(moveUp)
  styles = {
    ...styles,
    transform: `translateY(${moveUpPx}px)`
  }

  const deleteText = useMemo(() => {
    if (status === 'queued' || status === 'uploading') {
      return <>Cancel this upload.</>
    }
    if (status === 'finalizing' || status === 'success') {
      return <>Delete this file.</>
    }
    if (status === 'error') {
      return <>Remove failed file.</>
    }
  }, [status])

  return (
    <OuterDropdownList ref={dropdownRef} style={styles}>
      <DropdownItem onClick={onDeleteItemClick}>{deleteText}</DropdownItem>
    </OuterDropdownList>
  )
}
