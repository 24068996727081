import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import Overviews from './Overviews'
import GuidanceCombos from './GuidanceCombos'

export default {
  // if you need to customize these items in the mainNavigation,
  // then utilize props below and add deps to the array at bottom of useMemo
  // (useMemo is needed here otherwise our sidebar will be rerender EVERY TIME
  // an unrelated render happens)
  mainNavigation: () =>
    /* props */ useMemo(() => {
      return {
        header: {
          label: 'Personas',
          route: '/personas/overviews',
          active: ({ location: { pathname } }) => {
            return pathname.includes('/personas')
          }
        },
        items: [
          {
            label: 'Overviews',
            route: '/personas/overviews'
          },
          {
            label: 'Guidance',
            route: '/personas/guidance'
          }
        ]
      }
    }, []),
  routes: ({ url }) => (
    <Switch>
      <Route path={`${url}/overviews`} component={Overviews} />
      <Route path={`${url}/guidance`} component={GuidanceCombos} />
    </Switch>
  )
}
