import styled from 'styled-components/macro'

const Ring = styled.div`
  width: 37px;
  height: 37px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 7px solid #4100f5;
`

export default Ring
