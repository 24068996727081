import React from 'react'
import useCreateEventHandler from 'lib/useCreateEventHandler'
import SimpleBtn from 'domains/LoanRecommender/shared/components/SimpleBtn'
import { css } from 'styled-components/macro'
import GeneralError from 'domains/LoanRecommender/shared/components/GeneralError'
import Outer from './styled/Outer'
import Sentence from './styled/Sentence'
import ThumbIcon from './styled/ThumbIcon'
import BtnOuter from './styled/BtnOuter'
import thumbsUpSrc from './images/thumbs-up.png'
import thumbsDownSrc from './images/thumbs-down.png'

const btnBaseCss = css`
  & > * {
    transition: transform 0.2s;
  }
  &:hover > * {
    transform: rotate(-20deg);
  }
  &:active > * {
    transform: rotate(-25deg);
  }
`

const changeResponseBtnCss = `
  margin-right: 20px;
`

const firstBtnCss = css`
  margin-right: 20px;
  ${btnBaseCss}
`

const secondBtnCss = css`
  & > * {
    transform-origin: right bottom;
  }
  ${btnBaseCss}
`

const thumbsUpArgs = ['THUMBS_UP']
const thumbsDownArgs = ['THUMBS_DOWN']

export default function FeedbackSection({
  onChangeMyResponseClick,
  onChangeMyResponseCancelClick,
  onFeedbackCorrectStatusBtnClick,
  wantsToChangeResponse,
  didAlreadySubmit,
  generalError
}) {
  const onThumbsUpBtnClick = useCreateEventHandler(
    onFeedbackCorrectStatusBtnClick,
    thumbsUpArgs
  )
  const onThumbsDownBtnClick = useCreateEventHandler(
    onFeedbackCorrectStatusBtnClick,
    thumbsDownArgs
  )

  // if the user already submitted and hasn't chosen
  // to change their response, show thank you
  return (
    <Outer>
      {didAlreadySubmit && !wantsToChangeResponse ? (
        <>
          <Sentence>Thank you for your feedback.</Sentence>
          <BtnOuter>
            <SimpleBtn
              onClick={onChangeMyResponseClick}
              css={changeResponseBtnCss}
            >
              CHANGE MY RESPONSE
            </SimpleBtn>
          </BtnOuter>
        </>
      ) : (
        <>
          <Sentence>How'd I do?</Sentence>
          {generalError && <GeneralError>{generalError}</GeneralError>}
          <BtnOuter>
            <SimpleBtn onClick={onThumbsUpBtnClick} css={firstBtnCss}>
              <ThumbIcon src={thumbsUpSrc} />
            </SimpleBtn>
            <SimpleBtn onClick={onThumbsDownBtnClick} css={secondBtnCss}>
              <ThumbIcon src={thumbsDownSrc} />
            </SimpleBtn>
          </BtnOuter>
          {didAlreadySubmit && (
            <BtnOuter>
              <SimpleBtn
                borderColor="transparent"
                backgroundColor="transparent"
                onClick={onChangeMyResponseCancelClick}
              >
                CANCEL
              </SimpleBtn>
            </BtnOuter>
          )}
        </>
      )}
    </Outer>
  )
}
