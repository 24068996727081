import React from 'react'
import Input from 'components/Input'
import formatPhone from 'lib/formatters/formatPhone'
import validatePhone from 'lib/validators/phone'
import createRequired from 'lib/validators/createRequired'
import composeValidators from 'lib/composeValidators'
import { useFormData } from 'lib/useForm'
import { path } from 'ramda'
import BtnShowHideAnimated from 'domains/shared/BtnShowHideAnimated'
import { inputStyles } from '../shared/pallette'
import Title from '../shared/styled/Title'
import InputOuter from './styled/InputOuter'
import Outer from './styled/Outer'

const phoneValidations = composeValidators([
  validatePhone,
  createRequired('PHONE_NUMBER_REQUIRED')
])

export default function PhonePage() {
  const formData = useFormData()

  let valid
  if (!formData) {
    valid = false
  } else {
    valid = !path(
      ['phonePage', 'phone', 'onValueChangeErrors', 'length'],
      formData
    )
  }

  return (
    <Outer>
      <Title>Please enter your phone number.</Title>
      <InputOuter>
        <Input
          {...inputStyles}
          formatOnBlur={formatPhone}
          onValueChangeValidate={phoneValidations}
          name="phone"
          placeholder="(999) 999 9999"
        />
      </InputOuter>
      <BtnShowHideAnimated show={valid} as="button" maxWidth="200px">
        SEND CODE
      </BtnShowHideAnimated>
    </Outer>
  )
}
