import styled from 'styled-components/macro'

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 21px;
  margin-bottom: 11px;
`

export default Title
