import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const ProgressBarOuter = styled(animated.div)`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
`

export default ProgressBarOuter
