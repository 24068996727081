import { useQuery } from '@apollo/react-hooks'

import GET_LOAN_OFFICERS from '../LoanOfficersQuery'

export default function useLoanOfficers() {
  const { loading, errors, data } = useQuery(GET_LOAN_OFFICERS, {
    fetchPolicy: 'cache-and-network'
  })

  return {
    loading,
    data,
    errors
  }
}
