import styled from 'styled-components/macro'

const Pretitle = styled.h1`
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 28px;
  font-size: 18px;
  color: #0600f5;
  margin-bottom: 14px;
`

export default Pretitle
