import { useRef, useState, useEffect, useCallback } from 'react'

export default function useTicker(run, timeoutMs) {
  const [flag, setFlag] = useState(run)
  const firstTickTimestamp = useRef()

  function flipFlag() {
    setFlag(f => !f)
  }
  useEffect(() => {
    function startStepTimer(fn) {
      let t
      function tick() {
        t = setTimeout(() => {
          fn()
          t && tick()
        }, timeoutMs)
      }
      tick()
      return () => {
        clearTimeout(t)
        t = null
      }
    }

    let stop
    if (run) {
      firstTickTimestamp.current = null
      stop = startStepTimer(flipFlag)
    }
    return () => {
      if (stop) {
        stop()
      }
      firstTickTimestamp.current = null
    }
  }, [run, timeoutMs])

  const getFirstTickTimestamp = useCallback(() => {
    if (firstTickTimestamp.current) {
      return firstTickTimestamp.current
    }
    firstTickTimestamp.current = Date.now()
    return firstTickTimestamp.current
  }, [])

  return {
    tickFlag: flag,
    getFirstTickTimestamp
  }
}
