/*
 * ListAndCloseIcon
 *
 */

import React from 'react'
import Outer from './styled/Outer'
import { Line1, Line2, Line3 } from './styled/Line'
import ClickProvider from './providers/ClickProvider'
import HoverProvider from './providers/HoverProvider'

class ListAndCloseIcon extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      open,
      hover,
      onClick,
      onMouseEnter,
      onMouseLeave,
      startDesign
    } = this.props
    return (
      <Outer
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Line1 hover={hover} rotated={open} startDesign={startDesign} />
        <Line2 hover={hover} visible={!open} startDesign={startDesign} />
        <Line3 hover={hover} rotated={open} startDesign={startDesign} />
      </Outer>
    )
  }
}

export default HoverProvider(ClickProvider(ListAndCloseIcon))
