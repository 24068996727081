const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const formatDisclosureDate = date => {
  const parsedDate = new Date(Date.parse(date))
  const day = parsedDate.getDate()
  const monthIndex = parsedDate.getMonth()
  const year = parsedDate.getFullYear()
  return `${day} ${monthNames[monthIndex]} ${year}`
}

export default formatDisclosureDate
