import React from 'react'
import { PlusTile } from 'components/Tiles'
import { css } from 'styled-components/macro'
import Outer from './styled/Outer'
import FileTile from './components/FileTile'
import OuterTile from './styled/OuterTile'

const outerTileCss = css`
  @media (max-width: 750px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

// when we are stretched tiles for mobile,
// we want the removal of a tile to animate
// shinking vertically, so swap width prop
// for height css prop when animating to 0.
// We use !important here for an optimization
// technique, to avoid excessive rerenders of
// a styled-component (expensive), we simply
// override the passed-in style Object height
// to animate to 0px on removal during MOBILE
const wrapperTileCss = css`
  @media (max-width: 750px) {
    transition: height ${({ transitionDurMs }) => transitionDurMs}ms;
    width: 100%;
    height: ${({ status }) =>
      status === 'removing' ? '0px !important' : 'auto'};
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const ringsOuterCss = css`
  @media (max-width: 750px) {
    right: 115px;
  }
`

// IGNORE SINCE IT'S JUST FOR EXAMPLE
// eslint-disable-next-line
const testFiles = [
  {
    id: 1,
    status: 'uploading',
    progress: 0.7,
    fileData: {
      lastModified: 1570475277383,
      name: 'Screen Shot 2019-10-07 at 3.07.52 PM copy.png',
      size: 193131,
      type: 'image/png',
      webkitRelativePath: ''
    },
    uploadDate: new Date()
  },
  {
    status: 'queued',
    progress: 0,
    id: 2,
    fileData: {
      lastModified: 1570475277383,
      name: 'Screen Shot 2019-12-06 at 3.07.52 PM copy.png',
      size: 193131,
      type: 'image/jpeg',
      webkitRelativePath: ''
    },
    uploadDate: new Date()
  },
  {
    id: 3,
    status: 'success',
    progress: 1,
    fileData: {
      lastModified: 1570475277383,
      name: 'Screen Shot 2019-10-03 at 3.07.52 PM copy.png',
      size: 193131,
      type: 'application/pdf',
      webkitRelativePath: ''
    },
    uploadDate: new Date()
  },
  {
    id: 4,
    status: 'error',
    errorType: 'NETWORK_ERROR',
    progress: 0,
    fileData: {
      lastModified: 1570475277383,
      name: 'Screen Shot 2019-10-03 at 3.07.52 PM copy.png',
      size: 193131,
      type: 'application/msword',
      webkitRelativePath: ''
    },
    uploadDate: new Date()
  }
]

export default function DocsCurrent({
  browseFiles,
  files,
  deleteFileById,
  fileRemovalCompleteById
}) {
  // files = testFiles
  return (
    <Outer>
      <OuterTile customCss={outerTileCss}>
        <PlusTile label="Upload" onClick={browseFiles} hasBoxShadow={false} />
      </OuterTile>
      {files.map((file, idx) => (
        <FileTile
          key={file.id}
          delayFadeMs={idx * 50}
          file={file}
          outerTileCss={outerTileCss}
          wrapperTileCss={wrapperTileCss}
          ringsOuterCss={ringsOuterCss}
          deleteFileById={deleteFileById}
          fileRemovalCompleteById={fileRemovalCompleteById}
        />
      ))}
    </Outer>
  )
}
