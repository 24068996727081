import styled from 'styled-components/macro'

const Btn = styled.a`
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid rgba(41, 230, 209, 0.6);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  cursor: pointer;
  &:hover {
    border-color: rgba(41, 230, 209, 1);
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export default Btn
