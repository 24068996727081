import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DocManager from 'domains/DocManager'
// import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'
// import ForceAuthenticate from 'domains/CurrentUser/Authenticated'
const skipAnimation = process.env.NODE_ENV !== 'production'

export default function ConsumerPortal({ match: { url: matchUrl } }) {
  // const { fetchCurrentUserIfNeeded } = useCurrentUser()[1]
  // fetchCurrentUserIfNeeded()
  return (
    <>
      <Switch>
        <Route path={`${matchUrl}/doc-manager`} render={(props)=><DocManager {...props} skipAnimation={skipAnimation}/>} />
        <Redirect from="*" to={`${matchUrl}/doc-manager`} />
      </Switch>
    </>
  )
}

// <Fragment>
//   <ForceAuthenticate
//     allowedAfterLoginPathRgx={
//       new RegExp(`^${matchUrl}/doc-manager/(upload|all-docs)$`)
//     }
//     redirectToPath="/auth"
//     render={() => (
//       <Switch>
//         <Route path={`${matchUrl}/doc-manager`} component={DocManager} />
//         <Redirect from="*" to={`${matchUrl}/doc-manager`} />
//       </Switch>
//     )}
//   />
// </Fragment>
