import React, { useState, useEffect, useRef, useCallback } from 'react'
import SheetHeader from 'domains/BizDash/Shared/components/Sheet/styled/SheetHeader'
import PageTitle from 'domains/BizDash/Shared/styled/PageTitle'
import Sheet from 'domains/BizDash/Shared/components/Sheet'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import { withPersonaOverviewsProvider } from 'domains/BizDash/Personas/Shared/providers/PersonaOverviewsProvider'
import usePersonaOverviews from 'domains/BizDash/Personas/Shared/providers/PersonaOverviewsProvider/useable/usePersonaOverviews'
import { withPersonaGuidanceCombosProvider } from 'domains/BizDash/Personas/Shared/providers/PersonaGuidanceCombosProvider'
import usePersonaGuidanceCombos from 'domains/BizDash/Personas/Shared/providers/PersonaGuidanceCombosProvider/useable/usePersonaGuidanceCombos'
import { compose, prop, filter, map, equals } from 'ramda'
import getStatus from 'domains/shared/utils/getStatus'
import uuid from 'lib/uuid'
import GuidanceCombo from './components/GuidanceCombo'

function filterViaFuncAndCreateMissingIds({
  filter: func,
  items: personaCode,
  createMissingIds
}) {
  return compose(
    map(
      ({
        meta: {
          personaGuidanceComboId,
          personaOverviewId,
          personaOverviewsGuidanceComboId
        }
      }) => {
        return {
          id: createMissingIds
            ? personaOverviewsGuidanceComboId || uuid()
            : personaOverviewsGuidanceComboId,
          personaOverviewId,
          personaGuidanceComboId
        }
      }
    ),
    filter(func)
  )(personaCode)
}

function GuidanceCombos() {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false)
  const newItemId = useRef()

  const onAddNewClick = useCallback(() => {
    setIsAddingNewItem(!isAddingNewItem)
  }, [isAddingNewItem])

  const { fetchPersonaOverviews } = usePersonaOverviews()[1]
  const [
    { personaGuidanceCombos, statuses },
    {
      fetchPersonaGuidanceCombos,
      createPersonaGuidanceCombo,
      updatePersonaGuidanceCombo,
      deletePersonaGuidanceCombo,
      createPersonaOverviewsGuidanceCombos,
      deletePersonaOverviewsGuidanceCombos
    }
  ] = usePersonaGuidanceCombos()

  useEffect(() => {
    fetchPersonaOverviews()
    fetchPersonaGuidanceCombos()
  }, [])

  const onCreate = useCallback(
    async data => {
      const {
        id,
        description,
        guidanceConstant,
        loanPurposeType,
        personaCode,
        collisionBoost
      } = data
      // handle creation of 1 personaGuidanceCombo
      const { success: createPGCSuccess } = await createPersonaGuidanceCombo({
        id,
        description,
        guidanceConstant,
        loanPurposeType,
        collisionBoost
      })

      let createPOGCSuccess

      if (createPGCSuccess) {
        console.log('ready to create personaOverviewsGuidanceCombos')
        const pogcs = filterViaFuncAndCreateMissingIds({
          filter: prop('selected'),
          items: personaCode,
          createMissingIds: true
        })

        ;({
          success: createPOGCSuccess
        } = await createPersonaOverviewsGuidanceCombos(pogcs))
      }

      if (createPGCSuccess && createPOGCSuccess) {
        setIsAddingNewItem(false)
      }

      // handle creation of N personaOverviewsGuidanceCombos
    },
    [personaGuidanceCombos]
  )

  const onUpdate = useCallback(
    async data => {
      const {
        id,
        description,
        guidanceConstant,
        loanPurposeType,
        personaCode,
        collisionBoost
      } = data

      // handle update of 1 personaGuidanceCombo
      const { success: updatePGCSuccess } = await updatePersonaGuidanceCombo({
        id,
        description,
        guidanceConstant,
        loanPurposeType,
        collisionBoost
      })

      if (updatePGCSuccess) {
        console.log('ready to update personaOverviewsGuidanceCombos')
        const selectedPogcs = filterViaFuncAndCreateMissingIds({
          filter: prop('selected'),
          items: personaCode,
          createMissingIds: true
        })
        let deletablePogcs = filterViaFuncAndCreateMissingIds({
          filter: compose(
            equals(false),
            prop('selected')
          ),
          items: personaCode
        })
        deletablePogcs = filter(prop('id'), deletablePogcs)

        if (selectedPogcs.length) {
          await createPersonaOverviewsGuidanceCombos(selectedPogcs)
        }
        if (deletablePogcs.length) {
          await deletePersonaOverviewsGuidanceCombos(deletablePogcs)
        }
      }

      // This needs to be handled via the loading flag in ./components/GuidanceCombos
      // but it needs to be a composite value of
      // personaGuidanceCombo loading and N personaOverviewsGuidanceCombos loading
      // if (updatePGCSuccess && createPOGCSuccess && deletePOGCSuccess) {
      //   setIsAddingNewItem(false)
      // }

      // handle deletion of N personaOverviewsGuidanceCombos (selected: false) if exists
      // handle creation of N personaOverviewsGuidanceCombos (selected: true)
    },
    [personaGuidanceCombos]
  )

  const onDelete = useCallback(
    async data => {
      const { personaCode } = data

      const { success: deletePGCSuccess } = await deletePersonaGuidanceCombo(
        data
      )

      if (deletePGCSuccess) {
        let deletablePogcs = map(
          ({
            meta: {
              personaGuidanceComboId,
              personaOverviewId,
              personaOverviewsGuidanceComboId
            }
          }) => {
            return {
              id: personaOverviewsGuidanceComboId,
              personaOverviewId,
              personaGuidanceComboId
            }
          },
          personaCode
        )
        deletablePogcs = filter(prop('id'), deletablePogcs)
        if (deletablePogcs.length) {
          await deletePersonaOverviewsGuidanceCombos(deletablePogcs)
        }
      }

      // This needs to be handled via the loading flag in ./components/GuidanceCombos
      // but it needs to be a composite value of
      // personaGuidanceCombo loading and N personaOverviewsGuidanceCombos loading
      // if(deletePGCSuccess && deletePOGCSuccess){

      // }
    },
    [personaGuidanceCombos]
  )

  useEffect(() => {
    newItemId.current = uuid()
  }, [isAddingNewItem])

  return (
    <Sheet>
      <SheetHeader>
        <PageTitle>Persona Guidance</PageTitle>
        <SimpleBtn onClick={onAddNewClick}>
          {isAddingNewItem ? 'Cancel New' : 'Add New'}
        </SimpleBtn>
      </SheetHeader>
      <div>
        {isAddingNewItem && (
          <GuidanceCombo
            id={newItemId.current}
            isNew
            onSave={onCreate}
            loanPurposeType="purchase"
            guidanceConstant="highly_recommended"
          />
        )}
        {personaGuidanceCombos.map(guidanceCombo => (
          <GuidanceCombo
            loading={getStatus('loading', guidanceCombo.id, statuses)}
            key={guidanceCombo.id}
            onSave={onUpdate}
            onDelete={onDelete}
            {...guidanceCombo}
          />
        ))}
      </div>
    </Sheet>
  )
}

export default compose(
  withPersonaOverviewsProvider,
  withPersonaGuidanceCombosProvider
)(GuidanceCombos)
