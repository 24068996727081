import styled, { css } from 'styled-components/macro'

const createCircleCss = side => ({ rotated, startDesign }) => {
  if (rotated) {
    // if we are an 'X'
    return ''
  }
  if (startDesign === 'filter') {
    // if we are resembling a 'filter icon'
    return css`
      &:before {
        content: ' ';
        position: absolute;
        ${side}: 12%;
        top: 1px;
        transform: translate(0, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: white;
        transition: all 0.3s;
        ${({ hover }) =>
          hover
            ? css`
                ${side}: 50%;
              `
            : ''}
      }
    `
  }
  // this is the list icon
  return ''
}

const leftCircleCss = createCircleCss('left')
const rightCircleCss = createCircleCss('right')

const LineBase = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  position: absolute;
  left: 0;
  background-color: ${({ backgroundColor }) => backgroundColor || '#11003F'};
  transition: opacity 0.25s 0.2s, transform 0.25s 0.2s, top 0.25s 0.2s,
    width 0.25s 0.2s, background-color 0.2s;
`

const Line1 = styled(LineBase)`
  top: ${props => (props.rotated ? '6px' : 0)};
  transform: rotate(${props => (props.rotated ? '45deg' : 0)});
  ${leftCircleCss}
`

const Line2 = styled(LineBase)`
  top: 6px;
  opacity: ${props => (props.visible ? 1 : 0)};
  ${({ startDesign }) =>
    startDesign === 'filter'
      ? css`
          opacity: 0;
        `
      : ''}
`

function getLine3({ rotated, startDesign }) {
  if (rotated) {
    // if we are an 'X'
    return css`
      top: 6px;
      width: 20px;
      transform: rotate(-45deg);
    `
  }
  if (startDesign === 'filter') {
    // if we are resembling a 'filter icon'
    return css`
      top: 8px;
      width: 20px;
      transform: rotate(0);
    `
  }
  // this is the list icon
  return css`
    top: 12px;
    width: 20px;
    transform: rotate(0);
  `
}

const Line3 = styled(LineBase)`
  ${rightCircleCss}
  ${getLine3}
`

export { Line1, Line2, Line3 }
