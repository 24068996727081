import styled from 'styled-components/macro'

const Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  font-family: Gotham_Book;
  letter-spacing: 0.25px;
  margin-bottom: 15px;
`

export default Title
