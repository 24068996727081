import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const RingBase = styled(animated.div)`
  border-radius: 50%;
  border: 7px solid #4100f5;
  height: 44px;
  width: 44px;
  top: 50%;
  left: 50%;
  position: absolute;
  @media (max-width: 400px) {
    height: 30px;
    width: 30px;
    border-width: 5px;
  }
`

export default RingBase
