import React, { memo, useEffect } from 'react'
import { path, isNil } from 'ramda'
import { useNotificationsActions } from 'lib/notifications'
import { ErrorNotification } from 'components/Notifications'
import extractErrorTypeFromApolloError from 'lib/extractErrorTypeFromApolloError'
import useCurrentLoanUploaderLogout from 'domains/DocManager/hocs/withUploadCodeLogin/useable/useCurrentLoanUploaderLogout'

import FooterPopBtn from '../FooterPopNav/styled/FooterPopBtn'

const DoneUploadingLogoutBtn = memo(function DoneUploadingLogoutBtnComp(props) {
  const { logout, error: logoutNetworkError } = useCurrentLoanUploaderLogout()
  const { upsertNote, dismissingNote } = useNotificationsActions()

  async function onSignOutClick() {
    dismissingNote('LOGOUT_NETWORK_ERROR')
    try {
      await logout()
    } catch (e) {
      // handle error from hook itself
      if (extractErrorTypeFromApolloError(e)) {
        return
      }
      throw e
    }
  }

  const onSignOutHandler = e => {
    e.preventDefault()
    onSignOutClick()
  }

  useEffect(() => {
    const statusCode = path(['networkError', 'statusCode'], logoutNetworkError)
    if (!isNil(statusCode)) {
      upsertNote(
        {
          id: 'LOGOUT_NETWORK_ERROR',
          textPaddingRight: '30px',
          component: ErrorNotification,
          stretchToMaxWidth: '452px',
          tag: 'error',
          mainText: 'Uh oh. There was a problem logging you out.',
          subtext:
            'Wait a few seconds and try again. Ensure your internet connection in working.'
        },
        { prepend: true }
      )
    }
  }, [logoutNetworkError])

  return (
    <form onSubmit={onSignOutHandler}>
      <FooterPopBtn
        fontSize="14px"
        padding="20px 14px"
        width="220px"
        preventDefault={false}
      >
        I'M DONE
      </FooterPopBtn>
    </form>
  )
})

export default DoneUploadingLogoutBtn
