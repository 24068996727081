import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const DotsRing = styled(animated.div)`
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`

export default DotsRing
