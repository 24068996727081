import React, { useEffect, useState, useCallback, useRef } from 'react'
import GalaxyAnimation from 'components/GalaxyAnimation'
import { useSpring } from 'react-spring'
import createCancellableTimelineAsync from 'lib/createCancellableTimelineAsync'
import delay from 'lib/delay'
import easing from 'lib/easing'
// import useTicker from 'lib/useTicker'
import useSafeDispatch from 'lib/useSafeDispatch'
import UnderLay from './styled/Underlay'
import Outer from './styled/Outer'

export default function withGalaxyIntroAnimation(Child) {
  return function GalaxyIntroAnimation(props) {
    const { skipAnimation = true } = props

    const [status, _setStatus] = useState('expand')
    const setStatus = useSafeDispatch(_setStatus)

    async function runAllSteps({ call }) {
      async function runAllStatuses() {
        await call(setStatus, 'expand')
        await call(delay, 1000)
        await call(setStatus, 'dropped')
        // await call(delay, 2000)
      }

      try {
        await Promise.all([runAllStatuses()])
      } catch (e) {
        if (e.message === 'CANCELLED') {
          // console.log('handled cancelled!')
          return
        }
        throw e
      }
    }

    const cancellableRunAllStepsRef = useRef()
    let cancellableRunAllSteps = createCancellableTimelineAsync(
      runAllSteps,
      cancellableRunAllStepsRef
    )

    const [readyToRemove, _setReadyToRemove] = useState(false)
    const setReadyToRemove = useSafeDispatch(_setReadyToRemove)

    // status = 'expand'
    useEffect(() => {
      if (status === 'expand' || status === 'dropped') {
        setReadyToRemove(false)
      }
    }, [status])

    const onReadyToRemove = useCallback(() => {
      setStatus('hide')
      setReadyToRemove(true)
    }, [])

    // const isIntroOverlayVisible = status !== 'hide'

    const underlayAnims = useSpring({
      opacity: status === 'dropped' || status === 'hide' ? 0 : 1,
      config: {
        easing: easing.easeInOutCubic,
        duration: 500
      }
    })

    // const { tickFlag, getFirstTickTimestamp } = useTicker(
    //   true,
    //   5000
    // )

    useEffect(() => {
      if (!skipAnimation) {
        cancellableRunAllSteps.cancel()
        const run = createCancellableTimelineAsync(
          runAllSteps,
          cancellableRunAllStepsRef
        )
        run()
      } else {
        cancellableRunAllSteps.cancel()
        setStatus('hide')
      }
    }, [
      /* tickFlag */
      skipAnimation
    ])

    return (
      <>
        {!readyToRemove && (
          <Outer>
            {/* DON'T INCLUDE UNDERLAY IN TESTS BECAUSE
               REACT-SPRING IS FORCING AN UPDATE IN AN ANIMATION
               FRAME CAUSING TEST TO THROW */
            process.env.NODE_ENV !== 'test' && (
              <UnderLay style={underlayAnims} />
            )}
            <GalaxyAnimation
              status={status}
              onReadyToRemove={onReadyToRemove}
            />
          </Outer>
        )}
        <Child isIntroOverlayVisible={!readyToRemove} {...props} />
      </>
    )
  }
}
