import styled from 'styled-components/macro'

const Outer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  border-radius: 5px;
`

export default Outer
