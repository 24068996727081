import styled from 'styled-components/macro'

const Code = styled.div`
  font-size: 40px;
  line-height: 1px;
  font-family: Gotham;
  font-weight: 700;
  padding: 20px;
  display: inline-block;
  @media (max-width: 760px) {
    font-size: 28px;
  }
`

export default Code
