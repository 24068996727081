import styled from 'styled-components/macro'

const Inner = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  border-radius: 8px;
  cursor: ${({ cursor }) => cursor || 'auto'};
`

export default Inner
