import styled from 'styled-components/macro'

const MetricLabel = styled.span`
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-family: Gotham_Book;
  letter-spacing: -0.25px;
`

export default MetricLabel
