import styled from 'styled-components/macro'

const SideLine = styled.div`
  max-width: 17px;
  flex: 1;
  border-left: 1px solid ${({ backgroundColor }) => backgroundColor};
  align-self: stretch;
`

export default SideLine
