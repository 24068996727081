import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Outer = styled(animated.div)`
  position: fixed;
  overflow: hidden;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export default Outer
