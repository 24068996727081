import styled from 'styled-components/macro'

const UnderGraphLabelText = styled.div`
  transform: translateX(${({ translateX }) => translateX || 0}%);
  display: inline-block;
  font-family: Gotham;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`

export default UnderGraphLabelText
