import React, { useState, useEffect, useCallback } from 'react'
import { find, propEq, path } from 'ramda'

export default function useEditor({
  field,
  value,
  isEditing,
  isLoading,
  selectOptions,
  errors,
  noValueText,
  updateEditedValue: customUpdateEditedValue,
  formatOutput,
  formatInput
}) {
  if (formatInput) {
    value = formatInput(value)
  }

  const [editedValue, setEditedValue] = useState(value)

  useEffect(() => {
    // only set edited value if we are not editing
    if (!isEditing) {
      setEditedValue(value)
    }
  }, [value])

  const updateEditedValue = useCallback(
    newValue => {
      if (customUpdateEditedValue) {
        newValue = customUpdateEditedValue({
          editedValue,
          chosenValue: newValue
        })
      }
      setEditedValue(newValue)
    },
    [field, editedValue, customUpdateEditedValue]
  )

  const onInputChange = useCallback(
    ({ target: { value: newValue } }) => {
      if (customUpdateEditedValue) {
        newValue = customUpdateEditedValue({
          editedValue,
          chosenValue: newValue
        })
      }
      setEditedValue(newValue)
    },
    [field, editedValue, customUpdateEditedValue]
  )

  const onSelectChange = useCallback(
    ({ target: { value: newValue } }) => {
      setEditedValue(newValue)
    },
    [field, editedValue]
  )

  return {
    isLoading,
    field,
    originalValue: value,
    editedValue,
    isEditing,
    updateEditedValue,
    onInputChange,
    errors,
    selectOptions,
    onSelectChange,
    noValueText,
    formatOutput
  }
}

export function collectFieldValuesFromEditors(editors) {
  return editors.reduce((acc, { editedValue, field, formatOutput }) => {
    return {
      ...acc,
      [field]: formatOutput ? formatOutput(editedValue) : editedValue
    }
  }, {})
}

export function createDataValueSwitcher({
  displayer: Displayer,
  inputCollector: InputCollector,
  getFieldErrors
}) {
  return ({
    editor: {
      field,
      errors,
      isLoading,
      isEditing,
      editedValue,
      originalValue,
      updateEditedValue,
      onInputChange,
      selectOptions,
      onSelectChange,
      noValueText
    },
    inputCollector: InputCollectorFromProps,
    displayer: DisplayerFromProps,
    ...restProps
  }) => {
    // console.log('field', isEditing, isLoading, editedValue, originalValue)
    if (isEditing || isLoading) {
      let foundErrors
      if (getFieldErrors) {
        foundErrors = getFieldErrors(field, errors)
      } else {
        foundErrors = errors
      }

      const Input = InputCollectorFromProps || InputCollector

      return (
        <Input
          {...restProps}
          onSelectChange={onSelectChange}
          selectOptions={selectOptions}
          editedValue={editedValue}
          updateEditedValue={updateEditedValue}
          onInputChange={onInputChange}
          errors={foundErrors}
        />
      )
    }

    let originalSelectedText
    let editedSelectedText

    if (selectOptions) {
      originalSelectedText = path(
        ['text'],
        find(propEq('value', originalValue), selectOptions)
      )
      editedSelectedText = path(
        ['text'],
        find(propEq('value', originalValue), selectOptions)
      )
    }

    const DisplayerComponent = DisplayerFromProps || Displayer

    return (
      <DisplayerComponent
        {...restProps}
        originalValue={originalValue}
        noValueText={noValueText}
        editedValue={editedValue}
        originalSelectedText={originalSelectedText}
        editedSelectedText={editedSelectedText}
      />
    )
  }
}
