import styled from 'styled-components/macro'

const ConfScore = styled.span`
  font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    height: 1px;
    background-color: #29e6d1;
    bottom: -5px;
    left: 0;
    right: 0;
  }
`

export default ConfScore
