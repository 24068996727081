import { css } from 'styled-components/macro'

export const disableSelection = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`

// if color does not exist, transition color, otherwise, color gets stuck
// using this trick for autofill color hiding
export const removeAutofillColors = css`
  input&:-webkit-autofill,
  input&:-webkit-autofill:hover,
  input&:-webkit-autofill:focus {
    transition-property: background-color
      ${({ color }) => (!color ? ', color' : '')};
    transition-duration: 9999s;
    transition-timing-function: ease-in-out;
    transition-delay: 9999s;
    ${({ color }) =>
      color
        ? css`
            color: ${color};
            -webkit-text-fill-color: ${color};
          `
        : ''}
  }
`

export const clearfix = css`
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
`

const touchSupport =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0

export const hasTouchSupport = () => touchSupport

export const hasNoTouchSupport = () => !touchSupport
