import styled from 'styled-components/macro'

const MetricRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ${({ css }) => css}
`

export default MetricRow
