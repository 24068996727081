/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import Outer from './styled/Outer'

function IconSwatch({ iconImagePath, marginRight }) {
  return <Outer iconImagePath={iconImagePath} marginRight={marginRight} />
}

export default IconSwatch
