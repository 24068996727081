import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Outer = styled(animated.div)`
  top: 50%;
  left: 50%;
  position: absolute;
`

export default Outer
