import { useRef, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

function useSimpleModal({ show }) {
  const portalElement = useRef()

  const createModalPortal = useCallback(
    modal => {
      if (show) {
        if (!portalElement.current) {
          portalElement.current = document.createElement('div')
          portalElement.current.style.cssText =
            'width: 100%; height: 100%; top: 0, left: 0; right: 0; bottom: 0; position: fixed;'
          document.body.appendChild(portalElement.current)
        }
        return createPortal(modal(), portalElement.current)
      }
      if (portalElement.current) {
        document.body.removeChild(portalElement.current)
        portalElement.current = null
      }
      return null
    },
    [show]
  )

  useEffect(() => {
    // CLEAN UP ON UNMOUNT
    return () => {
      if (portalElement.current) {
        document.body.removeChild(portalElement.current)
      }
    }
  }, [])

  return {
    createModalPortal
  }
}

export default useSimpleModal
