import { gql } from 'graphql.macro'

export default gql`
  mutation($id: String!) {
    deleteApplicationCampaign(id: $id) {
      applicationCampaignId
      errors {
        field
        message
      }
    }
  }
`
