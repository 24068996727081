import { useState, useEffect } from 'react'

export default function useSyncChange(item) {
  const [syncedItem, setSyncedItem] = useState(item)

  useEffect(() => {
    setSyncedItem(item)
  }, [item])

  return [syncedItem, setSyncedItem]
}
