import styled from 'styled-components/macro'

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #00023F;
  opacity: .95;
`

export default Overlay
