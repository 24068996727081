import { useState, useEffect } from 'react'

const emptyArr = []

export default function useComputeDimsFromAuto({
  ref,
  monitorWidth = true,
  monitorHeight = true,
  deps = emptyArr
}) {
  const [dims, setDims] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    let animationFrameId = null
    const measure = () => {
      animationFrameId = window.requestAnimationFrame(() => {
        const {
          current: {
            style: {
              // store original
              width: curWidth,
              height: curHeight
            }
          }
        } = ref
        // set to auto
        if (monitorHeight) {
          ref.current.style.height = 'auto'
        }
        if (monitorWidth) {
          ref.current.style.width = 'auto'
        }

        // compute height & width
        let height
        let width
        if (monitorHeight) {
          height = ref.current.clientHeight
        }
        if (monitorWidth) {
          width = ref.current.clientWidth
        }

        // reset
        if (monitorHeight) {
          ref.current.style.height = curHeight
        }
        if (monitorWidth) {
          ref.current.style.width = curWidth
        }

        // console.log('remeasure', width, height)
        setDims({ width, height })
      })
    }

    // if enable
    if (ref.current) {
      measure()
      window.addEventListener('resize', measure)
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId)
      window.removeEventListener('resize', measure)
    }
  }, [ref.current, monitorWidth, monitorHeight, ...deps])

  return dims
}
