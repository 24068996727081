/* eslint no-multi-assign: 0 */
import React from 'react'
import ProgressRing from 'components/ProgressRing'
import styled from 'styled-components/macro'
import useIsShowingInViewport from 'lib/useIsShowingInViewport'
import { useSpring } from 'react-spring'
import easing from 'lib/easing'
import Outer from './styled/Outer'
import IconAndTitle from './styled/IconAndTitle'
import ShieldSwatch from './components/ShieldSwatch'
import Title from './styled/Title'

const OuterRing = styled.div`
  width: 204px;
  margin-left: ${({ moreRingMarginLeft }) =>
    moreRingMarginLeft ? '87px' : '56px'};
  @media (max-width: ${({ moreRingMarginLeft }) =>
      moreRingMarginLeft ? '810px' : '1160px'}) {
    margin: 0 auto;
  }
`

function ScoreTile({ score, readyToAnimate, moreRingMarginLeft }) {
  const { isShowing, targetRef } = useIsShowingInViewport({
    once: true
  })

  if (!isShowing || !readyToAnimate) {
    score = 0
  }

  const progressScoreAnimation = useSpring({
    progress: score,
    config: {
      easing: easing.easeOutQuart,
      duration: 1500
    }
  })

  return (
    <Outer>
      <IconAndTitle>
        <ShieldSwatch />
        <Title>Confidence Score</Title>
      </IconAndTitle>
      <div ref={targetRef}>
        <OuterRing moreRingMarginLeft={moreRingMarginLeft}>
          <ProgressRing
            progressAnimation={progressScoreAnimation}
            centerTextAsPercent
          />
        </OuterRing>
      </div>
    </Outer>
  )
}

export default ScoreTile
