/*
 * Virtualized
 *
 */

import React from 'react'
import { map, isNil } from 'ramda'
import {
  Table,
  Column,
  InfiniteLoader,
  defaultTableRowRenderer,
  AutoSizer
} from 'react-virtualized'
import sizeMe from 'react-sizeme'
import styled from 'styled-components/macro'

const cacheIds = {}
// cacheIds = {
//   [listCacheId]: {
//     id: listCacheId,
//     lastVisibleRowIndex: 5,
//     lastVisibleColumnIndex: 1
//   }
// }

function updateListCache(id, obj = {}) {
  cacheIds[id] = obj
}
function getListCache(id) {
  return cacheIds[id] || {}
}

class VirtualWindowScrolledTable extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    this.scrollToItemViaListCache()
  }

  onRowsRendered = ({ startIndex }) => {
    const { listCacheId } = this.props
    updateListCache(listCacheId, {
      lastVisibleRowIndex: startIndex
    })
  }

  renderColumn = key => {
    const {
      getCellRenderer,
      getCellWidth,
      getCellFlexGrow,
      getHeaderRenderer,
      getColumnData
    } = this.props
    const cellRenderer = getCellRenderer(key)
    const width = getCellWidth(key)
    const flexGrow = getCellFlexGrow(key)
    const headerRenderer = getHeaderRenderer(key)
    const columnData = getColumnData(key)

    return (
      <Column
        cellRenderer={cellRenderer}
        headerRenderer={headerRenderer}
        columnData={columnData}
        dataKey={key}
        key={key}
        width={width}
        flexGrow={flexGrow}
      />
    )
  }

  // eslint-disable-next-line
  setTableRef = table => (this.table = table)

  isRowLoaded = ({ index }) => {
    const { isRowLoaded } = this.props
    return isRowLoaded(index)
  }

  rowGetter = ({ index }) => {
    const { items } = this.props
    return items[index] || {}
  }

  headerRowRenderer = ({ className, columns, style }) => {
    const { headerRowComponent } = this.props
    const Component = headerRowComponent || 'div'

    return (
      <Component className={className} role="row" style={style}>
        {columns}
      </Component>
    )
  }

  scrollToItemViaListCache() {
    const { listCacheId } = this.props

    const { lastVisibleRowIndex } = getListCache(listCacheId)
    if (!isNil(lastVisibleRowIndex)) {
      this.table.scrollToRow(lastVisibleRowIndex)
      updateListCache(listCacheId, {})
    }
  }

  render() {
    const {
      items = [],
      totalItems,
      columnDataKeys,
      headerHeight = 50,
      rowHeight = headerHeight,
      minimumBatchSize = 10,
      loadMoreRows
    } = this.props

    const RowComponent = styled(defaultTableRowRenderer)`
      display: flex;
      flex-wrap: nowrap;
      border-top: 1px solid rgba(221, 221, 221, 0.5);
      padding: 9px 0;
    `

    const rowRenderer = props => {
      return <RowComponent {...props} />
    }

    return (
      <AutoSizer>
        {({ width, height }) => {
          return (
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={loadMoreRows}
              minimumBatchSize={minimumBatchSize}
              rowCount={totalItems}
              threshold={50}
            >
              {({ onRowsRendered, registerChild }) => {
                return (
                  <div
                    ref={el => {
                      registerChild(el)
                    }}
                  >
                    <Table
                      items={items}
                      headerRowRenderer={this.headerRowRenderer}
                      rowRenderer={rowRenderer}
                      headerHeight={headerHeight}
                      rowGetter={this.rowGetter}
                      onRowsRendered={onRowsRendered}
                      height={height}
                      rowCount={items.length}
                      rowHeight={rowHeight}
                      width={width}
                      ref={this.setTableRef}
                    >
                      {map(this.renderColumn, columnDataKeys)}
                    </Table>
                  </div>
                )
              }}
            </InfiniteLoader>
          )
        }}
      </AutoSizer>
    )
  }
}

export default sizeMe({
  monitorHeight: false,
  monitorWidth: true
})(VirtualWindowScrolledTable)
