import React from 'react'
import styled, { css } from 'styled-components/macro'
import { animated } from 'react-spring'

function Outer({ style, className, children }) {
  return (
    <animated.div style={style} className={className}>
      {children}
    </animated.div>
  )
}

// const fromBottomMoveFade = ({ isFirstNonDismissedNote, visualStatus }) => keyframes`
//   ${
//     visualStatus === 'dismissing' || !isFirstNonDismissedNote
//       ? css`
//           0% {
//             transform: translateY(0px);
//             opacity: 1;
//           }
//           100% {
//             transform: translateY(-40px);
//             opacity: 0;
//           }
//         `
//       : isFirstNonDismissedNote
//       ? css`
//           0% {
//             transform: translateY(40px);
//             opacity: 0;
//           }
//           100% {
//             transform: translateY(0px);
//             opacity: 1;
//           }
//         `
//       : css`
//           0% {
//             transform: translateY(-40px);
//             opacity: 0;
//           }
//           100% {
//             transform: translateY(40px);
//             opacity: 0;
//           }
//         `
//   }
// `

// const fromTopMoveFade = ({ isFirstNonDismissedNote, visualStatus }) => keyframes`
//   ${
//     visualStatus === 'dismissing' || !isFirstNonDismissedNote
//       ? css`
//           0% {
//             transform: translateY(0px);
//             opacity: 1;
//           }
//           100% {
//             transform: translateY(40px);
//             opacity: 0;
//           }
//         `
//       : isFirstNonDismissedNote
//       ? css`
//           0% {
//             transform: translateY(-40px);
//             opacity: 0;
//           }
//           100% {
//             transform: translateY(0px);
//             opacity: 1;
//           }
//         `
//       : css`
//           0% {
//             transform: translateY(40px);
//             opacity: 0;
//           }
//           100% {
//             transform: translateY(-40px);
//             opacity: 0;
//           }
//         `
//   }
// `

// we add 50 TWICE to max-width @media query because
// we are compensating for the offset of the 'left'
// side of the notification AND the desired 25px offset
// that the right side WILL have
const OuterStyled = styled(Outer)`
  opacity: 0;
  transform: translateY(40px);
  position: fixed;
  bottom: 25px;
  left: 25px;
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};

  z-index: ${({ isFirstNonDismissedNote }) =>
    isFirstNonDismissedNote ? '10000' : '0'};
  touch-action: ${({ isFirstNonDismissedNote }) =>
    isFirstNonDismissedNote ? 'auto' : 'none'};
  pointer-events: ${({ isFirstNonDismissedNote }) =>
    isFirstNonDismissedNote ? 'auto' : 'none'};
  ${({ maxWidth }) =>
    maxWidth
      ? css`
          @media (max-width: ${parseInt(maxWidth, 10) + 50}px) {
            width: auto;
            right: 25px;
          }
        `
      : null}

  @media(max-width: 750px) {
    bottom: auto;
    top: 15px;
    left: 15px;
    right: 15px;
    width: auto;
    max-width: 100%;
  }
`
// animation: ${fromBottomMoveFade}
//   ${({ animationDurationMs }) => animationDurationMs || 400}ms ease
//   ${({ delay }) => delay || 0}s forwards;
// @media(max-width: 750px){
//   animation: ${fromTopMoveFade}
//     ${({ animationDurationMs }) => animationDurationMs || 400}ms ease
//     ${({ delay }) => delay || 0}s forwards;
// }

// @media(max-width: 750px){
//   bottom: auto;
//   top: 25px;
//   right: 25px;
//   width: auto;
//   max-width: 100%;
// }

export default OuterStyled
