import styled from 'styled-components/macro'

const Outer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;

  background-color: ${({ backgroundColor }) => backgroundColor};
`

export default Outer
