import { useQuery, useMutation } from '@apollo/react-hooks'
import { findIndex, propEq, remove } from 'ramda'

import GET_APPLICATION_CAMPAIGNS from '../ApplicationCampaignsQuery'
import CREATE_APPLICATION_CAMPAIGN from '../CreateApplicationCampaignMutation'
import DELETE_APPLICATION_CAMPAIGN from '../DeleteApplicationCampaignMutation'
import UPDATE_APPLICATION_CAMPAIGN from '../UpdateCampaignMutation'

const withoutObjectWithId = (applicationCampaignId, applicationCampaign) => {
  const idx = findIndex(
    propEq('id', applicationCampaignId),
    applicationCampaign
  )
  return idx !== -1 ? remove(idx, 1, applicationCampaign) : applicationCampaign
}

export default function useApplicationCampaigns() {
  const { loading, errors, data } = useQuery(GET_APPLICATION_CAMPAIGNS, {
    fetchPolicy: 'cache-and-network'
  })

  // , { error: createApplicationCampaignMutationError}
  const [createApplicationCampaign] = useMutation(CREATE_APPLICATION_CAMPAIGN, {
    update(
      cache,
      {
        data: { createApplicationCampaign: createApplicationCampaignResp }
      }
    ) {
      const { applicationCampaigns } = cache.readQuery({
        query: GET_APPLICATION_CAMPAIGNS
      })
      const { applicationCampaign } = createApplicationCampaignResp
      cache.writeQuery({
        query: GET_APPLICATION_CAMPAIGNS,
        data: {
          applicationCampaigns: applicationCampaigns.concat([
            applicationCampaign
          ])
        }
      })
    }
  })

  const [updateApplicationCampaign] = useMutation(UPDATE_APPLICATION_CAMPAIGN)

  const [deleteApplicationCampaign] = useMutation(DELETE_APPLICATION_CAMPAIGN, {
    update(
      cache,
      {
        data: { deleteApplicationCampaign: deleteApplicationCampaignResp }
      }
    ) {
      const { applicationCampaigns } = cache.readQuery({
        query: GET_APPLICATION_CAMPAIGNS
      })
      const { applicationCampaignId } = deleteApplicationCampaignResp
      if (applicationCampaignId)
        cache.writeQuery({
          query: GET_APPLICATION_CAMPAIGNS,
          data: {
            applicationCampaigns: withoutObjectWithId(
              applicationCampaignId,
              applicationCampaigns
            )
          }
        })
    }
  })

  return {
    loading,
    fetchErrors: errors,
    data,
    create: createApplicationCampaign,
    update: updateApplicationCampaign,
    delete: deleteApplicationCampaign
  }
}
