import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Title = styled(animated.h1)`
  font-family: Gotham;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
`

export default Title
