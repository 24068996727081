import React from 'react'
import { find } from 'ramda'
import { Field } from 'react-final-form'

const Condition = ({ name, mapping }) => (
  <Field name={name} subscription={{ value: true }}>
    {({ input: { value } }) => {
      const mapObj = find(k => k.value === value, mapping)
      const { text } = mapObj
      return text
    }}
  </Field>
)
export default Condition
