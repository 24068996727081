import React, { memo } from 'react'
import Outer from './styled/Outer'
import Inner from './styled/Inner'
import ArrowIcon from './components/ArrowIcon'

export default memo(function Arrow({ opacity, transform } = {}) {
  return (
    <Outer>
      <Inner style={{ opacity, transform }}>
        <ArrowIcon />
      </Inner>
    </Outer>
  )
})
