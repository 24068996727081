import { useContext } from 'react'
import { Context, ActionContext } from '../Context'

export function useDropdownsActions() {
  const actions = useContext(ActionContext)

  return actions
}

export function useDropdownsData() {
  const data = useContext(Context)
  return data
}
