import styled from 'styled-components/macro'

const SpinnerOuter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
`

export default SpinnerOuter
