/* eslint prefer-destructuring: 0 */
import { createContext } from 'react'

const Context = createContext()
const Provider = Context.Provider

const ActionContext = createContext()
const ActionProvider = ActionContext.Provider

export { Context, Provider, ActionContext, ActionProvider }
