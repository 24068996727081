import styled from 'styled-components/macro'

const MetricText = styled.span`
  position: relative;
  font-size: 24px;
  line-height: 30px;
  font-family: Gotham_Book;
  letter-spacing: -0.25px;
  display: inline-block;
  margin-right: 7px;
`

export default MetricText
