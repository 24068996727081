/**
 *
 * Landing.js
 *
 */

import React from 'react'
import DocumentDatagrid from 'domains/BizDash/Shared/components/Datagrid';

const PushedDocuments = () => <DocumentDatagrid filter='processed' />

export default PushedDocuments