import styled from 'styled-components/macro'

const SheetHeader = styled.h2`
  display: flex;
  flex: 0 0 auto;
  font-size: 36px;
  margin: 0 0 50px 0;
  ${({ css }) => css}
  @media (max-width: 760px) {
    margin-bottom: 20px;
  }
`

export default SheetHeader
