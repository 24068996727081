import styled from 'styled-components/macro'

const NoPeersMatch = styled.h3`
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  margin: 20px 20px;
`

export default NoPeersMatch
