import React from 'react'
import Outer from './styled/Outer'
import Halo from './styled/Halo'
import Ring from './styled/Ring'

export default function RingWithHalo() {
  return (
    <Outer>
      <Halo />
      <Ring />
    </Outer>
  )
}
