/**
 *
 * HeaderNav.js
 *
 */

import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { withRouter, Link } from 'react-router-dom'
import PushRefNavLink from './PushRefNavLink'
import NavUnderline from './NavUnderline'

const LinkContainer = styled.div`
  margin-right: 50px;
  display: inline-block;
`

const HeaderNavContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin-bottom: 10px;
  overflow-x: scroll;
`

const NavigationElements = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  padding-bottom: 40px;
  margin-bottom: -2px;
  white-space: nowrap;
  ${props => props.theme.font('medium')}
`

const HeaderNavLink = styled(({ active, ...props }) => <Link {...props} />)`
  box-sizing: content-box;
  display: block;
  letter-spacing: 1px;
  font-size: 12px;
  &&& {
    color: #aeaeae;
    ${props => props.theme.font('medium')}
    ${props =>
      props.active &&
      `
      color: #00023F;
      ${props.theme.font('strong')}
    `}
  }
`

const HeaderNav = ({ navItems, location }) => {
  const [selectedEl, setActiveEl] = useState(false)
  const doSelectEl = el => {
    setActiveEl(el)
  }

  return (
    <HeaderNavContainer>
      <NavigationElements>
        {navItems.map(navItemConfig => (
          <LinkContainer key={navItemConfig.route}>
            <PushRefNavLink
              active={location.pathname.includes(navItemConfig.route)}
              onActive={doSelectEl}
            >
              <HeaderNavLink
                active={location.pathname.includes(navItemConfig.route)}
                to={navItemConfig.route}
              >
                {navItemConfig.label.toUpperCase()}
              </HeaderNavLink>
            </PushRefNavLink>
          </LinkContainer>
        ))}
      </NavigationElements>
      <NavUnderline underlineEl={selectedEl} />
    </HeaderNavContainer>
  )
}

export default withRouter(HeaderNav)
