import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const TextCenter = styled(animated.div)`
  display: block;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  color: #00023f;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
`

export default TextCenter
