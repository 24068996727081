import React, { createContext, useContext } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'

// eslint-disable-next-line import/no-mutable-exports
let ApolloAppProvider
// eslint-disable-next-line import/no-mutable-exports
let MockedApolloProvider

if (process.env.NODE_ENV === 'test') {
  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const { MockedProvider } = require('@apollo/react-testing')
  const Context = createContext()
  const { Provider } = Context

  MockedApolloProvider = function TheMockedApolloProvider({ children, value }) {
    return <Provider value={value}>{children}</Provider>
  }

  ApolloAppProvider = function TheApolloAppProvider({ children }) {
    const data = useContext(Context) || {}
    return <MockedProvider {...data}>{children}</MockedProvider>
  }
} else {
  ApolloAppProvider = ApolloProvider
}

export { MockedApolloProvider }

export default ApolloAppProvider
