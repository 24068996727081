/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import Btn from 'components/SimpleBtn'
import Outer from './styled/Outer'

function SimpleBtn({ children, ...restProps }) {
  return (
    <Btn component={Outer} {...restProps}>
      {children}
    </Btn>
  )
}

export default SimpleBtn
