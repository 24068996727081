export default function composeValidators(validators) {
  return (...args) => {
    return validators.reduce((acc, validator) => {
      const error = validator(...args)
      if (error) {
        return [...acc, error]
      }

      return acc
    }, [])
  }
}
