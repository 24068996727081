import React, { useEffect, useState } from 'react'
import posed from 'react-pose'
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const AnimatedSvg = posed.svg({
  mounted: { staggerChildren: 100, delayChildren: 500 },
  inital: {},
  initialPose: 'inital'
})
const DropInGroup = posed.g({
  inital: { y: -10, opacity: 0 },
  mounted: { y: 0, opacity: 1 }
})
const DropInBall = posed.g({
  inital: { y: -25, opacity: 0 },
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 100,
        damping: 7
      }
    }
  }
})

const SvgLogo = props => {
  const { letterColor = '#FFFFFF', ballColor } = props
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return (
    <ResponsiveSvg wRatio={116} hRatio={55} maxWidth={300} {...props}>
      <AnimatedSvg
        pose={mounted ? 'mounted' : 'inital'}
        key="animatedSVG"
        width="1em"
        height="1em"
        viewBox="0 0 116 55"
        {...props}
      >
        <defs>
          <path
            d="M26.5971846,13.9823816 C26.5971846,21.1778681 20.7049786,27.0106268 13.4361559,27.0106268 C6.16733324,27.0106268 0.275127215,21.1778681 0.275127215,13.9823816 C0.275127215,6.78689516 6.16733324,0.954136448 13.4361559,0.954136448 C20.7049786,0.954136448 26.5971846,6.78689516 26.5971846,13.9823816"
            id="path-1"
          />
        </defs>
        <g
          id="Design"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
          transform="translate(0, 10)"
        >
          <g
            id="01-Disclosure-Search-Active"
            transform="translate(-45.000000, -36.000000)"
          >
            <g id="Group-6" transform="translate(45.000000, 36.000137)">
              <DropInGroup>
                <polygon
                  id="letter-l"
                  fill={letterColor}
                  points="1.01696429e-13 28.4439181 6.16934286 28.4439181 6.16934286 3.55271368e-15 1.01696429e-13 3.55271368e-15"
                />
              </DropInGroup>
              <DropInBall>
                <g id="letter-o" transform="translate(9.307314, 7.730801)">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <use
                    id="circle"
                    fill={ballColor || '#4DA8FF'}
                    xlinkHref="#path-1"
                  />
                  <polygon
                    id="Color"
                    fill={ballColor || '#4DFFC9'}
                    mask="url(#mask-2)"
                    points="-10 -8 105.679901 -8 105.679901 27.65375 -10 27.65375"
                  />
                </g>
              </DropInBall>
              <DropInGroup>
                <polygon
                  id="letter-w"
                  fill={letterColor}
                  points="59.5944108 20.2615056 55.4548795 7.48102376 50.1374154 7.48102376 46.0385516 20.2224634 42.5482746 7.55910806 36.2975966 7.55910806 42.994191 28.6000867 48.5142796 28.6000867 52.7758136 15.7805628 56.9560125 28.6000867 62.5167687 28.6000867 69.2946982 7.55910806 63.166023 7.55910806"
                />
              </DropInGroup>
              <DropInGroup>
                <path
                  d="M81.0376396,7.16937152 C88.3021532,7.16937152 91.6297599,12.5852711 91.6297599,18.5080336 C91.6297599,18.9758544 91.5890923,19.5210746 91.5484247,20.0669798 L76.4100974,20.0669798 C77.0186841,22.7554085 78.9671605,24.158186 81.7275615,24.158186 C83.7966137,24.158186 85.2991737,23.5348816 87.0036446,22.0149775 L90.5345892,25.0150585 C88.5047776,27.4308771 85.5824198,28.911054 81.6455129,28.911054 C75.1108753,28.911054 70.2814221,24.5088804 70.2814221,18.1182971 L70.2814221,18.0408977 C70.2814221,12.0784081 74.7056265,7.16937152 81.0376396,7.16937152 Z M76.3287622,16.3648251 L85.6230874,16.3648251 C85.2585061,13.7154385 83.6353703,11.9229244 81.0376396,11.9229244 C78.4805765,11.9229244 76.8153462,13.6763964 76.3287622,16.3648251 Z"
                  id="letter-e"
                  fill={letterColor}
                />
              </DropInGroup>
              <DropInGroup>
                <path
                  d="M101.444202,11.7674408 L101.444202,7.55910806 L95.2748591,7.55910806 L95.2748591,28.4439181 L101.444202,28.4439181 L101.444202,20.7293264 C101.444202,15.7422056 103.960597,13.3647442 108.059461,13.3647442 L108.384802,13.3647442 L108.384802,7.16937152 C104.731141,7.01388787 102.702043,8.88380133 101.444202,11.7674408 L101.444202,11.7674408 Z"
                  id="letter-r"
                  fill={letterColor}
                />
              </DropInGroup>
              <DropInGroup>
                <path
                  d="M112.621365,3.11515254 L113.313427,3.11515254 L114.071128,4.28230732 L114.828116,3.11515254 L115.501628,3.11515254 L115.501628,5.88029564 L114.883766,5.88029564 L114.883766,4.01312198 L114.089678,5.2247985 L114.033314,5.2247985 L113.238513,4.01312198 L113.238513,5.88029564 L112.621365,5.88029564 L112.621365,3.11515254 Z M110.498802,3.67133193 L109.638362,3.67133193 L109.638362,3.11515254 L112.013491,3.11515254 L112.013491,3.67133193 L111.153051,3.67133193 L111.153051,5.88029564 L110.498802,5.88029564 L110.498802,3.67133193 Z"
                  id="TM"
                  fill={letterColor}
                />
              </DropInGroup>
            </g>
          </g>
        </g>
      </AnimatedSvg>
    </ResponsiveSvg>
  )
}

export default SvgLogo
