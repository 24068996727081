import { gql } from 'graphql.macro'

export default gql`
  mutation($input: UploadDocsInput!, $uploads: [Upload!]!) {
    uploadDocs(input: $input, uploads: $uploads) {
      uploads {
        id
        deleteUntil
      }
      errors {
        field
        message
      }
    }
  }
`
