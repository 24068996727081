import styled from 'styled-components/macro'

const Label = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-family: Gotham;
  font-weight: 200;
  margin-bottom: 10px;
  color: #a0a0a0;
`

export default Label
