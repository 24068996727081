import { useQuery } from '@apollo/react-hooks'
import { pathOr } from 'ramda'

import CURRENT_LOAN_UPLOADER_QUERY from '../currentLoanUploaderQuery'

const isVerifiedInResponse = pathOr(false, ['currentLoanUploader', 'verified'])

export default function useCurrentLoanUploader() {
  const { error, loading, data } = useQuery(CURRENT_LOAN_UPLOADER_QUERY)

  const verified = isVerifiedInResponse(data)

  return {
    loading,
    error,
    verified
  }
}
