import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const InnerGlow = styled(animated.div)`
  border-radius: 50%;
  box-shadow: inset 0 0 150px #4100f5, 0 0 50px #4100f5;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 98%;
  height: 98%;
`

export default InnerGlow
