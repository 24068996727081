import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: relative;
  transform: translateY(${({ moveUp }) => (moveUp ? '-5px' : '0')});
  transition: all 0.2s;
  ${({ customCss }) => customCss}
`

export default Wrapper
