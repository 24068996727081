/* eslint no-unused-expressions: 0 */
/*
 * HoverProvider
 *
 */

import React from 'react'

export default function HoverProvider(Child) {
  return class Hover extends React.PureComponent {
    // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
      super(props)
      this.state = {
        hover: false
      }
    }

    onMouseEnter = () => this.setState({ hover: true })

    onMouseLeave = () => this.setState({ hover: false })

    render() {
      return (
        <Child
          {...this.props}
          {...this.state}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      )
    }
  }
}
