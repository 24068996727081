import styled from 'styled-components/macro'

const IconOuter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 24px;
`

export default IconOuter
