import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  max-width: 1160px;
  margin: auto;
  padding: 0 30px;
`

export default Outer
