import styled from 'styled-components/macro'

const Metric = styled.div`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.19px;
  color: white;
`

export default Metric
