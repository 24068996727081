import { useContext } from 'react'
import { ActionContext, DataContext } from '../Context'

export function useActions() {
  return useContext(ActionContext)
}

export function useData() {
  return useContext(DataContext)
}
