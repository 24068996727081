/* eslint no-multi-assign: 0 */
import React from 'react'

function PhoneBtn() {
  return (
    <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1293.000000, -36.000000)">
          <g transform="translate(39.000000, 37.000000)">
            <g transform="translate(1255.000000, 0.000000)">
              <circle
                stroke="#4100F5"
                strokeWidth="1.15368008"
                opacity="0.75"
                cx="20"
                cy="20"
                r="20"
              />
              <path
                d="M26.9995918,24.7329939 L26.9995918,26.6900173 C27.0010859,27.05738 26.847326,27.4083482 26.5760866,27.6566987 C26.3048473,27.9050492 25.9412663,28.0277655 25.5746655,27.9946995 C23.5633193,27.7765832 21.6312796,27.090649 19.9337877,25.9920122 C18.354491,24.9904493 17.0155231,23.6541337 16.0119722,22.0779654 C14.9073132,20.3761405 14.2198614,18.4385507 14.00531,16.4221678 C13.9722839,16.0574258 14.0943823,15.6956245 14.3417794,15.4251434 C14.5891765,15.1546623 14.9390905,15.000409 15.3060455,15.0000641 L17.2669532,15.0000641 C17.9231148,14.9936189 18.4823897,15.4736426 18.574225,16.1220909 C18.6569903,16.7483817 18.8104813,17.3633179 19.0317701,17.9551695 C19.2113468,18.4319493 19.0964899,18.9694336 18.737634,19.3316093 L17.9075164,20.1600825 C18.8380033,21.7932484 20.1929244,23.1454855 21.8293318,24.0741293 L22.6594494,23.2456561 C23.022344,22.887511 23.560895,22.7728816 24.0386212,22.9521026 C24.6316474,23.172953 25.2478041,23.32614 25.8753381,23.4087414 C26.5326099,23.5012827 27.0160569,24.0707323 26.9995918,24.7329939 Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PhoneBtn
