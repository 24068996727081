import React, { useEffect } from 'react'
import SheetHeader from 'domains/BizDash/Shared/components/Sheet/styled/SheetHeader'
import PageTitle from 'domains/BizDash/Shared/styled/PageTitle'
import { withPersonaOverviewsProvider } from 'domains/BizDash/Personas/Shared/providers/PersonaOverviewsProvider'
import usePersonaOverviews from 'domains/BizDash/Personas/Shared/providers/PersonaOverviewsProvider/useable/usePersonaOverviews'
import Spinner from 'components/Spinner'
import Sheet from 'domains/BizDash/Shared/components/Sheet'
import getStatus from 'domains/shared/utils/getStatus'
import PersonaOverviewsOuter from './styled/PersonaOverviewsOuter'
import Overview from './components/Overview'

function Overviews() {
  const [
    { personaOverviews, fetchLoading, fetchErrors, statuses },
    { fetchPersonaOverviews, updatePersonaOverview }
  ] = usePersonaOverviews()
  useEffect(() => {
    fetchPersonaOverviews()
  }, [])

  return (
    <Sheet>
      <SheetHeader>
        <PageTitle>Persona Overviews</PageTitle>
      </SheetHeader>
      {fetchLoading ? (
        <Spinner />
      ) : fetchErrors.length ? (
        'Error loading Persona Overviews'
      ) : personaOverviews.length ? (
        <PersonaOverviewsOuter>
          {personaOverviews.map(personaOverview => (
            <Overview
              loading={getStatus('loading', personaOverview.id, statuses)}
              errors={getStatus('errors', personaOverview.id, statuses)}
              onSave={updatePersonaOverview}
              key={personaOverview.id}
              {...personaOverview}
            />
          ))}
        </PersonaOverviewsOuter>
      ) : (
        'No persona overviews found'
      )}
    </Sheet>
  )
}

const OverviewsComposed = withPersonaOverviewsProvider(Overviews)

export default OverviewsComposed
