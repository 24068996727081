import React, { useMemo } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Search from 'domains/BizDash/Shared/components/Search'
import SheetHeader from 'domains/BizDash/Shared/components/Sheet/styled/SheetHeader'
import PageTitle from 'domains/BizDash/Shared/styled/PageTitle'
import Sheet from 'domains/BizDash/Shared/components/Sheet'
import Activity from './Activity/Base'

const SearchOuter = styled.div`
  flex: 0 1 auto;
  max-width: 400px;
  @media (max-width: 590px) {
    max-width: auto;
  }
`

const sheetHeaderCss = css`
  @media (max-width: 1155px) {
    display: block;
  }
`

export default {
  // if you need to customize these items in the mainNavigation,
  // then utilize props below and add deps to the array at bottom of useMemo
  // (useMemo is needed here otherwise our sidebar will be rerender EVERY TIME
  // an unrelated render happens)
  mainNavigation: () =>
    /* props */ useMemo(() => {
      return {
        header: {
          label: 'Disclosure Queue',
          route: '/disclosures/activity/all',
          active: ({ location: { pathname } }) =>
            pathname.includes('/disclosures')
        },
        items: [
          {
            label: 'All Documents',
            route: '/disclosures/activity/all'
          },
          {
            label: 'Queued',
            route: '/disclosures/activity/queued'
          },
          {
            label: 'Pulled',
            route: '/disclosures/activity/pulled'
          },
          {
            label: 'Pushed',
            route: '/disclosures/activity/pushed'
          },
          {
            label: 'Downloaded',
            route: '/disclosures/activity/downloaded'
          },
          {
            label: 'Errors',
            route: '/disclosures/activity/errors'
          }
        ]
      }
    }, []),
  routes: ({ url }) => (
    <Sheet overflow="hidden">
      <SheetHeader css={sheetHeaderCss}>
        <PageTitle>
          Disclosure Queue{' '}
          <span role="img" aria-label="mailbox">
            📬
          </span>
        </PageTitle>
        <SearchOuter>
          <Search />
        </SearchOuter>
      </SheetHeader>
      <Switch key={`${url}/activity`}>
        <Redirect exact from={`${url}/activity`} to={`${url}/activity/all`} />
        <Route path={`${url}/activity`} component={Activity} />
      </Switch>
    </Sheet>
  )
}
