import React, { memo, useState, useEffect, useCallback } from 'react'
import GalaxyAnimation from 'components/GalaxyAnimation'
import { DragHoverHelper } from 'lib/Droppable'
import useSyncChange from 'lib/useSyncChange'

export default memo(function FullScreenDropArea({
  onDrop,
  onDragLeave,
  isHoveringOver,
  onDragOver,
  droppedFiles,
  onAnimationStatusChange
}) {
  const [readyToRemove, setReadyToRemove] = useState(true)
  const [currentDroppedFiles, setCurrentDroppedFiles] = useSyncChange(
    droppedFiles
  )
  const [currentIsHoveringOver] = useSyncChange(isHoveringOver)

  let status
  if (currentIsHoveringOver) {
    status = 'expand'
  } else if (
    !currentIsHoveringOver &&
    currentDroppedFiles &&
    currentDroppedFiles.length
  ) {
    status = 'dropped'
  } else {
    status = 'hide'
  }

  // status = 'expand'
  useEffect(() => {
    if (status === 'expand' || status === 'dropped') {
      setReadyToRemove(false)
    }
  }, [status])

  useEffect(() => {
    onAnimationStatusChange(status)
  }, [status])

  const onReadyToRemove = useCallback(() => {
    setReadyToRemove(true)
    setCurrentDroppedFiles(null)
  }, [])

  return (
    <>
      {!readyToRemove && (
        <DragHoverHelper
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          expand
          position="fixed"
        >
          <GalaxyAnimation status={status} onReadyToRemove={onReadyToRemove} />
        </DragHoverHelper>
      )}
    </>
  )
})
