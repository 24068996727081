export default function mortgageTypeToPublicTitle({ mortgageType, isCombo }) {
  // OB uses Conforming / Non Conforming which means "Conventional",
  // Otherwise, OB will just use the specific mortgageType (FHA, VA, etc)
  let title
  mortgageType = /conforming/i.test(mortgageType)
    ? 'Conventional'
    : mortgageType

  if (isCombo) {
    title = `${mortgageType} COMBO`
  } else if (!/conventional/i.test(mortgageType)) {
    title = `${mortgageType} Loan`
  } else {
    title = mortgageType
  }
  return title
}
