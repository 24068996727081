/* eslint no-multi-assign: 0 */
/* eslint react/no-array-index-key: 0 */
import React, { Fragment } from 'react'
import ProgressRing from 'components/ProgressRing'
import { useSprings } from 'react-spring'
import styled from 'styled-components/macro'
import useIsShowingInViewport from 'lib/useIsShowingInViewport'
import Outer from './styled/Outer'

const RingOuter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

function NestedProgressRings({
  rings = [],
  ringSpacing = 18,
  ringStrokeWidth = 10,
  minRadius = 86
}) {
  const { isShowing, targetRef } = useIsShowingInViewport({
    once: true
  })

  const ringConfigs = useSprings(
    rings.length,
    rings.map(({ percent }) => {
      return {
        progress: isShowing ? percent : 0
      }
    })
  )

  const maxDim =
    2 *
      (minRadius + (ringConfigs.length - 1) * (ringSpacing + ringStrokeWidth)) +
    ringStrokeWidth

  const ringElements = ringConfigs.map((progressAnimation, i) => {
    const radius = minRadius + i * (ringSpacing + ringStrokeWidth)
    const { ringColor } = rings[i]

    return (
      <RingOuter ref={targetRef} key={i}>
        <ProgressRing
          radius={radius}
          strokeWidth={ringStrokeWidth}
          progressAnimation={progressAnimation}
          OuterComponent={Fragment}
          ringColor={ringColor}
          hasUnderlayRing={false}
        />
      </RingOuter>
    )
  })

  return (
    <Outer w={maxDim} h={maxDim}>
      {ringElements}
    </Outer>
  )
}

export default NestedProgressRings
