import styled from 'styled-components/macro'

const GroupTitle = styled.h2`
  font-size: 12px;
  line-height: 18px;
  font-family: Gotham;
  font-weight: 200;
  margin-bottom: 7px;
  color: #a0a0a0;
`

export default GroupTitle
