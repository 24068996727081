import React, { useState, useEffect } from 'react'
// import DotTextDotAnimator from 'components/DotTextDotAnimator'
import ProgressBar from 'components/ProgressBar'
import { useTransition, useSpring } from 'react-spring'
import easing from 'lib/easing'
import TextCenter from './styled/TextCenter'
import ProgressBarOuter from './styled/ProgressBarOuter'

export default function LoadingContent({ progressFraction, renderMessage }) {
  const messageElement = renderMessage()
  let transArr

  if (messageElement && !messageElement.key) {
    throw Error(
      'A key must be provided to the InnerText of the IconTileWithLabels so transitions can be deterministic!'
    )
  }

  if (messageElement) {
    transArr = [messageElement]
  } else {
    transArr = []
  }

  const textAnims = useTransition(transArr, ({ key }) => key, {
    unique: true,
    trail: 50,
    from: () => {
      return {
        transform: 'translateY(30px)',
        opacity: 0
      }
    },
    enter: () => {
      return {
        transform: 'translateY(0px)',
        opacity: 1
      }
    },
    leave: () => {
      return {
        transform: 'translateY(-30px)',
        opacity: 0
      }
    }
  })

  const progressChangeAnimationDuration = 1300

  const [showProgressBar, setShowProgressBar] = useState(true)
  useEffect(() => {
    let t
    if (progressFraction === 1) {
      t = setTimeout(() => {
        setShowProgressBar(false)
      }, progressChangeAnimationDuration)
    }
    return () => {
      t && clearTimeout(t)
      t = null
    }
  }, [progressFraction])

  const progressBarVisibilityAnim = useSpring({
    transform: showProgressBar ? `translateX(0%)` : `translateX(101%)`,
    opacity: showProgressBar ? 1 : 0,
    config: {
      easing: easing.easeInOutQuad,
      duration: 700
    }
  })

  return (
    <>
      {textAnims.map(
        ({ item, key, props }) =>
          item && (
            <TextCenter key={key} style={props}>
              {item}
            </TextCenter>
          )
      )}
      <ProgressBarOuter style={progressBarVisibilityAnim}>
        <ProgressBar
          barColor="#01CA01"
          fraction={progressFraction}
          height="3px"
          startAtZero
          progressChangeAnimationDuration={`${progressChangeAnimationDuration}ms`}
        />
      </ProgressBarOuter>
    </>
  )
}
