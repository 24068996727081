import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'
import posed from 'react-pose'
import { Flyout } from '../Flyout'
import DetailButton from '../DetailButton'
import ButtonPortal from './portal'

const Container = posed.div({
  stop: {},
  active: { staggerChildren: 50 }
})

const FlyoutContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  user-select: none;
`

const FlyoutContainer = posed.div(props => {
  return {
    closed: {
      opacity: 0,
      scale: 0.6,
      y: 10,
      originX: props.origin[1],
      originY: props.origin[0],
      applyAtEnd: { width: '80px', display: 'none' }
    },
    open: {
      opacity: 1,
      scale: 1,
      y: 0,
      originX: props.origin[1],
      originY: props.origin[0],
      applyAtStart: { width: '80px', display: 'block' }
    }
  }
})

const AnchorRef = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FlyoutDetailButton = props => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleClick = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(!open)
  }

  const handleDismiss = () => {
    setOpen(false)
  }

  const close = () => {
    handleDismiss()
  }

  const { render, children } = props
  const renderChildren = render ? render({ close }) : children
  return (
    <Container pose={open ? 'active' : 'stop'}>
      <DetailButton active={open} onClick={handleClick}>
        <AnchorRef ref={anchorRef}>
          {open && (
            <ButtonPortal parentRef={anchorRef}>
              <FlyoutContainer
                origin={['top', 'center']}
                initialPose="closed"
                pose="open"
              >
                <Flyout
                  show
                  anchor={anchorRef}
                  position="bottom"
                  onDismiss={handleDismiss}
                  size="md"
                >
                  <FlyoutContent>{renderChildren}</FlyoutContent>
                </Flyout>
              </FlyoutContainer>
            </ButtonPortal>
          )}
        </AnchorRef>
      </DetailButton>
    </Container>
  )
}

FlyoutDetailButton.propTypes = {}

export default FlyoutDetailButton
