import React from 'react'
import styled from 'styled-components/macro'
import Envelope from './svg/Envelope'

const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 16px 14px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  ${props => props.theme.font('medium')}
`

const EnvelopeIcon = styled(Envelope)`
  margin-right: 8px;
`

const MailtoButton = ({ to, children }) => {
  const handleClick = event => {
    event.preventDefault()
    window.location.href = `mailto:${to}`
  }
  return (
    <ContactButton onClick={handleClick}>
      <EnvelopeIcon maxWidth={14} /> {children}
    </ContactButton>
  )
}

export default MailtoButton
