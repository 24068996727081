import styled from 'styled-components/macro'

const Outer = styled.div`
  color: #00023f;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default Outer
