import React from 'react'
import Outer from './styled/Outer'
import Triangle from './styled/Triangle'
import TriangleBorderShadow from './styled/TriangleBorderShadow'

export default function TriangleWithBorder({ bottom, left }) {
  return (
    <Outer bottom={bottom} left={left}>
      <Triangle />
      <TriangleBorderShadow />
    </Outer>
  )
}
