/* eslint no-unused-expressions: 0 */
/*
 * ClickHandler
 *
 */

import React from 'react'

export default function ClickHandler(Child) {
  return class Click extends React.PureComponent {
    // eslint-disable-line react/prefer-stateless-function
    onClick = e => {
      e.preventDefault()
      const { onClick } = this.props
      onClick && onClick()
    }

    render() {
      return <Child {...this.props} onClick={this.onClick} />
    }
  }
}
