import React, { useState, useEffect } from 'react'
import Outer from './styled/Outer'
import BarOutline from './styled/BarOutline'
import Bar from './styled/Bar'
import Wave from './styled/Wave'

function useStartAtZero({ fraction, startAtZeroDuration }) {
  const [isReadyForFraction, setIsReadyForFraction] = useState(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsReadyForFraction(true)
    }, startAtZeroDuration)
    return () => clearTimeout(timeoutId)
  }, [startAtZeroDuration])

  if (!isReadyForFraction) {
    fraction = 0
  }

  return fraction
}

function ProgressBar({
  paint,
  height,
  backgroundColor = 'transparent',
  outlineColor = paint,
  barColor = paint,
  barBackgroundImage,
  fraction = 0,
  startAtZero = false,
  startAtZeroDuration = 100,
  progressChangeAnimationDuration,
  waveBackgroundColor
}) {
  if (barBackgroundImage) {
    barColor = 'transparent'
  }

  const zeroToFraction = useStartAtZero({ startAtZeroDuration, fraction })

  fraction = startAtZero ? zeroToFraction : fraction

  const percent = `-${100 - fraction * 100}%`

  return (
    <Outer backgroundColor={backgroundColor}>
      <BarOutline borderColor={outlineColor} height={height}>
        <Bar
          backgroundColor={barColor}
          backgroundImage={barBackgroundImage}
          translateX={percent}
          transitionDuration={progressChangeAnimationDuration}
        >
          <Wave backgroundColor={waveBackgroundColor} />
          <Wave backgroundColor={waveBackgroundColor} delay={0.2} />
          <Wave backgroundColor={waveBackgroundColor} delay={0.4} />
        </Bar>
      </BarOutline>
    </Outer>
  )
}

export default ProgressBar
