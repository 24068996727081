import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import SheetHeader from 'domains/BizDash/Shared/components/Sheet/styled/SheetHeader'
import Spinner from 'components/Spinner'
import Sheet from 'domains/BizDash/Shared/components/Sheet'
import PageTitle from 'domains/BizDash/Shared/styled/PageTitle'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import LoanOfficerListItem from './LoanOfficerListItem'
import LoanOfficerForm from '../LoanOfficerForm'
import { loanOfficerFormValidation } from '../validation'

import useLoanOfficers from '../graphql/hooks/useLoanOfficers'

const LoanOfficersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LoanOfficersList = () => {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false)
  const { loading, errors, data } = useLoanOfficers()

  const onAddNewClick = useCallback(
    () => setIsAddingNewItem(isAddingNewItemState => !isAddingNewItemState),
    []
  )

  const onCancelAddNew = useCallback(() => setIsAddingNewItem(false), [])

  const { loanOfficers } = data

  return (
    <Sheet>
      <SheetHeader>
        <PageTitle>Loan Officers</PageTitle>
        <SimpleBtn onClick={onAddNewClick}>
          {isAddingNewItem ? 'Cancel New' : 'Add New'}
        </SimpleBtn>
      </SheetHeader>
      {!loanOfficers && loading ? (
        <Spinner />
      ) : errors && errors.length > 0 ? (
        'Error loading Persona Overviews'
      ) : (
        <LoanOfficersContainer>
          {isAddingNewItem && (
            <LoanOfficerForm
              validate={loanOfficerFormValidation}
              onCancel={onCancelAddNew}
            />
          )}
          {loanOfficers.map(lo => (
            <LoanOfficerListItem key={lo.id} loanOfficer={lo} />
          ))}
        </LoanOfficersContainer>
      )}
    </Sheet>
  )
}

export default LoanOfficersList
