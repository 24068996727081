import styled from 'styled-components/macro'

const LabelOuter = styled.div`
  width: 50%;
  white-space: nowrap;
  display: flex;
  margin-bottom: 35px;
  @media (max-width: 550px) {
    width: 100%;
  }
`

export default LabelOuter
