/**
 *
 * Landing.js
 *
 */

import React from 'react'
import DocumentDatagrid from 'domains/BizDash/Shared/components/Datagrid';

const PrintedDocuments = () => <DocumentDatagrid filter='printed' />

export default PrintedDocuments