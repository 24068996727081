import { useState, useEffect, useMemo, useCallback } from 'react'
import ReactGA from 'react-ga'
import uuid from 'lib/uuid'
import {
  uniq,
  sortWith,
  descend,
  prop,
  mergeDeepRight,
  propEq,
  allPass,
  path
} from 'ramda'
import extractErrorTypeFromApolloError from 'lib/extractErrorTypeFromApolloError'
import useShowErrorNotificationForResponseError from 'domains/shared/useable/useShowErrorNotificationForResponseError'
// import delay from 'lib/delay'
import { useMutation } from '@apollo/react-hooks'
import isFunction from 'lodash/isFunction'
import UPLOAD_DOCS from './mutations/uploadDocsMutation'
import DELETE_DOC from './mutations/deleteDocMutation'

// import tempUploadImpl from './tempUploadImpl'

const sortByDate = sortWith([descend(prop('date'))])

function currentDatePlusMinutes(mins) {
  return new Date(Date.now() + mins * 60000)
}

function startUploads({ files, updateFiles, storeFile }) {
  // onStart is called SYNCHRONOUSLY,
  // immediately during looping of each item,
  // which means we don't have to worry about
  // setting files state will an initial state
  files.reduce((acc, file) => {
    const { id, fileData /* input */ } = file
    return {
      [id]: {
        ...file,
        status: 'uploading',
        progress: 0,
        uploadPromise: storeFile({
          variables: { input: { uploadMap: [{ id }] }, uploads: [fileData] },
          context: {
            fetchOptions: {
              useUpload: true,
              onProgress: ({ loaded /* total */ }) => {
                updateFiles(({ [id]: { fileData: { size } } }) => {
                  // console.log('progress', loaded)
                  return {
                    [id]: {
                      status: 'uploading',
                      progress: loaded / size,
                      progressBytes: loaded
                    }
                  }
                })
              },
              onAbort: () => {
                updateFiles({
                  [id]: {
                    isAborted: true,
                    progress: 0
                  }
                })
              },
              onSuccess: () => {
                ReactGA.event({
                  category: 'UploadFiles',
                  action: 'Successful Upload'
                })
                updateFiles(({ [id]: { fileData: { size } } }) => {
                  // console.log('done!', size)
                  return {
                    [id]: {
                      status: 'finalizing',
                      progress: 0.9,
                      progressBytes: size
                    }
                  }
                })
              },
              onStart: () => {
                updateFiles({
                  [id]: {
                    status: 'uploading',
                    progress: 0,
                    progressBytes: 0
                  }
                })
              },
              onError: () => {
                updateFiles({
                  [id]: {
                    status: 'error',
                    progress: 0
                  }
                })
              },
              // possibly send abort message to xhr
              onAbortPossible: abortHandler => {
                updateFiles(() => {
                  // console.log('done!', size)
                  return {
                    [id]: {
                      cancel: abortHandler
                    }
                  }
                })
              }
            }
          }
        })
          .then(data => {
            let deleteUntil = path(
              ['data', 'uploadDocs', 'uploads', 0, 'deleteUntil'],
              data
            )
            deleteUntil = deleteUntil ? new Date(deleteUntil) : deleteUntil

            updateFiles(({ [id]: { fileData: { size } } }) => {
              // console.log('done!', size)
              return {
                [id]: {
                  status: 'success',
                  progress: 1,
                  progressBytes: size,
                  deleteUntil: deleteUntil || currentDatePlusMinutes(10)
                }
              }
            })
          })
          .catch(e => {
            updateFiles(({ [id]: { status, isAborted } }) => {
              if (path(['networkError', 'customType'], e) === 'CANCEL') {
                if (status === 'queued' || status === 'uploading') {
                  // even though the upload was canceled,
                  // all we have to do is
                  // remove file from local
                  // console.log('canceling upload...')
                  return {
                    [id]: {
                      status: 'removing'
                    }
                  }
                }
                // the below status at this point in
                // the state-machine _should not be possible_,
                // you can't have a status of error and have
                // be able to reject/abort the request as
                // a user - the never happened.
                // aka (file type error on frontend, etc)
                // if (status === 'error') {
                //   console.log('removing failed upload locally...')
                //   // remove file from local
                //   return {
                //     [id]: {
                //       status: 'removing'
                //     }
                //   }
                // }
                // status = finalizing
                // eslint-disable-next-line no-else-return
                // console.log('deleting as a result of cancel...')
                // remove file from local
                return {
                  [id]: {
                    status: 'deleting'
                  }
                }
              }

              // It is a normal error at this point
              const errorType = extractErrorTypeFromApolloError(e)

              return {
                [id]: {
                  status: 'error',
                  errorType,
                  progress: 0
                }
              }
            })
          })
      }
    }
  }, {})
}

export default function useUploadFiles({ uploadConcurrency = 4 } = {}) {
  const [files, setFiles] = useState({})
  const [fileIdsToDelete, setFileIdsToDelete] = useState([])

  const updateFiles = useCallback(mergeableFiles => {
    setFiles(existingFiles => {
      if (isFunction(mergeableFiles)) {
        mergeableFiles = mergeableFiles(existingFiles)
      }
      return mergeDeepRight(existingFiles, mergeableFiles)
    })
  }, [])

  const deleteFileById = useCallback((id, { removeLocal } = {}) => {
    updateFiles(existingFiles => {
      return {
        [id]: {
          status: removeLocal ? 'removing' : 'deleting'
        }
      }
    })
    // only add to delete-array if we need
    // need to process as delete
    if (!removeLocal) {
      setFileIdsToDelete(fileIds => {
        return uniq([...fileIds, id])
      })
    }
  }, [])

  const fileRemovalCompleteById = useCallback(id => {
    setFiles(({ [id]: unused, ...existingFiles }) => {
      return existingFiles
    })
  }, [])

  const [deleteDocMutation, { error: deleteError }] = useMutation(DELETE_DOC)

  useShowErrorNotificationForResponseError({
    id: 'FILE_UPLOAD_DELETE_ERROR',
    serverError: deleteError,
    autoDismissAfterMs: 2000
  })

  // useEffect(()=>)

  const deleteDocFlow = useCallback(
    async fileIds => {
      const proms = fileIds.map(id =>
        deleteDocMutation({
          variables: {
            input: {
              memberUploadId: id
            }
          }
        })
      )
      // const proms = [delay(1000)]
      try {
        await Promise.all(proms)
        const updatedFileStatuses = fileIds.reduce((acc, id) => {
          return {
            ...acc,
            [id]: {
              status: 'removing'
            }
          }
        }, {})
        updateFiles(updatedFileStatuses)
      } catch (e) {
        // handle error from hook itself
        const errorType = extractErrorTypeFromApolloError(e)
        if (errorType) {
          // set error on individual uploads
          const updatedFileErrors = fileIds.reduce((acc, id) => {
            return {
              ...acc,
              [id]: {
                status: 'error',
                errorType
              }
            }
          }, {})
          updateFiles(updatedFileErrors)
          return
        }
        throw e
      }
    },
    [deleteDocMutation]
  )

  // delete execution
  useEffect(() => {
    if (fileIdsToDelete.length) {
      // reset file-ids-to-delete array so we only
      // process them once
      setFileIdsToDelete([])
      deleteDocFlow(fileIdsToDelete)
    }
  }, [files, fileIdsToDelete, deleteDocFlow])

  // input is addition meta data we may want to send
  // with the graphql mutation, for now it is UNUSED
  function queueNewFiles(newFiles, input = {}) {
    newFiles = newFiles.reduce((acc, file) => {
      const id = uuid()
      return {
        ...acc,
        [id]: {
          id,
          status: 'queued',
          progress: 0,
          progressBytes: 0,
          fileData: file,
          uploadDate: new Date(),
          date: Date.now(),
          input
        }
      }
    }, {})
    updateFiles(newFiles)
  }

  const filesArray = useMemo(() => {
    return sortByDate(Object.values(files))
  }, [files])

  const [uploadDocsMutation] = useMutation(UPLOAD_DOCS)

  useEffect(() => {
    const filesReadyForUpload = filesArray.filter(
      allPass([propEq('status', 'queued'), propEq('validationSuccess', true)])
    )
    const totalUploading = filesArray.filter(propEq('status', 'uploading'))
      .length

    if (filesReadyForUpload.length && totalUploading < uploadConcurrency) {
      // get difference for total-allowed-to-upload at this point
      const totalAllowedToUploadNow = uploadConcurrency - totalUploading
      const filesPermittedToUpload = filesReadyForUpload.slice(
        0,
        totalAllowedToUploadNow
      )
      startUploads({
        files: filesPermittedToUpload,
        updateFiles,
        storeFile: uploadDocsMutation // tempUploadImpl
      })
    }
  }, [filesArray])

  return {
    files: filesArray,
    filesById: files,
    queueNewFiles,
    updateFiles,
    deleteFileById,
    fileRemovalCompleteById
  }
}
