import axios from 'axios'
import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'

const axiosInst = axios.create({
  baseURL: LOWER_API_DOMAIN
})

axiosInst.defaults.withCredentials = true

export default axiosInst
