import React, { useState, useCallback } from 'react'
import { uniq } from 'ramda'
import styled from 'styled-components/macro'
import SheetHeader from 'domains/BizDash/Shared/components/Sheet/styled/SheetHeader'
import Spinner from 'components/Spinner'
import Sheet from 'domains/BizDash/Shared/components/Sheet'
import PageTitle from 'domains/BizDash/Shared/styled/PageTitle'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import ApplicationCampaignListItem from './ApplicationCampaignListItem'
import CampaignForm from '../CampaignForm'

import useApplicationCampaign from '../graphql/hooks/useApplicationCampaign'
import { campaignFormValidation } from '../validation'

const ApplicationCampaignsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ApplicationCampaignsList = () => {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false)
  const {
    loading: loadingApplicationCampaign,
    fetchErrors: applicationCampaignFetchErrors,
    data: applicationCampaignData = {},
    create: createApplicationCampaign,
    update: updateApplicationCampaign,
    delete: deleteApplicationCampaign
  } = useApplicationCampaign()

  const onAddNewClick = useCallback(
    () => setIsAddingNewItem(isAddingNewItemState => !isAddingNewItemState),
    []
  )

  const onDeleteApplicationCampaign = useCallback(
    id => deleteApplicationCampaign({ variables: { id } }),
    []
  )

  const onSubmit = useCallback(data => {
    const { applicationCampaign } = data
    if (applicationCampaign.id) {
      updateApplicationCampaign({
        variables: {
          input: applicationCampaign
        }
      })
    } else {
      createApplicationCampaign({
        variables: {
          input: applicationCampaign
        }
      })
    }
  })

  const { applicationCampaigns = [] } = applicationCampaignData

  // TODO: this is a hack - part needs to be moved to backend and part memoized
  const existingCampaignIds = uniq(
    applicationCampaigns.map(ac => ac.campaignType)
  )

  const campaignTypes = [
    { title: 'UTM', id: 'utm' },
    { title: 'Organic', id: 'organic' },
    { title: 'Loan Officer Token', id: 'lotoken' }
  ]

  const newCamapignTypes = [
    { title: 'UTM', id: 'utm' },
    ...[
      { title: 'Organic', id: 'organic' },
      { title: 'Loan Officer Token', id: 'lotoken' }
    ].filter(ct => !existingCampaignIds.includes(ct.id))
  ]

  const getCampaignTypes = ({ campaignType = null }) => {
    return [
      ...newCamapignTypes,
      ...(campaignTypes.filter(ct => ct.id === campaignType) || [])
    ]
  }

  const validate = campaignFormValidation

  return (
    <Sheet>
      <SheetHeader>
        <PageTitle>Campaigns</PageTitle>
        <SimpleBtn onClick={onAddNewClick}>
          {isAddingNewItem ? 'Cancel New' : 'Add New'}
          {/* {createApplicationCampaignMutationError} */}
        </SimpleBtn>
      </SheetHeader>
      {!applicationCampaigns && loadingApplicationCampaign ? (
        <Spinner />
      ) : applicationCampaignFetchErrors &&
        applicationCampaignFetchErrors.length > 0 ? (
        'Error loading Persona Overviews'
      ) : (
        <ApplicationCampaignsContainer>
          {isAddingNewItem && (
            <CampaignForm
              name="applicationCampaign"
              campaignTypes={newCamapignTypes}
              validate={validate}
              onCancel={onAddNewClick}
              onSubmit={onSubmit}
            />
          )}
          {applicationCampaigns.map((applicationCampaign, idx) => (
            <ApplicationCampaignListItem
              key={applicationCampaign.id}
              idx={idx}
              applicationCampaign={applicationCampaign}
              campaignTypes={getCampaignTypes(applicationCampaign)}
              onSave={onSubmit}
              onDelete={onDeleteApplicationCampaign}
            />
          ))}
        </ApplicationCampaignsContainer>
      )}
    </Sheet>
  )
}

export default ApplicationCampaignsList
