import styled from 'styled-components/macro'

const Title = styled.h2`
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 70px;
  font-size: 60px;
  color: #00023f;
  max-width: 730px;
  margin: 0 auto;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 52px;
    line-height: 56px;
    letter-spacing: -1.73px;
    max-width: 656px;
  }
`

export default Title
