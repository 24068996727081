import styled from 'styled-components/macro'

const Title = styled.div`
  font-family: Gotham_Book;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.27px;
  font-weight: 200;
`

export default Title
