import styled from 'styled-components/macro'

const DataLabel = styled.h5`
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: -0.09px;
`

export default DataLabel
