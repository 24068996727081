import styled from 'styled-components/macro'

const CardText = styled.div`
  font-family: Gotham;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  color: #00023f;
  position: absolute;
  bottom: 14px;
  left: 20px;
`

export default CardText
