import React, { useState, useEffect } from 'react'
import { useSimpleModal } from 'lib/useSimpleModal'
import RingWithHalo from 'domains/LoanRecommender/shared/components/RingWithHalo'
import GeneralError from 'domains/LoanRecommender/shared/components/GeneralError'
import CloseBtn, { CloseBtnTopRightOuter } from 'components/CloseBtn'
import { useTransition, useSpring, animated } from 'react-spring'
import useTransitionStatuses from 'lib/react-spring/useTransitionStatuses'
import { SyncHeightParent, SyncHeightChild } from 'lib/SyncHeight'
import Outer from './styled/Outer'
import Overlay from './styled/Overlay'
import ModalInner from './styled/ModalInner'
import RingOuter from './styled/RingOuter'
import SlideOuter from './styled/SlideOuter'
import ReadyToLearn from './components/ReadyToLearn'
import Done from './components/Done'
import ModalWrapper from './styled/ModalWrapper'

function NotFoundSlide() {
  return <div>Not Found</div>
}

const slideConstToCompConfig = {
  READY_TO_LEARN: ReadyToLearn,
  DONE: Done,
  NOT_FOUND: NotFoundSlide
}

export default function FeedbackModal({
  modalOpen,
  modalSlide,
  loadingSlide,
  onReadyToLearnSubmit,
  generalError,
  onCloseBtnClick,
  ...restProps
}) {
  const [animatedModalOpen, setAnimatedModalOpen] = useState(modalOpen)

  const [statuses, config] = useTransitionStatuses(s => s, {
    from: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      status: 0
    },
    unique: true,
    enter: { status: 1, opacity: 1 },
    leave: { status: 2, opacity: 0 }
  })

  useEffect(() => {
    if (modalOpen) {
      setAnimatedModalOpen(true)
    }
    // if we only have one status and it's the null status,
    // we can close the modal if it's supposed to be closed
    else if (Object.keys(statuses).length === 1 && statuses.null) {
      setAnimatedModalOpen(modalOpen)
    }
  }, [modalOpen, statuses])

  const { createModalPortal } = useSimpleModal({
    show: animatedModalOpen
  })

  const modalAnimation = useSpring({
    opacity: modalOpen ? 1 : 0
  })

  const transitions = useTransition(modalSlide, s => s, config)

  const slides = transitions.map(
    ({ item: slideName, props: { status, ...restStyles }, key }) => {
      const Slide = slideConstToCompConfig[slideName]
      return (
        Slide && (
          <SyncHeightParent key={key}>
            {({ height, setHeight }) => (
              <div style={{ height: `${height}px` }}>
                <animated.div style={restStyles}>
                  <SyncHeightChild setHeight={setHeight}>
                    <Slide
                      {...restProps}
                      animationStatus={statuses[key]}
                      onCloseBtnClick={onCloseBtnClick}
                    />
                  </SyncHeightChild>
                </animated.div>
              </div>
            )}
          </SyncHeightParent>
        )
      )
    }
  )

  return createModalPortal(() => (
    <Outer pointerEvents={modalOpen ? 'auto' : 'none'} style={modalAnimation}>
      <Overlay />
      <CloseBtnTopRightOuter>
        <CloseBtn onClick={onCloseBtnClick} />
      </CloseBtnTopRightOuter>
      <ModalWrapper>
        <ModalInner>
          <RingOuter>
            <RingWithHalo />
          </RingOuter>
          {generalError && <GeneralError>{generalError}</GeneralError>}
          <SlideOuter>
            <form onSubmit={onReadyToLearnSubmit}>{slides}</form>
          </SlideOuter>
        </ModalInner>
      </ModalWrapper>
    </Outer>
  ))
}
