import styled from 'styled-components/macro'

const Bar = styled.div.attrs(({ translateX }) => {
  return {
    style: {
      transform: `translateX(${translateX || '-100%'})`
    }
  }
})`
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) => backgroundImage || 'none'};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: transform
    ${({ transitionDuration }) => transitionDuration || '1.3s'};
`

export default Bar
