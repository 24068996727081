import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { path } from 'ramda'

import CURRENT_LOAN_UPLOADER_LOGOUT_MUTATION from '../graphql/currentLoanUploaderLogoutMutation'

export default function useCurrentLoanUploaderLogout() {
  const client = useApolloClient()
  const [logoutCurrentLoanUploaderMutation, { error, loading }] = useMutation(
    CURRENT_LOAN_UPLOADER_LOGOUT_MUTATION
  )

  const logout = async () => {
    const result = await logoutCurrentLoanUploaderMutation()
    client.resetStore()

    const resultPath = path(
      ['data', 'signOutCurrentLoanUploader', 'success'],
      result
    )

    return resultPath
  }

  return {
    loading,
    error,
    logout
  }
}
