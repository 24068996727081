import React, { useLayoutEffect } from 'react'
import useGlobalStyleAdjuster from 'domains/shared/GlobalStyle/usable/useGlobalStyleAdjuster'
import Title from './styled/Title'
import Pretitle from './styled/Pretitle'
import Outer from './styled/Outer'

export default function ThankYou({ onLoginClick }) {
  const { updateStyles } = useGlobalStyleAdjuster()

  useLayoutEffect(() => {
    updateStyles({
      body: {
        bgColor: '#FFFFFF'
      }
    })
  }, [updateStyles])

  return (
    <Outer>
      <Pretitle>Yikes!</Pretitle>
      <Title>
        For your security, please use the latest version of Chrome, Firefox,
        Safari, or Edge.
      </Title>
    </Outer>
  )
}
