import React, { useState, useCallback, useEffect } from 'react'
import useEditor, { collectFieldValuesFromEditors } from 'lib/useEditor'
import Editor from 'components/QuickEditor'
import Spinner from 'components/Spinner'
import { path } from 'ramda'
import LabelAndValue from 'domains/BizDash/Shared/styled/LabelAndValue'
import Label from 'domains/BizDash/Shared/styled/EditorInputLabel'
import EditSaveCancel from 'domains/BizDash/Shared/components/EditSaveCancel'
import Outer from './styled/Outer'
import CodeOuter from './styled/CodeOuter'
import InfoOuter from './styled/InfoOuter'
import Code from './styled/Code'
import Divider from './styled/Divider'

function Overview({
  code,
  simpleMeaning,
  description,
  id,
  onSave,
  loading,
  errors
}) {
  const [isEditing, setIsEditing] = useState(false)

  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [isEditing])

  const simpleMeaningEditor = useEditor({
    field: 'simpleMeaning',
    value: simpleMeaning,
    isEditing,
    isLoading: loading,
    errors
  })

  const descEditor = useEditor({
    field: 'description',
    value: description,
    isEditing,
    isLoading: loading,
    errors
  })

  const onSaveClick = useCallback(() => {
    const editors = [simpleMeaningEditor]
    if (code.slice(0, 1) === 'p') {
      editors.push(descEditor)
    }

    const data = collectFieldValuesFromEditors(editors)

    onSave({
      ...data,
      id
    })
  }, [onSave, id, simpleMeaningEditor, descEditor])

  useEffect(() => {
    if (!loading && !path(['length'], errors)) {
      setIsEditing(false)
    }
  }, [loading])

  return (
    <Outer>
      {loading && <Spinner borderRounded />}
      <CodeOuter>
        <Code>{code.toUpperCase()}</Code>
      </CodeOuter>
      <Divider />
      <InfoOuter>
        <LabelAndValue>
          <Label>Simple Meaning</Label>
          <Editor editor={simpleMeaningEditor}>{simpleMeaning}</Editor>
        </LabelAndValue>
        {code.slice(0, 1) === 'p' && (
          <LabelAndValue>
            <Label>Description</Label>
            <Editor sz="sm" editor={descEditor}>
              {description}
            </Editor>
          </LabelAndValue>
        )}
        <EditSaveCancel
          isEditing={isEditing}
          onSaveClick={onSaveClick}
          onCancelClick={toggleIsEditing}
          neverShowDelete
          onEditClick={toggleIsEditing}
        />
      </InfoOuter>
    </Outer>
  )
}

export default Overview
