import styled from 'styled-components/macro'

const CardInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
`

export default CardInner
