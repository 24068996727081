import React, { useLayoutEffect, useEffect } from 'react'
import useGlobalStyleAdjuster from 'domains/shared/GlobalStyle/usable/useGlobalStyleAdjuster'
import SimpleBtn from 'components/SimpleBtn'
import { useGetUrl } from 'lib/UrlTracker'
import useWindowResize from 'lib/useWindowResize'
import { path } from 'ramda'
import Hero from './components/Hero'
import HeaderBar from './components/HeaderBar'
import useAnimations from './usable/useAnimations'
import useFetchLoanRecommendation from './usable/useFetchLoanRecommendation'
import Outer from './styled/Outer'
import BtnBottomOuter from './styled/BtnBottomOuter'
import Content from './components/Content'

function LoanRecommender() {
  const { queryStringParams = {} } = useGetUrl()
  const { updateStyles } = useGlobalStyleAdjuster()
  const {
    width: windowInnerWidth,
    height: windowInnerHeight
  } = useWindowResize()

  let [
    { data: loaiData, error: loaiDataError },
    { updateLocalLoanRecommendationData }
  ] = useFetchLoanRecommendation({
    loaiToken: queryStringParams.loai_token
  })
  loaiData = loaiData || {}

  const {
    restart,
    cancel,
    updateStep,
    ringSmallStyles,
    introTextStyles,
    ringStyles,
    innerGlowStyles,
    headerBarStyles,
    networkPatternStyles,
    mainTitleStyles,
    dataContentStyles,
    dataContentStep
  } = useAnimations({
    windowInnerWidth,
    windowInnerHeight,
    allowProgressToGreeting: !!path(['loanOfficerFullName'], loaiData)
  })

  const enableScrollableContent = dataContentStep === 1

  useLayoutEffect(() => {
    updateStyles({
      body: {
        bgColor: 'rgb(0,2,63)'
      }
    })
  }, [updateStyles])

  function skipToLastAnimation() {
    cancel()
    updateStep({
      smallRingAndTextStep: 3,
      ringStep: 5,
      innerGlowStep: 3,
      headerBarStep: 1,
      networkPatternStep: 1,
      mainTitleStep: 1,
      dataContentStep: 1
    })
  }

  const animationIsAtStart = dataContentStep === 0

  // if someone has an incorrect loai_token
  // or the animation was restarted,
  // make sure to check if we still have
  // an error fetching data
  useEffect(() => {
    if (loaiDataError) {
      skipToLastAnimation()
    }
  }, [loaiDataError, animationIsAtStart])

  return (
    <Outer>
      <HeaderBar
        style={headerBarStyles}
        borrowerFirstName={loaiData.borrowerFirstName}
        borrowerLastName={loaiData.borrowerLastName}
        loanNumber={loaiData.loanNumber}
        loaiDataError={loaiDataError}
      />
      <Hero
        ringSmallStyles={ringSmallStyles}
        introTextStyles={introTextStyles}
        ringStyles={ringStyles}
        innerGlowStyles={innerGlowStyles}
        networkPatternStyles={networkPatternStyles}
        borrowerFirstName={loaiData.borrowerFirstName}
        borrowerLastName={loaiData.borrowerLastName}
        loanOfficerFullName={loaiData.loanOfficerFullName}
        loanNumber={loaiData.loanNumber}
        loaiDataError={loaiDataError}
      />
      <Content
        enableScrollableContent={enableScrollableContent}
        mainTitleStyles={mainTitleStyles}
        dataContentStyles={dataContentStyles}
        loaiData={loaiData}
        loaiDataError={loaiDataError}
        updateLocalLoanRecommendationData={updateLocalLoanRecommendationData}
      />
      {queryStringParams.introcontrols === '1' && (
        <>
          <BtnBottomOuter side="right">
            <SimpleBtn onClick={skipToLastAnimation}>Skip Intro</SimpleBtn>
          </BtnBottomOuter>
          <BtnBottomOuter side="left">
            <SimpleBtn onClick={restart}>Reset Intro</SimpleBtn>
          </BtnBottomOuter>
        </>
      )}
    </Outer>
  )
}

export default LoanRecommender
