import styled from 'styled-components/macro'

// Move Inner container back down
// to the top of the viewport
const Inner = styled.div`
  position: relative;
  margin-top: 10vh;
  height: 100vh;
`

export default Inner
