import React from 'react'
import Input from 'components/Input'
import composeValidators from 'lib/composeValidators'
import createRequired from 'lib/validators/createRequired'
import Outer from './styled/Outer'
import Title from '../shared/styled/Title'
import { inputStyles } from '../shared/pallette'
// import { useFormData } from 'lib/useForm'

const codeValidations = composeValidators([
  value =>
    value && (value.length !== 5 || /[^\d]/g.test(value))
      ? 'INVALID_CODE'
      : undefined,
  createRequired('CODE_REQUIRED')
])
const enterKey = 13
const ctrlKey = 17
const cmdKey = 91
const vKey = 86
const cKey = 67
const deleteKey = 8
const backspaceKey = 46
const aKey = 65
const leftKey = 37
const upKey = 38
const rightKey = 39
const downKey = 40

function formatToMaxLengthOf(maxLength) {
  return ({ event, value }) => {
    const { key, keyCode } = event
    const notSpecialKeys =
      keyCode !== enterKey &&
      keyCode !== ctrlKey &&
      keyCode !== cmdKey &&
      keyCode !== vKey &&
      keyCode !== cKey &&
      keyCode !== deleteKey &&
      keyCode !== backspaceKey &&
      keyCode !== aKey &&
      keyCode !== leftKey &&
      keyCode !== upKey &&
      keyCode !== rightKey &&
      keyCode !== downKey

    if (!/\d/.test(key) && notSpecialKeys) {
      event.preventDefault()
      return
    }

    if (notSpecialKeys && value && value.length >= maxLength) {
      event.preventDefault()
      return { value: value.slice(0, maxLength) }
    }
  }
}

const formatToMaxLengthOf5 = formatToMaxLengthOf(5)

export default function VerifyCode() {
  // const formData = useFormData()

  // let valid
  // if (!formData) {
  //   valid = false
  // } else {
  //   valid = !path(
  //     ['phonePage', 'phone', 'onValueChangeErrors', 'length'],
  //     formData
  //   )
  // }

  return (
    <Outer>
      <Title maxWidth="516px">Enter the code  we texted you.</Title>
      <Input
        {...inputStyles}
        maxWidth="225px"
        fontFamily="monospace"
        placeHolderFontFamily="Gotham"
        formatOnType={formatToMaxLengthOf5}
        onValueChangeValidate={codeValidations}
        placeHolderLetterSpacing="28px"
        letterSpacing="17px"
        extendInputWidth="108%"
        name="code"
        placeholder="*****"
      />
    </Outer>
  )
}
