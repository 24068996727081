import styled from 'styled-components/macro'

const PeerMatchOuter = styled.div`
  position: relative;
  padding: 50px 54px 100px 0;
  margin: 0 auto;
  @media (max-width: 1245px) {
    max-width: 510px;
    padding: 120px 0 140px 0;
  }
  @media (max-width: 768px) {
    padding: 69px 0 102px 0;
  }
  @media (max-width: 630px) {
    padding: 69px 15px 102px 15px;
  }
`

export default PeerMatchOuter
