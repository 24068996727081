/* eslint no-use-before-define: 0 */
import React, { useReducer, useMemo, useCallback } from 'react'
import lowerAxios from 'domains/shared/utils/lowerApiAxios'
import useSafeDispatch from 'lib/useSafeDispatch'
import { mergeDeepRight, update, findIndex, propEq } from 'ramda'
import {
  ensureErrorsAsArray,
  responseNotOk
} from 'domains/shared/utils/responseHelpers'
import { Provider as ContextProvider } from './context'

const FETCH_LOADING = 'FETCH_LOADING'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'
const UPDATE_STATUSES = 'UPDATE_STATUSES'
const MERGE_RESOURCE = 'MERGE_RESOURCE'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case FETCH_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        personaOverviews: payload.personaOverviews
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        personaOverviews: [],
        fetchErrors: payload.errors
      }
    }
    case UPDATE_STATUSES: {
      return mergeDeepRight(state, { statuses: { [payload.id]: payload } })
    }
    case MERGE_RESOURCE: {
      const idx = findIndex(propEq('id', payload.id), state.personaOverviews)
      if (idx === -1) {
        return state
      }
      const data = {
        ...state.personaOverviews[idx],
        ...payload
      }
      return {
        ...state,
        personaOverviews: update(idx, data, state.personaOverviews)
      }
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    personaOverviews: [],
    fetchLoading: false,
    fetchErrors: [],
    statuses: {}
  }
}

export function withPersonaOverviewsProvider(Child) {
  return function PersonaOverviewsProvider({ children, ...restProps }) {
    let [state, dispatch] = useReducer(reducer, {}, init)
    dispatch = useSafeDispatch(dispatch)

    /*
     * ACTIONS AS CALLBACKS
     */

    /*
     * ACTION: fetchPersonaOverviews
     */
    const fetchPersonaOverviews = useCallback(
      async function fetchPersonaOverviews() {
        dispatch({
          type: FETCH_LOADING
        })

        try {
          const {
            data: { personaOverviews }
          } = await lowerAxios.get(
            `/persona-overviews`
            // { params: { loai_token: loaiToken } }
          )

          dispatch({
            type: FETCH_SUCCESS,
            payload: {
              personaOverviews
            }
          })
        } catch (e) {
          // debugger
          if (responseNotOk(e)) {
            dispatch({
              type: FETCH_ERROR,
              payload: {
                errors: ensureErrorsAsArray(e)
              }
            })
            return
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: updatePersonaOverview
     */
    const updatePersonaOverview = useCallback(
      async function updatePersonaOverview(data) {
        const { id } = data
        dispatch({
          type: UPDATE_STATUSES,
          payload: {
            id,
            loading: true
          }
        })

        try {
          await lowerAxios.patch(`/persona-overviews/${id}`, data)

          dispatch({
            type: UPDATE_STATUSES,
            payload: {
              id,
              loading: false,
              errors: []
            }
          })
          dispatch({
            type: MERGE_RESOURCE,
            payload: data
          })
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                id,
                loading: false,
                errors: ensureErrorsAsArray(e)
              }
            })
            return
          }
          throw e
        }
      },
      [state]
    )

    const providerValue = useMemo(() => {
      return [
        state,
        // provide all public api:
        {
          updatePersonaOverview,
          fetchPersonaOverviews
        }
      ]
    }, [dispatch, state])

    return (
      <ContextProvider value={providerValue}>
        {Child ? <Child {...restProps} /> : children}
      </ContextProvider>
    )
  }
}

export default withPersonaOverviewsProvider()
