import styled from 'styled-components/macro'

const SocialIconsOuter = styled.div`
  display: flex;
  padding: 33px 0 0 0;
  float: left;
  @media (max-width: 890px) {
    display: none;
  }
`

export default SocialIconsOuter
