import React from 'react'
import useInput from 'lib/useInput'
import Outer from './styled/Outer'
import Underline from './styled/Underline'
import TextInput from './styled/TextInput'

const underlineFocusedColorDefault = '#0600F5'
const underlineBlurredColorDefault = '#AAAAAA'

const underlineFocusedHeightDefault = '2px'
const underlineBlurredHeightDefault = '1px'

export default function Input(props) {
  const {
    placeholder,
    fontSize,
    lineHeight,
    maxWidth,
    letterSpacing,
    fontFamily,
    placeHolderFontFamily,
    placeHolderLetterSpacing,
    textAlign,
    inputMarginLeft,
    name,
    formatOnType,
    formatOnBlur,
    formatOnChange,
    onValueChangeValidate,
    underlineFocusedColor = underlineFocusedColorDefault,
    underlineBlurredColor = underlineBlurredColorDefault,
    underlineFocusedHeight = underlineFocusedHeightDefault,
    underlineBlurredHeight = underlineBlurredHeightDefault,
    extendInputWidth,
    inputCss,
    outerCss
  } = props

  const { inputRef, ...inputData } = useInput({
    name,
    formatOnType,
    formatOnBlur,
    formatOnChange,
    onValueChangeValidate
  })
  const { focused, value } = inputData

  let inputWidth
  let inputMaxWidth

  if (extendInputWidth) {
    inputWidth = extendInputWidth
    inputMaxWidth = extendInputWidth
  }

  return (
    <Outer customCss={outerCss} maxWidth={maxWidth}>
      <TextInput
        type="text"
        placeholder={placeholder}
        fontSize={fontSize}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        placeHolderLetterSpacing={placeHolderLetterSpacing}
        width={inputWidth}
        maxWidth={inputMaxWidth}
        fontFamily={fontFamily}
        placeHolderFontFamily={placeHolderFontFamily}
        textAlign={textAlign}
        marginLeft={inputMarginLeft}
        {...inputData}
        customCss={inputCss}
        ref={inputRef}
      />
      <Underline
        color={
          focused || value.length
            ? underlineFocusedColor
            : underlineBlurredColor
        }
        height={
          focused || value.length
            ? underlineFocusedHeight
            : underlineBlurredHeight
        }
      />
    </Outer>
  )
}
