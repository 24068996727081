import styled from 'styled-components/macro'

const Title = styled.h2`
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: -0.4px;
  flex: 1;
`

export default Title
