import { useState, useCallback, useMemo, useEffect } from 'react'
import { filter, propEq, compose, map } from 'ramda'

function getFilesFromEvent(e) {
  const { items, files } = e.dataTransfer
  if (items) {
    return compose(
      map(item => item.getAsFile()),
      filter(propEq('kind', 'file'))
    )(items)
  }
  return files.length ? files : []
}

export default function useEvents({ onReceiveFiles }) {
  const [{ isHoveringOver, droppedFiles }, setState] = useState({
    isHoveringOver: false,
    droppedFiles: []
  })

  function mergeState(newState) {
    setState(s => {
      return {
        ...s,
        ...newState
      }
    })
  }

  const onDrop = useCallback(e => {
    e.preventDefault()
    const files = getFilesFromEvent(e)
    mergeState({
      isHoveringOver: false,
      droppedFiles: files
    })
  }, [])

  useEffect(() => {
    if (droppedFiles && droppedFiles.length) {
      // for now, just call the provided callback
      // to start uploading files.
      // Eventually we will do the 'dot' animation
      // from cursor, but not a priority
      onReceiveFiles && onReceiveFiles(droppedFiles, { dropped: true })
      // setState({ droppedFiles: undefined })
    }
  }, [droppedFiles])

  const onDragEnter = useCallback(
    e => {
      e.preventDefault()

      if (!isHoveringOver) {
        // console.log('enter!', e.target)
        mergeState({ isHoveringOver: true })
      }
    },
    [isHoveringOver]
  )

  const onDragLeave = useCallback(e => {
    e.preventDefault()
    // only supports IE 9+ (which is true for React too, so no worries)
    if (!e.relatedTarget && !e.toElement) {
      mergeState({ isHoveringOver: false })
    }
  }, [])

  const onDragOver = useCallback(
    e => {
      e.preventDefault()

      if (!isHoveringOver) {
        // console.log('enter!', e.target)
        mergeState({ isHoveringOver: true })
      }
    },
    [isHoveringOver]
  )

  const handlers = useMemo(() => {
    return {
      onDrop,
      onDragEnter,
      onDragLeave,
      onDragOver
    }
  }, [onDrop, onDragEnter, onDragLeave, onDragOver])

  return {
    handlers,
    isHoveringOver,
    droppedFiles
  }
}
