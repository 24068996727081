import styled from 'styled-components/macro'

const CloseBtnTopRightOuter = styled.div`
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  @media (max-width: 500px) {
    right: 30px;
  }
`

export default CloseBtnTopRightOuter
