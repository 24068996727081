import React from 'react'
import TextArea from 'domains/LoanRecommender/shared/components/TextArea'
import { css } from 'styled-components/macro'
import SimpleBtn from 'domains/LoanRecommender/shared/components/SimpleBtn'
import { filter, propEq, compose } from 'ramda'
import { useTrail } from 'react-spring'
import Outer from './styled/Outer'
import Title from '../shared/styled/Title'
import Subtitle from '../shared/styled/Subtitle'
import TextAreaOuter from './styled/TextAreaOuter'
import BtnOuter from '../shared/styled/BtnOuter'

const textAreaCss = css`
  min-height: 150px;
`

const getErrorsFor = (field, errors) =>
  compose(filter(propEq('field', field)))(errors)

// function use

export default function ReadyToLearn({
  onFeedbackTextInputChange,
  inputData: { feedbackText = '' },
  submitErrors,
  animationStatus
}) {
  const errors = getErrorsFor('feedbackText', submitErrors)
  const childAnimations = useTrail(4, {
    from: {
      transform: 'translateY(70px)'
    },
    to: {
      transform: `translateY(0px)`
    },
    reverse: animationStatus === 'LEAVING'
  })

  return (
    <Outer>
      <Title style={childAnimations[0]}>I’m ready to learn.</Title>
      <Subtitle style={childAnimations[1]}>
        Please leave detailed feedback for documentation.
      </Subtitle>
      <TextAreaOuter style={childAnimations[2]}>
        <TextArea
          cssStyles={textAreaCss}
          onInputChange={onFeedbackTextInputChange}
          value={feedbackText}
          errors={errors}
        />
      </TextAreaOuter>
      <BtnOuter style={childAnimations[3]}>
        <SimpleBtn as="button" type="submit" maxWidth="192px">
          SUBMIT
        </SimpleBtn>
      </BtnOuter>
    </Outer>
  )
}
