import React, { useReducer, useMemo } from 'react'
import { mergeDeepRight } from 'ramda'
import GlobalStyleBase from './styled/GlobalStyle'
import GothamFontsStyle from './styled/GothamFontsStyle'
import SanitizeStyle from './styled/SanitizeStyle'
import { ContextProvider } from './context'

function reducer(state, action) {
  switch (action.type) {
    case 'updateStyles':
      return mergeDeepRight(state, { styles: action.payload || {} })
    default:
      throw new Error()
  }
}

function init() {
  return {
    styles: {
      body: {
        bgColor: 'white'
      }
    }
  }
}

export default function GlobalStyle({ children }) {
  const [state, dispatch] = useReducer(reducer, {}, init)

  const providerValue = useMemo(() => {
    return {
      dispatch
    }
  }, [dispatch])

  return (
    <ContextProvider value={providerValue}>
      <SanitizeStyle />
      <GothamFontsStyle />
      <GlobalStyleBase {...state} />
      {children}
    </ContextProvider>
  )
}
