import { createGlobalStyle } from 'styled-components/macro'

const SanitizeStyle = createGlobalStyle`
article,
aside,
details, figcaption,
figure,
footer,
header,
main, menu,
nav,
section,
summary {   display: block;
}


audio,
canvas,
progress,
video {
  display: inline-block;
}


audio:not([controls]) {
  display: none;
  height: 0;
}


template, [hidden] {
  display: none;
}



*,
::before,
::after {
  background-repeat: no-repeat;   box-sizing: inherit; }


::before,
::after {
  text-decoration: inherit;   vertical-align: inherit; }


html {
  box-sizing: border-box;   cursor: default;   font-family: sans-serif;   line-height: 1.5;   -ms-text-size-adjust: 100%;   -webkit-text-size-adjust: 100%; }



body {
  margin: 0;
}


h1 {
  font-size: 2em;
  margin: .67em 0;
}



code,
kbd,
pre,
samp {
  font-family: monospace, monospace;   font-size: 1em; }


hr {
  height: 0;   overflow: visible; }


nav ol,
nav ul {
  list-style: none;
}



abbr[title] {
  border-bottom: 1px dotted;   text-decoration: none; }


b,
strong {
  font-weight: inherit;
}


b,
strong {
  font-weight: bolder;
}


dfn {
  font-style: italic;
}


mark {
  background-color: #ffff00;
  color: #000000;
}


progress {
  vertical-align: baseline;
}


small {
  font-size: 83.3333%;
}


sub,
sup {
  font-size: 83.3333%;   line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}


::-moz-selection {
  background-color: #b3d4fc;   color: #000000;   text-shadow: none;
}

::selection {
  background-color: #b3d4fc;   color: #000000;   text-shadow: none;
}



audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}


img {
  border-style: none;
}


svg {
  fill: currentColor;
}


svg:not(:root) {
  overflow: hidden;
}



a {
  background-color: transparent;   -webkit-text-decoration-skip: objects; }


a:hover {
  outline-width: 0;
}



table {
  border-collapse: collapse;
  border-spacing: 0;
}



button,
input,
select,
textarea {
  background-color: transparent;   border-style: none;   color: inherit;   font-size: 1em;   margin: 0; }


button,
input {   overflow: visible;
}


button,
select {   text-transform: none;
}


button,
html [type="button"], [type="reset"],
[type="submit"] {
  -webkit-appearance: button; }


::-moz-focus-inner {
  border-style: none;
  padding: 0;
}


:-moz-focusring {
  outline: 1px dotted ButtonText;
}


fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em;
}


legend {
  display: table;   max-width: 100%;   padding: 0;   white-space: normal; }


textarea {
  overflow: auto;   resize: vertical; }


[type="checkbox"],
[type="radio"] {
  padding: 0;
}


::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}


[type="search"] {
  -webkit-appearance: textfield;   outline-offset: -2px; }


::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}


::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}


::-webkit-file-upload-button {
  -webkit-appearance: button;   font: inherit; }



[aria-busy="true"] {
  cursor: progress;
}


[aria-controls] {
  cursor: pointer;
}


[aria-disabled] {
  cursor: default;
}



a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;   touch-action: manipulation;
}


[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}
`

export default SanitizeStyle
