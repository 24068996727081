import { createDataValueSwitcher } from 'lib/useEditor'
import { path, equals, reject } from 'ramda'
import TextArea from './components/TextArea'
import Sentence from './components/Sentence'

const splitOnSlash = s => reject(equals(''), s.split('/'))

const splitOnDot = s => s.split('.')

const Editor = createDataValueSwitcher({
  displayer: Sentence,
  inputCollector: TextArea,
  getFieldErrors: (field, errors) => {
    const fieldErrors = []
    errors = errors || []
    errors.forEach(error => {
      const innerErrors = path(['params', 'errors'], error) || []
      innerErrors.forEach(({ message, dataPath }) => {
        if (
          message &&
          dataPath &&
          equals(splitOnSlash(dataPath), splitOnDot(field))
        ) {
          fieldErrors.push({ message })
        }
      })
    })
    return fieldErrors
  }
})

export default Editor
