import React, { useCallback, useEffect, useRef } from 'react'
import Spinner from 'components/Spinner'
import { ErrorNotification, InfoNotification } from 'components/Notifications'
import bangIconPath from 'components/Notifications/images/bang-icon.png'
import { withFormProvider, useFormActions } from 'lib/useForm'
import { useNotificationsActions } from 'lib/notifications'
import { compose } from 'ramda'
import useShowErrorNotificationForResponseError from 'domains/shared/useable/useShowErrorNotificationForResponseError'
import extractErrorTypeFromApolloError from 'lib/extractErrorTypeFromApolloError'
import UploadCodeLoginPage from './components/UploadCodeLogin'
import useLoanUploadCodeLogin from './useable/useLoanUploadCodeLogin'
import useCurrentLoanUploader from './useable/useCurrentLoanUploader'

function withUploadCodeLogin(Child) {
  return function UploadCodeLogin(props) {
    const { isIntroOverlayVisible } = props
    const { getAllForms } = useFormActions()

    const {
      verified,
      loading: currentLoanUploaderLoading,
      error: currentLoanUploaderError
    } = useCurrentLoanUploader()

    const {
      login,
      error: loginNetworkError,
      loading: loginLoading
    } = useLoanUploadCodeLogin()
    const {
      upsertNote,
      dismissingNote,
      dismissingNotesByTag
    } = useNotificationsActions()

    const onSubmit = useCallback(async () => {
      if (!loginLoading) {
        const {
          docUploadCodePage: {
            code: { value, onValueChangeErrors }
          }
        } = getAllForms()
        if (onValueChangeErrors.length) {
          // we don't technically need a notification here because
          // we are formatting all values into allowed values
          // automatically, but if the user presses enter,
          // at least give feedback
          dismissingNotesByTag('error', {
            exceptIds: ['LOGIN_ATTEMPT_SUBMIT_ERROR']
          })
          upsertNote(
            {
              id: 'LOGIN_ATTEMPT_SUBMIT_ERROR',
              textPaddingRight: '30px',
              component: ErrorNotification,
              stretchToMaxWidth: '452px',
              tag: 'error',
              mainText: 'Invalid code.',
              subtext:
                'Please check your text messages from Lower.com for your Code.'
            },
            { prepend: true }
          )
          return
        }
        dismissingNote('LOGIN_ATTEMPT_SUBMIT_ERROR')

        let isValid
        try {
          isValid = await login(value)
        } catch (e) {
          // handle network/apollo specific errors from hook return-value itself
          // AND return, we don't want to show the isValid=false error since
          // it may be valid, our server just threw an error
          if (extractErrorTypeFromApolloError(e)) {
            return
          }
          throw e
        }

        if (!isValid) {
          upsertNote(
            {
              id: 'LOGIN_VALIDATION_ERROR',
              textPaddingRight: '20px',
              component: ErrorNotification,
              stretchToMaxWidth: '490px',
              tag: 'error',
              mainText: 'Hmm. That code doesn’t match our records.',
              subtext:
                'If you would like us to resend your code, please check with your loan advisor.'
            },
            { prepend: true }
          )
        }
      }
    }, [loginLoading])

    const showedHelpNote = useRef()
    // only show the login help
    // if we are fully landed on the login screen,
    // aka, we are not currently loading the current uploader,
    // we are not verified, and we haven't shown this note yet
    useEffect(() => {
      if (
        !isIntroOverlayVisible &&
        !currentLoanUploaderLoading &&
        !verified &&
        !showedHelpNote.current
      ) {
        upsertNote(
          {
            id: 'UPLOADER_LOGIN_HELP',
            component: InfoNotification,
            stretchToMaxWidth: '454px',
            textPaddingRight: '20px',
            mainText: 'Looking for your code?',
            subtext:
              'We texted it to you. Just let your loan advisor know if you can’t find it!',
            iconPath: bangIconPath
          },
          { prepend: true }
        )
        showedHelpNote.current = true
      }
    }, [currentLoanUploaderLoading, verified, isIntroOverlayVisible])

    // dismiss errors if we are loading or verified
    useEffect(() => {
      if (currentLoanUploaderLoading || loginLoading || verified) {
        dismissingNotesByTag('error')
      }
    }, [currentLoanUploaderLoading, loginLoading, verified])

    useShowErrorNotificationForResponseError({
      id: 'UPLOADER_LOGIN_NETWORK_ERROR',
      serverError: loginNetworkError
    })

    useShowErrorNotificationForResponseError({
      id: 'CURRENT_LOAN_UPLOADER_NETWORK_ERROR',
      serverError: currentLoanUploaderError
    })

    // only dismiss UPLOADER_LOGIN_HELP automatically
    // if actually verified
    useEffect(() => {
      if (verified) {
        dismissingNote('UPLOADER_LOGIN_HELP')
      }
    }, [verified])

    // dismiss all notes if we unmount
    useEffect(() => {
      return () => {
        dismissingNotesByTag('error')
        dismissingNote('UPLOADER_LOGIN_HELP')
      }
    }, [])

    if (currentLoanUploaderLoading) {
      return <Spinner paint="blue-grey" />
    }
    if (!verified) {
      return <UploadCodeLoginPage onSubmit={onSubmit} />
    }

    return <Child {...props} />
  }
}

export default compose(
  withFormProvider,
  withUploadCodeLogin
)
