import styled from 'styled-components/macro'

const Sub = styled.span`
  display: inline-block;
  vertical-align: baseline;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  margin-left: 4px;
`

export default Sub
