import { useAppStore } from '../store'

const useModalHook = () => {
  const [showModal, hideModal, currentModal] = useAppStore((state, actions) => {
    return [actions.showModal, actions.hideModal, state.modal.currentModal]
  })
  return { showModal, hideModal, currentModal }
}

export default useModalHook
