import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Subtitle = styled(animated.h2)`
  font-family: Gotham_Book;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.4px;
  margin-bottom: 40px;
`

export default Subtitle
