/* eslint no-use-before-define: 0 */
import React, { useReducer, useMemo, useCallback } from 'react'
import lowerAxios from 'domains/shared/utils/lowerApiAxios'
import useSafeDispatch from 'lib/useSafeDispatch'
import {
  mergeDeepRight,
  update,
  findIndex,
  propEq,
  compose,
  prop,
  map,
  indexBy,
  reject,
  values,
  identity,
  pluck
} from 'ramda'
import {
  ensureErrorsAsArray,
  responseNotOk
} from 'domains/shared/utils/responseHelpers'
import { Provider as ContextProvider } from './context'

const FETCH_LOADING = 'FETCH_LOADING'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'
const UPDATE_STATUSES = 'UPDATE_STATUSES'
const UPDATE_MULTI_STATUSES = 'UPDATE_MULTI_STATUSES'
const MERGE_RESOURCE = 'MERGE_RESOURCE'
const MERGE_RESOURCES = 'MERGE_RESOURCES'
const DELETE_RESOURCE = 'DELETE_RESOURCE'
const DELETE_RESOURCES = 'DELETE_RESOURCES'

function markListOfStatuses({ statuses, items }) {
  return compose(
    indexBy(prop('id')),
    map(({ id }) => {
      return {
        id,
        ...statuses
      }
    })
  )(items)
}

function mergeResources(state, payload) {
  const { resourceName, data: incomingDataList } = payload
  const incomingDataListByIds = indexBy(prop('id'), incomingDataList)
  const resources = state[resourceName]

  const updatedItems = map(resource => {
    if (incomingDataListByIds[resource.id]) {
      incomingDataListByIds[resource.id].merged = true
      return {
        ...resource,
        ...incomingDataListByIds[resource.id]
      }
    }
    return resource
  }, resources)

  const appendItems = reject(prop('merged'), values(incomingDataListByIds))

  return {
    ...state,
    [resourceName]: updatedItems.concat(appendItems)
  }
}

function deleteResources(state, { data: ids, resourceName }) {
  let resources = state[resourceName]
  const indexedIds = indexBy(identity, ids)
  resources = reject(resource => indexedIds[resource.id], resources)

  return {
    ...state,
    [resourceName]: resources
  }
}

function deleteResource(state, { data: { id }, resourceName }) {
  let resources = state[resourceName]
  resources = reject(propEq('id', id), resources)
  return {
    ...state,
    [resourceName]: resources
  }
}

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case FETCH_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        personaGuidanceCombos: payload.personaGuidanceCombos,
        personaOverviewsGuidanceCombos: payload.personaOverviewsGuidanceCombos
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        personaGuidanceCombos: [],
        personaOverviewsGuidanceCombos: [],
        fetchErrors: payload.errors
      }
    }
    case UPDATE_STATUSES: {
      return mergeDeepRight(state, { statuses: { [payload.id]: payload } })
    }
    case UPDATE_MULTI_STATUSES: {
      return mergeDeepRight(state, { statuses: payload.statuses })
    }
    case MERGE_RESOURCE: {
      const { resourceName, data: incomingData } = payload
      const resources = state[resourceName]
      const idx = findIndex(propEq('id', incomingData.id), resources)
      if (idx === -1) {
        return {
          ...state,
          [resourceName]: [incomingData, ...resources]
        }
      }
      const data = {
        ...resources[idx],
        ...incomingData
      }
      return {
        ...state,
        [resourceName]: update(idx, data, resources)
      }
    }
    case MERGE_RESOURCES: {
      state = mergeResources(state, payload)
      return state
    }
    case DELETE_RESOURCE: {
      state = deleteResource(state, payload)
      return state
    }
    case DELETE_RESOURCES: {
      state = deleteResources(state, payload)
      return state
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    personaGuidanceCombos: [],
    personaOverviewsGuidanceCombos: [],
    fetchLoading: false,
    fetchErrors: [],
    statuses: {}
  }
}

export function withPersonaGuidanceCombosProvider(Child) {
  return function PersonaGuidanceCombosProvider({ children, ...restProps }) {
    let [state, dispatch] = useReducer(reducer, {}, init)
    dispatch = useSafeDispatch(dispatch)

    /*
     * ACTIONS AS CALLBACKS
     */

    /*
     * ACTION: fetchPersonaGuidanceCombos
     */
    const fetchPersonaGuidanceCombos = useCallback(
      async function fetchPersonaGuidanceCombos() {
        dispatch({
          type: FETCH_LOADING
        })

        try {
          const {
            data: { personaGuidanceCombos, personaOverviewsGuidanceCombos }
          } = await lowerAxios.get(`/persona-guidance-combos`, {
            params: { include_persona_overviews_guidance_combos: true }
          })

          dispatch({
            type: FETCH_SUCCESS,
            payload: {
              personaGuidanceCombos,
              personaOverviewsGuidanceCombos
            }
          })
        } catch (e) {
          // debugger
          if (responseNotOk(e)) {
            dispatch({
              type: FETCH_ERROR,
              payload: {
                errors: ensureErrorsAsArray(e)
              }
            })
            return
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: updatePersonaGuidanceCombo
     */
    const updatePersonaGuidanceCombo = useCallback(
      async function updatePersonaGuidanceCombo(data) {
        const { id } = data
        dispatch({
          type: UPDATE_STATUSES,
          payload: {
            id,
            loading: true
          }
        })

        try {
          await lowerAxios.patch(`/persona-guidance-combos/${id}`, data)

          dispatch({
            type: UPDATE_STATUSES,
            payload: {
              id,
              loading: false,
              errors: []
            }
          })
          dispatch({
            type: MERGE_RESOURCE,
            payload: {
              data,
              resourceName: 'personaGuidanceCombos'
            }
          })
          return { success: true }
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                id,
                loading: false,
                errors: ensureErrorsAsArray(e)
              }
            })
            return { success: false }
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: createPersonaGuidanceCombo
     */
    const createPersonaGuidanceCombo = useCallback(
      async function createPersonaGuidanceCombo(data) {
        const { id } = data
        dispatch({
          type: UPDATE_STATUSES,
          payload: {
            id,
            loading: true
          }
        })

        try {
          await lowerAxios.post(`/persona-guidance-combos`, data)

          dispatch({
            type: UPDATE_STATUSES,
            payload: {
              id,
              loading: false,
              errors: []
            }
          })
          dispatch({
            type: MERGE_RESOURCE,
            payload: {
              data,
              resourceName: 'personaGuidanceCombos'
            }
          })
          return { success: true }
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                id,
                loading: false,
                errors: ensureErrorsAsArray(e)
              }
            })
            return { success: false }
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: deletePersonaGuidanceCombo
     */
    const deletePersonaGuidanceCombo = useCallback(
      async function deletePersonaGuidanceCombo(data) {
        const { id } = data
        dispatch({
          type: UPDATE_STATUSES,
          payload: {
            id,
            loading: true
          }
        })
        try {
          await lowerAxios.delete(`/persona-guidance-combos/${id}`)

          dispatch({
            type: UPDATE_STATUSES,
            payload: {
              id,
              loading: false,
              errors: []
            }
          })
          dispatch({
            type: DELETE_RESOURCE,
            payload: {
              data,
              resourceName: 'personaGuidanceCombos'
            }
          })
          return { success: true }
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                id,
                loading: false,
                errors: ensureErrorsAsArray(e)
              }
            })
            return { success: false }
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: createPersonaOverviewsGuidanceCombos
     */
    const createPersonaOverviewsGuidanceCombos = useCallback(
      async function createPersonaOverviewsGuidanceCombos(items) {
        const loadingStatusesByIds = markListOfStatuses({
          statuses: { loading: true },
          items
        })

        dispatch({
          type: UPDATE_MULTI_STATUSES,
          payload: { statuses: loadingStatusesByIds }
        })

        try {
          await lowerAxios.post(
            `/persona-overviews-guidance-combos/create-all`,
            { personaOverviewsGuidanceCombos: items }
          )
          const notLoadingStatusesByIds = markListOfStatuses({
            statuses: { loading: false },
            items
          })

          dispatch({
            type: UPDATE_MULTI_STATUSES,
            payload: { statuses: notLoadingStatusesByIds }
          })
          dispatch({
            type: MERGE_RESOURCES,
            payload: {
              data: items,
              resourceName: 'personaOverviewsGuidanceCombos'
            }
          })
          return { success: true }
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                statuses: markListOfStatuses({
                  statuses: { loading: false, errors: ensureErrorsAsArray(e) },
                  items
                })
              }
            })
            return { success: false }
          }
          throw e
        }
      },
      [state]
    )

    /*
     * ACTION: deletePersonaOverviewsGuidanceCombos
     */
    const deletePersonaOverviewsGuidanceCombos = useCallback(
      async function deletePersonaOverviewsGuidanceCombos(items) {
        const loadingStatusesByIds = markListOfStatuses({
          statuses: { loading: true },
          items
        })

        dispatch({
          type: UPDATE_MULTI_STATUSES,
          payload: { statuses: loadingStatusesByIds }
        })
        const ids = pluck('id', items)
        try {
          await lowerAxios.delete(`/persona-overviews-guidance-combos`, {
            data: { ids }
          })
          const notLoadingStatusesByIds = markListOfStatuses({
            statuses: { loading: false },
            items
          })

          dispatch({
            type: UPDATE_MULTI_STATUSES,
            payload: { statuses: notLoadingStatusesByIds }
          })
          dispatch({
            type: DELETE_RESOURCES,
            payload: {
              data: ids,
              resourceName: 'personaOverviewsGuidanceCombos'
            }
          })
          return { success: true }
        } catch (e) {
          if (responseNotOk(e)) {
            dispatch({
              type: UPDATE_STATUSES,
              payload: {
                statuses: markListOfStatuses({
                  statuses: { loading: false, errors: ensureErrorsAsArray(e) },
                  items
                })
              }
            })
            return { success: false }
          }
          throw e
        }
      },
      [state]
    )

    const providerValue = useMemo(() => {
      return [
        state,
        // provide all public api:
        {
          createPersonaOverviewsGuidanceCombos,
          deletePersonaOverviewsGuidanceCombos,
          createPersonaGuidanceCombo,
          updatePersonaGuidanceCombo,
          deletePersonaGuidanceCombo,
          fetchPersonaGuidanceCombos
        }
      ]
    }, [dispatch, state])

    return (
      <ContextProvider value={providerValue}>
        {Child ? <Child {...restProps} /> : children}
      </ContextProvider>
    )
  }
}

export default withPersonaGuidanceCombosProvider()
