import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  height: 100px;
  margin-bottom: 19px;
  ${({ customCss }) => customCss}
`

export default Outer
