import React from 'react'
import Outer from './styled/Outer'
import Ring from './styled/Ring'
import Wrapper from './styled/Wrapper'

export default function CardRings({
  largestRingDiameterPx = 230,
  ringSpacePx = 60,
  totalRings = 3,
  ringsOuterCss
}) {
  const rings = Array(totalRings)
    .fill()
    .map((unused, i) => {
      const ringDiameter = largestRingDiameterPx - i * ringSpacePx
      if (ringDiameter < 0) {
        return null
      }
      return <Ring key={i} diameter={ringDiameter} />
    })
    .filter(item => item)

  return (
    <Wrapper>
      <Outer customCss={ringsOuterCss} size={largestRingDiameterPx}>
        {rings}
      </Outer>
    </Wrapper>
  )
}
