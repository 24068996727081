const theme = {
  primaryColor: '#4100F3',
  fonts: {
    strong: {
      font: 'Gotham_Bold, sans-serif',
      weight: 'regular'
    },
    medium: {
      font: 'Gotham, sans-serif',
      weight: '400'
    },
    regular: {
      font: 'Gotham_Book, sans-serif',
      weight: 'regular'
    },
    light: {
      font: 'Gotham, sans-serif',
      weight: '100'
    }
  }
}

export default {
  ...theme,
  font: font =>
    `
      font-family: ${theme.fonts[font].font};
      font-weight: ${theme.fonts[font].weight};
      font-style: ${theme.fonts[font].style ? this.fonts[font].style : 'normal'}
    `
}
