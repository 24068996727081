import styled from 'styled-components/macro'

const ModalInner = styled.div`
  position: relative;
  margin-top: 20vh;
  color: white;
  text-align: center;
  padding: 0 35px;
  transition: margin-top 0.2s;
  @media (max-height: 725px) {
    margin-top: 13vh;
  }
`

export default ModalInner
