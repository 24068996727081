import React, { memo } from 'react'
import Outer from './styled/Outer'

const svgStyles = {
  display: 'block'
}

function DeleteIcon({
  innerRef,
  onMouseEnter,
  onMouseLeave,
  onClick,
  darkPaint
}) {
  const backgroundColor = darkPaint ? '#F2F2F2' : '#FFFFFF'
  return (
    <Outer
      onClick={onClick}
      ref={innerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      backgroundColor={backgroundColor}
    >
      <svg
        style={svgStyles}
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-623.000000, -165.000000)"
            fill="#00023F"
            fillRule="nonzero"
          >
            <g transform="translate(450.000000, 143.000000)">
              <g transform="translate(1.000000, 0.000000)">
                <g transform="translate(172.000000, 22.000000)">
                  <path d="M7.55386125,-1.99840144e-15 C7.85558526,-1.99840144e-15 8.1325759,0.150692795 8.27365342,0.391592627 L9.108,1.818 L11.4545455,1.81818182 C11.7557917,1.81818182 12,2.06239014 12,2.36363636 C12,2.66488259 11.7557917,2.90909091 11.4545455,2.90909091 L10.8,2.909 L10.8,11.2645161 C10.8,11.6707127 10.4354319,12 9.9857143,12 L2.0142857,12 C1.56456813,12 1.2,11.6707127 1.2,11.2645161 L1.2,2.909 L0.545454545,2.90909091 C0.244208318,2.90909091 1.67902613e-13,2.66488259 1.67865721e-13,2.36363636 C1.67828829e-13,2.06239014 0.244208318,1.81818182 0.545454545,1.81818182 L2.89,1.818 L3.72625537,0.391592627 C3.86733289,0.150692795 4.14432353,-1.99840144e-15 4.44604753,-1.99840144e-15 L7.55386125,-1.99840144e-15 Z M9.57857144,2.92140761 L2.42142856,2.92140761 L2.42142856,10.8967742 L9.57857144,10.8967742 L9.57857144,2.92140761 Z M4.4,4.17565983 C4.73728818,4.17565983 5.01071428,4.42262534 5.01071428,4.72727273 L5.01071428,4.72727273 L5.01071428,9.09090909 C5.01071428,9.39555648 4.73728818,9.64252199 4.4,9.64252199 C4.06271182,9.64252199 3.78928572,9.39555648 3.78928572,9.09090909 L3.78928572,9.09090909 L3.78928572,4.72727273 C3.78928572,4.42262534 4.06271182,4.17565983 4.4,4.17565983 Z M7.6,4.17565983 C7.93728818,4.17565983 8.21071428,4.42262534 8.21071428,4.72727273 L8.21071428,4.72727273 L8.21071428,9.09090909 C8.21071428,9.39555648 7.93728818,9.64252199 7.6,9.64252199 C7.26271182,9.64252199 6.98928572,9.39555648 6.98928572,9.09090909 L6.98928572,9.09090909 L6.98928572,4.72727273 C6.98928572,4.42262534 7.26271182,4.17565983 7.6,4.17565983 Z M7.30862884,1.10322579 L4.69127994,1.10322579 L4.27979517,1.80586512 L7.72011362,1.80586512 L7.30862884,1.10322579 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Outer>
  )
}

export default memo(DeleteIcon)
