import styled from 'styled-components/macro'

const SubText = styled.p`
  margin: 0;
  font-family: Gotham;
  font-weight: 400;
  line-height: 18px;
  font-size: 10px;
  letter-spacing: -0.21px;
  color: white;
`

export default SubText
