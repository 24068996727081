import React from 'react'
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgLogout = props => (
  <ResponsiveSvg wRatio={14} hRatio={15} maxWidth={20} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 14 15" {...props}>
      <g
        stroke="#00023F"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.333 3.93a5.684 5.684 0 1 1-8.04 0M7.316 1v6.316" />
      </g>
    </svg>
  </ResponsiveSvg>
)

export default SvgLogout
