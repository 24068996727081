import React from 'react'

export default function InstagramIcon() {
  return (
    <svg
      width="17px"
      height="17px"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, 1.000000)">
          <g>
            <g>
              <g transform="translate(0.015562, 0.074303)">
                <g transform="translate(0.883299, 0.188378)">
                  <path
                    d="M14.4576724,4.38137672 C14.4224726,3.58783776 14.2998921,3.04592893 14.1206697,2.57169951 C13.9353455,2.08148982 13.6874504,1.66570678 13.2843653,1.25125542 C12.8812515,0.836774474 12.4769,0.581889111 12.0001055,0.391368738 C11.5389106,0.207062915 11.0118348,0.0810554988 10.2400874,0.0448335454 C9.46672817,0.00855240568 9.21981159,0 7.25063819,0 C5.28149357,0 5.03457698,0.00855240568 4.26121779,0.0448335454 C3.48944158,0.0810554988 2.96239456,0.207062915 2.50119963,0.391368738 C2.02440512,0.581889111 1.62005368,0.836774474 1.21693984,1.25125542 C0.813854779,1.66570678 0.565959627,2.08148982 0.380664261,2.57169951 C0.201413012,3.04592893 0.0788325579,3.58783776 0.0436327703,4.38137672 C0.00834663811,5.1765137 0,5.4303929 0,7.45509356 C0,9.47979423 0.00834663811,9.73364383 0.0436327703,10.5288104 C0.0788325579,11.3223494 0.201413012,11.8642582 0.380664261,12.3384876 C0.565959627,12.8286973 0.813854779,13.2444803 1.21693984,13.6589317 C1.62005368,14.0734127 2.02440512,14.328298 2.50119963,14.5188184 C2.96239456,14.7031242 3.48944158,14.8291612 4.26121779,14.865324 C5.03457698,14.9016347 5.28149357,14.9102167 7.25063819,14.9102167 C9.21981159,14.9102167 9.46672817,14.9016347 10.2400874,14.865324 C11.0118348,14.8291612 11.5389106,14.7031242 12.0001055,14.5188184 C12.4769,14.328298 12.8812515,14.0734127 13.2843653,13.6589317 C13.6874504,13.2444803 13.9353455,12.8287269 14.1206697,12.3384876 C14.2998921,11.8642582 14.4224726,11.3223494 14.4576724,10.5288104 C14.4929585,9.73364383 14.5013052,9.47979423 14.5013052,7.45509356 C14.5013052,5.43042249 14.4929585,5.1765137 14.4576724,4.38137672"
                    stroke="#00023F"
                    strokeWidth="1.58090628"
                  />
                  <ellipse
                    stroke="#00023F"
                    strokeWidth="1.58090628"
                    cx="7.1721605"
                    cy="7.26160991"
                    rx="3.1721605"
                    ry="3.26160991"
                  />
                  <ellipse
                    fill="#00023F"
                    cx="11.1329145"
                    cy="3.16486068"
                    rx="1.13291447"
                    ry="1.16486068"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
