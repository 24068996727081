import React, { memo, useMemo, useRef } from 'react'
import mapTotal from 'lib/loops/mapTotal'
import { animated } from 'react-spring'
import DotsRingBase from './styled/DotsRing'
import Inner from './styled/Inner'

function DotsRing({
  opacity,
  dimFactor,
  style,
  interpolateFromRingWidth,
  totalDots = 0
}) {
  function calcDim(w) {
    return w * dimFactor
  }

  const outerStyle = {
    ...style,
    opacity,
    width: interpolateFromRingWidth(calcDim),
    height: interpolateFromRingWidth(calcDim)
  }

  // 360deg = 2pi

  const radsPerDot = useMemo(() => (2 * Math.PI) / totalDots, [totalDots])

  const canvasRef = useRef()

  function redraw(w) {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      canvas.width = w
      canvas.height = w
      const r = w / 2.0
      const rSmall = r - 5
      mapTotal(idx => {
        const rads = radsPerDot * idx
        const x = rSmall * Math.cos(rads) + r
        const y = rSmall * Math.sin(rads) + r
        ctx.beginPath()
        ctx.fillStyle = 'white'

        // cx, cy, radius, as, ae
        ctx.arc(x, y, 2, 0, 2 * Math.PI)
        ctx.fill()
      }, totalDots)
    }
  }
  // debugger
  const val = outerStyle.width.interpolate(redraw)

  return (
    <DotsRingBase style={outerStyle}>
      <Inner>
        <animated.canvas ref={canvasRef} val={val} />
      </Inner>
    </DotsRingBase>
  )
}

// {mapTotal((i)=>
//         <Dot key={i} idx={i} radsPerDot={radsPerDot} radiusInterpolation={radiusInterpolation} />
//     , totalDots)}

export default memo(DotsRing)
