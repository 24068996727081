export default function onChangeFormatToMaxLengthOf(
  maxLength,
  { replaceRgx = /^$/ } = {}
) {
  return ({
    event: {
      target: { value }
    }
  }) => {
    value = (value || '').replace(replaceRgx, '').slice(0, maxLength)
    return { value }
  }
}
