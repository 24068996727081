import styled from 'styled-components/macro'

const Outer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 171px;
`

export default Outer
