import React, {
  memo,
  useState,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react'
import debounce from 'lib/debounce'
import styled from 'styled-components/macro'
import { useTransition, animated } from 'react-spring'
import Outer from './styled/Outer'

const ButtonContainer = styled(animated.div)`
  width: 100%;
`

const Buttons = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterPopNav = ({ children, show }) => {
  const [scrollVisible, setScrollVisible] = useState(true)
  const scrollY = useRef(0)
  const scrollTimer = useRef(false)

  const handleScroll = useCallback(
    debounce(() => {
      clearInterval(scrollTimer.current)
      if (window.scrollY > scrollY.current) {
        setScrollVisible(false)
        scrollTimer.current = setInterval(() => setScrollVisible(true), 1000)
      } else {
        setScrollVisible(true)
      }
      scrollY.current = window.scrollY
    }, 15),
    []
  )

  useLayoutEffect(() => {
    document.addEventListener('scroll', handleScroll, true)
    handleScroll()
    return () => {
      document.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  const showFooter = scrollVisible && show

  const transitions = useTransition(showFooter, null, {
    from: { opacity: 0, transform: 'translateY(40px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(40px)' },
    unique: true
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Outer key={key}>
          <ButtonContainer style={props}>
            <Buttons>{children}</Buttons>
          </ButtonContainer>
        </Outer>
      )
  )
}

export default memo(FooterPopNav)
