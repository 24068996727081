import React, { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'

const InputLabel = styled.label`
  display: block;
`

const InputWrapper = styled.div`
  position: relative;
`

const FieldWrapper = styled.div`
  margin: 0 10px 10px 0;
  flex: 0 0 auto;
  width: 100%;
  max-width: 300px;
`

const StyledSelect = styled.select`
  font-size: 20px;
  line-height: 26px;
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 15px;
  color: #00023f;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 45px;
`

const Error = styled.div`
  position: absolute;
  top: -10px;
  left: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
`
const ErrorMessage = styled.div`
  color: red;
  padding: 5px;
  font-size: 1em;
  flex: 1 0 auto;
`

const InputField = ({
  input,
  placeholder,
  tabIndex,
  label,
  required,
  focus,
  selectOptions,
  meta: { valid, touched, error }
}) => {
  const inputElement = useRef(null)
  const isInvalid = !valid && touched

  useEffect(() => {
    if (focus) inputElement.current.focus()
  }, [])

  return (
    <FieldWrapper>
      {label && (
        <InputLabel>
          {label} {required && '(Required)'}
        </InputLabel>
      )}
      <InputWrapper>
        <StyledSelect
          tabIndex={tabIndex}
          ref={inputElement}
          {...input}
          placeholder={placeholder || label}
          type="text"
          valid={valid}
        >
          {selectOptions.map(({ text, value }, i) => (
            <option key={i} value={value}>
              {text}
            </option>
          ))}
        </StyledSelect>
        {isInvalid && (
          <Error>
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        )}
      </InputWrapper>
    </FieldWrapper>
  )
}

export default InputField
