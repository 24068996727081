/* eslint no-unused-expressions: 0 */
import { useReducer } from 'react'
import isFunction from 'lodash/isFunction'
import { has } from 'ramda'

const MERGE_STATUS = 'MERGE_STATUS'
const DELETE_STATUS_KEY = 'DELETE_STATUS_KEY'
function reducer(state, { type, payload = {} }) {
  switch (type) {
    case MERGE_STATUS: {
      return state[payload.key] === payload.status
        ? state
        : { ...state, [payload.key]: payload.status }
    }
    case DELETE_STATUS_KEY: {
      if (has(payload.key, state)) {
        const { [payload.key]: removedKey, ...newState } = state
        return newState
      }
      return state
    }
    default: {
      throw new Error()
    }
  }
}

async function callDirNextCancel(
  dirConfig,
  status,
  item,
  getKey,
  mergeStatus,
  next,
  cancel
) {
  const key = getKey ? getKey(item) : item
  mergeStatus(key, status)

  if (isFunction(dirConfig)) {
    dirConfig = dirConfig(item)
  }
  // if it's *still* a function, provide next and cancel
  if (isFunction(dirConfig)) {
    const res = await Promise.resolve(dirConfig(next, cancel))
    return res
  }

  await next(dirConfig)
}

function init() {
  return {
    // [identifier]: 'LEAVING'
  }
}

export default function useTransitionStatuses(
  getKey,
  { enter, leave, onDestroyed, ...config }
) {
  let [statuses, dispatch] = useReducer(reducer, {}, init)
  const mergeStatus = (key, status) =>
    dispatch({
      type: MERGE_STATUS,
      payload: {
        key,
        status
      }
    })

  const newConfig = {
    ...config,
    enter: item => async (next, cancel) => {
      callDirNextCancel(
        enter,
        'ENTERING',
        item,
        getKey,
        mergeStatus,
        next,
        cancel
      )
    },
    leave: item => async (next, cancel) => {
      callDirNextCancel(
        leave,
        'LEAVING',
        item,
        getKey,
        mergeStatus,
        next,
        cancel
      )
    },
    onDestroyed: item => {
      const key = getKey ? getKey(item) : item
      dispatch({
        type: DELETE_STATUS_KEY,
        payload: {
          key
        }
      })
      onDestroyed && onDestroyed(item)
    }
  }

  return [statuses, newConfig]
}
