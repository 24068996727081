import { useCallback, useState, useRef } from 'react'
import useIntersectionObserver from 'lib/useIntersectionObserver'

const defaultThreshold = [0, 0.25, 0.5, 0.75, 1]
const defaultRootMargin = '0% 0% -28% 0%'

export default function useIsShowingInViewport({
  once,
  threshold = defaultThreshold,
  rootMargin = defaultRootMargin
}) {
  const [isShowing, setIsShowing] = useState(false)
  const isShowingRef = useRef()
  isShowingRef.current = isShowing
  const root = useRef(null)
  const target = useRef()
  const setIsShowingMemoized = useCallback(
    ([{ isIntersecting }]) => {
      const isCurrentShowing = isShowingRef.current
      // if isCurrentShowing is different then the
      // next value
      if (isIntersecting !== isCurrentShowing) {
        // if we want to set isShowing to
        // true multiple times, or isIntersecting is
        // about to be true
        if (!once || isIntersecting) {
          setIsShowing(isIntersecting)
        }
      }
    },
    [setIsShowing, once]
  )

  useIntersectionObserver({
    root,
    target,
    threshold,
    rootMargin,
    onIntersect: setIsShowingMemoized
  })
  return {
    isShowing,
    rootRef: root,
    targetRef: target
  }
}
