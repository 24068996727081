import styled from 'styled-components/macro'

const GuidanceText = styled.p`
  font-family: Gotham_Book;
  font-weight: 100;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.43px;
  margin-bottom: 10px;
  @media (max-width: 520px) {
    font-size: 20px;
  }
`

export default GuidanceText
