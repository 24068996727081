import React, { useRef, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'

const InputLabel = styled.label`
  display: block;
`

const InputWrapper = styled.div`
  display: flex;
`

const FieldWrapper = styled.div`
  margin: 0 10px 0 0;
  flex: 0 0 auto;
  width: 100%;
  max-width: 300px;
`

const StyledInput = styled.input`
  display: inline-block;
  font-size: 20px;
  line-height: 26px;
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 15px;
  color: #00023f;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  max-width: 300px;
  height: 45px;
  flex: 1 0 auto;
`

const Error = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  flex: 1 0 auto;
`
const ErrorMessage = styled.div`
  color: red;
  padding: 5px;
  font-size: 1em;
`

const FieldErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`

const ExampleUrl = styled.div`
  margin-bottom: 10px;
`

const InputField = props => {
  const {
    input,
    placeholder,
    tabIndex,
    label,
    required,
    focus,
    urlTemplate,
    meta: { error, submitError, valid, touched }
  } = props

  const { value } = input

  const inputElement = useRef(null)

  useEffect(() => {
    if (focus) inputElement.current.focus()
  }, [])

  const exampleUrl = useMemo(() => (urlTemplate ? urlTemplate(value) : false), [
    urlTemplate,
    value
  ])

  return (
    <FieldErrorContainer>
      <FieldWrapper>
        {label && (
          <InputLabel>
            {label} {required && '(Required)'}
          </InputLabel>
        )}
        <InputWrapper>
          <StyledInput
            tabIndex={tabIndex}
            ref={inputElement}
            {...input}
            placeholder={placeholder || label}
            type="text"
            valid={valid}
          />
          {!valid && touched && (
            <Error>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </Error>
          )}
        </InputWrapper>
      </FieldWrapper>
      {exampleUrl && value !== '' && (
        <ExampleUrl>
          Example:{' '}
          <a target="_blank" rel="noopener noreferrer" href={exampleUrl}>
            {exampleUrl}
          </a>
        </ExampleUrl>
      )}
    </FieldErrorContainer>
  )
}

export default InputField
