/* eslint no-use-before-define: 0 */
import React, {
  useReducer,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { Provider as ContextProvider } from 'domains/CurrentUser/context'
import api from 'domains/CurrentUser/api'
import { mergeDeepRight, path } from 'ramda'

// const reduceMergeDeep = reduce(mergeDeepRight, {})
const FETCH_CURRENT_USER_ACTION = 'FETCH_CURRENT_USER_ACTION'
const FETCH_CURRENT_USER_LOADING = 'FETCH_CURRENT_USER_LOADING'
const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR'
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
const CLEAR_ERRORS = 'CLEAR_ERRORS'
const LOGIN_USER_LOADING = 'LOGIN_USER_LOADING'

function setCurrentUserLoadingState(state, loading) {
  return mergeDeepRight(state, { user: { loading } })
}

function setCurrentUserData(state, { user }) {
  return mergeDeepRight(state, { user: { currentUser: user } })
}

function setErrors(state, errors) {
  return mergeDeepRight(state, { user: { errors } })
}

function setFetchCurrentUserError(state, error) {
  return mergeDeepRight(state, { user: { fetchCurrentUserError: error } })
}

function setLoginUserLoading(state, loading) {
  return mergeDeepRight(state, { user: { loginUserLoading: loading } })
}

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case FETCH_CURRENT_USER_ACTION:
      return mergeDeepRight(state, {
        execute: { fetchCurrentUserAction: payload.status }
      })
    case FETCH_CURRENT_USER_LOADING:
      return setCurrentUserLoadingState(state, payload.status)
    case FETCH_CURRENT_USER_SUCCESS:
      state = setCurrentUserData(state, payload)
      return setFetchCurrentUserError(state, null)
    case FETCH_CURRENT_USER_ERROR:
      state = setCurrentUserData(state, {})
      return setFetchCurrentUserError(state, payload.error)
    case LOGIN_USER_LOADING:
      state = setLoginUserLoading(state, true)
      return setErrors(state, [])
    case LOGIN_USER_SUCCESS:
      state = setCurrentUserData(state, payload)
      state = setErrors(state, [])
      return setLoginUserLoading(state, false)
    case LOGOUT_USER_SUCCESS:
      return init()
    case CLEAR_ERRORS:
      return setErrors(state, [])
    case LOGIN_USER_ERROR:
      return setErrors(state, [payload.error])
    default:
      throw new Error()
  }
}

function init() {
  return {
    user: {
      currentUser: null,
      loading: false,
      loginUserLoading: false,
      errors: [],
      fetchCurrentUserError: null
    },
    execute: {
      fetchCurrentUserAction: false
    }
  }
}

export default function CurrentUserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {}, init)

  let {
    user: { currentUser, loading, errors, fetchCurrentUserError },
    execute: { fetchCurrentUserAction }
  } = state

  const prevCurrentUserIdRef = useRef()
  const currentUserId = path(['id'], currentUser)
  // if a different user signed in,
  // that means we should fetch their data due
  // to the fact that the currentUserId has changed
  const differentCurrentUserId = !!(
    currentUserId &&
    prevCurrentUserIdRef.current &&
    prevCurrentUserIdRef.current !== currentUserId
  )
  prevCurrentUserIdRef.current = currentUserId

  // Helper/Private Function
  function setCurrentUserLoading(l) {
    dispatch({
      type: FETCH_CURRENT_USER_LOADING,
      payload: {
        status: l
      }
    })
  }

  // Helper/Private Function
  function setFetchCurrentUserAction(s) {
    dispatch({
      type: 'FETCH_CURRENT_USER_ACTION',
      payload: {
        status: s
      }
    })
  }

  // Helper/Private Function
  async function fetchCurrentUser() {
    setCurrentUserLoading(true)
    setFetchCurrentUserAction(false)
    dispatch({
      type: FETCH_CURRENT_USER_ERROR,
      payload: {
        error: null
      }
    })

    const {
      data: { user }
    } = await api.currentUser()

    if (user.error) {
      dispatch({
        type: FETCH_CURRENT_USER_ERROR,
        payload: {
          error: user.error
        }
      })
    } else {
      dispatch({
        type: FETCH_CURRENT_USER_SUCCESS,
        payload: {
          user
        }
      })
    }
    setCurrentUserLoading(false)
    setFetchCurrentUserAction(false)
  }

  /*
   * EXECUTION OF ACTIONS:
   * these functions are handled
   * within useEffect to prevent
   * duplicate calls to actions that
   * may be called by multiple children
   * without state having a chance to
   * update within the parent component
   */
  useEffect(() => {
    if (
      fetchCurrentUserAction &&
      !loading &&
      !fetchCurrentUserError &&
      (!currentUserId || differentCurrentUserId)
    ) {
      fetchCurrentUser()
    }
  }, [
    fetchCurrentUserAction,
    loading,
    fetchCurrentUserError,
    currentUserId,
    differentCurrentUserId
  ])
  /*
   * ACTIONS AS CALLBACKS
   */

  /*
   * ACTION: fetchCurrentUserIfNeeded
   */

  const fetchCurrentUserIfNeeded = useCallback(() => {
    if (
      !loading &&
      !fetchCurrentUserError &&
      (!currentUserId || differentCurrentUserId)
    ) {
      setFetchCurrentUserAction(true)
    }
  }, [currentUserId, fetchCurrentUserError, loading, differentCurrentUserId])

  /*
   * ACTION: logout
   */
  const logout = useCallback(async function logout() {
    await api.logoutUser()
    dispatch({
      type: LOGOUT_USER_SUCCESS
    })
  }, [])

  /*
   * ACTION: login
   */
  const login = useCallback(async function login({ email, password }) {
    dispatch({
      type: LOGIN_USER_LOADING
    })

    const {
      data: { user }
    } = await api.loginUser({ email, password })

    if (user.error) {
      return dispatch({
        type: LOGIN_USER_ERROR,
        payload: {
          error: 'Invalid Email'
        }
      })
    }

    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: {
        user
      }
    })
    setFetchCurrentUserAction(false)
    dispatch({
      type: FETCH_CURRENT_USER_ERROR,
      payload: {
        error: null
      }
    })
  }, [])

  /*
   * ACTION: clearErrors
   */
  const clearErrors = useCallback(async function clearErrors() {
    dispatch({
      type: CLEAR_ERRORS
    })
  }, [])

  const providerValue = useMemo(() => {
    return [
      state,
      // provide all public api:
      {
        fetchCurrentUserIfNeeded,
        logout,
        login,
        clearErrors
      }
    ]
  }, [
    // all state variables must be passed below,
    // because we spread them above into an new object,
    // so if you want your state key to have current/fresh
    // data, it better be in this list
    currentUser,
    loading,
    errors,
    fetchCurrentUserError
  ])

  return <ContextProvider value={providerValue}>{children}</ContextProvider>
}
