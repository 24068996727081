import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Outer = styled(animated.div)`
  padding: 37px 37px 0 37px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

export default Outer
