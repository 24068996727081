import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'

import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'

import customFetch from './customFetch'

// const request = async (_operation) => {
//   // const token = await AsyncStorage.getItem('token');
//   // operation.setContext({
//   //   headers: {
//   //     authorization: token
//   //   }
//   // });
// };

// const requestLink = new ApolloLink((operation, forward) =>
//   new Observable(observer => {
//     let handle;
//     Promise.resolve(operation)
//       .then(oper => request(oper))
//       .then(() => {
//         handle = forward(operation).subscribe({
//           next: observer.next.bind(observer),
//           error: observer.error.bind(observer),
//           complete: observer.complete.bind(observer),
//         });
//       })
//       .catch(observer.error.bind(observer));

//     return () => {
//       if (handle) handle.unsubscribe();
//     };
//   })
// );

/* eslint-disable */
function stripGraphqlAttr(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripGraphqlAttr(obj[property], propToDelete)
      obj[property] = newData
    } else if (property === propToDelete) {
      delete obj[property]
    }
  }
  return obj
}
/* eslint-disable */

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripGraphqlAttr(operation.variables, '__typename')
    return forward ? forward(operation) : null
  }
})

const cache = new InMemoryCache({
  // Example of cache redirect config for when needed
  // cacheRedirects: {
  //   Query: {
  //     movie: (_, { id }, { getCacheKey }) =>
  //       getCacheKey({ __typename: 'Movie', id });
  //   }
  // }
})

const createApolloClient = () =>
  new ApolloClient({
    link: ApolloLink.from([
      onError(({ response, graphQLErrors, networkError }) => {
        // if (internalServerError) {
        //   // send to logging service
        // }
        // if (networkError) {
        //   // logoutUser();
        // }
      }),
      removeTypenameMiddleware,
      createUploadLink({
        uri: `${LOWER_API_DOMAIN}/graphql`,
        credentials: 'include',
        fetch: customFetch
      })
      // new HttpLink({
      //   uri: `${LOWER_API_DOMAIN}/graphql`,
      //   credentials: 'include'
      // })
    ]),
    cache
  })

export default createApolloClient
