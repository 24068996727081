import styled from 'styled-components/macro'

const Item = styled.div`
  width: ${({ width }) => width || '370px'};
  margin-bottom: 60px;
  @media (max-width: 810px) {
    margin-left: auto;
    margin-right: auto;
  }
  ${({ css }) => css}
`

export default Item
