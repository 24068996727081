/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import Item from './styled/Item'
import MailBtn from './components/MailBtn'
import PhoneBtn from './components/PhoneBtn'
import UserBtn from './components/UserBtn'

function NavBtns({ outerStyles }) {
  return (
    <Outer style={outerStyles}>
      <Item tabletOpacity={0}>
        <MailBtn />
      </Item>
      <Item tabletOpacity={0}>
        <PhoneBtn />
      </Item>
      <Item>
        <UserBtn />
      </Item>
    </Outer>
  )
}

export default NavBtns
