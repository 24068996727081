import React, { useState, useRef } from 'react'
import useMeasure from 'lib/useMeasure'

export function SyncHeightParent({ children }) {
  const [height, setHeight] = useState(0)

  if (!children) {
    return null
  }

  return children({ height, setHeight })
}

export function SyncHeightChild({ setHeight, children }) {
  const ref = useRef(null)
  const { height } = useMeasure(ref)

  setHeight(height)

  return <div ref={ref}>{children}</div>
}
