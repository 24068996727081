import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components/macro'
import UserLoginForm from 'domains/CurrentUser/Forms/Login'
import { currentUserPresent } from 'domains/CurrentUser/helpers'
import useLoginUser from 'domains/CurrentUser/useable/useLoginUser'
import Content from 'domains/BizDash/Shared/components/Content'

import UserNav from 'domains/BizDash/Shared/components/UserNav'

import Header from 'domains/BizDash/Shared/components/Header'
import MailtoLink from 'domains/BizDash/Shared/components/MailtoLink'
import IfNotAuthenticated from 'domains/CurrentUser/IfNotAuthenticated'

import { Redirect } from 'react-router-dom'

import LoginError from '../LoginError'

const LoginModalContainer = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginModal = styled.div`
  max-width: 420px;
  width: 100%;
`

const ModalBody = styled.h1`
  color: white;
`

const LoginModalSection = posed.div({
  enter: { staggerChildren: 50, delayChildren: 200 },
  exit: { staggerChildren: 50 }
})

const UserLoginModal = () => {
  const { currentUser, login, errors, loading, clearErrors } = useLoginUser()
  if (currentUserPresent({ currentUser })) {
    return (
      <Redirect
        to={{
          pathname: '/disclosures'
        }}
      />
    )
  }

  return (
    <Content>
      <Header>
        <UserNav />
        <IfNotAuthenticated
          render={() => <MailtoLink to="support@lower.com" />}
        />
      </Header>
      <LoginModalContainer>
        <LoginModal>
          <PoseGroup preEnterPose="beforeEnter">
            {errors && errors.length > 0 ? (
              <LoginModalSection key="loginmodalerror">
                <LoginError clearErrors={clearErrors} errors={errors} />
              </LoginModalSection>
            ) : (
              <LoginModalSection key="loginmodalform">
                <ModalBody>
                  <UserLoginForm
                    onSubmit={login}
                    clearErrors={clearErrors}
                    errors={errors}
                    loading={loading}
                  />
                </ModalBody>
              </LoginModalSection>
            )}
          </PoseGroup>
        </LoginModal>
      </LoginModalContainer>
    </Content>
  )
}

export default UserLoginModal
