import styled from 'styled-components/macro'

const Outer = styled.a`
  align-self: center;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #00023f;
  background-color: #00023f;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  cursor: pointer;
  margin-right: ${({ marginRight }) => marginRight || '20px'};
  &:hover {
    opacity: 0.8;
  }
  &:last-child {
    margin-right: 0;
  }
`

export default Outer
