import React from 'react'
import CardRings from 'components/CardRings'
import useMouseEnterLeaveState from 'lib/useMouseEnterLeaveState'
import Card from '../../shared/components/Card'
import Title from './styled/Title'
import Note from './styled/Note'
import Wrapper from '../../styled/Wrapper'
import IconTopLeft from './styled/IconTopLeft'
import IconBottomRight from './styled/IconBottomRight'
import CardText from './styled/CardText'
import TitleNoteWrapper from './styled/TitleNoteWrapper'
import LoadingContent from './components/LoadingContent'
import CardInner from './styled/CardInner'
import TopRightContent from './styled/TopRightContent'

export default function IconTileWithLabels({
  title,
  note,
  cardText,
  iconTopLeftPath,
  iconTopLeftCss,
  iconBottomRightPath,
  iconBottomRightCss,
  topRightElement,
  progressFraction,
  status,
  renderMessage,
  outerCss,
  cardCss,
  ringsOuterCss,
  hasBoxShadow = true,
  onMouseEnter,
  onMouseLeave,
  isOverCard
}) {
  const {
    isOver,
    handleMouseEnter,
    handleMouseLeave
  } = useMouseEnterLeaveState()

  if (!onMouseEnter && !onMouseLeave) {
    onMouseEnter = handleMouseEnter
    onMouseLeave = handleMouseLeave
    isOverCard = isOver
  }

  // if we are any state other than success,
  // we slightly dim title and note
  const titleNoteOpacity = status !== 'success' ? 0.25 : 1

  return (
    <Wrapper customCss={outerCss} moveUp={isOverCard}>
      <Card
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        backgroundColor="white"
        hasBoxShadow={hasBoxShadow}
        customCss={cardCss}
      >
        <CardRings ringsOuterCss={ringsOuterCss} />
        <CardInner>
          {status === 'success' && (
            <>
              <IconTopLeft src={iconTopLeftPath} customCss={iconTopLeftCss} />
              <IconBottomRight
                src={iconBottomRightPath}
                customCss={iconBottomRightCss}
              />
              <CardText>{cardText}</CardText>
            </>
          )}
          <TopRightContent>{topRightElement}</TopRightContent>
          <LoadingContent
            progressFraction={progressFraction}
            renderMessage={renderMessage}
          />
        </CardInner>
      </Card>
      <TitleNoteWrapper opacity={titleNoteOpacity}>
        <Title>{title}</Title>
        <Note>{note}</Note>
      </TitleNoteWrapper>
    </Wrapper>
  )
}
