import React, { useMemo } from 'react'
import { map } from 'ramda'
import styled from 'styled-components/macro'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import TextInputField from '../../Shared/components/Form/TextInputField'
import TextareaField from '../../Shared/components/Form/TextareaField'
import ExampleUrlField from '../../Shared/components/Form/ExampleUrlField'
import SelectInputField from '../../Shared/components/Form/SelectInputField'
import LeadSourceConfigForm from '../LeadSourceConfigForm'
import Condition from '../../Shared/components/Form/Condition'

const StyledField = styled(Field)``

const FomChild = styled.div`
  padding: 0 20px 20px 0;
  width: 33%;
  flex: 1 0 200px;
`

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`

const FieldRowHeader = styled.h2`
  margin-bottom: 20px;
`

const applicationCampaignForm = ({ name, campaignTypes }) => {
  const campaignSelectOptions = useMemo(() => {
    return [
      { text: '', value: '' },
      ...map(ct => ({ text: ct.title, value: ct.id }), campaignTypes)
    ]
  }, [campaignTypes])

  return (
    <>
      <StyledField
        focus
        name={`${name}.campaignType`}
        label="Campaign Type"
        placeholder="Type"
        selectOptions={campaignSelectOptions}
        required
        component={SelectInputField}
      />
      <StyledField
        name={`${name}.name`}
        label="Name"
        placeholder="Name"
        required
        component={TextInputField}
      />
      <StyledField
        name={`${name}.newLeadPostbackUrl`}
        label="New Lead Postback URL"
        placeholder="URL"
        component={TextInputField}
      />
      <Condition when={`${name}.campaignType`} is="utm">
        <StyledField
          name={`${name}.utmContent`}
          label="UTM Content"
          placeholder="UTM Content"
          required
          urlTemplate={val => `https://app.lower.com/apply?utm_content=${val}`}
          component={ExampleUrlField}
        />
      </Condition>
      <StyledField
        name={`${name}.description`}
        label="Description"
        placeholder="Description"
        component={TextareaField}
      />
      <FieldRowHeader>Velocify Lead Sources</FieldRowHeader>
      <FieldRow>
        <FieldArray name={`${name}.leadSourceConfigs`}>
          {({ fields }) =>
            fields.map((fieldName, index) => (
              <FomChild key={fieldName}>
                <LeadSourceConfigForm index={index} name={fieldName} />
              </FomChild>
            ))
          }
        </FieldArray>
      </FieldRow>
    </>
  )
}

export default applicationCampaignForm
