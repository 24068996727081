/* eslint prefer-const: 0 */
import React from 'react'
import Svg from './styled/Svg'

export default function useArrowRight(config) {
  let { arrowRight, parentStyles, transRight } = config

  let paddingRight
  let element

  if (arrowRight) {
    element = <ArrowRight transRight={transRight} />
    // ElWidth + rightDistance + 6
    paddingRight = 52
  }

  parentStyles = {
    ...parentStyles,
    paddingRight
  }

  return {
    element,
    parentStyles
  }
}

function ArrowRight({ transRight }) {
  return (
    <Svg
      transRight={transRight}
      width="18px"
      height="17px"
      viewBox="0 0 18 17"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-880.000000, -986.000000)"
          stroke="#FFFFFF"
          strokeWidth="1.61500003"
        >
          <g transform="translate(607.000000, 969.000000)">
            <g transform="translate(274.000000, 18.000000)">
              <path d="M0.299999952,7.48999899 L15.6425002,7.48999899" />
              <polyline points="8.37500008 0.222498881 15.6425002 7.48999899 8.37500008 14.7574991" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
