import React from 'react'
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgEnvelope = props => (
  <ResponsiveSvg wRatio={16} hRatio={13} maxWidth={300} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 16 13">
      <g
        stroke="#8384FF"
        strokeWidth={1.6}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.4 1h11.2c.77 0 1.4.619 1.4 1.375v8.25c0 .756-.63 1.375-1.4 1.375H2.4c-.77 0-1.4-.619-1.4-1.375v-8.25C1 1.619 1.63 1 2.4 1z" />
        <path d="M15 2.375L8 7.188 1 2.375" />
      </g>
    </svg>
  </ResponsiveSvg>
)

export default SvgEnvelope
