import React from 'react'
import styled from 'styled-components/macro'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import CampaignFields from '../CampaignFields'

const StyledForm = styled(Form)`
  grid-column-start: span 4;
`

const StyledSubmitButton = styled.button`
  align-self: center;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #00023f;
  background-color: #00023f;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  cursor: pointer;
  margin-right: ${({ marginRight }) => marginRight || '20px'};
  &:hover {
    opacity: 0.8;
  }
  &:last-child {
    margin-right: 0;
  }
`

const rootName = 'applicationCampaign'

const applicationCampaignForm = ({
  applicationCampaign,
  campaignTypes,
  onSubmit,
  onCancel,
  validate
}) => {
  // TODO: pull this in from somehwere on the server
  const formDefaults = {
    leadSourceConfigs: [
      {
        loanPurposeId: '1'
      },
      {
        loanPurposeId: '2'
      },
      {
        loanPurposeId: '3'
      }
    ]
  }
  const withDefaults = { ...formDefaults, ...applicationCampaign }

  return (
    <StyledForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ [rootName]: withDefaults }}
      mutators={{ ...arrayMutators }}
      subscription={{ submitting: true, touched: true }}
      render={({ handleSubmit, submitting, pristine, invalid }) => {
        // if (invalid) {
        //   debugger
        // }
        return (
          <div>
            {invalid && 'Error with form - please contact support'}
            <CampaignFields campaignTypes={campaignTypes} name={rootName} />
            <StyledSubmitButton
              disabled={submitting || pristine}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </StyledSubmitButton>
            <StyledSubmitButton type="button" onClick={onCancel}>
              Cancel
            </StyledSubmitButton>
          </div>
        )
      }}
    />
  )
}

export default applicationCampaignForm
