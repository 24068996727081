import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Contents from './Contents'

export const Box = styled.div``

const Controller = props => {
  const contents = useRef(null)
  const [offsetState, setOffsetState] = useState({
    relativeOffset: 0,
    triggerBoundingRect: {}
  })

  const handlePageClick = event => {
    const { anchor, onDismiss } = props
    if (
      // eslint-disable-next-line
      event.target instanceof Node &&
      anchor &&
      !anchor.contains(event.target) &&
      contents.current &&
      !contents.current.contains(event.target)
    ) {
      onDismiss()
    }
  }

  const updateTriggerRect = ({ positionRelativeToAnchor, anchor }) => {
    let rectAnchor
    let rectPositionRelativeToAnchor
    if (anchor) {
      rectAnchor = anchor
      rectPositionRelativeToAnchor = positionRelativeToAnchor
    } else {
      return
    }

    let triggerBoundingRect
    let relativeOffset
    if (rectAnchor) {
      triggerBoundingRect = rectAnchor.getBoundingClientRect()
      // Needed for correct positioning within Contents.js
      relativeOffset = {
        x: rectPositionRelativeToAnchor
          ? triggerBoundingRect.left - rectAnchor.offsetLeft
          : 0,
        y: rectPositionRelativeToAnchor
          ? triggerBoundingRect.top - rectAnchor.offsetTop
          : 0
      }
    }

    setOffsetState({ relativeOffset, triggerBoundingRect })
  }

  useEffect(() => {
    updateTriggerRect(props)
  }, [])

  const {
    anchor,
    bgColor,
    children,
    position,
    positionRelativeToAnchor,
    shouldFocus
  } = props

  if (!anchor) {
    return null
  }

  const { relativeOffset, triggerBoundingRect } = offsetState

  const width = 140

  return (
    <Box>
      <div ref={contents}>
        {contents.current ? (
          <Contents
            bgColor={bgColor}
            onClick={handlePageClick}
            onResize={() =>
              updateTriggerRect({ anchor, positionRelativeToAnchor })
            }
            positionRelativeToAnchor={positionRelativeToAnchor}
            position={position}
            relativeOffset={relativeOffset}
            shouldFocus={shouldFocus}
            triggerRect={triggerBoundingRect}
            width={width}
          >
            {children}
          </Contents>
        ) : null}
      </div>
    </Box>
  )
}

export default Controller
