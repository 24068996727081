import { gql } from 'graphql.macro'
import ApplicationCampaignFragment from './ApplicationCampaignFragment'

export default gql`
  ${ApplicationCampaignFragment}
  mutation($input: ApplicationCampaignInput!) {
    createApplicationCampaign(input: $input) {
      applicationCampaign {
        ...ApplicationCampaignFragment
      }
      errors {
        field
        message
      }
    }
  }
`
