import React from 'react'
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgDownload = props => (
  <ResponsiveSvg wRatio={16} hRatio={16} maxWidth={20} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 15 16" {...props}>
      <g
        stroke="#00023F"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 11.083v1.95c0 .718.647 1.3 1.444 1.3h10.112c.797 0 1.444-.582 1.444-1.3v-1.95M4.405 7.4L7.294 10l2.889-2.6M7.294 10V.9" />
      </g>
    </svg>
  </ResponsiveSvg>
)

export default SvgDownload
