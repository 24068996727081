import { useMemo } from 'react'
import { find } from 'ramda'
import DocAnimated from './components/DocAnimated'
import DefaultSidePreview from './components/DefaultSidePreview'

const themes = [
  {
    afterLoginPathMatch: /^\/consumer-portal\/doc-manager/,
    pretitle: 'Upload Your Documents',
    introTips: [
      'For security, we use multiple ways to verify your identity.',
      'To login, use your phone number that you gave to the Lower Loan Officer that you have been working with.'
    ],
    mainComponent: DocAnimated
  }
]

const defaultTheme = {
  pretitle: "Let's Get Started",
  introTips: ['Enter your phone number to get started and login.'],
  mainComponent: DefaultSidePreview
}

export function useThemeForAfterLoginPath({ afterLoginPath }) {
  return useMemo(() => {
    const theme = find(
      ({ afterLoginPathMatch: afterLoginPathMatchRgx }) =>
        afterLoginPathMatchRgx.test(afterLoginPath),
      themes
    )

    if (theme) {
      return theme
    }

    return defaultTheme
  }, [afterLoginPath])
}
