import React from 'react'
import Svg from './styled/Svg'

export default function ArrowIcon() {
  return (
    <Svg
      width="35px"
      height="38px"
      viewBox="0 0 35 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-715.000000, -496.000000)"
          stroke="#4100F5"
          strokeWidth="4.80000019"
        >
          <g>
            <g transform="translate(679.000000, 459.000000)">
              <g>
                <g transform="translate(39.000004, 39.600006)">
                  <path d="M14.7455997,4.26325641e-14 L14.7455997,32.6592001" />
                  <polyline points="29.0304001 14.5152 14.5152 0 -1.13686838e-13 14.5152" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
