import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const DotPlanet = styled(animated.div)`
  border-radius: 50%;
  background-color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
`

export default DotPlanet
