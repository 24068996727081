/**
 *
 * DotTextDotAnimator.js
 *
 */

import React from 'react'
import DotsOuter from './styled/DotsOuter'
import Dot from './styled/Dot'

export default function DotTextDotAnimator(props) {
  const { hideDots, height, left = true, right = true, children } = props

  return (
    <>
      {!hideDots && left && (
        <DotsOuter height={height}>
          <Dot side="left">.</Dot>
          <Dot side="left" delay={0.2}>
            .
          </Dot>
          <Dot side="left" delay={0.4}>
            .
          </Dot>
        </DotsOuter>
      )}
      {children}
      {!hideDots && right && (
        <DotsOuter height={height}>
          <Dot>.</Dot>
          <Dot delay={0.2}>.</Dot>
          <Dot delay={0.4}>.</Dot>
        </DotsOuter>
      )}
    </>
  )
}
