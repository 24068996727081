import { useReducer, useCallback, useEffect } from 'react'
import { path } from 'ramda'
import { ErrorNotification } from 'components/Notifications'
// import { useMutation } from '@apollo/react-hooks'
// import VERIFY_PHONE_MUTATION from './mutations/verifyPhoneMutation'
import delay from 'lib/delay'

const EXECUTE_SUBMIT_VERIFY_PHONE = 'EXECUTE_SUBMIT_VERIFY_PHONE'
const SUBMIT_VERIFY_PHONE_RESPONSE = 'SUBMIT_VERIFY_PHONE_RESPONSE'
const SUBMIT_VERIFY_PHONE_LOADING = 'SUBMIT_VERIFY_PHONE_LOADING'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case EXECUTE_SUBMIT_VERIFY_PHONE:
      return {
        ...state,
        executeSubmitVerifyPhone: payload.status
      }
    case SUBMIT_VERIFY_PHONE_RESPONSE:
      return {
        ...state,
        submitVerifyPhoneResponseData: payload.data
      }
    case SUBMIT_VERIFY_PHONE_LOADING:
      return {
        ...state,
        loadingSubmitVerifyPhone: payload.status
      }
    default:
      throw new Error()
  }
}

function init() {
  return {
    submitVerifyPhoneResponseData: undefined,
    loadingSubmitVerifyPhone: false,
    executeSubmitVerifyPhone: false
  }
}

const errorsToHuman = {
  INVALID_PHONE_NUMBER: {
    mainText: 'Invalid phone number.',
    subtext: 'Make sure there 10 digits. ex: (555) 555 5555'
  },
  PHONE_NUMBER_REQUIRED: {
    mainText: 'Your phone number is required.',
    subtext: 'It looks like you forgot to type your phone number.'
  }
}

const asyncErrorsToHuman = {
  PHONE_NUMBER_DOES_NOT_EXIST: {
    mainText: 'Hmm. We can’t find a match.',
    subtext: 'Please enter a different number.'
  },
  UNKNOWN: {
    mainText: 'Uh oh. Looks like there was a problem.',
    subtext: 'Wait a few seconds and try again, then contact support.'
  }
}

export default function useVerifyPhone({
  formActions = {},
  notificationActions
}) {
  // const [
  //   verifyPhoneMutation
  //   // { error: errorMutation, loading: loadingMutation }
  // ] = useMutation(VERIFY_PHONE_MUTATION)

  const [state, dispatch] = useReducer(reducer, {}, init)
  const { upsertNote, dismissNotesByTag, dismissAllNotes } = notificationActions

  const {
    loadingSubmitVerifyPhone,
    submitVerifyPhoneResponseData: { error: responseError } = {},
    executeSubmitVerifyPhone
  } = state

  // const {
  //   phonePage: {
  //     phone
  //   }={}
  // } = formData

  useEffect(() => {
    if (responseError && !loadingSubmitVerifyPhone) {
      let errorId = responseError
      let errorConfig = asyncErrorsToHuman[errorId]

      if (!errorConfig) {
        errorId = 'UNKNOWN'
        errorConfig = asyncErrorsToHuman[errorId]
      }
      upsertNote(
        {
          id: errorId,
          component: ErrorNotification,
          stretchToMaxWidth: '352px',
          tag: 'error',
          ...errorConfig
        },
        { prepend: true }
      )
    }
  }, [responseError, loadingSubmitVerifyPhone])

  const submitVerifyPhone = useCallback(() => {
    dispatch({
      type: EXECUTE_SUBMIT_VERIFY_PHONE,
      payload: {
        status: true
      }
    })
  }, [])

  async function asyncSubmitVerifyPhone() {
    const { phonePage: { phone } = {} } = formActions.getAllForms()

    dispatch({
      type: EXECUTE_SUBMIT_VERIFY_PHONE,
      payload: {
        status: false
      }
    })

    if (
      !phone ||
      !phone.value ||
      path(['onValueChangeErrors', 'length'], phone)
    ) {
      const errorId = phone.onValueChangeErrors[0] || 'PHONE_NUMBER_REQUIRED'
      const errorConfig = errorsToHuman[errorId]
      dismissNotesByTag('error', { exceptIds: [errorId] })
      upsertNote(
        {
          id: errorId,
          component: ErrorNotification,
          stretchToMaxWidth: '360px',
          tag: 'error',
          ...errorConfig
        },
        { prepend: true }
      )
      return
    }

    dismissAllNotes()

    dispatch({
      type: SUBMIT_VERIFY_PHONE_LOADING,
      payload: {
        status: true
      }
    })
    console.log('submitting!', phone)
    await delay(1000)
    // const result = await verifyPhoneMutation({
    //   variables: { input: { phone: phone.value } }
    // })
    // const referenceId = path(['data', 'verifyPhone', 'referenceId'], result)

    dispatch({
      type: SUBMIT_VERIFY_PHONE_LOADING,
      payload: {
        status: false
      }
    })

    // TEMP
    // SUCCESS
    // dispatch({
    //   type: SUBMIT_VERIFY_PHONE_RESPONSE,
    //   payload: {
    //     data: {
    //       referenceId
    //     }
    //   }
    // })

    // TEMP
    // FAIL
    dispatch({
      type: SUBMIT_VERIFY_PHONE_RESPONSE,
      payload: {
        data: {
          error: 'PHONE_NUMBER_DOES_NOT_EXIST'
        }
      }
    })
  }

  useEffect(() => {
    if (executeSubmitVerifyPhone) {
      asyncSubmitVerifyPhone()
    }
  }, [executeSubmitVerifyPhone])

  return {
    ...state,
    submitVerifyPhone
  }
}
