/* eslint no-multi-assign: 0 */
import React from 'react'
import Ring from './components/Ring'
import RingSmall from './components/RingSmall'
import IntroText from './components/IntroText'
import NetworkPattern from './components/NetworkPattern'
import Outer from './styled/Outer'
import Inner from './styled/Inner'

function Hero({
  onHeroClick,
  ringSmallStyles,
  introTextStyles,
  ringStyles,
  innerGlowStyles,
  networkPatternStyles,
  borrowerFirstName,
  borrowerLastName,
  loanOfficerFullName
}) {
  return (
    <Outer onClick={onHeroClick}>
      <Inner>
        <NetworkPattern style={networkPatternStyles} />
        <RingSmall style={ringSmallStyles} />
        <IntroText
          style={introTextStyles}
          loanOfficerFullName={loanOfficerFullName}
          borrowerFirstName={borrowerFirstName}
          borrowerLastName={borrowerLastName}
        />
        <Ring style={ringStyles} innerGlowStyle={innerGlowStyles} />
      </Inner>
    </Outer>
  )
}

export default Hero
