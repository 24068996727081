import styled from 'styled-components/macro'

const HamburgerOuter = styled.div`
  padding: 39px 32px 6px 0;
  @media (max-width: 750px) {
    padding-top: 29px;
  }
`

export default HamburgerOuter
