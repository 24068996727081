import { gql } from 'graphql.macro'
import LEAD_SOURCE_CONFIG_FRAGMENT from './LeadSourceConfigFragment'

export default gql`
  ${LEAD_SOURCE_CONFIG_FRAGMENT}
  fragment ApplicationCampaignFragment on ApplicationCampaign {
    id
    name
    description
    utmContent
    campaignType
    newLeadPostbackUrl
    leadSourceConfigs {
      ...LeadSourceConfigFragment
    }
  }
`
