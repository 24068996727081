/**
 *
 * Search.js
 *
 */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import throttle from 'lodash/throttle'
import useDisclosuresSearch from '../../../DisclosureQueue/useable/useDisclosuresSearch'
import MagGlass from './svg/MagGlass'

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: body;
  overflow: hidden;
  padding: 10px;
  font-size: 14px;
`

const Icon = styled.div`
  flex: 0 0 15px;
`

const InputContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 260px;
  margin-left: 20px;
`

const SearchInput = styled.input`
  display: block;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  font-weight: 300;
  width: 100%;
  font-size: 14px;
  ${props => props.theme.font('regular')}
  ::placeholder {
    color: #aeaeae;
  }
`

const SearchInputStroke = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  background: #dddddd;
  opacity: 0.75;
`

const Search = () => {
  const didMountRef = useRef(false)
  const [searchValue, setSearchValue] = useState('')
  const { setSearchQuery } = useDisclosuresSearch()
  const throttleSearch = useRef(
    throttle(newValue => setSearchQuery(newValue), 500, { leading: false })
  )

  useEffect(() => {
    if (didMountRef.current)
      if (searchValue === '') {
        throttleSearch.current.cancel()
        setSearchQuery(null)
      } else {
        throttleSearch.current(searchValue)
      }
    else didMountRef.current = true
  }, [searchValue])

  const handleInputChange = ({ target: { value } }) => setSearchValue(value)

  return (
    <SearchContainer>
      <Icon>
        <MagGlass />
      </Icon>
      <InputContainer>
        <SearchInput
          onChange={handleInputChange}
          value={searchValue}
          placeholder="Search"
        />
        <SearchInputStroke />
      </InputContainer>
    </SearchContainer>
  )
}

export default Search
