import styled from 'styled-components/macro'

const Note = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Gotham_Book;
  letter-spacing: -0.25px;
`

export default Note
