import styled from 'styled-components/macro'

const UnderGraphLabelOuter = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  margin: 24px 0 10px 0;
`

export default UnderGraphLabelOuter
