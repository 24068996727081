import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import useModal from '../useModal'

const ModalPortal = () => {
  const portalElement = useRef(document.createElement('div'))
  const modalRootRef = useRef(document.getElementById('modals'))
  const { currentModal } = useModal()

  useEffect(() => {
    modalRootRef.current.appendChild(portalElement.current)
    return () => {
      modalRootRef.current.removeChild(portalElement.current)
    }
  }, [currentModal])

  return createPortal(<div>{currentModal}</div>, portalElement.current)
}

export default ModalPortal
