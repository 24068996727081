import styled from 'styled-components/macro'

const Divider = styled.div`
  position: relative;
  max-width: 1px;
  flex: 1;
  background-color: #26275b;
  @media (max-width: 1245px) {
    display: none;
  }
`

export default Divider
