import React from 'react'
import posed from 'react-pose'
import { easing } from 'popmotion'

const Circle = posed.circle({
  stop: { y: 0 },
  active: {
    y: 0,
    transition: {
      y: ({ from }) => ({
        type: 'keyframes',
        duration: 200,
        values: [from, -3, 1, from],
        times: [0, 0.3, 0.8, 1],
        easings: [
          easing.linear,
          easing.linear,
          easing.cubicBezier(0.17, 0.67, 0.83, 0.67)
        ]
      })
    }
  }
})

const SvgDots = props => (
  <svg width="1.3em" height="1.3em" viewBox="0 0 17 3" {...props}>
    <g fillRule="evenodd">
      <Circle fill="#AEAEAE" cx={1.5} cy={1.5} r={1.5} />
      <Circle fill="#AEAEAE" cx={8.5} cy={1.5} r={1.5} />
      <Circle fill="#AEAEAE" cx={15.5} cy={1.5} r={1.5} />
    </g>
  </svg>
)

export default SvgDots
