import { gql } from 'graphql.macro'
import LoanOfficerFragment from './LoanOfficerFragment'

export default gql`
  ${LoanOfficerFragment}
  mutation($input: LoanOfficerUpdateInput!) {
    updateLoanOfficer(input: $input) {
      loanOfficer {
        ...LoanOfficerFragment
      }
      errors {
        field
        message
      }
    }
  }
`
