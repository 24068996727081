/**
 *
 * PushRefNavLink.js
 *
 */

import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

const PushRefLinkContainer = styled.div`
  display: inline-block;
  a {
    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #00023f;
    }
    text-decoration: none;
  }
`

const PushRefNavLink = ({ children, active, onActive }) => {
  const elementRef = React.createRef()
  useEffect(() => {
    if (active) onActive(elementRef.current)
  }, [active])
  return (
    <PushRefLinkContainer ref={elementRef}>{children}</PushRefLinkContainer>
  )
}

export default PushRefNavLink
