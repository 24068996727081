import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  padding: 0 135px;
  border-bottom: 1px solid #eeeeee;
  @media (max-width: 750px) {
    padding: 0 28px;
  }
`

export default Outer
