import { path } from 'ramda'

const emptyObj = {}
const emptyArr = []
export function ensureErrorsAsArray(e, { noDefault } = emptyObj) {
  const errors = path(['response', 'data', 'errors'], e)
  if (errors && errors.length) {
    return errors
  }
  let error = path(['response', 'data', 'message'], e)

  if (!error && noDefault) {
    return emptyArr
  }

  error = error || 'Unkown Error. Contact Support.'

  return [{ message: error }]
}

export function isHttpResponse(e) {
  return !!path(['response', 'status'], e)
}

export function responseNotOk(e) {
  return isHttpResponse(e) && !path(['response', 'ok'], e)
}

export function convertStatusToGeneralError(e, action) {
  const code = path(['response', 'status'], e)
  // if there is no code, there is no backend error
  if (!code) {
    return
  }

  switch (code) {
    case 500: {
      return `There was an error ${action} the data (500). contact support.`
    }
    case 404: {
      return `It's seems like that request endpoint was not found. contact support.`
    }

    // The below 2 statuses should be handled
    // by the individual input fields that
    // failed the request
    case 422: {
      return `The data you were ${action} could not be processed. Please fix the errors and retry.`
    }
    case 400: {
      return `The data you were ${action} resulted in a bad request. Please fix the errors and retry.`
    }
    case 401: {
      return `You are unauthorized to make this request.`
    }
    default: {
      return 'Uknown error :( Contact support.'
    }
  }
}
