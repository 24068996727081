import styled from 'styled-components/macro'

const Title = styled.h2`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 16px;
  max-width: 185px;
`

export default Title
