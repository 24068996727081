import React, { memo, useReducer, useCallback, useMemo } from 'react'
import isFunction from 'lodash/isFunction'
import { mergeDeepRight } from 'ramda'
import { DataProvider, ActionProvider } from '../Context'

const UPDATE = 'UPDATE'
const UNREGISTER = 'UNREGISTER'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case UPDATE: {
      return {
        ...state,
        data: mergeDeepRight(state.data, {
          [payload.data.id]: payload.data
        })
      }
    }
    case UNREGISTER: {
      const { id } = payload
      const {
        data: { [id]: unused, ...dataRest },
        ...stateRest
      } = state
      return {
        ...stateRest,
        data: dataRest
      }
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    data: {}
  }
}

export function createWithProvider(/* opts = {} */) {
  return function withProvider(Child) {
    if (Child) {
      Child = memo(Child)
    }
    return function Provider({ children, ...restProps }) {
      const [{ data }, dispatch] = useReducer(reducer, {}, init)

      const update = useCallback(newData => {
        dispatch({
          type: UPDATE,
          payload: {
            data: newData
          }
        })
      }, [])

      const unregister = useCallback(id => {
        dispatch({
          type: UNREGISTER,
          payload: {
            id
          }
        })
      }, [])

      const actionContext = useMemo(() => {
        return {
          update,
          unregister
        }
      }, [update, unregister])

      const dataContext = useMemo(() => {
        return {
          data
        }
      }, [data])

      if (Child) {
        children = <Child {...restProps}>{children}</Child>
      } else if (isFunction(children)) {
        children = children(restProps)
      }

      return (
        <DataProvider value={dataContext}>
          <ActionProvider value={actionContext}>{children}</ActionProvider>
        </DataProvider>
      )
    }
  }
}

export const withProvider = createWithProvider()
export const Provider = withProvider()
