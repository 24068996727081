import styled from 'styled-components/macro'

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ bgColor }) => bgColor || '#4100F5'} url(${({ src }) => src})
    center center no-repeat;
  border-radius: 4px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: middle;
`

export default Icon
