import styled from 'styled-components/macro'

const Title = styled.h3`
  vertical-align: middle;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.19px;
  display: inline-block;
`

export default Title
