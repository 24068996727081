import React from 'react'
import StatusHeaderCell from './styled/StatusHeaderCell'

const StatusHeaderRenderer = () => {
  return (
    <StatusHeaderCell>
      Status
    </StatusHeaderCell>
  )
}
  

export default StatusHeaderRenderer
