/* eslint no-multi-assign: 0 */
/* eslint no-param-reassign: 0 */
import React, { useRef } from 'react'
import Outer from './styled/Outer'
import Svg from './styled/Svg'
import Wrapper from './styled/Wrapper'
import ProgressCircle from './styled/ProgressCircle'
import CenterText from './styled/CenterText'

function ProgressRing({
  ringColor,
  cssStyles,
  progressAnimation,
  OuterComponent,
  radius: r = 99,
  strokeWidth = 5,
  centerTextAsPercent,
  hasUnderlayRing = true
}) {
  const totalProgressUnits = r * 2 * Math.PI
  const minTotalProgressUnits = totalProgressUnits - 12

  const centerDim = r + strokeWidth

  const dim = centerDim * 2
  const centerTextRef = useRef()

  let progressValue = minTotalProgressUnits
  const fallbackTextCenter = 0
  let innerTextAnimation

  if (progressAnimation) {
    progressValue = progressAnimation.progress.interpolate(v => {
      const res = totalProgressUnits - (v / 100) * totalProgressUnits
      return res < minTotalProgressUnits ? res : minTotalProgressUnits
    })
    innerTextAnimation = progressAnimation.progress.interpolate(v => {
      if (centerTextRef.current) {
        centerTextRef.current.innerText = Math.ceil(v)
      }
    })
  }

  const styles = {
    width: `${dim}px`,
    height: `${dim}px`
  }

  // cannot pass props into fragment
  let outerComponentProps = {}

  if (!OuterComponent) {
    OuterComponent = Wrapper
    outerComponentProps = {
      css: cssStyles
    }
  }

  return (
    <OuterComponent {...outerComponentProps}>
      <Outer>
        <Svg style={styles} viewBox={`0 0 ${dim} ${dim}`} version="1.1">
          <g transform={`rotate(-90, ${centerDim}, ${centerDim})`}>
            {hasUnderlayRing && (
              <circle
                fill="none"
                stroke="#180069"
                strokeWidth={strokeWidth}
                cx="50%"
                cy="50%"
                r={r}
              />
            )}
            <ProgressCircle
              fill="none"
              stroke={ringColor || '#00D3BC'}
              strokeWidth={strokeWidth}
              cx="50%"
              cy="50%"
              r={r}
              strokeDasharray={totalProgressUnits}
              strokeDashoffset={progressValue}
              strokeLinecap="round"
            />
          </g>
        </Svg>
      </Outer>
      {centerTextAsPercent && (
        <CenterText unused={innerTextAnimation} ref={centerTextRef}>
          {fallbackTextCenter}
        </CenterText>
      )}
    </OuterComponent>
  )
}

export default ProgressRing
