import { useMutation } from '@apollo/react-hooks'
import { findIndex, propEq, remove, path } from 'ramda'

import GET_LOAN_OFFICERS from '../LoanOfficersQuery'
import CREATE_LOAN_OFFICER from '../CreateLoanOfficerMutation'
import DELETE_LOAN_OFFICER from '../DeleteLoanOfficerMutation'
import UPDATE_LOAN_OFFICER from '../UpdateLoanOfficerMutation'

const withoutObjectWithId = (loanOfficerId, loanOfficers) => {
  const idx = findIndex(propEq('id', loanOfficerId), loanOfficers)
  return idx !== -1 ? remove(idx, 1, loanOfficers) : loanOfficers
}

export default function useMutateLoanOfficer() {
  const [
    createLoanOfficer,
    { error: createError, loading: createLoading }
  ] = useMutation(CREATE_LOAN_OFFICER, {
    update(
      cache,
      {
        data: { createLoanOfficer: createLoanOfficerResp }
      }
    ) {
      if (
        createLoanOfficerResp.errors &&
        createLoanOfficerResp.errors.length > 0
      )
        return
      const { loanOfficers } = cache.readQuery({ query: GET_LOAN_OFFICERS })
      const { loanOfficer } = createLoanOfficerResp
      cache.writeQuery({
        query: GET_LOAN_OFFICERS,
        data: { loanOfficers: loanOfficers.concat([loanOfficer]) }
      })
    }
  })

  const [deleteLoanOfficer] = useMutation(DELETE_LOAN_OFFICER, {
    update(
      cache,
      {
        data: { deleteLoanOfficer: deleteLoanOfficerResp }
      }
    ) {
      const { loanOfficers } = cache.readQuery({ query: GET_LOAN_OFFICERS })
      const { loanOfficerId } = deleteLoanOfficerResp
      if (loanOfficerId)
        cache.writeQuery({
          query: GET_LOAN_OFFICERS,
          data: {
            loanOfficers: withoutObjectWithId(loanOfficerId, loanOfficers)
          }
        })
    }
  })

  const [
    updateLoanOfficer,
    { error: updateError, loading: updateLoading }
  ] = useMutation(UPDATE_LOAN_OFFICER)

  const saveLoanOfficer = async opts => {
    const recordId = path(['variables', 'input', 'id'], opts)
    const createOrUpdate = recordId ? updateLoanOfficer : createLoanOfficer
    const mutationKey = recordId ? 'updateLoanOfficer' : 'createLoanOfficer'
    const result = await createOrUpdate(opts)
    const resultPath = path(['data', mutationKey], result)
    return resultPath
  }

  return {
    loading: createLoading || updateLoading,
    errors: updateError || createError,
    saving: createLoading || updateLoading,
    save: saveLoanOfficer,
    delete: deleteLoanOfficer
  }
}
