import styled from 'styled-components/macro'

const Sup = styled.span`
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  margin: 2px 0 0 4px;
`

export default Sup
