import styled from 'styled-components/macro'

const Select = styled.select`
  font-size: 20px;
  line-height: 26px;
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 15px;
  color: #00023f;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 45px;
`

export default Select
