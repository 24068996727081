import React from 'react'
import ListAndCloseIcon from 'components/ListAndCloseIcon'
import Logo from 'domains/shared/LogoAnimated'
import Outer from './styled/Outer'
import HamburgerOuter from './styled/HamburgerOuter'
import SocialIconsOuter from './styled/SocialIconsOuter'
import SocialOuter from './styled/SocialOuter'
import LogoOuter from './styled/LogoOuter'
import RightArea from './styled/RightArea'
import NavContent from './styled/NavContent'
import InstagramIcon from './components/InstagramIcon'
import FacebookIcon from './components/FacebookIcon'
import TwitterIcon from './components/TwitterIcon'
import LinkedInIcon from './components/LinkedInIcon'
import GlassdoorIcon from './components/GlassdoorIcon'
import ZillowIcon from './components/ZillowIcon'

const socialIcons = [
  {
    href: 'https://instagram.com',
    component: InstagramIcon
  },
  {
    href: 'https://facebook.com',
    component: FacebookIcon
  },
  {
    href: 'https://twitter.com',
    component: TwitterIcon
  },
  {
    href: 'https://linkedin.com',
    component: LinkedInIcon
  },
  {
    href: 'https://glassdoor.com',
    component: GlassdoorIcon
  },
  {
    href: 'https://zillow.com',
    component: ZillowIcon
  }
]

export default function Header({ rightContentComponent: RightContentComp }) {
  return (
    <Outer>
      <HamburgerOuter>
        <ListAndCloseIcon />
      </HamburgerOuter>
      <LogoOuter>
        <Logo letterColor="#00023F" ballColor="#4100F5" />
      </LogoOuter>
      <NavContent>
        <SocialIconsOuter>
          {socialIcons.map(({ href, component: SocialIcon }) => (
            <SocialOuter href={href} key={href}>
              <SocialIcon />
            </SocialOuter>
          ))}
        </SocialIconsOuter>
        <RightArea>{RightContentComp && <RightContentComp />}</RightArea>
      </NavContent>
    </Outer>
  )
}
