import styled from 'styled-components/macro'

const Outer = styled.div`
  color: #00023f;
  display: flex;
  flex-wrap: wrap;
  max-width: 1214px;
  margin: 0 113px;
  @media (max-width: 750px) {
    margin: 0 30px;
  }
`

export default Outer
