import React from 'react'
import styled from 'styled-components/macro'

import Dots from './svgs/Dots'

const StyledDetailButton = styled.div`
  display: flex;
  padding: 12px 14px;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  top: -8px;
  left: 0;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  ${props => `
        fill: ${props.theme.primaryColor};
        ${
          props.active
            ? `
                box-shadow: 0 0 0 1px ${props.theme.primaryColor};
                fill: ${props.theme.primaryColor};
            `
            : `
                fill: ${props.theme.light};
            `
        }
        }
    `}
`

const DetailButton = ({ onClick, active, children }) => {
  return (
    <StyledDetailButton onClick={onClick} active={active}>
      {children}
      <Dots />
    </StyledDetailButton>
  )
}

DetailButton.propTypes = {}

export default DetailButton
