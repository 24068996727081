import styled from 'styled-components/macro'

const Divider = styled.div`
  width: 41px;
  height: 2px;
  background-color: #29e6d1;
  margin-bottom: 26px;
`

export default Divider
