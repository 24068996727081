import { useMutation } from '@apollo/react-hooks'
import { path, pathOr } from 'ramda'

import VERIFY_LOAN_UPLOAD_CODE_MUTATION from '../verifyLoanUploadCodeMutation'
import CURRENT_LOAN_UPLOADER_QUERY from '../currentLoanUploaderQuery'

const isVerifiedInResponse = pathOr(false, ['verifyLoanUploadCode', 'verified'])

export default function useLoanUploadCodeLogin() {
  const [verifyLoanUploadCodeMutation, { error, loading }] = useMutation(
    VERIFY_LOAN_UPLOAD_CODE_MUTATION
  )

  const login = async uploadCode => {
    const result = await verifyLoanUploadCodeMutation({
      variables: {
        input: {
          uploadCode
        }
      },
      update: (cache, { data }) => {
        const { currentLoanUploader } = cache.readQuery({
          query: CURRENT_LOAN_UPLOADER_QUERY
        })
        const verified = isVerifiedInResponse(data)

        cache.writeQuery({
          query: CURRENT_LOAN_UPLOADER_QUERY,
          data: { currentLoanUploader: { ...currentLoanUploader, verified } }
        })
      }
    })

    const resultPath = path(
      ['data', 'verifyLoanUploadCode', 'verified'],
      result
    )

    return resultPath
  }

  return {
    loading,
    error,
    login
  }
}
