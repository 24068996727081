import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'

const UploadContainer = styled.div``

const UploadArea = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.active ? `background: rgba(0,255,0,0.5);` : ``)}
`

const Dropzone = ({ onDropFiles }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    onDrop: onDropFiles
  })

  return (
    <UploadContainer>
      <UploadArea {...getRootProps({})} active={isDragActive}>
        <input {...getInputProps()} />
        Upload some files here
      </UploadArea>
    </UploadContainer>
  )
}

export default Dropzone
