import styled from 'styled-components/macro'

const Outer = styled.a`
  display: inline-block;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.08);
  }
`

export default Outer
