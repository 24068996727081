import { useContext } from 'react'
import {
  createWithFormProvider,
  withFormProvider,
  FormProvider
} from './FormProvider'
import { Context, PrivateContext } from './Context'
import Form from './Form'

export { createWithFormProvider, withFormProvider, FormProvider, Form }

export function useFormActions() {
  const { current } = useContext(Context)

  return current
}

export function useFormData() {
  const { data } = useContext(PrivateContext)

  return data
}
