import React from 'react'

export default function LinkedInIcon() {
  return (
    <svg
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-109.000000, 0.000000)" fill="#00023F">
          <g>
            <g transform="translate(109.034253, 0.000000)">
              <g transform="translate(0.015562, 0.074303)">
                <path d="M12.688642,12.7599489 L9.96851438,12.7599489 L9.96851438,8.15425232 C9.96851438,6.99782198 9.57516648,6.20684365 8.59021064,6.20684365 C7.83999468,6.20684365 7.39113397,6.74167183 7.19446002,7.2548065 C7.12229337,7.43920124 7.10484648,7.69368266 7.10484648,7.95233591 L7.10484648,12.7599489 L4.38551189,12.7599489 C4.38551189,12.7599489 4.42040566,4.95863313 4.38551189,4.15263622 L7.10484648,4.15263622 L7.10484648,5.37164396 C7.46726582,4.78258204 8.11280048,3.94988545 9.55692655,3.94988545 C11.3460251,3.94988545 12.688642,5.17890557 12.688642,7.82384365 L12.688642,12.7599489 Z M1.52025792,2.97534675 L1.50281104,2.97534675 C0.590021853,2.97534675 -1.77635684e-15,2.31369505 -1.77635684e-15,1.48767337 C-1.77635684e-15,0.644130031 0.609054816,0 1.5377048,0 C2.46873391,0 3.04210192,0.644130031 3.05875576,1.48767337 C3.05875576,2.31369505 2.46873391,2.97534675 1.52025792,2.97534675 Z M0.160194105,12.7599489 L0.160194105,4.15263622 L2.88111478,4.15263622 L2.88111478,12.7599489 L0.160194105,12.7599489 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
