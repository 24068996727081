import { useState, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const emptyObj = {}

export default function useMeasure(
  ref,
  { disable, watchWindowResize } = emptyObj
) {
  const [bounds, setContentRect] = useState({
    firstRender: true,
    contentRect: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    boundingClientRect: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    scrollY: window.scrollY,
    windowInnerWidth: window.innerWidth
  })

  useLayoutEffect(() => {
    let animationFrameId = null
    const measure = arg => {
      const entry = arg[0]
      animationFrameId = window.requestAnimationFrame(() => {
        const res = ref.current.getBoundingClientRect()
        let obj = {
          boundingClientRect: res
        }
        if (entry) {
          obj.contentRect = entry.contentRect
        }

        setContentRect(cur => {
          return {
            ...cur,
            ...obj,
            scrollY: window.scrollY,
            windowInnerWidth: window.innerWidth,
            firstRender: false
          }
        })
      })
    }

    const ro = new ResizeObserver(measure)
    // if enable
    if (ref.current && !disable) {
      ro.observe(ref.current)
      watchWindowResize && window.addEventListener('resize', measure)
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId)
      window.removeEventListener('resize', measure)
      ro.disconnect()
    }
  }, [ref.current, disable, watchWindowResize])

  return bounds
}
