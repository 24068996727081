import React, { memo, useState, useCallback, useMemo, useRef } from 'react'
import isFunction from 'lodash/isFunction'
import { equals, mergeDeepRight, path } from 'ramda'
import { Provider, PrivateProvider } from '../Context'

export function createWithFormProvider(/* opts = {} */) {
  return function withFormProvider(Child) {
    if (Child) {
      Child = memo(Child)
    }
    return function FormProvider({ children, ...restProps }) {
      const [state, setState] = useState({})
      const [privateContext, setPrivateContext] = useState({})

      const setPrivateContextCallback = useCallback(
        data => {
          const res = mergeDeepRight(privateContext, data)
          if (!equals(res, privateContext)) {
            setPrivateContext(res)
          }
        },
        [privateContext]
      )

      const privateContextWithActions = useMemo(() => {
        return {
          ...privateContext,
          setContext: setPrivateContextCallback
        }
      }, [setPrivateContextCallback, privateContext])

      const setValue = useCallback(
        (k, value) => {
          const data = state[k] || {}
          setState({
            ...state,
            [k]: {
              ...data,
              value
            }
          })
        },
        [setState, state]
      )

      const privateContextRef = useRef()
      privateContextRef.current = privateContext

      function getAllForms() {
        return path(['current', 'data'], privateContextRef)
      }

      // const context = useMemo(()=>{
      //   return {
      //     setValue,
      //     getValue
      //   }
      // }, [setValue, state])

      // Our actions should not cause a rerender of children
      const contextRef = useRef({
        setValue,
        getAllForms
      })

      if (Child) {
        children = <Child {...restProps} />
      } else if (isFunction(children)) {
        children = children(restProps)
      }

      return (
        <PrivateProvider value={privateContextWithActions}>
          <Provider value={contextRef}>{children}</Provider>
        </PrivateProvider>
      )
    }
  }
}

export const withFormProvider = createWithFormProvider()
export const FormProvider = withFormProvider()
