/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import IconSwatch from 'components/IconSwatch'
import ProgressBar from 'components/ProgressBar'
import Outer from './styled/Outer'
import BarAndData from './styled/BarAndData'
import TopOuter from './styled/TopOuter'
import Label from './styled/Label'
import Metric from './styled/Metric'

function SingleLineBarGraph({
  label,
  displayTotal,
  fraction,
  barColor,
  backgroundColor,
  leftIconImagePath
}) {
  return (
    <Outer>
      <IconSwatch iconImagePath={leftIconImagePath} marginRight="25px" />
      <BarAndData>
        <TopOuter>
          <Label>{label}</Label>
          <Metric>{displayTotal}</Metric>
        </TopOuter>
        <ProgressBar
          backgroundColor={backgroundColor}
          barColor={barColor}
          fraction={fraction}
          height="4px"
        />
      </BarAndData>
    </Outer>
  )
}

export default SingleLineBarGraph
