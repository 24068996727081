import styled from 'styled-components/macro'
import { isNil } from 'ramda'

const Item = styled.div`
  opacity: 1;
  transition: opacity 1s ease-in-out;
  @media (max-width: 955px) {
    opacity: ${({ tabletOpacity }) =>
      !isNil(tabletOpacity) ? tabletOpacity : 1};
  }
`

export default Item
