import React, { memo } from 'react'
import { any, propSatisfies, path, values, pipe } from 'ramda'
import FooterPopNav from '../FooterPopNav'
import DoneUploadingLogoutBtn from '../DoneUploadingLogoutBtn'

const fileGroupHasWorkingGroup = fg =>
  any(
    propSatisfies(
      v => ['uploading', 'queued', 'finalizing'].includes(v),
      'status'
    ),
    Object.values(fg.grouped)
  )

const FooterDoneBtn = memo(function DoneButtonManagerComp(props) {
  const fileGroups = pipe(
    path(['fileGroups', 'groups']),
    values
  )(props)
  const filesStillWorking = any(fileGroupHasWorkingGroup, fileGroups)
  const showFooterPop = fileGroups.length > 0 && !filesStillWorking

  return (
    <FooterPopNav show={showFooterPop}>
      <DoneUploadingLogoutBtn />
    </FooterPopNav>
  )
})

export default FooterDoneBtn
