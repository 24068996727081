/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import Inner from './styled/Inner'
import Brand from './components/Brand'
import BorrowerInfo from './components/BorrowerInfo'
import NavBtns from './components/NavBtns'

function HeaderBar({
  style,
  borrowerFirstName,
  borrowerLastName,
  loanNumber,
  loaiDataError
}) {
  return (
    <Outer style={style}>
      <Inner>
        <Brand />
        <BorrowerInfo
          borrowerFirstName={borrowerFirstName}
          borrowerLastName={borrowerLastName}
          loanNumber={loanNumber}
          loaiDataError={loaiDataError}
        />
        {loanNumber && <NavBtns />}
      </Inner>
    </Outer>
  )
}

export default HeaderBar
