// a library to wrap and simplify api calls
import qs from 'qs'
import fetch from 'unfetch'
import { LOWER_API_DOMAIN } from 'domains/shared/utils/apiUrls'

const create = () => {
  function getDisclosureQueue(data) {
    const url = new URL(`${LOWER_API_DOMAIN}/disclosures?${qs.stringify(data)}`)
    return fetch(url, {
      method: 'get',
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then(response => {
        return { data: response }
      })
  }

  function resetDisclosure(disclosureId) {
    const url = new URL(`${LOWER_API_DOMAIN}/disclosures/reset`)
    return fetch(url, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ disclosureId })
    })
      .then(res => res.json())
      .then(response => {
        return response
      })
  }

  function downloadDisclosure(disclosureId) {
    window.location.assign(
      `${LOWER_API_DOMAIN}/disclosures/download/${disclosureId}`
    )
  }

  return {
    getDisclosureQueue,
    resetDisclosure,
    downloadDisclosure
  }
}
const theApi = create()

export default theApi
