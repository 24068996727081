import styled from 'styled-components/macro'

const PlusLine = styled.div`
  position: absolute;
  background-color: #00023f;
  transform: translate(-50%, -50%);
`

const PlusLineVertical = styled(PlusLine)`
  left: 50%;
  top: 50%;
  width: 21px;
  height: 2px;
`

const PlusLineHorizontal = styled(PlusLine)`
  left: 50%;
  top: 50%;
  width: 2px;
  height: 21px;
`

const PlusLineContainer = styled.div`
  position: relative;
  width: 21px;
  height: 21px;
  margin: 0 10px;
`

export { PlusLineVertical, PlusLineHorizontal, PlusLineContainer }
