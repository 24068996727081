import React, { useMemo } from 'react'
import { groupBy, slice, compose, prop, toPairs, toUpper } from 'ramda'
import Outer from './styled/Outer'
import MultiToggleGroup from './components/MultiToggleGroup'

export default function PersonaCodeMultiSelect({
  editedValue,
  updateEditedValue
  // errors
}) {
  // console.log('edited value', editedValue)

  const groups = useMemo(
    () =>
      toPairs(
        groupBy(
          compose(
            toUpper,
            slice(0, 1),
            prop('value')
          ),
          editedValue
        )
      ),
    [editedValue]
  )

  return (
    <Outer>
      {groups.map(([categoryStr, group]) => {
        const category =
          categoryStr === 'P'
            ? 'Personas'
            : categoryStr === 'S'
            ? 'Subpersonas'
            : categoryStr === 'E'
            ? 'Emergency'
            : 'Unknown'

        return (
          <MultiToggleGroup
            key={category}
            category={category}
            onBtnClick={updateEditedValue}
            group={group}
          />
        )
      })}
    </Outer>
  )
}
