import docIcon from './images/doc-icon.png'
import jpgIcon from './images/jpg-icon.png'
import pdfIcon from './images/pdf-icon.png'
import pngIcon from './images/png-icon.png'
import secureIcon from './images/secure-icon.png'

const fileThemeConfig = {
  png: {
    text: 'PNG',
    iconPath: pngIcon,
    color: '#0158FF'
  },
  jpeg: {
    text: 'JPG',
    iconPath: jpgIcon,
    color: '#01CA01'
  },
  pdf: {
    text: 'PDF',
    iconPath: pdfIcon,
    color: '#6B00D1'
  },
  msword: {
    text: 'Word Doc',
    iconPath: docIcon,
    color: '#4100F5'
  }
}

export const secureIconPath = secureIcon

export default fileThemeConfig
