/* eslint prefer-destructuring: 0 */
import { createContext } from 'react'

const DataContext = createContext()
const DataProvider = DataContext.Provider

const ActionContext = createContext()
const ActionProvider = ActionContext.Provider

export { DataContext, DataProvider, ActionContext, ActionProvider }
