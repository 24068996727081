import React, { useCallback } from 'react'
import { reduce, assoc, pickAll, merge, identity, filter } from 'ramda'
import styled from 'styled-components/macro'
import { Form, Field } from 'react-final-form'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'
import TextInputField from '../../Shared/components/Form/TextInputField'
import ExampleUrlField from '../../Shared/components/Form/ExampleUrlField'
import ToggleField from '../../Shared/components/Form/ToggleField'

import useMutateLoanOfficer from '../graphql/hooks/useMutateLoanOfficer'

const compact = filter(identity)

const StyledForm = styled(Form)`
  grid-column-start: span 4;
`

const FormContainer = styled.form`
  display: block;
  margin-bottom: 40px;
`

const StyledField = styled(Field)``

const StyledSubmitButton = styled.button`
  align-self: center;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #00023f;
  background-color: #00023f;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  cursor: pointer;
  margin-right: ${({ marginRight }) => marginRight || '20px'};
  &:hover {
    opacity: 0.8;
  }
  &:last-child {
    margin-right: 0;
  }
`

const loanOfficerForm = ({ loanOfficer, onCancel, validate }) => {
  const { loading, errors, save: saveLoanOfficer } = useMutateLoanOfficer()

  const onSubmit = useCallback(
    async formValue => {
      const selectedFields = pickAll(
        [
          'id',
          'name',
          'avatarUrl',
          'token',
          'email',
          'jobTitle',
          'mobilePhone',
          'workPhone',
          'nmlsId',
          'isActive'
        ],
        formValue
      )

      const selectedFieldsWithoutUndefined = compact(selectedFields)

      const input = merge(
        { avatarUrl: null, isActive: false },
        selectedFieldsWithoutUndefined
      )
      const result = await saveLoanOfficer({
        variables: {
          input
        }
      })

      if (result && !result.errors) {
        if (!(loanOfficer && loanOfficer.id) && onCancel) onCancel()
      } else {
        const formErrors = reduce(
          (accum, { field, message }) => assoc(field, message, accum),
          {},
          result.errors
        )
        return formErrors
      }
    },
    [onCancel]
  )

  return (
    <StyledForm
      subscription={{ submitting: true, touched: true }}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ isActive: true, ...loanOfficer }}
      render={({ handleSubmit, submitting, pristine, invalid, form }) => {
        return (
          // TODO: clean up this nasty issue
          <FormContainer
            onSubmit={event => {
              const hs = handleSubmit(event)
              if (hs) {
                hs.then(submissionErrors => {
                  if (!submissionErrors && !(loanOfficer && loanOfficer.id)) {
                    form.reset()
                  }
                })
              }
            }}
          >
            {invalid && 'Form has errors yo'}
            {errors && errors.message}
            <StyledField
              focus
              name="name"
              label="Name"
              placeholder="Name"
              required
              component={TextInputField}
            />
            <StyledField
              name="avatarUrl"
              label="Avatar URL"
              placeholder="Avatar URL"
              component={TextInputField}
            />
            <StyledField
              name="token"
              label="Token"
              placeholder="Token"
              required
              urlTemplate={val => `https://app.lower.com/apply?lo=${val}`}
              component={ExampleUrlField}
            />
            <StyledField
              name="email"
              label="Email"
              placeholder="Email"
              required
              component={TextInputField}
            />
            <StyledField
              name="jobTitle"
              label="Job Title"
              placeholder="Job Title"
              component={TextInputField}
            />
            <StyledField
              name="mobilePhone"
              label="Mobile Phone"
              placeholder="Mobile Phone"
              component={TextInputField}
            />
            <StyledField
              name="workPhone"
              label="Work Phone"
              placeholder="Work Phone"
              component={TextInputField}
            />
            <StyledField
              name="nmlsId"
              label="NMLS ID"
              placeholder="NMLS ID"
              component={TextInputField}
            />
            <StyledField
              name="isActive"
              label="Active"
              type="checkbox"
              component={ToggleField}
            />
            <StyledSubmitButton
              disabled={loading || submitting || pristine}
              type="submit"
            >
              {loading || submitting ? 'Saving...' : 'Submit'}
            </StyledSubmitButton>
            {onCancel && <SimpleBtn onClick={onCancel}>Cancel</SimpleBtn>}
          </FormContainer>
        )
      }}
    />
  )
}

export default loanOfficerForm
