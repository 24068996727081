import styled from 'styled-components/macro'

const SideDot = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export default SideDot
