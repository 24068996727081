import styled from 'styled-components/macro'

const Title = styled.h2`
  font-family: Gotham;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: -2px;
  line-height: 70px;
  color: #00023f;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  margin-bottom: 35px;
`

export default Title
