/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import Title from './styled/Title'

export default function PersonaTopLabel({ title }) {
  return (
    <Outer>
      <Title>{title}</Title>
    </Outer>
  )
}
