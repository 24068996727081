import { useAppStore } from '../../store'

const useDisclosuresSearch = () => {
  const [searchQuery, setSearchQuery] = useAppStore((state, actions) => {
    return [
      state.disclosureQueue.disclosures.searchQuery, 
      actions.setSearchQuery
    ]
  })

  return {searchQuery, setSearchQuery};
}

export default useDisclosuresSearch