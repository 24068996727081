import styled from 'styled-components/macro'

const Outer = styled.div`
  position: absolute;
  top: -24px;
  right: 0;
  transform: translate(50%, -50%);
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ customCss }) => customCss}
`

export default Outer
