import styled from 'styled-components/macro'

const Outer = styled.div`
  display: inline-block;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  ${({ customCss }) => customCss}
`

export default Outer
