import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { createPortal } from 'react-dom'

const ElementPosition = styled.div`
  position: absolute;
  z-index: 9999999;
  background: white;
  ${props => `
      top: ${props.position.y}px;
      left: ${props.position.x}px;
  `}
`

const ButtonPopupPortal = ({ children, parentRef }) => {
  const [position, setPosition] = useState(null)
  const portalElement = useRef(document.createElement('div'))
  const rootRef = useRef(document.getElementById('buttonPopups'))

  const handleScroll = () => {
    window.requestAnimationFrame(() => {
      setPosition(
        parentRef.current && parentRef.current.getBoundingClientRect()
      )
    })
  }

  useEffect(() => {
    document.addEventListener(
      'scroll',
      () => {
        handleScroll()
      },
      true
    )
    rootRef.current.appendChild(portalElement.current)
    handleScroll()
    return () => {
      rootRef.current.removeChild(portalElement.current)
      document.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  return createPortal(
    <ElementPosition
      position={
        position ? { x: position.left, y: position.top } : { x: 0, y: -500 }
      }
    >
      {children}
    </ElementPosition>,
    portalElement.current
  )
}

export default ButtonPopupPortal
