import styled from 'styled-components/macro'
// import { animated } from 'react-spring'

const DataContent = styled.div`
  margin-bottom: 50px;
  position: relative;
  transform: ${({ transform }) => transform};
  opacity: ${({ opacity }) => opacity};
  transition: ${({ config: { duration = 1000, easing = 'ease-out' } }) =>
    `all ${duration}ms ${easing}`};
`

export default DataContent
