import { useMemo, useState, useEffect, useCallback } from 'react'
import {
  indexBy,
  prop,
  any,
  propEq,
  anyPass,
  omit,
  mergeDeepRight,
  path
} from 'ramda'
import useTicker from 'lib/useTicker'
import uuid from 'lib/uuid'

const emptyObj = {}
const indexById = indexBy(prop('id'))
const isError = propEq('status', 'error')
const isSuccess = propEq('status', 'success')
const isUploadingOrQueued = anyPass([
  propEq('status', 'uploading'),
  propEq('status', 'queued'),
  propEq('status', 'finalizing')
])

function groupFiles(allFiles) {
  // const allFilesById = allFiles
  const errorFiles = allFiles.filter(isError)
  const successFiles = allFiles.filter(isSuccess)
  const workingFiles = allFiles.filter(isUploadingOrQueued)
  const workingAndSuccessFiles = workingFiles.concat(successFiles)
  return {
    allFiles,
    errorFiles,
    successFiles,
    workingFiles,
    workingAndSuccessFiles
  }
}

function groupIsNotFinalized(group) {
  return !path(['finalized'], group)
}

// we need to "group" by all files with status "uploading" OR "queued"
// within the same "upload window" (any file which was "uploading"
// during the time period of any other file "uploading" groups it in
// that given window. Basically, as soon as ALL FILES on the screen
// are FINISHED, the given upload "window", ends)
export function useComposeFileGroupsForStats(filesById) {
  const [allFileGroups, setAllFileGroups] = useState({ groups: {} })

  const removeFileGroupById = useCallback(id => {
    setAllFileGroups(allGroups => {
      if (allGroups.groups[id]) {
        const existingGroups = omit([id], allGroups.groups)
        return {
          ...allGroups,
          groups: existingGroups
        }
      }

      return allGroups
    })
  }, [])

  const {
    currentGroupId,
    groups: { [currentGroupId]: currentGroup = emptyObj } = emptyObj
  } = allFileGroups

  const {
    grouped: allFiles = emptyObj
    // workingFiles = emptyObj,
    // workingAndSuccessFiles = emptyObj,
    // errorFiles = emptyObj,
    // successFiles = emptyObj
  } = currentGroup

  useEffect(() => {
    // const newFiles = ! Object.values(filesById).filter(isUploadingOrQueued)
    const allIncomingFiles = Object.values(filesById)

    // we may need a new group if there aren't any files that exist
    // in a group that are currently uploading/queued
    const currentGroupIsUploading = any(
      file => allFiles[file.id] && isUploadingOrQueued(file),
      allIncomingFiles
    )
    const atleastOneFileWorking =
      currentGroupIsUploading || any(isUploadingOrQueued, allIncomingFiles)

    if (!currentGroupIsUploading && atleastOneFileWorking) {
      // we need a new group - old group is done - and add new file(s) coming in.
      const grouped = indexById(
        allIncomingFiles.filter(incomingFile =>
          isUploadingOrQueued(incomingFile)
        )
      )
      setAllFileGroups(allGroupsConfig => {
        const newCurrentGroupId = uuid()
        return mergeDeepRight(allGroupsConfig, {
          currentGroupId: newCurrentGroupId,
          groups: {
            [newCurrentGroupId]: {
              groupId: newCurrentGroupId,
              grouped
            }
          }
        })
      })
    } else if (currentGroupIsUploading) {
      // update existing current group
      // add existing files back in the group and files that are newly uploading/queued
      const grouped = indexById(
        allIncomingFiles.filter(
          incomingFile =>
            allFiles[incomingFile.id] || isUploadingOrQueued(incomingFile)
        )
      )
      setAllFileGroups(allGroupsConfig => {
        return mergeDeepRight(allGroupsConfig, {
          groups: {
            [currentGroupId]: {
              grouped
            }
          }
        })
      })
    } else if (
      !currentGroupIsUploading &&
      currentGroupId &&
      groupIsNotFinalized(currentGroup)
    ) {
      const grouped = indexById(
        allIncomingFiles.filter(incomingFile => allFiles[incomingFile.id])
      )
      // console.log('finalizing current group', grouped)

      setAllFileGroups(allGroupsConfig => {
        return mergeDeepRight(allGroupsConfig, {
          groups: {
            [currentGroupId]: {
              grouped,
              finalized: true
            }
          }
        })
      })
    }
  }, [filesById])

  return {
    fileGroups: allFileGroups,
    removeFileGroupById
  }
}

export default function useComputeCurrentUploadStats(fileGroup) {
  const { groupId, finalized, grouped: allFilesObj } = fileGroup

  const {
    allFiles,
    errorFiles,
    workingFiles,
    workingAndSuccessFiles
  } = useMemo(() => {
    return groupFiles(Object.values(allFilesObj))
  }, [allFilesObj])

  const totalFiles = allFiles.length
  const totalFilesWorkingOrSuccess = workingAndSuccessFiles.length
  const totalFilesError = errorFiles.length
  const totalWorkingFiles = workingFiles.length

  const totalBytesWorkingOrSuccess = useMemo(
    () =>
      Object.values(workingAndSuccessFiles).reduce(
        (acc, { fileData: { size = 0 } } = {}) => acc + size,
        0
      ),
    [workingAndSuccessFiles]
  )

  const currentBytesWorkingOrSuccess = useMemo(
    () =>
      Object.values(workingAndSuccessFiles).reduce(
        (acc, { progressBytes }) => acc + progressBytes,
        0
      ),
    [workingAndSuccessFiles]
  )
  // start a looping timer every ~ 1 ish seconds (don't rely on the 1 sec duration,
  // we will use a timestamp for exactness) when we have files in window.
  // The tickFlag will flip on an interval, so we can fire
  // useEffect function to compute time left based on prev progress
  // and current progress
  const { tickFlag, getFirstTickTimestamp } = useTicker(
    !!totalWorkingFiles,
    1000
  )

  // const prevTimestampMsRef = useRef()
  // const prevBytesWorkingOrSuccessRef = useRef()
  const [timeRemaining, setTimeRemaining] = useState()
  useEffect(() => {
    if (totalFilesWorkingOrSuccess) {
      // const curTimestampMsVal = Date.now()
      // prevTimestampMsVal = prevTimestampMsRef.current || curTimestampMsVal
      // const timestampMsDelta = curTimestampMsVal - prevTimestampMsVal

      let remainBytes =
        totalBytesWorkingOrSuccess - currentBytesWorkingOrSuccess
      if (remainBytes < 0) {
        remainBytes = 0
      }
      const remainingToCurrent = remainBytes / currentBytesWorkingOrSuccess
      let estimatedCompletionTime
      if (Number.isFinite(remainingToCurrent)) {
        estimatedCompletionTime = Math.round(
          ((remainBytes / currentBytesWorkingOrSuccess) *
            (Date.now() - getFirstTickTimestamp())) /
            1000
        )
      } else {
        estimatedCompletionTime = Infinity
      }
      // prevBytesWorkingOrSuccessRef.current = currentBytesWorkingOrSuccess
      // prevTimestampMsRef.current = curTimestampMsVal
      setTimeRemaining(estimatedCompletionTime)
    }
  }, [
    tickFlag,
    totalFilesWorkingOrSuccess,
    getFirstTickTimestamp,
    totalBytesWorkingOrSuccess,
    currentBytesWorkingOrSuccess
  ])

  // if(!allFilesArr.length){
  //   prevTimestampMsRef.current = null
  //   prevBytesWorkingOrSuccessRef.current = null
  // }
  const uploadsComplete = finalized

  return {
    groupId,
    uploadsComplete,
    totalFilesWorkingOrSuccess,
    currentBytesWorkingOrSuccess,
    totalBytesWorkingOrSuccess,
    totalFiles,
    totalFilesError,
    timeRemaining
  }
}
