import styled from 'styled-components/macro'

const Subtitle = styled.h2`
  font-family: Gotham_Book;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  font-weight: 100;
`

export default Subtitle
