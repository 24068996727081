import styled from 'styled-components/macro'

const Subtitle = styled.h2`
  font-family: Gotham_Book;
  letter-spacing: -0.4px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export default Subtitle
