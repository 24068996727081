import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Outer = styled(animated.div)`
  top: 50%;
  left: 50%;
  position: absolute;
  font-family: Gotham;
  font-weight: bold;
  font-spacing: -0.4;
  font-size: 36px;
  line-height: 20px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  max-width: 420px;
  width: 100%;
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  @media (max-width: 400px) {
    max-width: 312px;
  }
`

export default Outer
