import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Router from 'domains/shared/Router'
import LoanRecommender from 'domains/LoanRecommender'
import BizDash from 'domains/BizDash'
import { UrlTracker } from 'lib/UrlTracker'

import PublicAuth from 'domains/PublicAuth'
import SimpleHeaderSidebarLayout from 'domains/shared/SimpleHeaderSidebarLayout'
import ConsumerPortal from 'domains/ConsumerPortal'
import MemberFileUploadPortal from 'domains/MemberFileUploadPortal'
import MemberNavRightContent from 'domains/MemberNavRightContent'
import ApolloProvider from 'domains/shared/ApolloProvider'
import getApolloClient from 'domains/shared/ApolloClient'

const client = getApolloClient()

// function NoMatch() {
//   return <div>This path does not exist</div>
// }

export default function AppRouter() {
  return (
    <Router>
      <ApolloProvider client={client}>
        <UrlTracker
          render={() => (
            <Switch>
              <Route path="/loai-recommendation" component={LoanRecommender} />
              <Route
                path="/(auth|consumer-portal)"
                render={() => (
                  <SimpleHeaderSidebarLayout
                    rightContentComponent={MemberNavRightContent}
                  >
                    <Switch>
                      <Route path="/auth" component={PublicAuth} />
                      <Route
                        path="/consumer-portal"
                        component={ConsumerPortal}
                      />
                    </Switch>
                  </SimpleHeaderSidebarLayout>
                )}
              />
              <Route
                path="/(admin-sign-in|disclosures|personas|loanofficers|campaigns)"
                component={BizDash.routes}
              />
              <Route
                path="/(member-upload-portal-test)"
                component={MemberFileUploadPortal.routes}
              />
              <Redirect from="*" to="/consumer-portal/doc-manager/upload" />
            </Switch>
          )}
        />
      </ApolloProvider>
    </Router>
  )
}
