import styled from 'styled-components/macro'

const BtnBase = styled.a`
  cursor: pointer;
  display: block;
  height: 30px;
  padding: 7px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #8c8c8c;
  background-color: ${({ selected }) => (selected ? 'white' : '#a0a0a0')};
  font-size: 16px;
  line-height: 16px;
  color: ${({ selected }) => (selected ? '#3cb9f5' : '#f5f5f5')};
  &:hover {
    color: ${({ selected }) => (selected ? '#2d92c3' : 'white')};
    background-color: ${({ selected }) => (selected ? 'white' : '#b7b7b7')};
  }
`

export default BtnBase
