import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import useMeasure from 'lib/useMeasure'

export const Container = styled.div`
  position: absolute;
  margin-top: 10px;
  transform: translateY(-12px);
`

export const Content = styled.div`
  color: gray;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  background: white;
`
export const HideShow = styled.div`
  ${props => {
    return props.show
      ? `
          display: block;
      `
      : `
        display: none;
      `
  }}
`
export const InnerContent = styled.div`
  display: flex;
`
export const CaretContainer = styled.div`
  position: absolute;
  fill: white;
  height: 24px;
  width: 100%;
  pointer-events: none;
`

// const SPACES_INDEX_MAP = {
//   '0': 'up',
//   '1': 'right',
//   '2': 'down',
//   '3': 'left',
// };

// const DIR_INDEX_MAP = {
//   up: 0,
//   right: 1,
//   down: 2,
//   left: 3,
// };

// const MARGIN = 24;
export const CARET_HEIGHT = 24
export const BORDER_RADIUS = 8

// /**
//  * Determines the main direction the flyout opens
//  */
// export function getMainDir(flyoutSize, position, triggerRect, windowSize) {
//   // Chose the main direction for the flyout based on available spaces & user preference
//   let mainDir
//   // if (idealDirection && spaces[DIR_INDEX_MAP[idealDirection]] > 0) {
//   //   // user pref
//   //   mainDir = idealDirection;
//   // } else {
//   //   // If no direction pref, chose the direction in which there is the most space available
//   //   mainDir = SPACES_INDEX_MAP[spaces.indexOf(max)];
//   // }
//   return mainDir
// }

/**
 * Calculates flyout and caret offsets for styling
 */
export function adjustOffsets(base) {
  const flyoutLeft = base.left
  const flyoutTop = base.top
  return {
    flyoutOffset: {
      top: flyoutTop,
      left: flyoutLeft
    }
  }
}

/* Calculates baseline top and left offset for flyout */
export function baseOffsets(
  relativeOffset,
  flyoutSize,
  position,
  triggerRect,
  windowSize
) {
  // TOP OFFSET
  let top
  if (position === 'center') {
    top =
      windowSize.scrollY +
      (triggerRect.bottom - triggerRect.height / 2 - flyoutSize.height / 2) -
      2
  } else if (position === 'bottom-left') {
    top = windowSize.scrollY + triggerRect.bottom
  } else if (position === 'bottom') {
    top = windowSize.scrollY + triggerRect.bottom
  } else {
    // left and right
    top = windowSize.scrollY + triggerRect.top
  }

  // LEFT OFFSET
  let left
  if (position === 'center') {
    left =
      windowSize.scrollX +
      (triggerRect.left + triggerRect.width / 2 - flyoutSize.width / 2)
  } else if (position === 'bottom-left') {
    left = windowSize.scrollX + triggerRect.left - flyoutSize.width
  } else if (position === 'bottom') {
    left =
      windowSize.scrollX +
      (triggerRect.left + triggerRect.width / 2 - flyoutSize.width / 2)
  } else {
    // down and up
    left = windowSize.scrollX + triggerRect.left
  }

  // Adjusts for the relative parent container
  top -= relativeOffset.y
  left -= relativeOffset.x
  return { top, left }
}

const getFlyoutPosition = props => {
  const {
    relativeOffset,
    position,
    positionRelativeToAnchor,
    triggerRect,
    width,
    flyout
  } = props

  const windowSize = {
    height: window.innerHeight,
    width: window.innerWidth,
    scrollY: positionRelativeToAnchor ? 0 : window.scrollY, // scroll not needed for relative elements
    scrollX: positionRelativeToAnchor ? 0 : window.scrollX
  }

  const flyoutSize = {
    height: flyout ? flyout.height : 0,
    width
  }

  // Gets the base offset that positions the flyout based on the position only
  const base = baseOffsets(
    relativeOffset,
    flyoutSize,
    position,
    triggerRect,
    windowSize
  )

  // Adjusts for the subdirection of the caret
  const { flyoutOffset } = adjustOffsets(base)

  return {
    flyoutOffset
  }
}

// state = {
//   flyoutOffset: {
//     top: undefined,
//     right: undefined,
//     bottom: undefined,
//     left: undefined
//   }
//   // caretOffset: {
//   //   top: undefined,
//   //   right: undefined,
//   //   bottom: undefined,
//   //   left: undefined
//   // },
//   // position: null
// }

const Contents = props => {
  const flyoutRef = useRef(null)
  const flyoutBounds = useMeasure(flyoutRef)
  const { children, width, onClick, onResize } = props

  useEffect(() => {
    document.addEventListener('click', onClick, true)
    window.addEventListener('resize', onResize)
    return () => {
      document.removeEventListener('click', onClick, true)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const { flyoutOffset } = getFlyoutPosition({ flyout: flyoutBounds, ...props })

  return (
    <Container style={{ ...flyoutOffset }}>
      <CaretContainer />
      <Content ref={flyoutRef} tabIndex={-1}>
        <InnerContent style={{ width }}>{children}</InnerContent>
      </Content>
    </Container>
  )
}

export default Contents
