/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import PatternBg from './styled/PatternBg'
import Overlay from './styled/Overlay'

function NetworkPattern({ style }) {
  return (
    <Outer style={style}>
      <PatternBg />
      <Overlay />
    </Outer>
  )
}

export default NetworkPattern
