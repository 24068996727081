/**
 *
 * Content.js
 *
 */

import styled from 'styled-components/macro'

const AppContentContainer = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr 100px;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    'header header header'
    'sidebar body .';
  min-height: 100vh;
  color: #00023f;
  ${props => props.theme.font('regular')}
  background: linear-gradient(to bottom right, #4100F5, #3A02D5);
  @media (max-width: 1155px) {
    grid-template-columns: 50px 1fr 50px;
  }
  @media (max-width: 760px) {
    grid-template-columns: 10px 1fr 10px;
  }
`

export default AppContentContainer
