import React, { useRef, memo, useEffect } from 'react'

const FileValidator = memo(function FileValidatorComp({
  validateFile,
  updateFiles,
  children,
  ...rest
}) {
  const file = rest
  const { id } = file

  useEffect(() => {
    let cancelled
    function executeUpdateFiles(...args) {
      if (!cancelled) {
        updateFiles(...args)
      }
    }

    function markValid() {
      executeUpdateFiles({
        [id]: {
          validationSuccess: true,
          frontendValidationErrors: []
        }
      })
    }

    function markInvalid(frontendValidationErrors) {
      executeUpdateFiles({
        [id]: {
          status: 'error',
          validationSuccess: false,
          frontendValidationErrors
        }
      })
    }

    validateFile({
      file,
      markValid,
      markInvalid,
      updateFiles: executeUpdateFiles
    })

    return () => {
      cancelled = true
    }
  }, [file, validateFile, updateFiles])
})

// eslint-disable-next-line react/no-multi-comp
const FilesValidator = memo(function FilesValidatorComp({
  files,
  validateFile,
  updateFiles
}) {
  // very important!
  // we remove the errors array because
  // the validateFile func has the potential
  // to continually immutably replace
  // any frontendValidationErrors arrays set via markInvalid
  return files.map(({ frontendValidationErrors, ...file }) => {
    return (
      <FileValidator
        key={file.id}
        {...file}
        validateFile={validateFile}
        updateFiles={updateFiles}
      />
    )
  })
})

export default function useValidateFileOnChange({
  files,
  validateFile,
  updateFiles
}) {
  const portalElement = useRef()

  if (!portalElement.current) {
    portalElement.current = document.createElement('div')
  }

  return {
    validatorRunner: (
      <FilesValidator
        files={files}
        validateFile={validateFile}
        updateFiles={updateFiles}
      />
    )
  }
}
