/* eslint no-multi-assign: 0 */
import React, { memo } from 'react'
import { path } from 'ramda'
import Outer from './styled/Outer'
import TitleArea from './styled/TitleArea'
import Title from './styled/Title'
import Subtitle from './styled/Subtitle'
import ConfScore from './styled/ConfScore'
import DataContent from './styled/DataContent'
import LoansOverview from './components/LoansOverview'
import PersonalMetrics from './components/PersonalMetrics'
import PeerMatchPersona from './components/PeerMatchPersona'
import SalesGuidance from './components/SalesGuidance'
import Feedback from './components/Feedback'

// TEMPORARY FOR TESTING

function Content({
  enableScrollableContent,
  mainTitleStyles,
  dataContentStyles,
  loaiData,
  loaiDataError,
  updateLocalLoanRecommendationData
}) {
  const outerPosition = enableScrollableContent ? 'absolute' : 'fixed'

  const score = parseInt(loaiData.confidenceScore, 10) || 0
  const errorStatus = path(['response', 'status'], loaiDataError)

  return (
    <Outer position={outerPosition}>
      <TitleArea {...mainTitleStyles}>
        {!loaiDataError ? (
          <>
            <Title>Here is my loan recommendation.</Title>
            <Subtitle>
              <ConfScore>{score}</ConfScore> Confidence Score
            </Subtitle>
          </>
        ) : (
          <>
            <Title>
              {errorStatus === 404
                ? `Hmmm. I'm not sure how you got here.`
                : `Error computing recommendation...`}
            </Title>
            <Subtitle>
              {errorStatus === 404
                ? `Please contact support if you think this url is accurate. (trust me, it's not though, I checked...)`
                : `While the machine was learning, it encountered an error. Don't worry, contact support at support@lower.com.`}
            </Subtitle>
          </>
        )}
      </TitleArea>
      {!loaiDataError && (
        <DataContent {...dataContentStyles}>
          <LoansOverview
            score={score}
            loaiData={loaiData}
            readyToAnimate={enableScrollableContent}
          />
          <PersonalMetrics loaiData={loaiData} />
          <PeerMatchPersona loaiData={loaiData} />
          <SalesGuidance loaiData={loaiData} />
          <Feedback
            loaiData={loaiData}
            updateLocalLoanRecommendationData={
              updateLocalLoanRecommendationData
            }
          />
        </DataContent>
      )}
    </Outer>
  )
}

export default memo(Content)
