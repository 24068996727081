import React, { useState } from 'react'
import posed from 'react-pose'
import styled from 'styled-components/macro'
import GlowUpButton from 'domains/BizDash/Shared/components/GlowUpButton'

const StyledForm = styled.form`
  button {
    ${GlowUpButton}
    width: 100%;
  }
`

const StyledLabel = styled.label`
  transition: all 0.3s ease-in-out;
  opacity: 0;
  font-size: 12px;
  margin-bottom: 6px;
  ${props =>
    props.shown &&
    `
    opacity: 1
  `}
  input {
    display: block;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    border-bottom: 1px solid rgba(77, 255, 201, 0.5);
    ${props =>
      props.shown
        ? `
      border-bottom: 1px solid rgba(77,255,201, 1);
    `
        : `
      &:focus {
        border-bottom: 1px solid rgba(77,255,201, 1);
      }
    `}
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 15px;
    font-size: 16px;
    ::placeholder {
      ${props => props.theme.font('regular')}
      color: #FFF;
      opacity: 1;
    }
  }
`

const InputGroup = styled.div`
  margin-bottom: 70px;
`

const ModalHeader = styled.h1`
  color: white;
  font-size: 36px;
`

const FormItemChild = posed.div({
  enter: { opacity: 1, x: 0, transition: { duration: 200 } },
  exit: { opacity: 0, x: -100, transition: { duration: 200 } },
  beforeEnter: { opacity: 0, x: 100 }
})

const UserLoginForm = ({ onSubmit }) => {
  const [form, setValues] = useState({
    email: '',
    password: ''
  })

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(form)
  }

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputGroup>
        <FormItemChild>
          <ModalHeader>Login to your account</ModalHeader>
        </FormItemChild>
        <FormItemChild>
          <StyledLabel shown={form.email !== ''}>
            Email Address
            <input
              name="email"
              type="text"
              label="Email"
              placeholder="Email Address"
              onChange={updateField}
            />
          </StyledLabel>
        </FormItemChild>
        <FormItemChild>
          <StyledLabel shown={form.password !== ''}>
            Password
            <input
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              onChange={updateField}
            />
          </StyledLabel>
        </FormItemChild>
        <FormItemChild>
          <div>
            <button type="submit">LOGIN</button>
          </div>
        </FormItemChild>
      </InputGroup>
    </StyledForm>
  )
}

export default UserLoginForm
