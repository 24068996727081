import styled from 'styled-components/macro'

const Note = styled.p`
  font-family: Gotham_Book;
  font-weight: 200;
  font-size: 11px;
  letter-spacing: -0.07px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777777;
`

export default Note
