import React, { memo } from 'react'
import { DotOrbit, DotOrbitInner } from './styled/DotOrbit'
import DotPlanet from './styled/DotPlanet'

export default memo(function Dot({
  distancePx,
  size,
  opacity,
  item: { singleRevolutionDurMs, angleDegrees }
}) {
  return (
    <DotOrbit
      style={{
        width: distancePx.interpolate(
          [-1, 0, 1, 2],
          ['-2px', '0px', '2px', '4px']
        ),
        height: distancePx.interpolate(
          [-1, 0, 1, 2],
          ['-2px', '0px', '2px', '4px']
        ),
        opacity
      }}
    >
      <DotOrbitInner
        angleDegrees={angleDegrees}
        singleRevolutionDurMs={singleRevolutionDurMs}
      >
        <DotPlanet
          style={{
            width: size.interpolate(x => `${x}px`),
            height: size.interpolate(x => `${x}px`)
          }}
        />
      </DotOrbitInner>
    </DotOrbit>
  )
})
