import React from 'react'
import styled from 'styled-components/macro'

const ResponsiveSvg = ({ children, wRatio, hRatio, maxWidth, className }) => {
  const paddingBottomFromRatio = (hRatio / wRatio) * 100
  return (
    <SvgOutter maxWidth={maxWidth} className={className}>
      <StyledSvgContainer paddingBottomFromRatio={paddingBottomFromRatio}>
        {children}
      </StyledSvgContainer>
    </SvgOutter>
  )
}

const SvgOutter = styled.div`
  width: 100%;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
`

const StyledSvgContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  svg {
    position: absolute;
    overflow: visible;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  padding-bottom: ${props => props.paddingBottomFromRatio}%;
`

export default ResponsiveSvg
