import styled from 'styled-components/macro'

const Underline = styled.div`
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  height: ${({ height }) => height || '1px'};
  display: block;
  width: 100%;
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  left: 0;
  background: ${({ solid = true, color = '#AAAAAA' }) =>
    solid
      ? color
      : ` repeating-linear-gradient(to right,
        ${color},
        ${color} 3px,
        transparent 3px,
        transparent 15px)
    `};
`

export default Underline
