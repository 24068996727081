import styled from 'styled-components/macro'

const Outer = styled.a`
  display: block;
  padding: 8px 12px 5px 12px;
  border-radius: 4px;
  color: #00023f;
  font-family: Gotham;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  &:hover {
    background-color: #f2f2f2;
  }
`

export default Outer
