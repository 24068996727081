import React, { useEffect, useLayoutEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import useGlobalStyleAdjuster from 'domains/shared/GlobalStyle/usable/useGlobalStyleAdjuster'
// import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'
// import { currentUserPresent } from 'domains/CurrentUser/helpers'
import { useGetUrl } from 'lib/UrlTracker'
import { withFormProvider, useFormActions, Form } from 'lib/useForm'
import delay from 'lib/delay'
import { useNotificationsActions } from 'lib/notifications'
import {
  // ErrorNotification,
  InfoNotification,
  lockIconPath
} from 'components/Notifications'
import PhonePage from './components/PhonePage'
import VerifyCodePage from './components/VerifyCodePage'
import { useThemeForAfterLoginPath } from './themes'

import Outer from './styled/Outer'
import ColumnsOuter from './styled/ColumnsOuter'
import MainArea from './styled/MainArea'
import Pretitle from './styled/Pretitle'
import PreviewArea from './styled/PreviewArea'

import useVerifyPhone from './useable/useVerifyPhone'
import useVerifyCode from './useable/useVerifyCode'

function PublicAuth({ match: { url: matchUrl } }) {
  // eslint-disable-next-line
  // const [{ currentUser }, { fetchCurrentUserIfNeeded }] = useCurrentUser()
  // fetchCurrentUserIfNeeded()

  const { updateStyles } = useGlobalStyleAdjuster()
  useLayoutEffect(() => {
    updateStyles({
      body: {
        bgColor: '#FFFFFF'
      }
    })
  }, [updateStyles])

  const {
    queryStringParams: { after_login_path: afterLoginPath } = {},
    location,
    history
  } = useGetUrl()

  const {
    pretitle,
    // introTips,
    mainComponent: MainSidePreview
  } = useThemeForAfterLoginPath({ afterLoginPath })

  const formActions = useFormActions()
  const notificationActions = useNotificationsActions()

  const {
    submitVerifyPhone,
    submitVerifyPhoneResponseData: { referenceId } = {}
  } = useVerifyPhone({
    formActions,
    notificationActions
  })

  const {
    submitVerifyCode,
    submitVerifyCodeResponseData: { valid: isCodeValid } = {}
  } = useVerifyCode({
    referenceId,
    formActions,
    notificationActions
  })

  const { search: queryString, pathname } = location

  // console.log('auth rendered!', Date.now())
  const { upsertNote } = notificationActions

  const showSecurityNote = async () => {
    await delay(400)
    upsertNote({
      id: 'SECURITY_LOGIN_NOTE',
      component: InfoNotification,
      stretchToMaxWidth: '383px',
      textOuterMaxWidth: '247px',
      mainText:
        'For your security we use multiple ways to verify your identity.',
      iconPath: lockIconPath
    })
  }

  useEffect(() => {
    showSecurityNote()
  }, [])

  useEffect(() => {
    // Ensure we are on the right page based on
    // if we have a referenceId and the current page
    if (referenceId && pathname === `${matchUrl}/phone`) {
      history.push(`${matchUrl}/verify-code${queryString}`)
      return
    }
    if (!referenceId && pathname === `${matchUrl}/verify-code`) {
      history.replace(`${matchUrl}/phone${queryString}`)
      return
    }
    if (isCodeValid) {
      const nextPath = afterLoginPath || '/consumer-portal'
      history.replace(nextPath)
    }
  }, [referenceId, isCodeValid, pathname])

  // if (currentUserPresent({ currentUser })) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/consumer-portal'
  //       }}
  //     />
  //   )
  // }

  return (
    <Outer>
      <ColumnsOuter>
        <MainArea>
          <Pretitle>{pretitle}</Pretitle>
          <Switch>
            <Route
              path={`${matchUrl}/phone`}
              render={() => (
                <Form name="phonePage" onSubmit={submitVerifyPhone}>
                  <PhonePage />
                </Form>
              )}
            />
            <Route
              path={`${matchUrl}/verify-code`}
              render={() => (
                <Form name="codePage" onSubmit={submitVerifyCode}>
                  <VerifyCodePage />
                </Form>
              )}
            />
            <Redirect
              from="*"
              to={{ ...location, pathname: `${matchUrl}/phone` }}
            />
          </Switch>
        </MainArea>
        <PreviewArea>
          <MainSidePreview />
        </PreviewArea>
      </ColumnsOuter>
    </Outer>
  )
}

export default withFormProvider(PublicAuth)
