import styled, { css } from 'styled-components/macro'
import { removeAutofillColors } from 'components/helpers/styledHelpers'
import propOrDefault from 'lib/propOrDefault'

const defaultFontFamily = 'Gotham'

const TextInput = styled.input`
  z-index: 1;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  width: ${({ width }) => width || 'auto'};
  appearance: none;
  display: inline-block;
  padding: 0;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  min-width: ${({ minWidth }) => minWidth || '50px'};
  border: none;
  border-radius: none;
  font-family: ${({ fontFamily }) => fontFamily || defaultFontFamily};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  outline: none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  margin-left: ${propOrDefault('marginLeft', 0)};


  font-weight: ${({ fontWeight }) => fontWeight || '200'};
  &::-webkit-contacts-auto-fill-button {
    position: absolute;
    right: -10px;
    top: -10px;
  }
  &::-ms-clear, &::-ms-reveal {
    display: none !important;
  }

  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};

  opacity: ${({ opacity }) => opacity || '1'};
  ${({
    color = '#00023F',
    placeHolderColor = color,
    placeHolderLetterSpacing = 'normal',
    fontFamily = defaultFontFamily,
    placeHolderFontFamily = fontFamily
  }) => `
    color: ${color};
    &::placeholder{
      color: ${placeHolderColor};
      opacity: .2;
      letter-spacing: ${placeHolderLetterSpacing};
      font-family: ${placeHolderFontFamily};
    }
  `}
  ${({ inputCss }) => inputCss || ''}
  font-size: ${({ fontSize, computedFontSize }) =>
    (computedFontSize && `${computedFontSize}px`) || fontSize || '45px'};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};

  ${removeAutofillColors}

  ${({ pointerEvents }) =>
    pointerEvents
      ? css`
          pointer-events: ${pointerEvents};
        `
      : ''}

  ${({ customCss }) => customCss}
`

export default TextInput
