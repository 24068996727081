import { useRef, useCallback } from 'react'

const emptyObj = {}
export default function useKeysDownTracker() {
  const keysRef = useRef({})
  const trackKeyDown = useCallback((e, config = emptyObj) => {
    const { key, keyCode } = e
    keysRef.current[keyCode] = { key, keyCode }
    return { ...config, keysDown: keysRef.current }
  }, [])
  const trackKeyUp = useCallback((e, config = emptyObj) => {
    keysRef.current = {}
    return { ...config, keysDown: keysRef.current }
  }, [])

  return {
    trackKeyDown,
    trackKeyUp
  }
}
