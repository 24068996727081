/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'
import Svg from './styled/Svg'

function ShieldSwatch() {
  return (
    <Outer>
      <Svg width="13px" height="15px" viewBox="0 0 13 15" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-981.000000, -719.000000)" fill="#FFFFFF">
            <g transform="translate(962.000000, 702.000000)">
              <g transform="translate(0.000000, 1.000000)">
                <path d="M25.5,31 C25.5,31 32,28 32,23.5 L32,17.5 L25.5,16 L19,17.5 L19,23.5 C19,28 25.5,31 25.5,31 Z" />
              </g>
            </g>
          </g>
        </g>
      </Svg>
    </Outer>
  )
}

export default ShieldSwatch
