/*
 * ResultsArea
 *
 */
import React from 'react'
import styled from 'styled-components/macro'
import Outer from './styled/Outer'
import HeaderRow from './styled/HeaderRow'
import {
  DefaultHeaderRenderer,
  DefaultCellRenderer,
  StatusCellRenderer,
  StatusHeaderRenderer,
  DetailCellRenderer
} from './components/CellRenderer'
import VirtualAutosizeScrolledTable from '../VirtualAutosizeScrolledTable'
import ResourceData from './components/CellRenderer/styled/ResourceData'
import CellFormat from './components/CellFormatter'
import formatDisclosureDate from './format'
import useDisclosures from '../../../DisclosureQueue/useable/useDisclosures'

const NoneFound = styled.div`
  margin: 40px 0;
`

const cellWidths = {
  filename: 500,
  status: 140,
  encompass_attachment_id: 160,
  created_at: 160,
  detail: 100
}

const cellFlexGrow = {
  filename: 1,
  status: 0,
  encompass_attachment_id: 1,
  created_at: 0,
  detail: 0
}

const createFormattedDataCellRenderer = opts => ({ cellData }) => (
  <ResourceData>
    <CellFormat {...opts} cellData={cellData} />
  </ResourceData>
)

const cellRenderers = {
  defaultHeaderRenderer: DefaultHeaderRenderer,
  statusHeaderRenderer: StatusHeaderRenderer,
  defaultCellRenderer: DefaultCellRenderer,
  statusCellRenderer: StatusCellRenderer,
  detailCellRenderer: DetailCellRenderer,
  created_atCellRenderer: createFormattedDataCellRenderer({
    makeFormatter: () => {
      return formatDisclosureDate
    }
  })
}

const ResultsArea = ({ filter }) => {
  const {
    disclosures,
    paging,
    loading,
    fetchMoreDisclosures,
    resetDisclosure,
    downloadDisclosure
  } = useDisclosures({ filter })
  const dataItems = disclosures

  if (loading && (!dataItems || dataItems.length === 0)) {
    return <div />
  }

  const fetchMore = () => {
    fetchMoreDisclosures({ filter, cursor: paging.cursor })
  }

  const getCellRenderer = dataKey =>
    (dataKey && cellRenderers[`${dataKey}CellRenderer`]) ||
    cellRenderers.defaultCellRenderer

  const getHeaderRenderer = dataKey =>
    (dataKey && cellRenderers[`${dataKey}HeaderRenderer`]) ||
    cellRenderers.defaultHeaderRenderer

  const getCellWidth = dataKey => cellWidths[dataKey]
  const getCellFlexGrow = dataKey => cellFlexGrow[dataKey]

  const { totalItems } = paging
  const totalItemsParsed = parseInt(totalItems, 10) || disclosures.length

  const isFetchingNewDataset = loading
  const selectedColumns = [
    'status',
    'filename',
    'created_at',
    'encompass_attachment_id',
    'detail'
  ]
  const isRowLoaded = index => {
    return !!dataItems[index]
  }

  const getColumnData = () => {
    return {
      reset: resetDisclosure,
      download: downloadDisclosure
    }
  }

  const fetchMoreItems = () => {
    if (!paging.isLast) fetchMore()
  }

  if (!dataItems || dataItems.length === 0) {
    return (
      <NoneFound>
        No disclosures found in that queue{' '}
        <span role="img" aria-label="uhoh">
          🙃
        </span>
      </NoneFound>
    )
  }

  return (
    <Outer>
      <VirtualAutosizeScrolledTable
        listCacheId="all-disclosures"
        isFetchingNewDataset={isFetchingNewDataset}
        headerHeight={48}
        minimumBatchSize={1000}
        rowHeight={47}
        isRowLoaded={isRowLoaded}
        headerRowComponent={HeaderRow}
        items={dataItems}
        totalItems={totalItemsParsed}
        getCellRenderer={getCellRenderer}
        getHeaderRenderer={getHeaderRenderer}
        getCellWidth={getCellWidth}
        getCellFlexGrow={getCellFlexGrow}
        columnDataKeys={selectedColumns}
        getColumnData={getColumnData}
        loadMoreRows={fetchMoreItems}
      />
    </Outer>
  )
}

export default ResultsArea
