import styled from 'styled-components/macro'

const BarOutline = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: ${({ height }) => height || '10px'};
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'none'};
  border-radius: 5px;
`

export default BarOutline
