import React from 'react'
import SubmitInput from '../Shared/SubmitInput'
import useLoanUploadCodeLogin from '../graphql/hooks/useLoanUploadCodeLogin'
import useSignoutLoanUploadCodeUser from '../graphql/hooks/useSignoutLoanUploadCodeUser'
import useCurrentLoanUploader from '../graphql/hooks/useCurrentLoanUploader'

const testUploadForm = () => {
  const { login, error, loading } = useLoanUploadCodeLogin()
  const {
    verified,
    loading: currentLoanUploaderLoading,
    error: currentLoanUploaderError
  } = useCurrentLoanUploader()
  const { logout } = useSignoutLoanUploadCodeUser()

  return verified ? (
    <div>
      <h1>LOGGED IN!!</h1>
      <button type="button" onClick={logout}>
        Logout
      </button>
    </div>
  ) : login ? (
    <div>
      {currentLoanUploaderLoading && 'Loading Current User...'}
      {loading && 'Verifying Code...'}
      {error && JSON.stringify(error)}
      {currentLoanUploaderError && JSON.stringify(currentLoanUploaderError)}
      <SubmitInput label="code" onSubmit={uploadCode => login(uploadCode)} />
    </div>
  ) : (
    <div>Something went wrong</div>
  )
}

export default testUploadForm
