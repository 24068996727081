function clean(value) {
  const digits = value.replace(/[^\d]/g, '').slice(0, 10)
  let out = ''
  const matched = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
  // first if value.length,
  // add "(" as first char if doesn't exist

  if (matched[1]) {
    out += `(${matched[1]}`
  }

  if (matched[1].length >= 3) {
    out += `) ${matched[2]}`
  }

  if (matched[2].length >= 3) {
    out += ` ${matched[3]}`
  }
  return out
}

export default function formatPhone({ value }) {
  return { value: clean(value) }
}

// const ctrlDown = false,
//     ctrlKey = 17,
//     cmdKey = 91,
//     vKey = 86,
//     cKey = 67

// export default function formatPhoneWithCaret(props){
//   const {prevValue, value, event, getImmediateCaretPosition} = props
//   let newCaret

//   const digits = value.replace(/[^\d]/g, '').slice(0, 10)
//   let out = clean(value)
//   const {caret} = getImmediateCaretPosition()
//   // first if value.length,
//   // add "(" as first char if doesn't exist
//   let totalAdded = 0

//   if(event.keyCode === 8 || event.keyCode === 46){
//     if(caret === 5 && out.slice(4,6) === ') '){
//       newCaret = caret - 1
//       out = clean(out.slice(0, 2) + out.slice(3))

//     }
//     else if(caret === 9 && out.slice(9,10) === ' '){
//       out = clean(out.slice(0, 8) + out.slice(9))
//       newCaret = caret - 1
//     }
//     else{
//       console.log('catch all')
//       newCaret = caret
//     }
//   }
//   else{
//     if(out !== value && out.length > 1){
//       if(caret === 5){
//         totalAdded = 2
//       }
//       else if(caret === 9){
//         totalAdded = 1
//       }
//       // else{
//       //   totalAdded = 1
//       // }
//       newCaret = totalAdded + caret
//     }
//   }

//   return {value: out, caret: newCaret}
// }
