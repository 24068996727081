import React, { useRef } from 'react'
import posed from 'react-pose'
import styled from 'styled-components/macro'
import { Flyout } from '../../Flyout'
import PortalPop from '../../PortalPopup'

const UserMenuFlyoutMenuContainer = styled.div`
  position: relative;
`

const FlyoutContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  user-select: none;
`

const FlyoutContainer = posed.div(props => {
  return {
    closed: {
      opacity: 0,
      scale: 0.6,
      y: 0,
      originX: props.origin[1],
      originY: props.origin[0],
      applyAtEnd: { display: 'none' }
    },
    open: {
      opacity: 1,
      scale: 1,
      y: 0,
      staggerChildren: 50,
      originX: props.origin[1],
      originY: props.origin[0],
      applyAtStart: { display: 'block' }
    }
  }
})

const AnchorRef = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
`

const UserMenuFlyoutMenu = props => {
  const anchorRef = useRef(null)
  const { render, open, handleDismiss, children } = props
  const renderChildren = render ? render() : children
  return (
    <UserMenuFlyoutMenuContainer>
      <AnchorRef ref={anchorRef}>
        <PortalPop anchor={anchorRef.current}>
          <FlyoutContainer
            initialPose="closed"
            origin={['38px', '-18px']}
            pose={open ? 'open' : 'closed'}
          >
            <Flyout
              show
              anchor={anchorRef}
              position="bottom-left"
              onDismiss={handleDismiss}
              size="md"
            >
              <FlyoutContent>{renderChildren}</FlyoutContent>
            </Flyout>
          </FlyoutContainer>
        </PortalPop>
      </AnchorRef>
    </UserMenuFlyoutMenuContainer>
  )
}

UserMenuFlyoutMenu.propTypes = {}

export default UserMenuFlyoutMenu
