import styled from 'styled-components/macro'

const IconBottomRight = styled.img`
  position: absolute;
  bottom: 15px;
  right: 16px;
  ${({ customCss }) => customCss}
`

export default IconBottomRight
