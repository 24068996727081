import styled, { css } from 'styled-components/macro'
// import { animated } from 'react-spring'

const Outer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 24px auto;
  flex-wrap: wrap;
  max-width: 1034px;
  @media (max-width: 1160px) {
    max-width: 729px;
  }
  @media (max-width: 810px) {
    max-width: 470px;
  }
  ${({ isTwoColumnMaxWidth }) =>
    isTwoColumnMaxWidth
      ? css`
          max-width: 841px;
          justify-content: start;
          @media (max-width: 1160px) {
            max-width: 729px;
          }
          @media (max-width: 810px) {
            max-width: 470px;
          }
        `
      : null}
`

export default Outer
