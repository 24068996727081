import React, { useState } from 'react'

const SubmitInput = ({ label, onSubmit }) => {
  const [val, setVal] = useState('')

  const handleSubmit = evt => {
    evt.preventDefault()
    onSubmit(val)
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor={label}>
        {label}:
        <input
          type="text"
          name={label}
          value={val}
          onChange={e => setVal(e.target.value)}
        />
      </label>
      <input type="submit" value="Submit" />
    </form>
  )
}

export default SubmitInput
