import React, { useState } from 'react'
import Card from '../../shared/components/Card'
import {
  PlusLineVertical,
  PlusLineHorizontal,
  PlusLineContainer
} from './styled/PlusLine'
import LabelContainer from './styled/LabelContainer'
import Wrapper from '../../styled/Wrapper'

export default function PlusTile({ onClick, outerCss, cardCss, label }) {
  const [hovered, setHovered] = useState()

  return (
    <Wrapper moveUp={hovered} customCss={outerCss}>
      <Card
        cursor="pointer"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        aria-label="add file button"
        backgroundColor="#E7EAEC"
        customCss={cardCss}
      >
        <LabelContainer>
          <PlusLineContainer>
            <PlusLineVertical />
            <PlusLineHorizontal />
          </PlusLineContainer>
          {label}
        </LabelContainer>
      </Card>
    </Wrapper>
  )
}
