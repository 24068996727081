import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Outer = styled(animated.div)`
  width: 106px;
  height: 106px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default Outer
