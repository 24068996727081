import React, { Fragment } from 'react'
import {
  useDropdownsData,
  useDropdownsActions
} from 'lib/DropdownsManager/useDropdowns'

export default function DropdownsDisplayer() {
  const { sortedDropdowns } = useDropdownsData()
  const actions = useDropdownsActions()

  // sortedDropdowns.map((note)=>{
  //   console.log(note)
  // })

  return sortedDropdowns.map(({ render, component: Comp, ...rest }) => {
    if (render) {
      return (
        <Fragment key={rest.id}>{render({ ...rest, ...actions })}</Fragment>
      )
    }
    if (Comp) {
      return <Comp key={rest.id} {...rest} {...actions} />
    }
    return null
  })
}
