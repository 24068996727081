import React, { useCallback, useEffect } from 'react'
import { isNil } from 'ramda'
import errorIconPath from './images/error-icon.png'
import Notification from './components/Notification'

import lockIconPath from './images/lock-icon.png'
import uploadIconPath from './images/upload-icon.png'
import checkmarkIconPath from './images/checkmark-icon.png'

export { lockIconPath, uploadIconPath, checkmarkIconPath }

const errorStyles = {
  backgroundColor: '#FF3365',
  outerIconRingColor: '#FF6F92'
}
const animationDurationMsDefault = 400

function useDismissAnimationFlow({
  dismissingNote,
  dismissNote,
  autoDismissAfterMs,
  animationDurationMs,
  visualStatus,
  isFirstNonDismissedNote,
  id
}) {
  const visible = visualStatus !== 'dismissing' && visualStatus !== 'dismissed'

  const dismissAnimationFlow = useCallback(() => {
    if (visible) {
      dismissingNote(id)
    }
  }, [visible, id])

  useEffect(() => {
    let t
    if (visualStatus === 'dismissing') {
      t = setTimeout(() => {
        dismissNote(id)
      }, animationDurationMs)
    }
    return () => clearTimeout(t)
  }, [visualStatus, id])

  useEffect(() => {
    let t
    if (visible && isFirstNonDismissedNote && !isNil(autoDismissAfterMs)) {
      t = setTimeout(() => dismissingNote(id), autoDismissAfterMs)
    }
    return () => clearTimeout(t)
  }, [visible, isFirstNonDismissedNote, autoDismissAfterMs, id])

  return {
    dismissAnimationFlow
  }
}

export function ErrorNotification({
  id,
  mainText,
  subtext,
  stretchToMaxWidth,
  textPaddingRight,
  visualStatus,
  shakeKey,
  isFirstNonDismissedNote,
  textOuterMaxWidth,
  dismissingNote,
  dismissNote,
  animationDurationMs = animationDurationMsDefault,
  autoDismissAfterMs,
  ...rest
}) {
  const { dismissAnimationFlow } = useDismissAnimationFlow({
    dismissingNote,
    dismissNote,
    autoDismissAfterMs,
    animationDurationMs,
    visualStatus,
    isFirstNonDismissedNote,
    id
  })

  return (
    <Notification
      {...rest}
      id={id}
      mainText={mainText}
      subtext={subtext}
      iconPath={errorIconPath}
      stretchToMaxWidth={stretchToMaxWidth}
      textPaddingRight={textPaddingRight}
      visualStatus={visualStatus}
      shakeKey={shakeKey}
      isFirstNonDismissedNote={isFirstNonDismissedNote}
      textOuterMaxWidth={textOuterMaxWidth}
      onCloseClick={dismissAnimationFlow}
      animationDurationMs={animationDurationMs}
      {...errorStyles}
    />
  )
}

const infoStyles = {
  backgroundColor: '#4100F5',
  outerIconRingColor: '#4B40FE'
}

export function InfoNotification({
  id,
  mainText,
  subtext,
  renderContent,
  iconPath,
  stretchToMaxWidth,
  textPaddingRight,
  visualStatus,
  shakeKey,
  isFirstNonDismissedNote,
  textOuterMaxWidth,
  dismissingNote,
  dismissNote,
  autoDismissAfterMs,
  animationDurationMs = animationDurationMsDefault,
  ...rest
}) {
  const { dismissAnimationFlow } = useDismissAnimationFlow({
    dismissingNote,
    dismissNote,
    autoDismissAfterMs,
    animationDurationMs,
    visualStatus,
    isFirstNonDismissedNote,
    id
  })

  return (
    <Notification
      {...rest}
      id={id}
      mainText={mainText}
      subtext={subtext}
      renderContent={renderContent}
      iconPath={iconPath}
      stretchToMaxWidth={stretchToMaxWidth}
      textPaddingRight={textPaddingRight}
      visualStatus={visualStatus}
      shakeKey={shakeKey}
      isFirstNonDismissedNote={isFirstNonDismissedNote}
      textOuterMaxWidth={textOuterMaxWidth}
      onCloseClick={dismissAnimationFlow}
      animationDurationMs={animationDurationMs}
      {...infoStyles}
    />
  )
}
