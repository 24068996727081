import React from 'react'
import Btn from './styled/Btn'

export default function CloseBtn({ onClick }) {
  function onClickWrapped(e) {
    e.preventDefault()
    if (onClick) {
      onClick()
    }
  }
  return (
    <Btn onClick={onClickWrapped}>
      <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <g
            transform="translate(-386.000000, -931.000000)"
            stroke="#FFFFFF"
            strokeWidth="2"
          >
            <g transform="translate(29.000000, 917.000000)">
              <g transform="translate(358.650391, 15.591797)">
                <path d="M0,0 L8.97949219,8.97949219" />
                <path
                  d="M0,0 L8.97949219,8.97949219"
                  transform="translate(4.489746, 4.489746) rotate(-270.000000) translate(-4.489746, -4.489746) "
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Btn>
  )
}
