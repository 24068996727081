import { gql } from 'graphql.macro'
import LoanOfficerFragment from './LoanOfficerFragment'

export default gql`
  ${LoanOfficerFragment}
  {
    loanOfficers {
      ...LoanOfficerFragment
    }
  }
`
