import { useContext } from 'react'
import { Context } from 'domains/CurrentUser/context'

export default function useCurrentUser() {
  const [{ user }, actions] = useContext(Context)
  const { currentUser, loading, fetchCurrentUserError } = user || {}
  return [
    {
      ...user,
      isLoadingComputed: loading || (!currentUser && !fetchCurrentUserError)
    },
    actions
  ]
}
