/* eslint no-multi-assign: 0 */
import React from 'react'
import Outer from './styled/Outer'

function Brand({ outerStyles }) {
  return (
    <Outer style={outerStyles}>
      <svg width="82px" height="31px" viewBox="0 0 82 31" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-39.000000, -40.000000)">
            <g transform="translate(39.000000, 37.000000)">
              <g transform="translate(0.000000, 3.000000)">
                <path
                  d="M30.6859511,15.0449559 C30.6859511,23.3294405 23.9704357,30.0449559 15.6859511,30.0449559 C7.40146642,30.0449559 0.685951053,23.3294405 0.685951053,15.0449559 C0.685951053,6.76047124 7.40146642,0.0449558748 15.6859511,0.0449558748 C23.9704357,0.0449558748 30.6859511,6.76047124 30.6859511,15.0449559 Z M25.5757919,15.0449559 C25.5757919,9.59463703 21.1481,5.17653482 15.6859511,5.17653482 C10.2238021,5.17653482 5.79611017,9.59463703 5.79611017,15.0449559 C5.79611017,20.4952747 10.2238021,24.9133769 15.6859511,24.9133769 C21.1481,24.9133769 25.5757919,20.4952747 25.5757919,15.0449559 Z"
                  fill="#4100F5"
                />
                <path
                  d="M41.344,22 L41.344,10.8 L43.808,10.8 L43.808,19.76 L49.392,19.76 L49.392,22 L41.344,22 Z M57.114667,22.192 C53.658667,22.192 51.178667,19.616 51.178667,16.432 L51.178667,16.4 C51.178667,13.216 53.690667,10.608 57.146667,10.608 C60.602667,10.608 63.082667,13.184 63.082667,16.368 L63.082667,16.4 C63.082667,19.584 60.570667,22.192 57.114667,22.192 Z M57.146667,19.92 C59.130667,19.92 60.506667,18.352 60.506667,16.432 L60.506667,16.4 C60.506667,14.48 59.098667,12.88 57.114667,12.88 C55.130667,12.88 53.754667,14.448 53.754667,16.368 L53.754667,16.4 C53.754667,18.32 55.162667,19.92 57.146667,19.92 Z M64.661334,22 L69.461334,10.72 L71.733334,10.72 L76.533334,22 L73.957334,22 L72.933334,19.488 L68.197334,19.488 L67.173334,22 L64.661334,22 Z M69.077334,17.312 L72.053334,17.312 L70.565334,13.68 L69.077334,17.312 Z M79.120001,12.48 L79.120001,10.32 L81.680001,10.32 L81.680001,12.48 L79.120001,12.48 Z M79.184001,22 L79.184001,13.424 L81.616001,13.424 L81.616001,22 L79.184001,22 Z"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Outer>
  )
}

export default Brand
