import styled from 'styled-components/macro'

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid white;
  position: relative;
  z-index: 1;
`

export default Triangle
