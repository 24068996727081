import React, { useRef, useCallback } from 'react'
import { isNil } from 'ramda'
import { useSpring } from 'react-spring'
import useWindowResize from 'lib/useWindowResize'
import Outer from './styled/Outer'
import Wrapper from './styled/Wrapper'
import Inner from './styled/Inner'
import CircleOuter from './styled/CircleOuter'
import Circle from './styled/Circle'
import IconOuter from './styled/IconOuter'
import Icon from './styled/Icon'
import TextOuter from './styled/TextOuter'
import MainText from './styled/MainText'
import SubText from './styled/SubText'
import Shadow from './styled/Shadow'
import CloseBtnOuter from './styled/CloseBtnOuter'
import CloseBtn from './components/CloseBtn'

export default function Notification({
  mainText,
  id,
  text = mainText,
  subtext,
  renderContent,
  iconPath,
  backgroundColor,
  outerIconRingColor,
  stretchToMaxWidth,
  textPaddingRight,
  shakeKey,
  isFirstNonDismissedNote,
  textOuterMaxWidth,
  onCloseClick,
  animationDurationMs,
  visualStatus
}) {
  const textOuterMarginTop = subtext ? '8px' : '5px'
  const outerMaxWidth = stretchToMaxWidth || '100%'
  const outerWidth = stretchToMaxWidth ? '100%' : 'auto'

  let shakeFlagRef = useRef(undefined)
  const shakeKeyRef = useRef(shakeKey)

  if (!isNil(shakeKey) && shakeKeyRef.current !== shakeKey) {
    shakeFlagRef.current = !shakeFlagRef.current
  } else {
    shakeFlagRef.current = undefined
  }

  shakeKeyRef.current = shakeKey

  const wasVisibleRef = useRef()
  const wasVisible = wasVisibleRef.current
  wasVisibleRef.current = isFirstNonDismissedNote

  const { width: windowWidth } = useWindowResize()
  let translateY
  let opacity
  if (visualStatus === 'show' && isFirstNonDismissedNote) {
    translateY = '0px'
    opacity = 1
  } else if (visualStatus === 'show') {
    translateY = windowWidth > 750 ? `40px` : `-40px`
    opacity = 0
  } else if (visualStatus === 'dismissing') {
    translateY = windowWidth > 750 ? `40px` : `-40px`
    opacity = 0
  } else {
    translateY = windowWidth > 750 ? `40px` : `-40px`
    opacity = 0
  }

  const animStyles = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(${windowWidth > 750 ? `40px` : `-40px`})`
    },
    to: {
      opacity,
      transform: `translateY(${translateY})`
    }
  })

  const handleCloseClick = useCallback(() => {
    onCloseClick(id)
  }, [id])

  return (
    <Outer
      style={animStyles}
      translateY={translateY}
      opacity={opacity}
      animationDurationMs={animationDurationMs}
      maxWidth={outerMaxWidth}
      width={outerWidth}
      isFirstNonDismissedNote={isFirstNonDismissedNote}
      wasVisible={wasVisible}
      visualStatus={visualStatus}
    >
      <Wrapper shakeFlag={shakeFlagRef.current}>
        <Shadow />
        <Inner backgroundColor={backgroundColor}>
          <CircleOuter>
            <Circle backgroundColor={outerIconRingColor}>
              <IconOuter>
                <Icon src={iconPath} />
              </IconOuter>
            </Circle>
          </CircleOuter>
          <TextOuter
            maxWidth={textOuterMaxWidth}
            marginTop={textOuterMarginTop}
            paddingRight={textPaddingRight}
          >
            <MainText bold={!!subtext}>{text}</MainText>
            {subtext && <SubText>{subtext}</SubText>}
            {renderContent && renderContent()}
          </TextOuter>
          <CloseBtnOuter>
            <CloseBtn onClick={handleCloseClick} />
          </CloseBtnOuter>
        </Inner>
      </Wrapper>
    </Outer>
  )
}
