/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import ProgressBar from 'components/ProgressBar'
import Outer from './styled/Outer'
import UnderGraphLabelOuter from './styled/UnderGraphLabelOuter'
import TriangleAreaOuter from './styled/TriangleAreaOuter'
import TriangleOuter from './styled/TriangleOuter'
import TriangleSvg from './styled/TriangleSvg'
import UnderGraphLabel from './styled/UnderGraphLabel'
import UnderGraphLabelText from './styled/UnderGraphLabelText'

function ArrowLabelSingleLineBarGraph({
  barBackgroundImage,
  backgroundColor,
  underGraphLabelText,
  arrowFraction = 0
}) {
  barBackgroundImage =
    barBackgroundImage ||
    'linear-gradient(to right, rgb(255,65,103), rgb(223,174,3), rgb(41,230,209))'
  backgroundColor = backgroundColor || 'transparent'

  const triangleOuterTransX = arrowFraction * 100
  const triangleSvgTransX = 0 - arrowFraction * 100

  return (
    <Outer>
      <TriangleAreaOuter>
        <TriangleOuter translateX={triangleOuterTransX}>
          <TriangleSvg
            translateX={triangleSvgTransX}
            width="16px"
            height="13px"
            viewBox="0 0 16 13"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-492.000000, -2560.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M493.710705,2555.34795 L505.412753,2557.36965 C505.956976,2557.46367 506.321935,2557.98107 506.227913,2558.5253 C506.192775,2558.72868 506.095564,2558.91621 505.949617,2559.06216 L496.269268,2568.74251 C495.878743,2569.13303 495.245578,2569.13303 494.855054,2568.74251 C494.709108,2568.59656 494.611897,2568.40903 494.576759,2568.20565 L492.55506,2556.5036 C492.461038,2555.95938 492.825997,2555.44198 493.37022,2555.34795 C493.48288,2555.32849 493.598044,2555.32849 493.710705,2555.34795 Z"
                  transform="translate(499.813708, 2562.606602) rotate(-135.000000) translate(-499.813708, -2562.606602) "
                />
              </g>
            </g>
          </TriangleSvg>
        </TriangleOuter>
      </TriangleAreaOuter>
      <ProgressBar
        backgroundColor={backgroundColor}
        barBackgroundImage={barBackgroundImage}
        fraction={1}
        height="10px"
      />
      <UnderGraphLabelOuter>
        <UnderGraphLabel translateX={triangleOuterTransX}>
          <UnderGraphLabelText translateX={triangleSvgTransX}>
            {underGraphLabelText}
          </UnderGraphLabelText>
        </UnderGraphLabel>
      </UnderGraphLabelOuter>
    </Outer>
  )
}

export default ArrowLabelSingleLineBarGraph
