import { useReducer, useCallback, useEffect } from 'react'
import { path } from 'ramda'
import { ErrorNotification } from 'components/Notifications'
import { useMutation } from '@apollo/react-hooks'
import VERIFY_CODE_MUTATION from './mutations/verifyCodeMutation'

const EXECUTE_SUBMIT_VERIFY_CODE = 'EXECUTE_SUBMIT_VERIFY_CODE'
const SUBMIT_VERIFY_CODE_RESPONSE = 'SUBMIT_VERIFY_CODE_RESPONSE'
const SUBMIT_VERIFY_CODE_LOADING = 'SUBMIT_VERIFY_CODE_LOADING'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case EXECUTE_SUBMIT_VERIFY_CODE:
      return {
        ...state,
        executeSubmitVerifyCode: payload.status
      }
    case SUBMIT_VERIFY_CODE_RESPONSE:
      return {
        ...state,
        submitVerifyCodeResponseData: payload.data
      }
    case SUBMIT_VERIFY_CODE_LOADING:
      return {
        ...state,
        loadingSubmitVerifyCode: payload.status
      }
    default:
      throw new Error()
  }
}

function init() {
  return {
    submitVerifyCodeResponseData: undefined,
    loadingSubmitVerifyCode: false,
    executeSubmitVerifyCode: false
  }
}

const errorsToHuman = {
  CODE_REQUIRED: {
    mainText: '5 digit Code is required.',
    subtext: 'We sent you a Code to your phone - please enter it.'
  },
  INVALID_CODE: {
    mainText: 'Invalid Code.',
    subtext: 'Please enter the Code we sent to your phone.'
  }
}

const asyncErrorsToHuman = {
  ASYNC_INVALID_CODE: {
    mainText: 'Hmm. That Code does not match.',
    subtext: 'Please enter the Code we sent to your phone.'
  },
  UNKNOWN: {
    mainText: 'Uh oh. Looks like there was a problem.',
    subtext: 'Wait a few seconds and try again, then contact support.'
  }
}

export default function useVerifyCode({
  referenceId,
  formActions = {},
  notificationActions
}) {
  const [verifyCodeMutation] = useMutation(VERIFY_CODE_MUTATION)

  const [state, dispatch] = useReducer(reducer, {}, init)
  const { upsertNote, dismissNotesByTag, dismissAllNotes } = notificationActions

  const {
    loadingSubmitVerifyCode,
    submitVerifyCodeResponseData: { error: responseError } = {},
    executeSubmitVerifyCode
  } = state

  useEffect(() => {
    if (responseError && !loadingSubmitVerifyCode) {
      let errorId = responseError
      let errorConfig = asyncErrorsToHuman[errorId]

      if (!errorConfig) {
        errorId = 'UNKNOWN'
        errorConfig = asyncErrorsToHuman[errorId]
      }
      upsertNote(
        {
          id: errorId,
          component: ErrorNotification,
          stretchToMaxWidth: '352px',
          tag: 'error',
          ...errorConfig
        },
        { prepend: true }
      )
    }
  }, [responseError, loadingSubmitVerifyCode])

  const submitVerifyCode = useCallback(() => {
    dispatch({
      type: EXECUTE_SUBMIT_VERIFY_CODE,
      payload: {
        status: true
      }
    })
  }, [])

  async function asyncSubmitVerifyCode() {
    const { codePage: { code } = {} } = formActions.getAllForms()

    dispatch({
      type: EXECUTE_SUBMIT_VERIFY_CODE,
      payload: {
        status: false
      }
    })

    if (!code || !code.value || path(['onValueChangeErrors', 'length'], code)) {
      const errorId = code.onValueChangeErrors[0] || 'CODE_NUMBER_REQUIRED'
      const errorConfig = errorsToHuman[errorId]
      dismissNotesByTag('error', { exceptIds: [errorId] })
      upsertNote(
        {
          id: errorId,
          component: ErrorNotification,
          stretchToMaxWidth: '360px',
          tag: 'error',
          ...errorConfig
        },
        { prepend: true }
      )
      return
    }

    dismissAllNotes()

    dispatch({
      type: SUBMIT_VERIFY_CODE_LOADING,
      payload: {
        status: true
      }
    })
    console.log('submitting!', code)
    // await delay(1000)
    const result = await verifyCodeMutation({
      variables: {
        input: {
          referenceId,
          code: code.value
        }
      }
    })
    const isValid = path(['data', 'verifyCode', 'isValid'], result)

    dispatch({
      type: SUBMIT_VERIFY_CODE_LOADING,
      payload: {
        status: false
      }
    })

    // TEMP
    // SUCCESS
    // dispatch({
    //   type: SUBMIT_VERIFY_CODE_RESPONSE,
    //   payload: {
    //     data: {
    //       valid: isValid
    //     }
    //   }
    // })

    dispatch({
      type: SUBMIT_VERIFY_CODE_RESPONSE,
      payload: {
        data: {
          valid: isValid,
          error: isValid ? null : 'ASYNC_INVALID_CODE'
        }
      }
    })
  }

  useEffect(() => {
    if (executeSubmitVerifyCode) {
      asyncSubmitVerifyCode()
    }
  }, [executeSubmitVerifyCode])

  return {
    ...state,
    submitVerifyCode
  }
}
