/* eslint no-multi-assign: 0 */
import React from 'react'
import { path } from 'ramda'
import Outer from './styled/Outer'
import Title from './styled/Title'
import Subtitle from './styled/Subtitle'
import PhoneBtn from '../NavBtns/components/PhoneBtn'
import MailBtn from '../NavBtns/components/MailBtn'
import NavBtnsOuter from './styled/NavBtnsOuter'

function BorrowerInfo({
  outerStyles,
  borrowerFirstName,
  borrowerLastName,
  loanNumber,
  loaiDataError
}) {
  const errorStatus = path(['response', 'status'], loaiDataError)

  return (
    <Outer style={outerStyles}>
      <Title>
        {!loaiDataError ? (
          <>
            {borrowerFirstName} {borrowerLastName}
          </>
        ) : (
          <>{errorStatus === 404 ? 'Loan Not Found' : 'AI Error...'}</>
        )}
      </Title>
      {!loaiDataError && <Subtitle>Loan ID #{loanNumber}</Subtitle>}
      <NavBtnsOuter>
        <PhoneBtn />
        <MailBtn />
      </NavBtnsOuter>
    </Outer>
  )
}

export default BorrowerInfo
