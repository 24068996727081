export const GOOGLE_ANALYTICS_UA =
  process.env.REACT_APP_GOOGLE_ANALYTICS_UA ||
  (process.env.NODE_ENV === 'production' ? 'UA-130949919-2' : 'UA-130949919-3')

// generic config options for react-ga
let config = {
  debug: true,
  titleCase: true
}

// overrides for production and test
if (process.env.NODE_ENV === 'production') {
  config.debug = false
} else if (process.env.NODE_ENV === 'test') {
  config.testMode = true
}

export const GOOGLE_ANALYTICS_CONFIG = config
