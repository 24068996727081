import React from 'react'
import Input from './styled/Input'
import ErrorItem from '../TextArea/styled/ErrorItem'

export default function NumberInput({ editedValue, onInputChange, errors }) {
  return (
    <>
      <Input type="number" onChange={onInputChange} value={editedValue} />
      {!!errors &&
        !!errors.length &&
        errors.map(({ message }, i) => (
          <ErrorItem key={i}>{message}</ErrorItem>
        ))}
    </>
  )
}
