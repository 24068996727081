import React from 'react'
import Outer from './styled/Outer'
import Inner from './styled/Inner'
import Shadow from './styled/Shadow'

export default function Card({
  hasBoxShadow,
  cursor,
  backgroundColor,
  onMouseEnter,
  onMouseLeave,
  onClick,
  customCss,
  children
}) {
  return (
    <Outer
      customCss={customCss}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Inner cursor={cursor} backgroundColor={backgroundColor}>
        {children}
      </Inner>
      {hasBoxShadow && <Shadow hasBoxShadow={hasBoxShadow} />}
    </Outer>
  )
}
