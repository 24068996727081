import styled from 'styled-components/macro'

const Outer = styled.div`
  @media (max-width: 1245px) {
    margin-bottom: 75px;
  }
  @media (max-width: 768px) {
    margin-bottom: 90px;
  }
`

export default Outer
