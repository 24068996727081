import React from 'react'
import ResourceData from './styled/ResourceData'

import StatusBubble from './styled/StatusBubble'
import StatusDataContainer from './styled/StatusDataContainer'

const translateStatusCopy = {
  processing: 'Pulled',
  processed: 'Pushed',
  in_kafka_queue: 'Queued',
  downloaded: 'Downloaded',
  error: 'Error',
  printed: 'Printed',
  shutdown: 'Shutdown'
}

const translateStatusColor = {
  processing: { color: '#FFBC00' },
  processed: { color: '#711CFC' },
  in_kafka_queue: { color: '#AEAEAE' },
  printed: { color: '#01CA01' },
  error: { color: '#FF3365' },
  shutdown: { color: '#FF3365' },
  downloaded: { color: '#0079F7' }
}

const StatusCellRenderer = ({ cellData }) => {
  return (
    <StatusDataContainer>
      <StatusBubble {...translateStatusColor[cellData]} />
      <ResourceData>{translateStatusCopy[cellData]}</ResourceData>
    </StatusDataContainer>
  )
}

export default StatusCellRenderer
