import React, {
  useReducer,
  createContext,
  useContext,
  useMemo,
  useRef,
  useEffect
} from 'react'

function createThunks(actions, ref, isMountedRef) {
  return Object.keys(actions).reduce((res, name) => {
    res[name] = async (...args) => {
      const [state, dispatch] = ref.current
      async function safeDispatch(...argsForDispatch) {
        if (isMountedRef.current) {
          return Promise.resolve(dispatch(...argsForDispatch))
        }
        return Promise.resolve()
      }

      await Promise.resolve(actions[name](...args)(safeDispatch, state))
    }
    return res
  }, {})
}

function createStore(reducer, actions, initialState) {
  const context = createContext([initialState, {}])
  const reducerFn = (state, action) => {
    return reducer(state, action)
  }

  const StoreProvider = ({ children }) => {
    const store = useReducer(reducerFn, initialState)

    const isMountedRef = useRef()
    isMountedRef.current = true

    useEffect(
      () => () => {
        isMountedRef.current = false
      },
      []
    )

    const currentRef = useRef(store)
    const thunks = useMemo(
      () => createThunks(actions, currentRef, isMountedRef),
      [currentRef]
    )

    currentRef.current = store
    return (
      <context.Provider value={[store[0], thunks]}>{children}</context.Provider>
    )
  }

  const useStore = selector => {
    const [state, sActions] = useContext(context)
    if (!selector) {
      return [state, actions]
    }

    const selected = selector(state, sActions)

    return selected
  }

  return [context, StoreProvider, useStore]
}

export default createStore
