import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  width: 50px;
  height: 48px;
  background: rgba(65, 0, 245, 0.3) url(${({ iconImagePath }) => iconImagePath})
    center center no-repeat;
  border-radius: 4px;
  margin-right: ${({ marginRight }) => marginRight || 0};
  display: inline-block;
  vertical-align: middle;
`

export default Outer
