import { useRef, useState, useLayoutEffect, useCallback } from 'react'
import { isNil } from 'ramda'

export default function useCaretPosition(props) {
  const ref = useRef()
  const [position, setPositionState] = useState({ start: 0, end: 0, caret: 0 })

  // Adoption from code at http://blogs.nitobi.com/alexei/wp-content/uploads/2008/01/getcaretselection3.js
  // This function will return the caret position in a text field
  function getPosition(input) {
    let docObj = input.ownerDocument

    let result = { input, start: 0, end: 0, caret: 0 }

    let range
    let rangeCopy

    if (navigator.appVersion.indexOf('MSIE') !== -1) {
      if (input.tagName === 'TEXTAREA') {
        if (input.value.charCodeAt(input.value.length - 1) < 14) {
          input.value = input.value.replace(/34/g, '') + String.fromCharCode(28)
        }
        range = docObj.selection.createRange()
        rangeCopy = range.duplicate()

        rangeCopy.moveToElementText(input)
        rangeCopy.setEndPoint('StartToEnd', range)
        result.end = input.value.length - rangeCopy.text.length

        rangeCopy.setEndPoint('StartToStart', range)
        result.start = input.value.length - rangeCopy.text.length
        result.caret = result.end

        if (
          input.value.substr(input.value.length - 1) === String.fromCharCode(28)
        ) {
          input.value = input.value.substr(0, input.value.length - 1)
        }
      } else {
        range = docObj.selection.createRange()
        rangeCopy = range.duplicate()

        result.start = 0 - rangeCopy.moveStart('character', -100000)
        result.end = result.start + range.text.length
        result.caret = result.end
      }
    } else {
      result.start = input.selectionStart
      result.end = input.selectionEnd
      result.caret = result.end
    }
    if (result.start < 0) {
      result = { input, start: 0, end: 0, caret: 0 }
    }
    return result
  }

  // Adoption from code at http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
  function setPosition({ input, start, end }) {
    if (typeof end === 'undefined') {
      end = start
    }
    if (input.setSelectionRange) {
      input.focus()
      input.setSelectionRange(start, end)
    } else {
      let range = input.createTextRange()
      range.collapse(true)
      range.moveEnd('character', start)
      range.moveStart('character', end)
      range.select()
    }
  }

  function calculateAndSetPosition() {
    if (ref.current) {
      const data = getPosition(ref.current)
      setPositionState(data)
    }
  }

  const [
    { desiredCaret, desiredStart, DesiredEnd },
    setDesiredPosition
  ] = useState({})
  // public function
  const setCaretPosition = useCallback(({ caret, start, end }) => {
    setDesiredPosition({
      desiredCaret: caret,
      desiredStart: start,
      DesiredEnd: end
    })
  }, [])

  useLayoutEffect(() => {
    if (isNil(desiredCaret) && (isNil(desiredStart) || isNil(DesiredEnd))) {
      return
    }
    let c = desiredCaret

    let s = desiredStart

    let e = DesiredEnd

    if (!isNil(c)) {
      s = c
      e = c
    }
    // console.log('setting desired', s, e)
    setDesiredPosition({})
    setPosition({ input: ref.current, start: s, end: e })
    setPositionState({ caret: c, start: s, end: e })
  }, [ref.current, desiredCaret, desiredStart, DesiredEnd])

  const onKeyUp = useCallback(calculateAndSetPosition, [ref.current])
  const onClick = useCallback(calculateAndSetPosition, [ref.current])
  const onFocus = useCallback(calculateAndSetPosition, [ref.current])

  return {
    ...props,
    onKeyUp,
    onClick,
    onFocus,
    inputRef: ref,
    setCaretPosition,
    getImmediateCaretPosition: useCallback(() => getPosition(ref.current), [
      ref.current
    ]),
    ...position
  }
}
