import React from 'react'
import Outer from './styled/Outer'
import DotOuter from './styled/DotOuter'
import SideDot from './styled/SideDot'
import SideLine from './styled/SideLine'
import Inner from './styled/Inner'
import Top from './styled/Top'
import MetricText from './styled/MetricText'
import MetricLabel from './styled/MetricLabel'
import Note from './styled/Note'

export default function BlockQuoteMetric({
  sideType,
  sideColor,
  metricText,
  metricLabel,
  note
}) {
  let side
  if (sideType === 'dot') {
    side = (
      <DotOuter>
        <SideDot backgroundColor={sideColor} />
      </DotOuter>
    )
  } else {
    side = <SideLine backgroundColor={sideColor} />
  }

  return (
    <Outer>
      {side}
      <Inner>
        <Top>
          <MetricText>{metricText}</MetricText>
          {metricLabel && <MetricLabel>{metricLabel}</MetricLabel>}
        </Top>
        {note && <Note>{note}</Note>}
      </Inner>
    </Outer>
  )
}
