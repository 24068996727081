import React from 'react'
import ResourceData from './styled/ResourceData'

const DefaultCellRenderer = ({cellData}) => {
  return (
    <ResourceData>
      {cellData}
    </ResourceData>
  )
}

export default DefaultCellRenderer
