import styled from 'styled-components/macro'
import personSvgPath from '../svg/person-icon.svg'

const PersonIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 109px;
  height: 109px;
  background: url(${personSvgPath}) center center no-repeat;
`

export default PersonIcon
