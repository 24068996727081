import React, { useState, useEffect, useRef, useCallback, memo } from 'react'
import { useTransition, useSpring } from 'react-spring'
import easing from 'lib/easing'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import randomIntBetween from 'lib/randomIntBetween'
import Outer from './styled/Outer'
import Ring from './styled/Ring'
import Dot from './components/Dot'
import Arrow from './components/Arrow'

function useSpringCompat(config) {
  const prevConfigRef = useRef()
  const prevConfig = prevConfigRef.current

  const [anim, set] = useSpring(() => {
    return config.from || {}
  })

  useEffect(() => {
    const same = isEqual(prevConfig, config)

    if (!same) {
      if (isArray(config.to)) {
        set(config)
      } else {
        set(config)
      }
    }

    prevConfigRef.current = config
  })

  return anim
}

function useRingAnimations({
  largestRingDiameterPx,
  status,
  ringSpacePx,
  totalRings,
  ref
}) {
  let delay = 0
  if (status === 'expand') {
    delay = 200
  } else if (status === 'dropped') {
    delay = 200
  } else {
    delay = 200
  }

  const ringConfigs = Array(totalRings)
    .fill()
    .map((unused, i) => {
      const ringDiameter = largestRingDiameterPx - i * ringSpacePx
      if (ringDiameter < 0) {
        return null
      }
      return { i, ringDiameter }
    })
    .filter(item => item)
    .reverse()

  const ringAnims = useTransition(ringConfigs, ({ i }) => i, {
    ref,
    unique: true,
    delay,
    trail: 50,
    from: () => {
      return {
        width: '0px',
        height: '0px',
        opacity: 0
      }
    },
    enter: ({ ringDiameter }) => {
      return {
        width: `${ringDiameter}px`,
        height: `${ringDiameter}px`,
        opacity: 1
      }
    },
    leave: () => {
      return {
        width: '0px',
        height: '0px',
        opacity: 0
      }
    }
  })

  return ringAnims
}

function useDotAnimations({ status, totalDots, maxDistance, ref }) {
  let delay = 0
  if (status === 'expand') {
    delay = 0
  } else if (status === 'dropped') {
    delay = 0
  } else {
    delay = 0
  }

  const [dotsState, setDotsState] = useState([])

  useEffect(() => {
    const separationInterval = maxDistance / totalDots
    const dotsConfig = Array(totalDots)
      .fill()
      .map((unused, i) => {
        let distancePx
        let angleDegrees
        let size
        let singleRevolutionDurMs
        if (!dotsState[i]) {
          distancePx = randomIntBetween(
            i * separationInterval + 100,
            Math.min((i + 1) * separationInterval, maxDistance)
          )
          angleDegrees = randomIntBetween(0, 259)
          size = randomIntBetween(5, 12)
          singleRevolutionDurMs = (i + 1) * 2500 + randomIntBetween(-50, 50)
        } else {
          ;({
            distancePx,
            angleDegrees,
            size,
            singleRevolutionDurMs
          } = dotsState[i])
        }
        distancePx = distancePx > maxDistance ? maxDistance : distancePx
        return { i, distancePx, angleDegrees, size, singleRevolutionDurMs }
      })
      .filter(item => item)

    setDotsState(dotsConfig)
  }, [totalDots, maxDistance])

  const dotAnims = useTransition(dotsState, ({ i }) => i, {
    ref,
    unique: true,
    delay,
    trail: 50,
    from: ({ angleDegrees, size }) => {
      return {
        distancePx: 0,
        angleDegrees,
        size,
        opacity: 0
      }
    },
    enter: ({ distancePx, angleDegrees, size }) => {
      return {
        distancePx,
        angleDegrees,
        size,
        opacity: 1
      }
    },
    leave: ({ angleDegrees, size }) => {
      return {
        distancePx: 0,
        angleDegrees,
        size,
        opacity: 0
      }
    }
  })

  return dotAnims
}

function useOuterAnimation({ status, onDestroyed = null }) {
  let visible
  let delay = 0
  let onRest
  if (status === 'expand') {
    visible = true
  } else if (status === 'dropped') {
    visible = false
    delay = 1050
    onRest = onDestroyed
  } else {
    visible = false
    delay = 200
    onRest = onDestroyed
  }

  const [outerAnim, set] = useSpring(() => {
    return { opacity: 0 }
  })

  useEffect(() => {
    set({
      opacity: visible ? 1 : 0,
      delay,
      onRest: ({ opacity }) => {
        if (opacity === 0) {
          onRest && onRest()
        }
      }
    })
  }, [visible, delay, onRest])

  return outerAnim
}

function useArrowAnimation({ status, ref }) {
  let configs = []
  let delay = 0

  if (status === 'expand') {
    configs = [
      {
        transform: `translateY(0px)`,
        opacity: 1,
        config: {
          easing: easing.easeOutQuart,
          duration: 500
        }
      }
    ]
  } else if (status === 'dropped') {
    delay = 100
    configs = [
      {
        transform: `translateY(50px)`,
        opacity: 1,
        config: {
          easing: easing.easeOutQuad,
          duration: 700
        }
      },
      {
        transform: `translateY(-200px)`,
        opacity: 0,
        config: {
          easing: easing.easeInQuart,
          duration: 500
        }
      }
    ]
  } else {
    delay = 100
    configs = [
      {
        transform: `translateY(20px)`,
        opacity: 0,
        config: {
          easing: easing.easeOutQuart,
          duration: 500
        }
      }
    ]
  }

  const arrowAnim = useSpringCompat({
    ref,
    delay,
    to: configs
  })

  return arrowAnim
}

export default memo(function GalaxyAnimation({ status, onReadyToRemove }) {
  const largestRingDiameterPx = 870
  const ringSpacePx = 200

  // const outerAnimRef = useRef()
  // const ringAnimsRef = useRef()
  // const dotAnimsRef = useRef()
  // const arrowAnimRef = useRef()

  let totalDots
  let totalRings
  let onOuterDestroyed

  const hideAndDestroy = useCallback(() => {
    onReadyToRemove && onReadyToRemove()
  }, [onReadyToRemove])

  if (status === 'expand') {
    totalDots = 8
    totalRings = 4
  } else if (status === 'dropped') {
    totalDots = 0
    totalRings = 0
    onOuterDestroyed = hideAndDestroy
  } else {
    totalDots = 0
    totalRings = 0
    onOuterDestroyed = hideAndDestroy
  }

  const outerAnim = useOuterAnimation({
    status,
    onDestroyed: onOuterDestroyed
    // ref: outerAnimRef
  })

  const ringAnims = useRingAnimations({
    status,
    largestRingDiameterPx,
    ringSpacePx,
    totalRings
    // ref: ringAnimsRef
  })

  const dotAnims = useDotAnimations({
    status,
    totalDots,
    maxDistance: largestRingDiameterPx / 2
    // ref: dotAnimsRef
  })

  const arrowAnim = useArrowAnimation({
    status
    // ref: arrowAnimRef
  })

  return (
    <>
      <Outer style={outerAnim}>
        {ringAnims.map(
          ({ item, key, props }) => item && <Ring key={key} style={props} />
        )}
        {dotAnims.map(
          ({ item, key, props }) =>
            item && <Dot key={key} item={item} {...props} />
        )}
        <Arrow {...arrowAnim} />
      </Outer>
    </>
  )
})
