import styled from 'styled-components/macro'

const Outer = styled.a`
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
`

export default Outer
