import { useMemo, useState, useEffect } from 'react'
import useTicker from 'lib/useTicker'
import useOnWindowFocus from 'lib/useOnWindowFocus'

export default function useCanDeleteFileProcessor({ run, deleteUntil }) {
  //   const filesWithExpireDates = useMemo(()=>{
  //
  //   }, [files])

  // const checker = useCallback(()=>{
  //   if(!deleteUntil){
  //     // if there is no deleteUntil date,
  //     // then just assume we are allowed to delete
  //     // indefinitely (it will currrently only be undefined
  //     // during the "optimistic save" duration, between
  //     // upload request and upload response, for which
  //     // we allow canceling anyways)
  //     return true
  //   }
  //   return (new Date()) < deleteUntil
  // }, [deleteUntil])

  const [continueChecking, setContinueChecking] = useState(true)

  const { tickFlag } = useTicker(continueChecking, 1000)

  const { isFocused } = useOnWindowFocus(continueChecking)

  // only recheck on our tick interval
  const canDelete = useMemo(() => {
    if (!deleteUntil) {
      // if there is no deleteUntil date,
      // then just assume we are allowed to delete
      // indefinitely (it will currrently only be undefined
      // during the "optimistic save" duration, between
      // upload request and upload response, for which
      // we allow canceling anyways)
      return true
    }
    const date = new Date()
    return date < deleteUntil
  }, [deleteUntil, tickFlag, isFocused])

  useEffect(() => {
    if (!run || !canDelete) {
      setContinueChecking(false)
    } else {
      setContinueChecking(true)
    }
  }, [run, canDelete])

  return {
    canDelete
  }
}
