import React from 'react'
import ArrowLabelSingleLineBarGraph from 'components/ArrowLabelSingleLineBarGraph'
import RingWithHalo from 'domains/LoanRecommender/shared/components/RingWithHalo'
import Outer from './styled/Outer'
import Inner from './styled/Inner'
import RingOuter from './styled/RingOuter'
import GuidanceText from './styled/GuidanceText'
import GuidanceOuter from './styled/GuidanceOuter'
import RecommendationBarOuter from './styled/RecommendationBarOuter'

const recommendationTextMapping = {
  highly_recommended: {
    publicText: 'Highly Recommended',
    arrowFraction: 1
  },
  recommended_with_caution: {
    publicText: 'Recommended With Caution',
    arrowFraction: 0.5
  },
  not_recommended: {
    publicText: 'Not Recommended',
    arrowFraction: 0
  }
}

export default function SalesGuidance({ loaiData }) {
  const {
    personaGuidanceCombo: {
      guidanceConstant: recommendationName,
      description: guidanceText = ''
    } = {}
  } = loaiData

  if (!recommendationName) {
    return null
  }

  const guidanceParas = guidanceText.split(/\n/)

  const {
    arrowFraction,
    publicText: underGraphLabelText
  } = recommendationTextMapping[recommendationName]

  return (
    <Outer>
      <Inner>
        <RingOuter>
          <RingWithHalo />
        </RingOuter>
        <GuidanceOuter>
          {guidanceParas.map((para, i) => (
            <GuidanceText key={i}>{para}</GuidanceText>
          ))}
        </GuidanceOuter>
        <RecommendationBarOuter>
          <ArrowLabelSingleLineBarGraph
            arrowFraction={arrowFraction}
            underGraphLabelText={underGraphLabelText}
          />
        </RecommendationBarOuter>
      </Inner>
    </Outer>
  )
}

// export default function SalesGuidance() {
//   return null
// }
