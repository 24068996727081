/* eslint no-restricted-syntax: 0 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import useCurrentUser from 'domains/CurrentUser/useable/useCurrentUser'
import TestUpload from './TestUpload'
import TestVerifyUploadCode from './TestVerifyUploadCode'

export default {
  routes: () => {
    const { fetchCurrentUserIfNeeded } = useCurrentUser()[1]
    fetchCurrentUserIfNeeded()

    return (
      <Switch>
        <Route
          exact
          path="/member-upload-portal-test/upload"
          component={TestUpload}
        />
        <Route
          exact
          path="/member-upload-portal-test/verify-upload-code"
          component={TestVerifyUploadCode}
        />
      </Switch>
    )
  }
}
