import createStore from 'domains/shared/Store'
import disclosureQueueReducer, { initialState } from './reducers'
import disclosureQueueActions from './actions'

const [DisclosuresContext, DisclosuresProvider, useDisclosuresStore] = createStore(disclosureQueueReducer, disclosureQueueActions, initialState);

export {
	useDisclosuresStore,
	DisclosuresProvider,
	DisclosuresContext,
}