import styled from 'styled-components/macro'

const GeneralError = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 10px;
  color: #ff5e5e;
`

export default GeneralError
