import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  padding-bottom: 30px;
  display: flex;
  @media (max-width: 760px) {
    padding: 0;
    margin-bottom: 35px;
    display: block;
    border-bottom: 1px solid #f9f9f9;
  }
`

export default Outer
