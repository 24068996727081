import styled from 'styled-components/macro'

const RingsOuter = styled.div`
  position: relative;
  margin: 0 auto 78px auto;
  display: flex;
  justify-content: space-around;
`

export default RingsOuter
