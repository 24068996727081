import React from 'react'
import TextAreaBase from './styled/TextArea'
import ErrorItem from './styled/ErrorItem'

export default function TextArea({ editedValue, onInputChange, errors }) {
  return (
    <>
      <TextAreaBase onChange={onInputChange} value={editedValue} />
      {!!errors &&
        !!errors.length &&
        errors.map(({ message }, i) => (
          <ErrorItem key={i}>{message}</ErrorItem>
        ))}
    </>
  )
}
