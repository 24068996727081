/* eslint no-multi-assign: 0 */
import React from 'react'

function PhoneBtn() {
  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1359.000000, -38.000000)">
          <g transform="translate(39.000000, 37.000000)">
            <g transform="translate(1321.000000, 2.000000)">
              <circle
                stroke="#29E6D1"
                cx="18.0000007"
                cy="18.0000007"
                r="18.0000007"
              />
              <text
                fontFamily="Gotham-Bold, Gotham"
                fontSize="14"
                fontWeight="bold"
                letterSpacing="-0.2333334"
                fill="#FFFFFF"
              >
                <tspan x="12.0406674" y="23.7000005">
                  M
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PhoneBtn
