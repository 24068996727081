import React, { memo, useReducer, useCallback, useMemo } from 'react'
import isFunction from 'lodash/isFunction'
import { sortBy, prop, mergeDeepRight, path } from 'ramda'
import { Provider, ActionProvider } from '../Context'

const SHOW = 'SHOW'
const HIDE = 'HIDE'
const TOGGLE = 'TOGGLE'

function reducer(state, { type, payload = {} }) {
  switch (type) {
    case SHOW: {
      return mergeDeepRight(state, {
        dropdowns: {
          [payload.dropdown.id]: { ...payload.dropdown, show: true }
        }
      })
    }
    case HIDE: {
      return mergeDeepRight(state, {
        dropdowns: {
          [payload.id]: { show: false }
        }
      })
    }
    case TOGGLE: {
      return mergeDeepRight(state, {
        dropdowns: {
          [payload.id]: {
            show: !path(['dropdowns', payload.id, 'show'], state)
          }
        }
      })
    }
    default: {
      throw new Error()
    }
  }
}

function init() {
  return {
    dropdowns: {}
  }
}

export function createWithDropdownsProvider(/* opts = {} */) {
  return function withDropdownsProvider(Child) {
    if (Child) {
      Child = memo(Child)
    }
    return function DropdownsProvider({ children, ...restProps }) {
      const [{ dropdowns }, dispatch] = useReducer(reducer, {}, init)

      const showDropdown = useCallback(dropdown => {
        dispatch({
          type: SHOW,
          payload: {
            dropdown
          }
        })
      }, [])

      const hideDropdown = useCallback(id => {
        dispatch({
          type: HIDE,
          payload: {
            id
          }
        })
      }, [])

      const toggleDropdown = useCallback(id => {
        dispatch({
          type: TOGGLE,
          payload: {
            id
          }
        })
      })

      const actionContext = useMemo(() => {
        return {
          showDropdown,
          hideDropdown,
          toggleDropdown
        }
      }, [showDropdown, hideDropdown, toggleDropdown])

      if (Child) {
        children = <Child {...restProps} />
      } else if (isFunction(children)) {
        children = children(restProps)
      }

      let sortedDropdowns = useMemo(
        () => sortBy(prop('id'), Object.values(dropdowns)),
        [dropdowns]
      )

      const context = useMemo(() => {
        return {
          sortedDropdowns,
          dropdowns
        }
      }, [sortedDropdowns, dropdowns])

      // find any no

      return (
        <Provider value={context}>
          <ActionProvider value={actionContext}>{children}</ActionProvider>
        </Provider>
      )
    }
  }
}

export const withDropdownsProvider = createWithDropdownsProvider()
export const DropdownsProvider = withDropdownsProvider()
