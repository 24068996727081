/**
 *
 * Sidebar.js
 *
 */

import React, { useState } from 'react'
import styled from 'styled-components/macro'
import IfAuthenticated from 'domains/CurrentUser/IfAuthenticated'
import Avatar from 'domains/CurrentUser/Avatar'
import useLogout from 'domains/CurrentUser/useable/useLogout'
import UserMenuFlyoutMenu from './UserMenuFlyoutMenu'
import FlyoutMenuItem, { FlyoutIcon } from '../FlyoutMenuItem'
import DownloadIcon from './svg/Logout'

const UserNavContainer = styled.div`
  display: flex;
`

const BizportHeaderAvatar = styled(Avatar)`
  cursor: pointer;
`

const UserNav = () => {
  const [open, setOpen] = useState(false)
  const [shown, setShown] = useState(false)

  const handleClick = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(!open)
    setShown(true)
  }

  const handleDismiss = () => {
    setOpen(false)
  }

  const onClose = () => {
    handleDismiss()
  }

  const logout = useLogout()
  return (
    <UserNavContainer>
      <IfAuthenticated
        render={() => (
          <>
            <BizportHeaderAvatar onClick={handleClick} />
            <UserMenuFlyoutMenu
              open={open}
              shown={shown}
              close={onClose}
              handleDismiss={handleDismiss}
              render={() => {
                return (
                  <FlyoutMenuItem onClick={logout}>
                    <FlyoutIcon>
                      <DownloadIcon />
                    </FlyoutIcon>{' '}
                    Logout
                  </FlyoutMenuItem>
                )
              }}
            />
          </>
        )}
      />
    </UserNavContainer>
  )
}

export default UserNav
