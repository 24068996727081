import styled from 'styled-components/macro'

const IconTopLeft = styled.img`
  position: absolute;
  top: 15px;
  left: 18px;
  ${({ customCss }) => customCss}
`

export default IconTopLeft
