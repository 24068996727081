import React, { useMemo } from 'react'
import Outer from './styled/Outer'
import SpinnerOuter from './styled/SpinnerOuter'
import SpinnerInner from './styled/SpinnerInner'

const emptyObj = {}

function useGetPaint({
  paint,
  borderRounded,
  overlayBackgroundColor,
  spinnerColor,
  spinnerBackgroundColor
}) {
  return useMemo(() => {
    // only one paint so far...
    if (paint === 'blue-grey') {
      return {
        overlayBackgroundColor: 'transparent',
        spinnerBackgroundColor: '#d2d2d2',
        spinnerColor: '#4100f5'
      }
    }
    return emptyObj
  }, [
    paint,
    borderRounded,
    overlayBackgroundColor,
    spinnerColor,
    spinnerBackgroundColor
  ])
}

export default function Spinner(props) {
  const {
    borderRounded,
    overlayBackgroundColor,
    spinnerColor,
    spinnerBackgroundColor
  } = useGetPaint(props)

  return (
    <Outer
      borderRounded={borderRounded}
      backgroundColor={overlayBackgroundColor}
    >
      <SpinnerOuter>
        <SpinnerInner
          backgroundColor={spinnerBackgroundColor}
          color={spinnerColor}
        />
      </SpinnerOuter>
    </Outer>
  )
}
