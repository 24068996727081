import styled from 'styled-components/macro'

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export default ModalWrapper
