import styled from 'styled-components/macro'

const OuterDropdownList = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  min-width: 124px;
  padding: 6px;
  border-radius: 8px;
  box-shadow: 15px 14px 40px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  transition: transform 0.2s;
`

export default OuterDropdownList
