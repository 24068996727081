import styled from 'styled-components/macro'

const MainText = styled.h3`
  margin: 0;
  font-family: Gotham;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  line-height: 18px;
  font-size: 14px;
  color: white;
`

export default MainText
