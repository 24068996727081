import { any, pathEq } from 'ramda'

export default function extractErrorTypeFromApolloError(error) {
  if (!error) {
    return
  }
  const { graphQLErrors = [], networkError } = error

  let uploadSizeError = false
  if (
    graphQLErrors[0] &&
    graphQLErrors[0].message &&
    graphQLErrors[0].message.indexOf('exceeds filesize') > -1
  ) {
    uploadSizeError = true
  }

  if (uploadSizeError) {
    return 'UPLOAD_SIZE_ERROR'
  }

  const internalServerError = any(
    pathEq(['extensions', 'code'], 'INTERNAL_SERVER_ERROR'),
    graphQLErrors
  )

  if (internalServerError) {
    return 'INTERNAL_SERVER_ERROR'
  }

  if (networkError) {
    return 'NETWORK_ERROR'
  }
}
