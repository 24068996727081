import { useContext, useCallback } from 'react'
import { Context } from 'domains/shared/GlobalStyle/context'

export default function useGlobalStyleAdjuster() {
  const { dispatch } = useContext(Context)
  const updateStyles = useCallback(payload => {
    dispatch({
      type: 'updateStyles',
      payload
    })
  }, [])

  return {
    updateStyles
  }
}
