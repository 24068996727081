import styled from 'styled-components/macro'

const Title = styled.h1`
  font-size: 48px;
  line-height: 48px;
  font-weight: 200;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    max-width: 422px;
  }
  @media (max-width: 550px) {
    font-size: 36px;
    line-height: 44px;
  }
`

export default Title
