import styled from 'styled-components/macro'

const TopRightContent = styled.div`
  position: absolute;
  top: 15px;
  right: 11px;
  z-index: 1000;
`

export default TopRightContent
