import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
`
/*
    height: 0;             // collapse the container's height
    width: width-value;    // specify any width you want (a percentage value, basically)
    // apply a padding using the following formula
    // this formula makes sure the aspect ratio of the container equals that of the svg graphic
    padding-top: (svg height / svg width) * width-value;
    position: relative;    // create positioning context for svg
*/

export default Outer
