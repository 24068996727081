/* eslint no-multi-assign: 0 */
/* eslint no-unused-expressions: 0 */
import React from 'react'
import SingleLineBarGraph from 'components/SingleLineBarGraph'
import useIsShowingInViewport from 'lib/useIsShowingInViewport'
import Outer from './styled/Outer'
import Row from './styled/Row'
import Item from './styled/Item'
import Spacer from './styled/Spacer'
import GraphOuter from './styled/GraphOuter'

import creditCardIconPath from './svg/credit-card-icon.svg'
import moneyIconPath from './svg/money-icon.svg'
import percentIconPath from './svg/percent-icon.svg'

// 3 - Down payment (Purchase) / LTV (Refi)
// 4 - monthly payment

function PersonalMetrics({ loaiData }) {
  let {
    combinedLtv,
    creditScore,
    downPaymentPercent,
    dtiBottomRatioPercent,
    isCombo,
    loanPurposeType,
    mortgageProductMonthlyPayment,
    combinedMonthlyIncome
  } = loaiData

  mortgageProductMonthlyPayment =
    parseInt(mortgageProductMonthlyPayment, 10) || 0
  combinedMonthlyIncome = parseInt(combinedMonthlyIncome, 10) || 0
  creditScore = parseInt(creditScore, 10) || 0
  combinedLtv = parseInt(combinedLtv, 10) || 0
  downPaymentPercent = parseInt(downPaymentPercent, 10) || 0
  dtiBottomRatioPercent = parseInt(dtiBottomRatioPercent, 10) || 0
  const combinedLtvFrac = combinedLtv / 100
  const downPaymentFrac = downPaymentPercent / 100
  const dtiBottomRatioFrac = dtiBottomRatioPercent / 100
  const creditScoreFrac = creditScore / 850

  return (
    <Outer>
      <Row tighten={!isCombo}>
        <Item>
          <MetricAnimateOnShow
            label="Credit Score"
            displayTotal={creditScore}
            fraction={creditScoreFrac}
            barColor="#29E6D1"
            backgroundColor="#171473"
            leftIconImagePath={creditCardIconPath}
          />
          {loanPurposeType === 'Purchase' ? (
            <MetricAnimateOnShow
              label="Down Payment"
              displayTotal={`${downPaymentPercent}%`}
              fraction={downPaymentFrac}
              barColor="#238DFC"
              backgroundColor="#171473"
              leftIconImagePath={moneyIconPath}
            />
          ) : (
            <MetricAnimateOnShow
              label="Loan to Value"
              displayTotal={`${combinedLtv}%`}
              fraction={combinedLtvFrac}
              barColor="#958EFF"
              backgroundColor="#171473"
              leftIconImagePath={moneyIconPath}
            />
          )}
        </Item>
        <Spacer />
        <Item>
          <MetricAnimateOnShow
            label="Debt to Income"
            displayTotal={`${dtiBottomRatioPercent}%`}
            fraction={dtiBottomRatioFrac}
            barColor="#C251CF"
            backgroundColor="#171473"
            leftIconImagePath={percentIconPath}
          />
          <MetricAnimateOnShow
            label="Monthly Payment"
            displayTotal={`$${mortgageProductMonthlyPayment}`}
            fraction={mortgageProductMonthlyPayment / combinedMonthlyIncome}
            barColor="#958EFF"
            backgroundColor="#171473"
            leftIconImagePath={moneyIconPath}
          />
        </Item>
      </Row>
    </Outer>
  )
}

function MetricAnimateOnShow({ fraction, ...restProps }) {
  const { isShowing, targetRef } = useIsShowingInViewport({
    once: true
  })

  if (!isShowing) {
    fraction = 0
  }

  return (
    <GraphOuter ref={targetRef}>
      <SingleLineBarGraph {...restProps} fraction={fraction} />
    </GraphOuter>
  )
}

export default PersonalMetrics
