import styled from 'styled-components/macro'

const CodeOuter = styled.div`
  padding: 30px 0;
  width: 200px;
  height: 100px;
  text-align: center;
  @media (max-width: 760px) {
    padding: 0;
    width: auto;
    height: auto;
    text-align: left;
  }
`

export default CodeOuter
