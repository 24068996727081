import styled from 'styled-components/macro'

// We stretch width when media is phone
// so title does not collapse to 2 lines
const Outer = styled.div`
  margin-top: 19px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
  transition: margin-top 1400ms ease-in-out;
  @media (max-width: 768px) {
    margin-top: 122px;
    width: 100%;
    padding: 0 20px;
  }
`

export default Outer
