/* eslint no-multi-assign: 0 */
import React, { useMemo } from 'react'
import { css } from 'styled-components/macro'
import PrettyBtn from 'components/PrettyBtn'
import mortgageTypeToPublicTitle from 'domains/shared/utils/mortgageTypeToPublicTitle'
import { find, propEq, path } from 'ramda'
import Outer from './styled/Outer'
import Item from './styled/Item'
import LoanTile from './components/LoanTile'
import ScoreTile from './components/ScoreTile'
// make sure the two loan tiles
// are have the same max-width
// when we media query to mobile

// also increase max-width 370 by 2*32
// to accommodate left/right padding
const loanItemCss = css`
  @media (max-width: 810px) {
    width: 100%;
    max-width: 434px;
    padding: 0 32px;
  }
`
const firstLoanItemCss = css`
  ${loanItemCss}
  @media (max-width: 810px) {
    margin-bottom: 38px;
  }
  ${({ isTwoColumnMaxWidth }) =>
    isTwoColumnMaxWidth
      ? css`
          @media (max-width: 810px) {
            margin-bottom: 85px;
          }
        `
      : null}
`

const secondLoanItemCss = css`
  ${loanItemCss}
  @media (max-width: 810px) {
    margin-bottom: 85px;
  }
`

const scoreTileOuterCss = css`
  ${({ isTwoColumnMaxWidth }) =>
    isTwoColumnMaxWidth
      ? css`
          margin-left: 107px;
          @media (max-width: 1160px) {
            margin-left: 50px;
          }
          @media (max-width: 810px) {
            margin-left: 0;
            width: 100%;
          }
          @media (max-width: 560px) {
            max-width: 434px;
            padding: 0 32px;
          }
        `
      : css`
          @media (max-width: 1160px) {
            width: 100%;
          }
          @media (max-width: 560px) {
            max-width: 434px;
            padding: 0 32px;
          }
        `}
`

const firstLoanTileCss = css`
  @media (max-width: 810px) {
    min-height: 0;
  }
`

const smallLoanTileCss = css`
  margin-bottom: 24px;
`

function LoansOverview({ score, readyToAnimate, loaiData }) {
  const {
    isCombo,
    mortgageProductLoanType,
    mortgageProductRate,
    mortgageProductAmorType,
    mortgageProductTerm,
    errors
  } = loaiData

  const firstLoanTitle = useMemo(
    () =>
      mortgageTypeToPublicTitle({
        mortgageType: mortgageProductLoanType,
        isCombo
      }),
    [mortgageProductLoanType, isCombo]
  )

  const firstLoanAmorType = (mortgageProductAmorType || '').toUpperCase()

  const specificErrorFromOB = useMemo(() => {
    const e = errors || []
    const badDataForOb = find(propEq('name', 'BAD_DATA_FOR_OB'), e)
    if (badDataForOb) {
      return `${path(['message'], badDataForOb)} - ${path(
        ['specific', 'modelState', 'payload', 0],
        badDataForOb
      )}`
    }

    const noProduct = find(
      propEq('name', 'BEST_EX_NO_PRODUCT_FOR_GIVEN_DATA'),
      e
    )
    if (noProduct) {
      return "Cannot recommend a loan product given borrower's dataset."
    }
  }, [errors])

  return (
    <Outer isTwoColumnMaxWidth={!isCombo}>
      {specificErrorFromOB ? (
        <span>
          Could not compute a loan recommendation. This problem originates from
          Optimal Blue. They stated "{specificErrorFromOB}"
        </span>
      ) : (
        <>
          <Item css={firstLoanItemCss} isTwoColumnMaxWidth={!isCombo}>
            <LoanTile
              pretitle="FIRST LIEN"
              title={firstLoanTitle}
              interestRate={mortgageProductRate}
              term={mortgageProductTerm}
              termUnits={`YR ${firstLoanAmorType}`}
              sz={!isCombo ? 'small' : ''}
              cssStyles={firstLoanTileCss}
            />
          </Item>
          {isCombo && (
            <Item css={secondLoanItemCss} width="321px">
              <LoanTile
                pretitle="SECOND LIEN"
                title="Andrews Credit Union"
                sz="small"
                cssStyles={smallLoanTileCss}
              />
              <PrettyBtn
                display="block"
                width="100%"
                textAlign="left"
                arrowRight
              >
                VIEW SECOND LIEN GUIDELINES
              </PrettyBtn>
            </Item>
          )}
          <Item
            css={scoreTileOuterCss}
            isTwoColumnMaxWidth={!isCombo}
            width="266px"
          >
            <ScoreTile
              score={score}
              moreRingMarginLeft={!isCombo}
              readyToAnimate={readyToAnimate}
            />
          </Item>
        </>
      )}
    </Outer>
  )
}

export default LoansOverview
