import styled from 'styled-components/macro'

const Outer = styled.h2`
  font-family: Gotham_Book;
  font-size: 22px;
  line-height: 26px;
  color: white;
  font-weight: 200;
`

export default Outer
