import { useState, useEffect, useCallback } from 'react'

export default function useOnWindowFocus(run) {
  const [{ focusChangedFlag, isFocused }, setState] = useState({
    focusChangedFlag: true,
    isFocused: true
  })

  const toggleFocusFlagAndMerge = useCallback(mergeable => {
    setState(cur => {
      return {
        ...cur,
        ...mergeable,
        focusChangedFlag: !cur.focusChangedFlag
      }
    })
  }, [])

  useEffect(() => {
    function onWindowFocus() {
      toggleFocusFlagAndMerge({ isFocused: true })
    }
    function onWindowBlur() {
      toggleFocusFlagAndMerge({ isFocused: false })
    }

    if (run) {
      window.addEventListener('focus', onWindowFocus, false)
      window.addEventListener('blur', onWindowBlur, false)
    }
    return () => {
      window.removeEventListener('focus', onWindowFocus)
      window.removeEventListener('blur', onWindowBlur)
    }
  }, [run, toggleFocusFlagAndMerge])

  return {
    focusChangedFlag,
    isFocused
  }
}
