import React from 'react'
import PrettyBtn from 'components/PrettyBtn'
import { useTransition, animated } from 'react-spring'

export default function BtnShowHideAnimated({
  show,
  as: asForwarded,
  children,
  ...restProps
}) {
  const transitions = useTransition(show, null, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    unique: true
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <PrettyBtn as={asForwarded} {...restProps}>
            {children}
          </PrettyBtn>
        </animated.div>
      )
  )
}
