import styled from 'styled-components/macro'

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`

export default LabelContainer
