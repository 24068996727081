import React from 'react'
import { compose } from 'ramda'
import LegacyBrowserWarning from '../../components/LegacyBrowserWarning'

function isIE() {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  const trident = ua.indexOf('Trident/')

  return msie > 0 || trident > 0
}

function withLegacyBrowserWarning(Child) {
  return function LegacyBrowserWarningContainer(props) {
    if (isIE()) {
      return <LegacyBrowserWarning />
    }

    return <Child {...props} />
  }
}

export default compose(withLegacyBrowserWarning)
