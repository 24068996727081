import styled from 'styled-components/macro'

const Area = styled.div`
  font-size: ${({ sz }) => (sz === 'sm' ? '16px' : '20px')};
  line-height: ${({ sz }) => (sz === 'sm' ? '22px' : '26px')};
  font-family: Gotham;
  font-weight: 300;
  margin-bottom: 20px;
  color: #00023f;
  padding: 7px;
`

export default Area
