import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { ContextProvider } from 'lib/UrlTracker/context'
import queryString from 'query-string'

function UrlTracker({ match, location, history, render }) {
  const queryStringParams = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const value = useMemo(() => {
    return {
      match,
      location,
      history,
      queryStringParams
    }
  }, [match, location, history])

  return <ContextProvider value={value}>{render()}</ContextProvider>
}

export default withRouter(UrlTracker)
