/**
 *
 * PushRefNavLink.js
 *
 */

import React from 'react'
import styled from 'styled-components/macro'

const NavUnderlineContainer = styled.div`
  width: ${props => props.width}px;
  height: 3px;
  background: #4dffc9;
  position: absolute;
  bottom: 30px;
  left: ${props => props.leftOffset}px;
  transition: all 0.2s ease-in-out;
`

const NavUnderline = ({ underlineEl }) => {
  if (!underlineEl) return null
  const { offsetWidth, offsetLeft } = underlineEl
  return <NavUnderlineContainer width={offsetWidth} leftOffset={offsetLeft} />
}

export default NavUnderline
