import styled from 'styled-components/macro'

const Title = styled.h1`
  font-weight: bold;
  letter-spacing: -0.4px;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 13px;
  @media (max-width: 400px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
`

export default Title
