import styled from 'styled-components/macro'

const TitleArea = styled.div`
  padding: 0 135px;
  @media (max-width: 750px) {
    padding: 0 28px;
  }
`

export default TitleArea
