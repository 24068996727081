import { gql } from 'graphql.macro'
import ApplicationCampaignFragment from './ApplicationCampaignFragment'

export default gql`
  ${ApplicationCampaignFragment}
  {
    applicationCampaigns {
      ...ApplicationCampaignFragment
    }
  }
`
