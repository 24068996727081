import styled from 'styled-components/macro'

const GuidanceOuter = styled.div`
  max-width: 990px;
  padding: 0 70px;
  margin: 0 auto 98px auto;
  text-align: center;
  @media (max-width: 645px) {
    padding: 0;
  }
`

export default GuidanceOuter
