import styled from 'styled-components/macro'

const Outer = styled.div`
  position: relative;
  width: 50px;
  height: 48px;
  background-color: rgba(65, 0, 245, 0.3);
  border-radius: 4px;
  margin-right: 25px;
  display: inline-block;
  vertical-align: middle;
`

export default Outer
