import styled from 'styled-components/macro'

const Title = styled.h1`
  font-weight: 600;
  line-height: 65px;
  font-size: 60px;
  font-family: Gotham;
  letter-spacing: -2px;
  color: #00023f;
  margin-bottom: 35px;
  @media (max-width: 835px) {
    margin-bottom: 60px;
  }
  @media (max-width: 750px) {
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -1.73px;
  }
`

export default Title
