import styled from 'styled-components/macro'

const DotsOuter = styled.div`
  display: inline-block;
  width: 20px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  height: ${({ height }) => height}px;
`

export default DotsOuter
