/* eslint no-multi-assign: 0 */
import React, { useMemo } from 'react'
import { map, addIndex, reduce, path } from 'ramda'
import mortgageTypeToPublicTitle from 'domains/shared/utils/mortgageTypeToPublicTitle'
import Outer from './styled/Outer'
import PeerMatch from './components/PeerMatch'
import Persona from './components/Persona'
import Row from './styled/Row'
import Item from './styled/Item'
import Divider from './styled/Divider'
import PeerMatchOuter from './styled/PeerMatchOuter'
import PersonaOuter from './styled/PersonaOuter'
import emergency from './images/emergency.svg'
import money from './images/money.svg'
import percent from './images/percent.svg'
import person from './images/person.svg'
import pulse from './images/pulse.svg'

const mapWithIndex = addIndex(map)

// id: "1013e140-e430-47b0-a2fd-03376ce9c0f2"
// isCombo: false
// loaiResultId: "78b9f10a-8176-42f7-adea-4a088e23afc3"
// loanProgramName: null
// mortgageType: "FHA"
// totalPeers: 106

// code: "p0"
// createdAt: "2019-04-23T22:43:50.694Z"
// description: "This household could be of any age, at any stage of life—what draws them together is their high debt-to-income ratio. They have little-to-no short-term savings or retirement savings, and struggle to afford monthly expenses."
// id: "7be57d74-fd46-4c78-acb5-90832256ac49"
// simpleMeaning: ""

const ringColors = [
  {
    ringColor: '#29E6D1'
  },
  {
    ringColor: '#4100F5'
  },
  {
    ringColor: '#C251CF'
  }
]

const swatchBlue = 'rgba(65, 0, 245, 0.3)'
const iconPathsMap = {
  sp0: {
    iconPath: percent,
    color: swatchBlue
  },
  sp1: {
    iconPath: person,
    color: swatchBlue
  },
  sp2: {
    iconPath: person,
    color: swatchBlue
  },
  sp3: {
    iconPath: person,
    color: swatchBlue
  },
  sp4: {
    iconPath: person,
    color: swatchBlue
  },
  sp5: {
    iconPath: money,
    color: swatchBlue
  },
  sp6: {
    iconPath: pulse,
    color: swatchBlue
  },
  sp7: {
    iconPath: pulse,
    color: swatchBlue
  },
  sp8: {
    iconPath: pulse,
    color: swatchBlue
  },
  e: {
    iconPath: emergency,
    color: '#D54E69',
    group: 1
  }
}

// const personaOverviewsExample = [
//   {
//     id: 1,
//     code: "p0",
//     description: "Just getting started. This household is young and able to handle monthly bills somewhat comfortably, but their debt-to-income could be elevated due to rapidly acquiring household items and cars, as well as expenses like rent, childcare and student loans.",
//     simpleMeaning: "Very High Debt"
//   },
//   {
//     id: 2,
//     code: "sp0",
//     description: "",
//     simpleMeaning: "Caution DTI"
//   },
//   {
//     id: 3,
//     code: "sp1",
//     description: "",
//     simpleMeaning: "Married, No Children"
//   },
//   {
//     id: 4,
//     code: "sp5",
//     description: "",
//     simpleMeaning: "Self-employed"
//   },
//   {
//     id: 5,
//     code: "sp6",
//     description: "",
//     simpleMeaning: "Young"
//   },
//   {
//     id: 6,
//     code: "E",
//     description: "",
//     simpleMeaning: "Emergency"
//   },
// ]

const emptyArr = []

function PeerMatchPersona({ loaiData }) {
  let { loaiPeerMatchProducts, personaOverviews, fullPersona } = loaiData

  loaiPeerMatchProducts = loaiPeerMatchProducts || emptyArr

  const rings = useMemo(() => {
    const total = reduce(
      (acc, { totalPeers }) => acc + totalPeers,
      0,
      loaiPeerMatchProducts
    )

    return mapWithIndex(({ id, isCombo, mortgageType, totalPeers }, idx) => {
      const title = mortgageTypeToPublicTitle({ mortgageType, isCombo })
      return {
        id,
        total: totalPeers,
        percent: (totalPeers / total) * 100,
        title,
        ...ringColors[idx]
      }
    }, loaiPeerMatchProducts.slice(0, 3))
  }, [loaiPeerMatchProducts])

  const persona = (personaOverviews && personaOverviews[0]) || {}
  const personaName = useMemo(() => (fullPersona || 'Unknown').toUpperCase(), [
    fullPersona
  ])

  const personaLabels = useMemo(() => {
    const sps = (personaOverviews || []).slice(1)
    return sps.map(({ id, simpleMeaning, code }) => {
      code = (code || '').toLowerCase()
      return {
        id,
        title: simpleMeaning,
        iconPath: path([code, 'iconPath'], iconPathsMap),
        color: path([code, 'color'], iconPathsMap),
        group: path([code, 'group'], iconPathsMap)
      }
    })
  }, [personaOverviews])

  const personaContent = persona.description
    ? persona.description
    : 'Persona could not be computed.'

  const personaTopLabel = persona.simpleMeaning ? persona.simpleMeaning : null

  return (
    <Outer>
      <Row>
        <Item>
          <PeerMatchOuter>
            <PeerMatch rings={rings} />
          </PeerMatchOuter>
        </Item>
        <Divider />
        <Item>
          <PersonaOuter>
            <Persona
              personaName={personaName}
              personaContent={personaContent}
              personaTopLabel={personaTopLabel}
              personaLabels={personaLabels}
            />
          </PersonaOuter>
        </Item>
      </Row>
    </Outer>
  )
}

export default PeerMatchPersona
