import { gql } from 'graphql.macro'
import LoanPurposeFragment from './LoanPurposeFragment'

export default gql`
  ${LoanPurposeFragment}
  {
    loanPurposes {
      ...LoanPurposeFragment
    }
  }
`
