import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Ring = styled(animated.div)`
  border-radius: 50%;
  border: 1px solid #78b9fc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default Ring
