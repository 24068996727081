import React from 'react'
import Outer from './styled/Outer'
import Content from './styled/Content'
import Header from './components/Header'

export default function SimpleHeaderSidebarLayout({
  children,
  rightContentComponent
}) {
  return (
    <Outer>
      <Header rightContentComponent={rightContentComponent} />
      <Content>{children}</Content>
    </Outer>
  )
}
