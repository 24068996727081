import React, { memo, Fragment } from 'react'
import { useData as useDroppableData } from 'lib/Droppable'
import { allPass, has, propEq } from 'ramda'

export default memo(function FullScreenDropDisplayer() {
  const { data } = useDroppableData()
  let items = Object.values(data).filter(
    allPass([has('render'), propEq('FULL_SCREEN', true)])
  )
  return items.map(item => <Fragment key={item.id}>{item.render()}</Fragment>)
})
