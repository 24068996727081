import styled, { css } from 'styled-components/macro'

const Btn = styled.a`
  display: inline-block;
  padding: 11px 20px 10px 20px;
  border: 1px solid ${({ borderColor }) => borderColor || '#04015c'};
  min-height: 50px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#04015c'};
  border-radius: 8px;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 0.25px;
  transition: transform 0.2s;
  ${({ maxWidth }) =>
    maxWidth
      ? css`
          width: 100%;
          max-width: ${maxWidth};
        `
      : null}
  &:hover {
    transform: scale(1.05);
  }
  ${({ css: cssStyles }) => cssStyles}
`

export default Btn
