import styled from 'styled-components/macro'

const Spacer = styled.div`
  width: 98px;
  height: 1px;
  @media (max-width: 1160px) {
    width: 77px;
  }
`

export default Spacer
