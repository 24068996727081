import styled from 'styled-components/macro'

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
  margin: 6px 0 30px 0;
  opacity: 0.3;
`

export default Divider
