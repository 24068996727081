import React from "react"
import ResponsiveSvg from 'domains/BizDash/Shared/components/ResponsiveSvg'

const SvgMagGlass = props => (
  <ResponsiveSvg wRatio={16} hRatio={17} maxWidth={20} {...props}>
    <svg width="1em" height="1em" viewBox="0 0 16 17" {...props}>
      <g
        transform="translate(1 1)"
        stroke="#00023F"
        strokeWidth={1.6}
        fill="none"
        fillRule="evenodd"
      >
        <ellipse cx={6.222} cy={6.316} rx={6.222} ry={6.316} />
        <path
          d="M10.889 11.842L14 15"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </ResponsiveSvg>
);

export default SvgMagGlass;
