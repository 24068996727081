import styled from 'styled-components/macro'

const DataNumber = styled.h4`
  font-family: Gotham_Book;
  font-size: 30px;
  line-height: 30px;
  font-weight: 200;
  letter-spacing: -0.09px;
`

export default DataNumber
