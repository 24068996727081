import { useState, useEffect } from 'react'
import debounce from 'lib/debounce'

export default function useWindowResize({ debounce: debounceMs = 50 } = {}) {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () =>
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    const handleResizeDebounced = debounce(handleResize, debounceMs)

    window.addEventListener('resize', handleResizeDebounced)

    return () => {
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [debounceMs])

  return size
}
