import React from 'react'
import { Route } from 'react-router-dom'

import All from 'domains/BizDash/DisclosureQueue/Activity/All'
import Queued from 'domains/BizDash/DisclosureQueue/Activity/Queued'
import Pulled from 'domains/BizDash/DisclosureQueue/Activity/Pulled'
import Pushed from 'domains/BizDash/DisclosureQueue/Activity/Pushed'
import Downloaded from 'domains/BizDash/DisclosureQueue/Activity/Downloaded'
import Errors from 'domains/BizDash/DisclosureQueue/Activity/Errors'
import Printed from 'domains/BizDash/DisclosureQueue/Activity/Printed'

export default {
  pageNavigation: () => [
    {
      label: 'All Documents',
      route: '/disclosures/activity/all'
    },
    {
      label: 'Queued',
      route: '/disclosures/activity/queued'
    },
    {
      label: 'Pulled',
      route: '/disclosures/activity/pulled'
    },
    {
      label: 'Pushed',
      route: '/disclosures/activity/pushed'
    },
    {
      label: 'Downloaded',
      route: '/disclosures/activity/downloaded'
    },
    {
      label: 'Printed',
      route: '/disclosures/activity/printed'
    },
    {
      label: 'Errors',
      route: '/disclosures/activity/errors'
    }
  ],
  routes: url => [
    <Route exact path={`${url}/all`} component={All} key={`${url}/all`} />,
    <Route
      exact
      path={`${url}/queued`}
      component={Queued}
      key={`${url}/queued`}
    />,
    <Route
      exact
      path={`${url}/pulled`}
      component={Pulled}
      key={`${url}/pulled`}
    />,
    <Route
      exact
      path={`${url}/pushed`}
      component={Pushed}
      key={`${url}/pushed`}
    />,
    <Route
      exact
      path={`${url}/downloaded`}
      component={Downloaded}
      key={`${url}/downloaded`}
    />,
    <Route
      exact
      path={`${url}/errors`}
      component={Errors}
      key={`${url}/errors`}
    />,
    <Route
      exact
      path={`${url}/printed`}
      component={Printed}
      key={`${url}/printed`}
    />
  ]
}
