import React from 'react'
import AppRouter from 'domains/shared/AppRouter'
import GlobalStyle from 'domains/shared/GlobalStyle'
import CurrentUserProvider from 'domains/CurrentUser/providers/CurrentUserProvider'
import {
  NotificationsProvider,
  NotificationsDisplayer
} from 'lib/notifications'
import { Provider as DroppableProvider } from 'lib/Droppable'
import FullScreenDropDisplayer from 'lib/Droppable/components/FullScreenDropDisplayer'
import { DropdownsProvider, DropdownsDisplayer } from 'lib/DropdownsManager'

export default function App() {
  return (
    <>
      <DropdownsProvider>
        <DroppableProvider>
          <NotificationsProvider>
            <CurrentUserProvider>
              <GlobalStyle>
                <AppRouter />
              </GlobalStyle>
            </CurrentUserProvider>
            <NotificationsDisplayer />
            <DropdownsDisplayer />
          </NotificationsProvider>
          <FullScreenDropDisplayer />
        </DroppableProvider>
      </DropdownsProvider>
    </>
  )
}
