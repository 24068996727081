import React, { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'

const InputLabel = styled.label`
  display: block;
`

const InputWrapper = styled.div``

const FieldWrapper = styled.div`
  margin: 0 10px 10px 0;
  flex: 0 0 auto;
  width: 100%;
  max-width: 300px;
`

const StyledToggle = styled.input``

const InputField = ({ input, label, required, focus }) => {
  const inputElement = useRef(null)

  useEffect(() => {
    if (focus) inputElement.current.focus()
  }, [])

  return (
    <FieldWrapper>
      {label && (
        <InputLabel>
          {label} {required && '(Required)'}
        </InputLabel>
      )}
      <InputWrapper>
        <StyledToggle
          type="checkbox"
          id={input.name}
          checked={input.value}
          onChange={input.onChange}
        />
      </InputWrapper>
    </FieldWrapper>
  )
}

export default InputField
