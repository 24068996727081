import React, { useCallback } from 'react'
import { map } from 'ramda'
import nanoid from 'nanoid'
import Dropzone from '../Shared/Dropzone'
import useUploadDocs from '../graphql/hooks/useUploadDocs'

const assocRandomId = obj => ({ file: obj, id: nanoid() })

const testUploadForm = () => {
  const { fileUploadStatus, uploadDocs } = useUploadDocs(1)
  const onDropFiles = useCallback(files => {
    const uploads = map(assocRandomId, files)
    uploadDocs({ uploads })
  }, [])

  return (
    <div>
      {Object.entries(fileUploadStatus).map(([k, { status, progress }]) => (
        <p key={k}>
          {k}: {status} - {progress * 100}%
        </p>
      ))}
      <Dropzone onDropFiles={onDropFiles} />
    </div>
  )
}

export default testUploadForm
