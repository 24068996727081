import { useCallback, useRef, useEffect } from 'react'

export default function useBrowseFiles({ onReceiveFiles }) {
  const inputRef = useRef()

  let input = inputRef.current
  // only create the input once since we are
  // literally just using it as a trigger
  if (!input) {
    input = document.createElement('input')
    inputRef.current = input
    input.setAttribute('type', 'file')
    input.setAttribute('multiple', 'multiple')
  }

  const handleOnChange = e => {
    // first, extract files from event because
    // the browser deletes the files from the
    // e.target.files array if we assign empty string
    // to input.value
    const files = [...e.target.files]
    inputRef.current.value = ''
    onReceiveFiles && onReceiveFiles(files, { dropped: false })
  }

  // make sure to change the event handler on the input if developer
  // provides a different one
  useEffect(() => {
    inputRef.current.addEventListener('change', handleOnChange)
    return () => inputRef.current.removeEventListener('change', handleOnChange)
  }, [onReceiveFiles])

  const browseFiles = useCallback(() => {
    inputRef.current.click()
  }, [])

  return {
    browseFiles
  }
}
