import React, { useState, useEffect, useCallback } from 'react'
import { compose } from 'ramda'
import ThankYou from '../../components/ThankYou'
import useCurrentLoanUploader from '../withUploadCodeLogin/useable/useCurrentLoanUploader'

function withUploadThankYou(Child) {
  return function UploadThankYou(props) {
    const [logoutVisible, setLogoutVisible] = useState(false)
    const [hasLoggedIn, setHasLoggedIn] = useState(false)
    const { verified } = useCurrentLoanUploader()

    useEffect(() => {
      if (!verified && hasLoggedIn) {
        setLogoutVisible(true)
      }
      if (verified) {
        setHasLoggedIn(true)
        setLogoutVisible(false)
      }
    }, [verified])

    const onLoginClick = useCallback(() => {
      setLogoutVisible(false)
    }, [])

    if (logoutVisible) {
      return <ThankYou onLoginClick={onLoginClick} />
    }

    return <Child {...props} />
  }
}

export default compose(withUploadThankYou)
