import styled from 'styled-components/macro'
import { animated } from 'react-spring'

const Underlay = styled(animated.div)`
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
`

export default Underlay
