import React from 'react'

export default function TwitterIcon() {
  return (
    <svg
      width="15px"
      height="14px"
      viewBox="0 0 15 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-71.000000, -1.000000)" fill="#00023F">
          <g>
            <g transform="translate(71.389985, 1.894737)">
              <g transform="translate(0.015562, 0.074303)">
                <path d="M13.016734,3.08762848 C13.0230784,3.2220805 13.0257974,3.35557895 13.0257974,3.49193808 C13.0257974,7.62848297 10.0330905,12.398192 4.56066047,12.398192 C2.8794154,12.398192 1.31689977,11.8804087 0,10.9916904 C0.233833546,11.0193437 0.469479754,11.0346006 0.709657621,11.0346006 C2.10450191,11.0346006 3.38605475,10.5358885 4.40386511,9.69580186 C3.1032793,9.66910217 2.00389911,8.76417337 1.62595884,7.52168421 C1.80722515,7.55696594 1.99302313,7.57794427 2.18516542,7.57794427 C2.45706489,7.57794427 2.71990105,7.5388483 2.9682359,7.46828483 C1.60873854,7.17935604 0.581864869,5.91493498 0.581864869,4.39877399 L0.581864869,4.35967802 C0.984276087,4.59425387 1.44197353,4.73442724 1.93048625,4.75063777 C1.13200813,4.18994427 0.607242153,3.23066254 0.607242153,2.14551084 C0.607242153,1.57146749 0.754067868,1.03365944 1.00965337,0.571182663 C2.47609785,2.46590712 4.66942026,3.71125697 7.14189279,3.84189474 C7.09113822,3.61304025 7.0648546,3.37465015 7.0648546,3.12863158 C7.0648546,1.39982663 8.39625568,0 10.0403412,0 C10.8950118,0 11.669019,0.379517028 12.2110053,0.986934985 C12.888035,0.84676161 13.5260924,0.586439628 14.1007066,0.226947368 C13.877749,0.959281734 13.4073629,1.57146749 12.7928701,1.958613 C13.3946743,1.88423529 13.9683822,1.71545511 14.5013052,1.46752941 C14.1025193,2.09497214 13.5985989,2.64613003 13.016734,3.08762848" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
