import React, { createContext, useContext } from 'react'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'

// eslint-disable-next-line import/no-mutable-exports
let UrlMemoryProvider
// eslint-disable-next-line import/no-mutable-exports
let Router

if (process.env.NODE_ENV === 'test') {
  const Context = createContext()
  const { Provider } = Context

  UrlMemoryProvider = function MemoryProvider({ children, value }) {
    return <Provider value={value}>{children}</Provider>
  }

  Router = function TheRouter({ children }) {
    const { initialEntries } = useContext(Context) || {}
    return (
      <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
    )
  }
} else {
  Router = BrowserRouter
}

export { UrlMemoryProvider }

export default Router
