import React, { useEffect } from 'react'
import { InfoNotification } from 'components/Notifications'
import uploadIconPath from 'components/Notifications/images/upload-icon.png'
import { isNil } from 'ramda'
import NotificationProgressBar from '../NotificationProgressBar'

function addSizeUnits(bytes) {
  if (bytes >= 1000000000) {
    return `${(bytes / 1000000000).toFixed(1)} GB`
  }
  if (bytes >= 1000000) {
    return `${(bytes / 1000000).toFixed(1)} MB`
  }
  if (bytes >= 1000) {
    return `${Math.floor(bytes / 1000)} KB`
  }
  return `${bytes} B`
}

function addTimeUnits(seconds) {
  if (seconds < 60) {
    return `${seconds} Seconds`
  }

  if (seconds < 3600) {
    let sec = ''
    if (seconds < 120) {
      sec = ` ${seconds - 60} sec`
    }
    return `${Math.floor(seconds / 60)} min${sec}`
  }
  if (seconds < 86400) {
    const hours = seconds / 3600
    const minutes = (hours % 1) * 60
    return `${Math.floor(hours)} h ${Math.floor(minutes)} min`
  }
  return 'At least a day...'
}

function getErrorMessageIfErrors(errors) {
  return errors ? `(${errors} files have errors)` : ''
}

export default function ProgressNotification({
  uploadsComplete,
  totalFilesWorkingOrSuccess,
  currentBytesWorkingOrSuccess,
  totalBytesWorkingOrSuccess,
  timeRemaining,
  totalFiles,
  totalFilesError,
  ...restProps
}) {
  const { dismissingNote, id } = restProps
  const transitionDurationMs = 1300
  useEffect(() => {
    let t
    if (uploadsComplete) {
      t = setTimeout(() => {
        t = null
        dismissingNote(id)
      }, transitionDurationMs)
    }
    return () => t && clearTimeout(t)
  }, [uploadsComplete, transitionDurationMs])

  // animate progress bar back to zero if all files errored out
  currentBytesWorkingOrSuccess =
    uploadsComplete && totalFilesError === totalFiles
      ? 0
      : currentBytesWorkingOrSuccess

  return (
    <InfoNotification
      {...restProps}
      stretchToMaxWidth="353px"
      textPaddingRight="30px"
      mainText={`Uploading ${totalFilesWorkingOrSuccess} Files`}
      subtext={
        <>
          {addSizeUnits(currentBytesWorkingOrSuccess)} of{' '}
          {addSizeUnits(totalBytesWorkingOrSuccess)}
          <span> &#8226; </span>
          {uploadsComplete
            ? `Done! ${getErrorMessageIfErrors(totalFilesError)}`
            : timeRemaining === Infinity || isNil(timeRemaining)
            ? 'Calculating...'
            : timeRemaining === 0
            ? 'Finalizing....'
            : `${addTimeUnits(timeRemaining)} ${getErrorMessageIfErrors(
                totalFilesError
              )}`}
        </>
      }
      renderContent={() => (
        <NotificationProgressBar
          currentBytesWorkingOrSuccess={currentBytesWorkingOrSuccess}
          totalBytesWorkingOrSuccess={totalBytesWorkingOrSuccess}
          transitionDuration={`${transitionDurationMs}ms`}
        />
      )}
      iconPath={uploadIconPath}
    />
  )
}
