/* eslint no-multi-assign: 0 */
/* eslint no-await-in-loop: 0 */
import { useReducer, useEffect, useRef } from 'react'
import { useSpring } from 'react-spring'
import easing from 'lib/easing'
import delay from 'lib/delay'
import { mergeDeepRight, isNil, reject } from 'ramda'
import createCancellableTimelineAsync from 'lib/createCancellableTimelineAsync'

const filterUndefinedKeys = reject(isNil)

const globalIncRingAndText = -10

function createAction(dispatch, type) {
  return payload => {
    dispatch({ type, payload })
  }
}

function animationReducer(state, action) {
  switch (action.type) {
    case 'updateStep':
      return mergeDeepRight(state, action.payload)
    default:
      throw new Error()
  }
}

function init() {
  return {
    smallRingAndTextStep: 0,
    ringStep: 0,
    innerGlowStep: 0,
    headerBarStep: 0,
    networkPatternStep: 0,
    mainTitleStep: 0,
    dataContentStep: 0
  }
}

function useAnimations({ windowInnerWidth, allowProgressToGreeting }) {
  const [state, dispatch] = useReducer(animationReducer, {}, init)
  const allowProgressToGreetingRef = useRef()
  allowProgressToGreetingRef.current = allowProgressToGreeting
  const {
    smallRingAndTextStep,
    ringStep,
    innerGlowStep,
    headerBarStep,
    networkPatternStep,
    mainTitleStep,
    dataContentStep
  } = state

  const updateStep = createAction(dispatch, 'updateStep')

  // console.log(innerGlowStep)

  async function runAllSteps({ call }) {
    async function runSmallRingAndText() {
      await call(updateStep, { smallRingAndTextStep: 0 })
      await call(delay, 2000)
      while (!allowProgressToGreetingRef.current) {
        await call(delay, 100)
      }
      await call(updateStep, { smallRingAndTextStep: 1 })
      await call(delay, 230)
      await call(updateStep, { smallRingAndTextStep: 2 })
      // time to finish slow-to-rest animation
      await call(delay, 1200)
      // time to wait while no animation
      await call(delay, 1500)
      await call(updateStep, { smallRingAndTextStep: 3 })
      // console.log('at end')
    }

    async function runRing() {
      await call(updateStep, { ringStep: 0 })
      await call(delay, 1000)
      await call(updateStep, { ringStep: 1 })
      await call(delay, 230)
      await call(updateStep, { ringStep: 2 })
      await call(delay, 1200)

      // Ring Bounce until ready
      while (!allowProgressToGreetingRef.current) {
        await call(updateStep, { ringStep: 3 })
        await call(delay, 1100)
        await call(updateStep, { ringStep: 4 })
        await call(delay, 1000)
      }

      // Ring Bounce
      await call(updateStep, { ringStep: 3 })
      await call(delay, 1100)
      await call(updateStep, { ringStep: 4 })
      await call(delay, 1000)

      await call(updateStep, { ringStep: 5 })
      await call(delay, 800)
    }

    async function runInnerGlow() {
      // do nothing yet
      await call(updateStep, { innerGlowStep: 0 })
      await call(delay, 2430)
      // wat for first step
      await call(delay, 1600)

      // Glow Bounce
      await call(updateStep, { innerGlowStep: 1 })
      // glow earlier than ring retraction at 1100
      await call(delay, 500)
      await call(updateStep, { innerGlowStep: 2 })
      await call(delay, 800)
      await call(updateStep, { innerGlowStep: 3 })
      await call(delay, 800)

      while (!allowProgressToGreetingRef.current) {
        // Glow Bounce again until ready
        await call(updateStep, { innerGlowStep: 1 })
        // glow earlier than ring retraction at 1100
        await call(delay, 500)
        await call(updateStep, { innerGlowStep: 2 })
        await call(delay, 800)
        await call(updateStep, { innerGlowStep: 3 })
        await call(delay, 800)
      }
    }

    async function runHeaderBar() {
      // do nothing yet, start hidden
      await call(updateStep, { headerBarStep: 0 })
      while (!allowProgressToGreetingRef.current) {
        await call(delay, 100)
      }
      await call(delay, 5600)
      // slide in from top, fade in opacity
      await call(updateStep, { headerBarStep: 1 })
    }

    async function runNetworkPattern() {
      // do nothing yet, start hidden
      await call(updateStep, { networkPatternStep: 0 })
      while (!allowProgressToGreetingRef.current) {
        await call(delay, 100)
      }
      await call(delay, 2900)
      // fade in opacity of pattern while ring is in center
      await call(updateStep, { networkPatternStep: 1 })
    }

    async function runMainTitle() {
      // do nothing yet, start hidden
      await call(updateStep, { mainTitleStep: 0 })
      while (!allowProgressToGreetingRef.current) {
        await call(delay, 100)
      }
      await call(delay, 5500)
      // fade in opacity of pattern while ring is in center
      await call(updateStep, { mainTitleStep: 1 })
    }

    async function runDataContent() {
      // do nothing yet, start hidden
      await call(updateStep, { dataContentStep: 0 })
      while (!allowProgressToGreetingRef.current) {
        await call(delay, 100)
      }
      await call(delay, 5600)
      // fade in opacity of pattern while ring is in center
      await call(updateStep, { dataContentStep: 1 })
    }

    try {
      await Promise.all([
        runSmallRingAndText(),
        runRing(),
        runInnerGlow(),
        runHeaderBar(),
        runNetworkPattern(),
        runMainTitle(),
        runDataContent()
      ])
    } catch (e) {
      if (e.message === 'CANCELLED') {
        // console.log('handled cancelled!')
        return
      }
      throw e
    }
  }

  const cancellableAsyncRef = useRef()
  const cancellableRunAllSteps = createCancellableTimelineAsync(
    runAllSteps,
    cancellableAsyncRef
  )

  useEffect(() => {
    cancellableRunAllSteps()
  }, [])

  let ringSmallOpacity
  let ringSmallTransY
  let ringSmallEasing = easing.linear
  let ringSmallDuration

  let introTextTransY
  let introTextOpacity
  let introTextEasing = easing.linear
  let introTextDuration

  if (smallRingAndTextStep === 0) {
    ringSmallTransY = -22
    ringSmallOpacity = 1
    ringSmallEasing = easing.linear

    introTextTransY = 30
    introTextOpacity = 0
    introTextEasing = easing.linear
  } else if (smallRingAndTextStep === 1) {
    ringSmallEasing = easing.linear
    introTextEasing = easing.linear
    introTextOpacity = 1
    ringSmallDuration = 230
    introTextDuration = 230

    if (windowInnerWidth > 400) {
      ringSmallTransY = -62
      introTextTransY = 3
    } else {
      ringSmallTransY = -30
      introTextTransY = 20
    }
  } else if (smallRingAndTextStep === 2) {
    ringSmallEasing = easing.easeOutCubic
    ringSmallDuration = 1200

    introTextOpacity = 1
    introTextEasing = easing.easeOutCubic
    introTextDuration = 1000

    if (windowInnerWidth > 400) {
      ringSmallTransY = -102
      introTextTransY = -27
    } else {
      ringSmallTransY = -70
      introTextTransY = -10
    }
  } else if (smallRingAndTextStep === 3) {
    ringSmallOpacity = 0
    ringSmallEasing = easing.easeOutCubic
    ringSmallDuration = 900

    introTextOpacity = 0
    introTextEasing = easing.easeOutCubic
    introTextDuration = 900
  }

  let ringTop
  let ringTransY
  let ringWidth
  let ringHeight
  let ringOpacity
  let ringBorderWidth
  let ringEasing = easing.easeOutCubic
  let ringDuration

  if (ringStep === 0) {
    // percent
    ringTop = 50
    // percent
    ringTransY = -50
    // px
    ringWidth = ringHeight = 44
    ringOpacity = 0
    ringEasing = easing.linear
    ringBorderWidth = 1
    ringEasing = easing.easeOutCubic
  } else if (ringStep === 1) {
    // EXPAND RING FAST
    // percent
    ringTop = 50
    // percent
    ringTransY = -50
    // px
    ringWidth = ringHeight = 300
    ringOpacity = 1
    ringEasing = easing.linear
    ringDuration = 230
  } else if (ringStep === 2) {
    // EXPAND RING FURTHER SLOW
    // percent
    ringTop = 50
    // percent
    ringTransY = -50
    // px

    // desktop
    if (windowInnerWidth > 1200) {
      ringWidth = ringHeight = 670
    } else {
      ringWidth = ringHeight = 542
    }

    ringOpacity = 1
    ringEasing = easing.easeOutCubic
    ringDuration = 1750
  } else if (ringStep === 3) {
    // EXPAND RING (START PULSE)
    // percent
    ringTop = 50
    // percent
    ringTransY = -50

    // desktop
    if (windowInnerWidth > 1200) {
      ringWidth = ringHeight = 700
    } else {
      ringWidth = ringHeight = 572
    }

    ringOpacity = 1
    ringEasing = easing.easeOutCubic
    ringDuration = 1200
  } else if (ringStep === 4) {
    // CONTRACT RING (END PULSE)

    // percent
    ringTop = 50
    // percent
    ringTransY = -50
    // px
    // desktop
    if (windowInnerWidth > 1200) {
      ringWidth = ringHeight = 670
    } else {
      ringWidth = ringHeight = 542
    }

    ringOpacity = 1
    ringEasing = easing.easeInOutCubic
    ringDuration = 1000
  } else if (ringStep === 5) {
    // EXPAND RING AND MOVE TO TOP
    // percent
    ringTop = 0
    // percent
    ringTransY = -78
    // px
    ringOpacity = 1
    ringBorderWidth = 2

    // px
    if (windowInnerWidth > 1200) {
      // desktop
      ringTransY = -78
      ringWidth = ringHeight = 950
    } else if (windowInnerWidth > 768) {
      // tablet
      ringTransY = -73
      ringWidth = ringHeight = 655
    } else {
      // mobile (get bigger again)
      ringTransY = -55
      ringWidth = ringHeight = 819
    }

    ringEasing = easing.easeInOutCubic
    ringDuration = 1600
  }

  let innerGlowOpacity
  let innerGlowEasing = easing.easeInOutQuad
  let innerGlowDuration

  if (innerGlowStep === 0) {
    innerGlowOpacity = 0.6
    innerGlowEasing = easing.easeInOutCubic
  } else if (innerGlowStep === 1) {
    innerGlowOpacity = 0.9
    innerGlowEasing = easing.easeInOutCubic
    innerGlowDuration = 600
  } else if (innerGlowStep === 2) {
    innerGlowOpacity = 0.6
    innerGlowEasing = easing.easeInOutCubic
    innerGlowDuration = 900
  } else if (innerGlowStep === 3) {
    innerGlowOpacity = 0.9
    innerGlowEasing = easing.easeInOutCubic
    innerGlowDuration = 1000
  }

  let headerBarOpacity
  let headerBarTransY
  const headerBarEasing = easing.easeOutQuint
  let headerBarDuration

  if (headerBarStep === 0) {
    headerBarOpacity = 0
    headerBarTransY = -200
  } else if (headerBarStep === 1) {
    headerBarOpacity = 1
    headerBarTransY = 0
    headerBarDuration = 800
  }

  let networkPatternOpacity
  const networkPatternEasing = easing.easeInOutQuint
  let networkPatternDuration

  if (networkPatternStep === 0) {
    networkPatternOpacity = 0
  } else if (networkPatternStep === 1) {
    networkPatternOpacity = 1
    networkPatternDuration = 2000
  }

  let mainTitleOpacity
  let mainTitleTransY
  // const mainTitleEasing = easing.easeOutCubic
  let mainTitleDuration

  if (mainTitleStep === 0) {
    mainTitleOpacity = 0
    // px
    mainTitleTransY = 200
  } else if (mainTitleStep === 1) {
    mainTitleOpacity = 1
    // px
    mainTitleTransY = 0
    mainTitleDuration = 600
  }

  let dataContentOpacity
  let dataContentTransY
  // const dataContentEasing = easing.easeOutCubic
  let dataContentDuration

  if (dataContentStep === 0) {
    dataContentOpacity = 0
    // px
    dataContentTransY = 200
  } else if (dataContentStep === 1) {
    dataContentOpacity = 1
    // px
    dataContentTransY = 0
    dataContentDuration = 700
  }

  function composeRingSmall() {
    return filterUndefinedKeys({
      transform: isNil(ringSmallTransY)
        ? undefined
        : `translate(-50%, ${globalIncRingAndText + ringSmallTransY}px)`,
      opacity: ringSmallOpacity,
      config: {
        easing: ringSmallEasing,
        duration: ringSmallDuration
      }
    })
  }

  function composeIntroText() {
    return filterUndefinedKeys({
      transform: isNil(introTextTransY)
        ? undefined
        : `translate(-50%, ${globalIncRingAndText + introTextTransY}px)`,
      opacity: introTextOpacity,
      config: {
        easing: introTextEasing,
        duration: introTextDuration
      }
    })
  }

  function composeRing() {
    return filterUndefinedKeys({
      top: isNil(ringTop) ? undefined : `${ringTop}%`,
      transform: isNil(ringTransY)
        ? undefined
        : `translate(-50%, ${ringTransY}%)`,
      rawWidth: ringWidth,
      width: isNil(ringWidth) ? undefined : `${ringWidth}px`,
      height: isNil(ringHeight) ? undefined : `${ringHeight}px`,
      borderWidth: isNil(ringBorderWidth) ? undefined : `${ringBorderWidth}px`,
      opacity: ringOpacity,
      config: {
        easing: ringEasing || easing.easeOutCubic,
        duration: ringDuration
      }
    })
  }

  function composeInnerGlow() {
    return filterUndefinedKeys({
      opacity: innerGlowOpacity,
      config: {
        easing: innerGlowEasing || easing.easeOutCubic,
        duration: innerGlowDuration
      }
    })
  }

  function composeHeaderBar() {
    return filterUndefinedKeys({
      transform: isNil(headerBarTransY)
        ? undefined
        : `translate(0, ${headerBarTransY}%)`,
      opacity: headerBarOpacity,
      config: {
        easing: headerBarEasing || easing.easeOutCubic,
        duration: headerBarDuration
      }
    })
  }

  function composeNetworkPattern() {
    return filterUndefinedKeys({
      opacity: networkPatternOpacity,
      config: {
        easing: networkPatternEasing || easing.easeOutCubic,
        duration: networkPatternDuration
      }
    })
  }

  // css3 animations for smoothness
  function composeMainTitle() {
    return filterUndefinedKeys({
      transform: isNil(mainTitleTransY)
        ? undefined
        : `translate(0, ${mainTitleTransY}px)`,
      opacity: mainTitleOpacity,
      config: {
        easing: 'ease-out',
        duration: mainTitleDuration
      }
    })
  }

  // css3 animations for smoothness
  function composeDataContent() {
    return filterUndefinedKeys({
      transform: isNil(dataContentTransY)
        ? undefined
        : `translate(0, ${dataContentTransY}px)`,
      opacity: dataContentOpacity,
      config: {
        easing: 'ease-out',
        duration: dataContentDuration
      }
    })
  }

  const [ringSmallStyles, setRingSmall] = useSpring(composeRingSmall)
  const [introTextStyles, setIntroText] = useSpring(composeIntroText)
  const [ringStyles, setRing] = useSpring(composeRing)
  const [innerGlowStyles, setInnerGlow] = useSpring(composeInnerGlow)
  const [headerBarStyles, setHeaderBar] = useSpring(composeHeaderBar)
  const [networkPatternStyles, setNetworkPattern] = useSpring(
    composeNetworkPattern
  )
  const mainTitleStyles = composeMainTitle()
  const dataContentStyles = composeDataContent()

  useEffect(() => {
    setRingSmall(composeRingSmall())
    setIntroText(composeIntroText())
    setRing(composeRing())
    setInnerGlow(composeInnerGlow())
    setHeaderBar(composeHeaderBar())
    setNetworkPattern(composeNetworkPattern())
    // these happen inherently
    // setMainTitle(composeMainTitle())
    // setDataContent(composeDataContent())
  }, [
    windowInnerWidth,
    smallRingAndTextStep,
    ringStep,
    innerGlowStep,
    headerBarStep,
    networkPatternStep
    // mainTitleStep,
    // dataContentStep
  ])

  function restart() {
    cancellableRunAllSteps.cancel()
    const run = createCancellableTimelineAsync(runAllSteps, cancellableAsyncRef)
    run()
  }

  function cancel() {
    cancellableRunAllSteps.cancel()
  }

  return {
    restart,
    cancel,
    updateStep,
    ringSmallStyles,
    introTextStyles,
    ringStyles,
    innerGlowStyles,
    headerBarStyles,
    networkPatternStyles,
    mainTitleStyles,
    dataContentStyles,
    ...state
  }
}

export default useAnimations
