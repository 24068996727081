/* eslint no-unused-expressions: 0 */
import React from 'react'
import Outer from './styled/Outer'

export {
  default as CloseBtnTopRightOuter
} from './styled/CloseBtnTopRightOuter'

export default function CloseBtn({ onClick }) {
  const handleClick = e => {
    e.preventDefault()
    onClick && onClick()
  }
  return (
    <Outer onClick={handleClick}>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.297874814"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1374.000000, -43.000000)"
            stroke="#FFFFFF"
            strokeWidth="2"
          >
            <g transform="translate(1375.000000, 44.000000)">
              <path d="M16,0 L0,16" />
              <path d="M0,0 L16,16" />
            </g>
          </g>
        </g>
      </svg>
    </Outer>
  )
}
