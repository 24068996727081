import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import SimpleBtn from 'domains/BizDash/Shared/components/SimpleBtn'

import LoanOfficerForm from '../../LoanOfficerForm'
import useMutateLoanOfficer from '../../graphql/hooks/useMutateLoanOfficer'

import missingImgSrc from './images/missing.png'
import { loanOfficerFormValidation } from '../../validation'

const LoanOfficerListItemContainer = styled.div`
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: purple;
  margin-right: 20px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
`

const Label = styled.div`
  flex: 1 1 auto;
`

const copyStringToClipboard = str => {
  // Create new element
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = str
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

const onDeleteLoanOfficer = (id, deleteLoanOfficerMutation) =>
  deleteLoanOfficerMutation({ variables: { id } })

const LoanOfficerListItem = ({ loanOfficer, onSubmit }) => {
  const [isEditingItem, setIsEditingItem] = useState(false)
  const {
    // loading,
    // errors,
    delete: deleteLoanOfficer
  } = useMutateLoanOfficer()

  const onEditClick = useCallback(() => setIsEditingItem(true), [])
  const onCancelClick = useCallback(() => setIsEditingItem(false), [])
  const onDeleteClick = useCallback(
    () => onDeleteLoanOfficer(loanOfficer.id, deleteLoanOfficer),
    []
  )
  const onCopyLinkClick = useCallback(() => {
    copyStringToClipboard(`https://app.lower.com/apply?lo=${loanOfficer.token}`)
  }, [])

  return (
    <LoanOfficerListItemContainer>
      {isEditingItem ? (
        <>
          <LoanOfficerForm
            loanOfficer={loanOfficer}
            validate={loanOfficerFormValidation}
            onSubmit={onSubmit}
            onCancel={onCancelClick}
          />
        </>
      ) : (
        <>
          <Avatar
            src={
              (loanOfficer.avatar && loanOfficer.avatar.thumb) || missingImgSrc
            }
          />
          <Label>{loanOfficer.name}</Label>
          <SimpleBtn onClick={onCopyLinkClick}>Copy Link</SimpleBtn>
          <SimpleBtn onClick={onEditClick}>Edit</SimpleBtn>
          <SimpleBtn onClick={onDeleteClick}>Delete</SimpleBtn>
        </>
      )}
    </LoanOfficerListItemContainer>
  )
}

export default LoanOfficerListItem
